import React from 'react';

class AttachmentError extends React.Component {
  render() {
    const { error } = this.props;
    return (
      <div className="error">
        <div>
          {` ${error.name} : ${error.description} `}
        </div>
      </div>
    );
  }
}

export default AttachmentError;
