import React from 'react';
import PropTypes from 'prop-types';

class ReceiveModal extends React.Component {
  static propTypes = {
    isOpen: PropTypes.bool,
  }

  state = {
    isOpen: false,
  }

  constructor(props) {
    super(props);

    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.saveReceives = this.saveReceives.bind(this);
  }

  openModal() {
    this.setState({
      isOpen: true,
    });
  }

  closeModal() {
    const { resetMark } = this.props;
    resetMark();
    this.setState({
      isOpen: false,
    });
  }

  saveReceives() {
    const { confirmReceiveGoods } = this.props;
    confirmReceiveGoods();
    this.closeModal();
  }

  render() {
    const { isOpen } = this.state;

    return (
      [
        <input key={0} autoComplete="off" className="modal-open" id="mark_received-modal" checked={isOpen} name="create-po-add" type="radio" value="on" />,
        <div key={1} className="modal sub-modal centered modal-s">
          <div className="modal-wrapper">
            <div className="modal-window window">
              <div className="window-header modal-header">
                {I18n.t('purchase_orders.form.mark_po_received')}
              </div>
              <div className="window-content">
                <p className="mute">
                  {I18n.t('purchase_orders.form.received_po_message')}
                </p>
              </div>
              <div className="window-footer modal-footer">
                <div className="items">
                  <label className="button button-transparent button-mute inverse link" onClick={this.closeModal}>{I18n.t('purchase_orders.form.cancel')}</label>
                  <button className="button button-primary" onClick={this.saveReceives}>{I18n.t('purchase_orders.form.mark_received')}</button>
                </div>
              </div>
            </div>
          </div>
          <label className="modal-backdrop" onClick={this.closeModal} />
        </div>,
      ]
    );
  }
}

export default ReceiveModal;
