class AutomaticMatchingModel {
  constructor(attributes) {
    this.matchViaPoNumber = attributes.attach_by_po_number || false;
    this.matchViaDescription = attributes.attach_by_description || false;
    this.matchViaProjectSupplier = attributes.attach_by_project_supplier || false;


    this.matchViaSupplierPercentageValue = attributes.attach_by_percentage_value || false;
    this.matchViaSupplierNumberValue = attributes.attach_by_value || false;

    this.supplierPercentageValue = attributes.attach_percentage_value || 0;
    this.supplierNumberValue = attributes.attach_value || 0;

    this.closeViaTotalPurchaseOrderAndInvoiceValue = attributes.close_by_value || false;
    this.closeViaReceivedValueEquality = attributes.close_equality_value || false;

    this.totalPurchaseOrderAndInvoiceValue = attributes.close_value || 0;
    this.totalPurchaseOrderAndInvoicePercentage = attributes.close_percentage_value || 0;

    this.enableLocation = attributes.enable_location || false;
    this.enableClass = attributes.enable_class || false;
    this.enableCustomer = attributes.enable_customer || false;
  }

  setCloseViaTotalPurchaseOrderAndInvoiceValue = () => {
    const toggledBooleanValue = !this.closeViaTotalPurchaseOrderAndInvoiceValue;

    this.closeViaTotalPurchaseOrderAndInvoiceValue = toggledBooleanValue;
    this.closeViaReceivedValueEquality = false;

    return this;
  }

  setCloseViaReceivedValueEquality = () => {
    const toggledBooleanValue = !this.closeViaReceivedValueEquality;

    this.closeViaReceivedValueEquality = toggledBooleanValue;
    this.closeViaTotalPurchaseOrderAndInvoiceValue = false;

    return this;
  }

  toParams = () => ({
    attach_by_po_number: this.matchViaPoNumber,
    attach_by_description: this.matchViaDescription,
    attach_by_project_supplier: this.matchViaProjectSupplier,
    attach_percentage_value: this.supplierPercentageValue,
    attach_by_value: this.matchViaSupplierNumberValue,
    attach_by_percentage_value: this.matchViaSupplierPercentageValue,
    attach_value: this.supplierNumberValue,
    close_by_value: this.closeViaTotalPurchaseOrderAndInvoiceValue,
    close_equality_value: this.closeViaReceivedValueEquality,
    close_value: this.totalPurchaseOrderAndInvoiceValue,
    close_percentage_value: this.totalPurchaseOrderAndInvoicePercentage,
    enable_location: this.enableLocation,
    enable_class: this.enableClass,
    enable_customer: this.enableCustomer,
  })
}

export default AutomaticMatchingModel;
