import React from 'react';

const Warning = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 12" {...props}>
    <path
      fill="currentColor"
      d="M.576 11.104h12.68L6.916.153l-6.34 10.95Zm6.917-1.73H6.34V8.223h1.153v1.153Zm0-2.305H6.34V4.764h1.153v2.305Z"
    />
  </svg>
);

export default Warning;
