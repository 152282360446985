import React from "react";
import { Calendar } from "react-bootstrap-icons";

export default function IconButton({
  text = "",
  icon = <Calendar />,
  onClick,
  success,
  warning,
  className,
}) {
  const textColor = () => {
    if (success) return "ct-text-green-success";
    if (warning) return "ct-text-red-warning";

    return "ct-text-gray";
  };

  return (
    <button
      className={`ct-p-2 ct-flex ct-cursor-pointer ct-text-sm ct-justify-center ct-items-center ct-w-fit ${textColor()} ct-uppercase ${className}`}
      onClick={onClick}
    >
      {icon}
      {text && <div>{text}</div>}
    </button>
  );
}
