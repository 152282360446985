import React from 'react';
import SelectCheckmark from '@/icons/SelectCheckmark';
import Close from '@/icons/Close';
import TableRowComponent from '.';
import TableCell from '../TableCell';
import TableButton from '../TableButton';
import TABLE_ROW_SIZE from './constants';

export default {
  component: TableRow,
  title: 'Tables/Regular/TableRow',
  args: {
    disableHover: false,
    size: 'SMALL',
  },
  argTypes: {
    disableHover: {
      control: 'boolean',
    },
    size: {
      control: 'radio',
      options: Object.keys(TABLE_ROW_SIZE),
    },
  },
};

export const TableRow = ({ size, ...rest }) => (
  <div style={{ width: '100%' }}>
    <table style={{ width: '100%' }}>
      <tbody>
        <TableRowComponent
          size={TABLE_ROW_SIZE[size]}
          actions={(
            <>
              <TableButton
                onClick={() => window.alert('Clicked!')} // eslint-disable-line no-alert
              >
                <SelectCheckmark />
              </TableButton>
              <TableButton
                onClick={() => window.alert('Clicked!')} // eslint-disable-line no-alert
              >
                <Close />
              </TableButton>
            </>
          )}
          {...rest}
        >
          <TableCell>2018-0012</TableCell>
          <TableCell>Test</TableCell>
          <TableCell>01.12.2020</TableCell>
          <TableCell>T&T Construction</TableCell>
          <TableCell>New budget</TableCell>
        </TableRowComponent>
      </tbody>
    </table>
  </div>
);
