import React, { useState } from 'react';
import SwitchComponent from './index';

export default {
  component: Switch,
  title: 'Design System/Buttons/Switch',
  args: {
    disabled: false,
  },
  argTypes: {
    disabled: {
      control: 'boolean',
    },
  },
};

export const Switch = ({ ...args }) => {
  const [value, setValue] = useState(false);
  return (
    <SwitchComponent
      value={value}
      onClick={() => setValue(!value)}
      disabled={args.disabled}
    />
  );
};
