import React from 'react';
import ToggleTrackingButton from './shared/toggle_tracking_button';

export default function CreditAccountInput({ automaticMatching, onClick }) {
  return (
    <div className="cell-5 offset-1 offset-right-1 t-cell-16 t-offcet-right-0 t-offset-0">
      <div className="grid space-2">
        <div className="row">
          <div className="cell-16">
            <h3 className="label">{I18n.t('settings.integrations.new.setup.add_cost_dimensions')}</h3>
          </div>
        </div>
        <div className="row">
          <div className="cell-16">
            <ToggleTrackingButton
              name="enableLocation"
              id="enable_location"
              enabled={automaticMatching.enableLocation}
              text={I18n.t('settings.integrations.new.setup.enable_location')}
              onClick={onClick}
            />
          </div>
        </div>
        <div className="row">
          <div className="cell-16">
            <ToggleTrackingButton
              name="enableClass"
              id="enable_class"
              enabled={automaticMatching.enableClass}
              text={I18n.t('settings.integrations.new.setup.enable_class')}
              onClick={onClick}
            />
          </div>
        </div>
        <div className="row">
          <div className="cell-16">
            <ToggleTrackingButton
              name="enableCustomer"
              id="enable_customer"
              enabled={automaticMatching.enableCustomer}
              text={I18n.t('settings.integrations.new.setup.enable_customer')}
              onClick={onClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
