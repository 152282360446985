import { DateTime } from 'luxon';

export const displayValue = (value) => {
  let transformedValue = '';
  const startDate = DateTime?.fromJSDate(value?.startDate) || undefined;
  const endDate = DateTime?.fromJSDate(value?.endDate) || undefined;

  if (startDate) transformedValue += startDate.toLocaleString(DateTime.DATE_SHORT);
  if (endDate && startDate.toMillis() !== endDate.toMillis()) transformedValue += ` - ${endDate.toLocaleString(DateTime.DATE_SHORT)}`;

  return transformedValue;
};

const createQuarters = dateTime => Array.from(Array(4)).map((_, index) => DateTime.fromFormat((index + 1).toString(), 'q').set({
  year: dateTime.year,
}));

export const createStaticRanges = dateTime => [
  ...createQuarters(dateTime).map((quarter, index) => ({
    label: `${index + 1}. Quarter`,
    range: () => ({
      startDate: quarter.toJSDate(),
      endDate: quarter.endOf('quarter').toJSDate(),
    }),
    isSelected() {
      return true;
    },
  })),
  {
    label: `Current Year (${dateTime.year})`,
    range: () => ({
      startDate: dateTime.startOf('year').toJSDate(),
      endDate: dateTime.endOf('year').toJSDate(),
    }),
    isSelected() {
      return true;
    },
  },
  {
    label: `Last Year (${dateTime.minus({ year: 1 }).year})`,
    range: () => ({
      startDate: dateTime.minus({ year: 1 }).startOf('year').toJSDate(),
      endDate: dateTime.minus({ year: 1 }).endOf('year').toJSDate(),
    }),
    isSelected() {
      return true;
    },
  },
];
