import React, { useState } from 'react';

import * as toast from '@/components/shared/toast';
import Modal from './shared/modal';
import PasteItemsModal from './purchase_order/modals/paste_items_modal';


export default function PasteLink({
  children, placeholder, fieldsMap, onImport, type, templateUrl, afterImport,
}) {
  const [renderPasteItemsModal, togglePasteItemsModal] = useState(false);
  const [isLoading, toggleLoading] = useState(false);

  const importAndClose = (results) => {
    toggleLoading(true);
    onImport(results).then(() => {
      if (afterImport === 'reload') {
        window.location.reload();
      }
      if (afterImport === 'close') {
        toggleLoading(false);
        togglePasteItemsModal(false);
      }
    })
      .catch((error) => {
        const { response: { status } } = error;
        if (status === 401) {
          toast.errorToast(I18n.t('commons.messages.unauthorized'));
        } else {
          toast.errorToast(I18n.t('commons.messages.error'));
        }
        toggleLoading(false);
      });
  };

  return (
    <React.Fragment>
      <span onClick={() => togglePasteItemsModal(true)}>
        { children }
      </span>
      {
        renderPasteItemsModal
          ? (
            <Modal>
              <PasteItemsModal
                type={type}
                templateUrl={templateUrl}
                onTogglePasteItemsModal={() => togglePasteItemsModal(false)}
                placeholder={placeholder}
                fieldsMap={fieldsMap}
                onImport={importAndClose}
                isLoading={isLoading}
              />
            </Modal>
          ) : null
        }
    </React.Fragment>
  );
}
