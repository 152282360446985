import React from 'react';
import { INTEGRATION_TYPES } from '../../../config/constants';

class IntegrationType extends React.Component {
  onClick = ({ target: { value } }) => {
    const { changeIntegrationScope } = this.props;

    changeIntegrationScope(value);
  }

  render() {
    const { integrationScope, integrationType, tenant } = this.props;

    return (
      <div className="cell-5 offset-1 offset-right-1 t-cell-16 t-offcet-right-0 t-offset-0">
        <div className="grid space-2">
          {
            tenant
              ? (
                <>
                  <div className="row">
                    <div className="cell-16">
                      <h3 className="label">Tenant name</h3>
                    </div>
                  </div>
                  <div className="row">
                    <div className="cell-16">
                      <div className="input disabled">
                        {tenant.name}
                        &nbsp;
                      </div>
                    </div>
                  </div>
                </>
              )
              : null
          }
          <div className="row">
            <div className="cell-16">
              <h3 className="label">{I18n.t('settings.integrations.new.setup.integration_type')}</h3>
            </div>
          </div>
          <div className="row">
            <div className="cell-16">
              <div className="items">
                {
                  [INTEGRATION_TYPES.QUICKBOOKS,
                    INTEGRATION_TYPES.XERO,
                    INTEGRATION_TYPES.SAGE_BUSINESS_CLOUD_ACCOUNTING].includes(integrationType)
                    ? null
                    : (
                      <label className="has-checkbox item">
                        <input
                          autoComplete="off"
                          id="fetch_integration"
                          type="radio"
                          value="fetch"
                          onClick={this.onClick}
                          checked={integrationScope === 'fetch'}
                        />
                        <span className="checkbox radio" />
                        <span className="label">Fetch transactions</span>
                      </label>
                    )
                }
                <label className="has-checkbox item">
                  <input
                    autoComplete="off"
                    id="post_integration"
                    type="radio"
                    value="post"
                    disabled={
                      [INTEGRATION_TYPES.QUICKBOOKS, INTEGRATION_TYPES.XERO,
                        INTEGRATION_TYPES.SAGE_BUSINESS_CLOUD_ACCOUNTING].includes(integrationType)}
                    onClick={this.onClick}
                    checked={integrationScope === 'post'}
                  />
                  <span className="checkbox radio" />
                  <span className="label">Post transactions</span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default IntegrationType;
