import uuid from 'uuid/v4';
import UserModel from './user_model';

class SupplierInvoiceCommentModel {
  constructor({
    id, user, body, action_type, approvers, supplier_invoice_number, external_doc_number, created_at,
  }) {
    this.id = id || '';
    this.user = new UserModel(user || {});
    this.actionType = action_type;
    this.body = body || '';
    this.uuid = uuid();
    this.approvers = approvers;
    this.externalDocNumber = external_doc_number;
    this.createdAt = created_at;
    this.supplierInvoiceNumber = supplier_invoice_number;
  }

  toParams = () => ({
    comment: {
      body: this.body,
    },
  })
}

export default SupplierInvoiceCommentModel;
