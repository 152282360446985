import uuid from 'uuid/v4';
import validate from 'validate.js';

class PasteItemRowValue {
  constructor(value, header, row) {
    this.uuid = uuid();
    this.value = value;
    this.header = header;
    this.row = row;
  }

  isValid = () => !this.validate()

  validate = () => {
    const { header: { field, fieldsMap } } = this;
    const constraints = {};
    Object.keys(fieldsMap).forEach((fieldMapKey) => {
      const fieldContraints = fieldsMap[fieldMapKey].constraints;
      if (fieldContraints) constraints[fieldMapKey] = fieldContraints;
    });
    const validateObject = this.row.toOrderItem();
    const validateResults = validate(validateObject, constraints, { fullMessages: false }) || {};

    return (validateResults[field] || [])[0];
  }
}

export default PasteItemRowValue;
