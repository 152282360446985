import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import IntegrationConnect from './integration_connect';
import CredentialsIntegrationConnect from './credentials_integration_connect';
import OauthIntegrationConnect from './oauth_integration_connect';
import IntegrationSetup from './integration_setup';
import IntegrationPostSetup from './integration_post_setup';
import IntegrationsService from '../../services/integrations_service';
import ConfirmationPopup from './shared/confirmation_popup';
import * as toast from '../shared/toast';
import { isCredentialType, isOauthType } from '../shared/integration_types';

class IntegrationHeader extends React.Component {
  static propTypes = {
    toggle: PropTypes.bool,
    connect: PropTypes.bool,
    openModal: PropTypes.bool,
  }

  constructor(props) {
    super(props);

    this.state = {
      toggle: false,
      connected: props.connected,
      openModal: false,
      reconnect: false,
      integrationScope: props.integrationWay,
    };
  }

  openModal = () => {
    const { connected, reconnect } = this.state;
    if (connected || reconnect) {
      this.setState({
        openModal: true,
      });
    }
  }

  onCheckboxClick = () => {
    const { connected, reconnect } = this.state;
    if (connected || reconnect) return this.openModal();
    return this.toggle();
  }

  closeModal = () => {
    this.setState({
      openModal: false,
      toggle: false,
    });
  }

  setIntegrationScope = (integrationScope) => {
    this.setState({ integrationScope });
  }

  changeIntegrationScope = (integrationScope) => {
    this.setState({ integrationScope }, () => {
      this.onSaveIntegrationWay();
    });
  }

  connect = () => {
    const { integrationConnectionSuccess } = this.props;
    this.setState({
      connected: true,
    }, () => {
      integrationConnectionSuccess();
      toast.clearWarningBox();
      toast.successToast(I18n.t('settings.integrations.common.connected'));
    });
  }

  forceDisconnect = () => {
    this.setState({
      connected: false,
      toggle: false,
      reconnect: false,
    });
  }

  onSaveIntegrationWay = () => {
    const { props: { integrationType }, state: { integrationScope } } = this;

    IntegrationsService
      .saveSettingsOnlyWay({
        integrationType,
        integrationWay: integrationScope,
      }).then(() => {
        toast.successToast(I18n.t('settings.integrations.new.setup.changes_saved'));
      }).catch(() => {
        toast.errorToast(I18n.t('settings.integrations.new.setup.error_save'));
      });
  }

  disconnect = () => {
    const { integrationsDisconnectionSuccess, isAnyOtherConnected } = this.props;
    const { connected, reconnect } = this.state;

    const { integrationType: propsIntegrationType } = this.props;
    if (connected || isAnyOtherConnected || reconnect) {
      IntegrationsService.disconnect().then(({ integrationType }) => {
        const additionalStateAtrributes = integrationType === propsIntegrationType ? { toggle: false } : {};

        this.setState({
          connected: false,
          openModal: false,
          reconnect: false,
          ...additionalStateAtrributes,
        }, () => {
          integrationsDisconnectionSuccess();
          toast.successToast(I18n.t('settings.integrations.common.disconnected'));
        });
      });
    }
  }

  edit = () => {
    this.setState({
      connected: false,
      reconnect: true,
    });
  }

  hide = () => {
    const { toggle } = this.state;
    if (toggle) this.setState({ toggle: false });
  }

  toggle = () => {
    const { props: { isAnyOtherConnected }, state: { toggle } } = this;
    if (isAnyOtherConnected) {
      this.setState({
        openModal: true,
        toggle: !toggle,
      });
    } else {
      this.setState({
        toggle: !toggle,
      });
    }
  }

  _toggleView = () => {
    const { integrationName, integrationType, updatedAt } = this.props;
    const { connected, reconnect, integrationScope } = this.state;

    if (connected) {
      if (integrationScope === 'post') {
        return (
          <IntegrationPostSetup
            integrationName={integrationName}
            integrationType={integrationType}
            updatedAt={updatedAt}
            edit={this.edit}
            hide={this.hide}
            openDisconnectModal={this.openModal}
            changeIntegrationScope={this.changeIntegrationScope}
            integrationScope={integrationScope}
          />
        );
      }
      return (
        <IntegrationSetup
          integrationName={integrationName}
          integrationType={integrationType}
          updatedAt={updatedAt}
          edit={this.edit}
          hide={this.hide}
          openDisconnectModal={this.openModal}
          changeIntegrationScope={this.changeIntegrationScope}
          integrationScope={integrationScope}
        />
      );
    }
    if (isOauthType(integrationType)) {
      return (
        <OauthIntegrationConnect
          connected={connected}
          connect={this.connect}
          reconnect={reconnect}
          integrationName={integrationName}
          integrationType={integrationType}
        />
      );
    }
    if (isCredentialType(integrationType)) {
      return (
        <CredentialsIntegrationConnect
          connected={connected}
          connect={this.connect}
          reconnect={reconnect}
          integrationName={integrationName}
          integrationType={integrationType}
          setIntegrationScope={this.setIntegrationScope}
        />
      );
    }
    return (
      <IntegrationConnect
        connected={connected}
        connect={this.connect}
        reconnect={reconnect}
        integrationName={integrationName}
        integrationType={integrationType}
      />
    );
  }

  formatDateTime = date => moment(date).format('DD/MM/YYYY')

  modalContent = () => (
    <React.Fragment>
      <h2>{I18n.t('settings.integrations.new.connect.disconnect_header')}</h2>
      <p>{I18n.t('settings.integrations.new.connect.disconnect_description')}</p>
    </React.Fragment>
  )

  render() {
    const { integrationName, updatedAt } = this.props;
    const { toggle, connected, openModal } = this.state;

    return (
      <form className="tile form-section tile-expandable" ref={this.form}>
        { openModal
          ? (
            <ConfirmationPopup
              content={this.modalContent()}
              onReject={this.closeModal}
              onConfirm={this.disconnect}
              confirmButtonType="danger"
              confirmButtonText={I18n.t('settings.integrations.new.connect.disconnect_button')}
            />
          )
          : null }
        <div className="tile-header form-section-header">
          <label className="h1 pointer" onClick={this.toggle}>
            <i className="icon-plugin" />
            &nbsp;
            {integrationName}
          </label>
          <div className="actions items">
            { updatedAt && !toggle
              ? (
                <React.Fragment>
                  <a className="link no-href">
                    <i className="icon-restore" />
                  </a>
                  <small className="link no-href">
                    {`${I18n.t('settings.integrations.new.last_updated')}: `}
                    {this.formatDateTime(updatedAt)}
                  </small>
                </React.Fragment>
              ) : null
            }
            <label className="radio-switch">
              <input id="active" readOnly onClick={this.onCheckboxClick} checked={connected || toggle} type="checkbox" />
              <span />
            </label>
          </div>
        </div>
        { toggle ? this._toggleView() : null }
      </form>
    );
  }
}

export default IntegrationHeader;
