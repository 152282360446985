import React from 'react';
import PasteItems from '../../../models/paste_items/paste_items';

import ModalWrapper from '../../shared/modal/modal_wrapper';
import ModalHeader from '../../shared/modal/modal_header';
import ModalFooter from '../../shared/modal/modal_footer';
import PasteItemsStep1 from './paste_items/paste_items_step1';
import PasteItemsStep2 from './paste_items/paste_items_step2';

class PasteItemsModal extends React.Component {
  constructor(props) {
    super(props);

    const pasteValue = '';

    this.state = {
      pasteItems: new PasteItems(pasteValue, props.fieldsMap),
      step: 1,
    };
  }

  onChangePasteValue = ({ target: { value } }) => {
    const { state: { pasteItems } } = this;

    pasteItems.pasteValue = value;
    this.setState({ pasteItems });
  }

  onContinue = () => {
    const {
      state: { step, pasteItems },
      props: { onImport },
    } = this;

    if (step === 1) {
      pasteItems.parse();
      this.setState(
        {
          pasteItems,
          step: 2,
        },
      );
    }
    if (step === 2) {
      this.setState({ step: 3 });
    }
    if (step === 3) {
      onImport(pasteItems.toOrderItems());
    }
  }

  onBack = () => {
    const { step } = this.state;
    this.setState({ step: step - 1 });
  }

  onChangeRowValue = (rowIndex, valueIndex, e) => {
    const { state: { pasteItems } } = this;
    const { target: { value } } = e;
    pasteItems.rows[rowIndex].values[valueIndex].value = value;
    this.setState({ pasteItems });
  }

  onChangeColumn = (rowIndex, entity) => {
    const { state: { pasteItems } } = this;
    const { field } = entity;
    pasteItems.headers[rowIndex].change(field);
    this.setState({ pasteItems });
  }

  onRemoveRow = (rowIndex) => {
    const { state: { pasteItems } } = this;
    pasteItems.remove(rowIndex);
    this.setState({ pasteItems });
  }


  render() {
    const {
      props: {
        onTogglePasteItemsModal,
        placeholder, isLoading, type, templateUrl,
      },
      state: {
        step, pasteItems,
      },
      onContinue, onChangePasteValue, onChangeRowValue, onChangeColumn, onBack,
      onRemoveRow,
    } = this;

    let stepComponent;

    if (step === 1) {
      stepComponent = (
        <PasteItemsStep1
          type={type}
          templateUrl={templateUrl}
          pasteItems={pasteItems}
          onChangePasteValue={onChangePasteValue}
          placeholder={placeholder}
        />
      );
    } else if (step === 2) {
      stepComponent = (
        <PasteItemsStep2
          onlyValid={false}
          pasteItems={pasteItems}
          onChangeRowValue={onChangeRowValue}
          onChangeColumn={onChangeColumn}
          onRemoveRow={onRemoveRow}
        />
      );
    } else if (step === 3) {
      stepComponent = (
        <PasteItemsStep2
          onlyValid
          pasteItems={pasteItems}
          onChangeColumn={onChangeColumn}
          onChangeRowValue={onChangeRowValue}
        />
      );
    }

    return (
      <ModalWrapper
        type="centered"
        active
        closeModal={onTogglePasteItemsModal}
        windowType="copy-window"
        isLoading={isLoading}
      >
        <ModalHeader>
          <span>
            <h3>{I18n.t(`purchase_orders.form.paste_items.${type}.title`)}</h3>
          </span>
          <span>
            <div className="circle-steps">
              <div className={`step ${step >= 1 ? 'active' : ''}`} />
              <div className="pause" />
              <div className={`step ${step >= 2 ? 'active' : ''}`} />
              <div className="pause" />
              <div className={`step ${step === 3 ? 'active' : ''}`} />
            </div>
          </span>
        </ModalHeader>
        {stepComponent}
        <ModalFooter>
          { step > 1
            && (
            <span className="on-left">
              <a
                className="button button-mute inverse"
                onClick={onBack}
              >
                {I18n.t('commons.actions.back')}
              </a>
            </span>
            )
          }
          <div className="items items-end center">
            <a
              className="button button-mute inverse"
              onClick={onTogglePasteItemsModal}
            >
              {I18n.t('commons.actions.cancel')}
            </a>
            <a
              className="button button-primary"
              onClick={onContinue}
            >
              {I18n.t('commons.actions.continue')}
            </a>
          </div>
        </ModalFooter>
      </ModalWrapper>
    );
  }
}

export default PasteItemsModal;
