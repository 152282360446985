import React from 'react';
import PropTypes from 'prop-types';
import { timeAgoInWords } from '@/tools/index';

class LastSavedAtTimer extends React.Component {
  static propTypes = {
    lastUpdatedAt: PropTypes.string,
    updatedAt: PropTypes.string,
  }

  state = {
    lastUpdatedAgoAt: 0,
  }

  constructor(props) {
    super(props);

    this.lastUpdatedAt = this.lastUpdatedAt.bind(this);
    this.setLastUpdatedAt = this.setLastUpdatedAt.bind(this);
  }

  componentDidMount() {
    this.setLastUpdatedAt();
    this.interval = setInterval(() => {
      this.setLastUpdatedAt();
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  setLastUpdatedAt() {
    this.setState({ lastUpdatedAgoAt: this.lastUpdatedAt() });
  }

  lastUpdatedAt() {
    const { updatedAt } = this.props;
    return timeAgoInWords(updatedAt);
  }

  render() {
    const { lastUpdatedAgoAt } = this.state;

    return (
      <span className="mute timestamp">
        {I18n.t('purchase_orders.form.last_saved')}
        {' '}
        {lastUpdatedAgoAt}
      </span>
    );
  }
}

export default LastSavedAtTimer;
