import React from 'react';

function Button({
  className, children, type, onClick, primary,
}) {
  const htmlClass = className || 'inverse button-mute';

  const primaryClass = primary ? 'button-primary' : `${htmlClass}`;

  if (type === 'label') {
    return (
      <label
        className={`button ${primaryClass}`}
        onClick={onClick}
      >
        {children}
      </label>
    );
  }

  return (
    <a
      className={`button ${primaryClass}`}
      onClick={onClick}
    >
      {children}
    </a>
  );
}

export default Button;
