export const BUTTON_VARIANT = {
  PRIMARY: 'PRIMARY',
  SECONDARY: 'SECONDARY',
  TERTIARY: 'TERTIARY',
  SUCCESS: 'SUCCESS',
  SUCCESS_INACTIVE: 'SUCCESS_INACTIVE',
  ERROR_PRIMARY: 'ERROR_PRIMARY',
  ERROR_SECONDARY: 'ERROR_SECONDARY',
};

export const BUTTON_VARIANT_CLASSES = {
  [BUTTON_VARIANT.PRIMARY]:
    'ct-border-transparent ct-bg-orange ct-text-white hover:ct-bg-orange-light disabled:ct-bg-gray-disabled',
  [BUTTON_VARIANT.SECONDARY]:
    'ct-bg-white ct-text-gray-medium ct-border-gray-medium hover:ct-text-gray-mid hover:ct-border-gray-mid disabled:ct-text-gray-light disabled:ct-border-gray-light',
  [BUTTON_VARIANT.TERTIARY]:
    'ct-border-transparent ct-bg-white ct-text-gray-medium hover:ct-text-gray-mid disabled:ct-text-gray-light',
  [BUTTON_VARIANT.SUCCESS]:
    'ct-border-transparent ct-bg-green ct-text-white hover:ct-bg-green-dark disabled:ct-bg-gray-disabled',
  [BUTTON_VARIANT.SUCCESS_INACTIVE]:
    'ct-border-transparent ct-bg-gray-disabled ct-text-green',
  [BUTTON_VARIANT.ERROR_PRIMARY]:
    'ct-border-transparent ct-bg-red-light ct-text-white hover:ct-bg-red-dark disabled:ct-bg-gray-disabled',
  [BUTTON_VARIANT.ERROR_SECONDARY]:
    'ct-bg-white ct-text-red-light ct-border-red-light hover:ct-text-red-dark hover:ct-border-red-dark disabled:ct-text-red-inactive disabled:ct-border-red-inactive',
};
