import React from 'react';

function UserRow({ user, selectUser }) {
  const active = user.selected ? 'active' : '';

  const onSelectUser = () => {
    const newUser = user;
    newUser.selected = !user.selected;
    selectUser(user);
  };

  return (
    <tr className="pointer" onClick={onSelectUser}>
      <td className="cell-14">
        <label className="avatar-user" htmlFor="search-user-12">
          <div className="avatar">
            <img src={user.avatarUrl} />
          </div>
          <span>
            {user.displayName}
          </span>
        </label>
      </td>
      <td className="actions cell-2 text-center">
        <label
          className={`check-switch ${active}`}
        >
          <span />
        </label>
      </td>
    </tr>
  );
}

export default UserRow;
