import React from 'react';
import DragItemHandle from './drag_item_handle';

function DescriptionLine({
  descriptionLine, onDescriptionLineChange, onDeleteDescriptionLine,
  saveDraft, editable, calculationMethod, showDelivery,
}) {
  const onChangeDescription = ({ target: { value } }) => {
    const newDescriptionLine = descriptionLine;
    newDescriptionLine.description = value;
    onDescriptionLineChange(newDescriptionLine);
  };

  const onDelete = () => {
    onDeleteDescriptionLine(descriptionLine);
  };

  const onBlur = () => {
    saveDraft();
  };

  const inputHeight = () => {
    const { description } = descriptionLine;
    const newLinesCount = description.split(/\r\n|\r|\n/).length;
    return `${newLinesCount * 17}px`;
  };

  const { isLock } = descriptionLine;
  const htmlClass = isLock() || !editable ? 'disabled' : '';

  return (
    <tr
      className={htmlClass}
      style={{ display: descriptionLine._destroy == 1 ? 'none' : '' }}
    >
      <td className="with-textarea" colSpan={calculationMethod === 'unit_measure' ? (showDelivery ? '14' : '11') : (showDelivery ? '11' : '8')}>
        <span className="icon-dialog mobile-ignore" />
        <span className="mobile-ignore">
          <textarea
            style={{ height: `${inputHeight()}` }}
            placeholder={I18n.t('commons.actions.enter')}
            className="input-table textarea"
            onChange={onChangeDescription}
            onBlur={onBlur}
            onFocus={(e) => { e.target.select(); }}
            value={descriptionLine.description}
          />
        </span>
      </td>
      <td className="actions">
        <span>
          <a className="on-hover remove">
            <i className="icon-delete_forever" onClick={onDelete} />
            <span className="mobile-inline">Remove item</span>
          </a>
        </span>
        <DragItemHandle />
      </td>
    </tr>
  );
}

export default DescriptionLine;
