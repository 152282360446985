import React from 'react';
import DropdownsService from '@/services/dropdowns_service';

class DropdownField extends React.Component {
  constructor(props) {
    super(props);
    this.timer = null;

    this.state = {
      entities: props.entities,
      inputValue: '',
    };
  }

  componentDidMount() {
    const { fieldName, type, scope } = this.props;

    if (type === 'fetch') {
      DropdownsService.getEnt(fieldName, '', '', '', scope)
        .then((response) => {
          this.setState({ entities: response });
        });
    }
  }

  componentDidUpdate(prevProps) {
    const { fieldName, scope } = this.props;

    if (scope !== prevProps.scope) {
      DropdownsService.getEnt(fieldName, '', '', '', scope)
        .then((response) => {
          this.setState({ entities: response });
        });
    }
  }

  onInputValue = ({ target: { value } }) => {
    const {
      fieldName, type, scope, notRequired, setErrors, errors,
    } = this.props;
    if (this.timer) clearTimeout(this.timer);
    this.setState({ inputValue: value }, () => {
      this.timer = setTimeout(() => {
        const { inputValue } = this.state;

        if (type === 'fetch') {
          DropdownsService.getEnt(fieldName, value, '', '', scope)
            .then((response) => {
              this.setState({ entities: response, inputValue });
            });
        }
      }, 500);
    });
    if (setErrors) {
      if (!notRequired && value === '') {
        setErrors({ ...errors, [fieldName]: true });
      } else {
        setErrors({ ...errors, [fieldName]: false });
      }
    }
  };

  onClick = (id) => {
    const { state: { entities }, props: { updateField, fieldName, field } } = this;
    const lookedEntity = entities.find(ent => ent.uuid === id);
    if (field[fieldName].id === lookedEntity.id) {
      updateField(fieldName, {});
    } else {
      updateField(fieldName, lookedEntity);
    }
  }

  removeDuplicates = myArr => myArr.filter(
    (obj, pos, arr) => arr.map(mapObj => mapObj.id).indexOf(obj.id) === pos,
  )

  openModal = () => {
    const { toogleModal } = this.props;
    toogleModal(true);
  }

  filterAccounts = () => {
    const {
      props: {
        fieldName, field,
      },
      state: { entities },
    } = this;

    return this.removeDuplicates([field[fieldName], ...entities])
      .filter(ent => ent.id !== '')
      .filter((ent) => {
        if (field.type.name == 'expense') { return this.expenseTypes().includes(ent.type); }
        return true;
      });
  }

  render() {
    const {
      props: {
        selectClass, cellClass, labelName, fieldName, placeholder, children,
        field, withModal = false, addEdit = false, errors, notRequired, inputWrapperClass,
        warnings = {}, emptyMessage, enterMessage = '',
      },
      state: { entities, inputValue },
    } = this;

    const hasErrorClass = errors[fieldName] ? 'has-error' : '';
    const hasWarning = warnings[fieldName] ? 'has-warning' : '';
    const requiredClass = notRequired ? '' : 'required';
    const allEntiteis = fieldName === 'account'
      ? this.filterAccounts() : entities;

    return (
      <div className={`${cellClass} ${hasErrorClass} ${hasWarning}`}>
        <div className={`${inputWrapperClass || ''} reduce ${requiredClass}`}>
          { withModal ? <label tabIndex="-1" className="add-edit-trigger"><i className="icon-add" onClick={this.openModal} /></label> : '' }
          <span className="label">{labelName}</span>
          <span className={`select ${selectClass || ''} ${addEdit ? 'add-edit' : ''}`}>
            <input type="checkbox" />
            <div className="control">
              <input
                placeholder={enterMessage || I18n.t('commons.actions.enter')}
                tabIndex="-1"
                type="text"
                onChange={this.onInputValue}
                value={inputValue}
              />
            </div>
            <div className="select-content">
              {
                allEntiteis.map((ent => (
                  <label
                    key={`${ent.id}_${fieldName}_label`}
                    onClick={() => this.onClick(ent.uuid)}
                    className={ent.id === field[fieldName].id ? 'checked' : ''}
                  >
                    {
                      ent.dispValues?.map(dispValue => <span key={dispValue}>{dispValue}</span>)
                    }
                  </label>
                )))
              }
              <div className="placeholder" data-placeholder={placeholder} data-empty={emptyMessage || I18n.t('commons.messages.data_empty')} />
            </div>
          </span>
        </div>
        <React.Fragment>
          { warnings[fieldName] ? <div className="hint error">{warnings[fieldName]}</div> : null }
          { children }
        </React.Fragment>
      </div>
    );
  }
}

export default DropdownField;
