import React from 'react';

const Checkmark = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 8" {...props}>
    <path
      fill="currentColor"
      d="M3.125 6.194 1.2 4.07l-.642.708L3.125 7.61l5.5-6.07-.642-.707-4.858 5.361Z"
    />
  </svg>
);

export default Checkmark;
