import React from 'react';
import { timeAgoInWords } from '@/tools/index';

function Activity({
  activity: {
    user, body, activity_type, emails, po_number, confirmed_delivery_date, created_at,
  },
}) {
  const userName = () => (user.first_name && user.last_name ? `${user.first_name} ${user.last_name}` : user.email);

  return (
    <div className="comment">
      <div className="avatar">
        <img src={user.avatar_url} />
      </div>
      <div className="comment-wrapper">
        <div className="user-name">
          { timeAgoInWords(created_at) }
        </div>
        <div className="comment-content">
          {I18n.t(`purchase_orders.activities.${activity_type}`, {
            po_number, user: userName(), emails, confirmed_delivery_date,
          }) }
          { ['commented', 'rejected'].includes(activity_type)
            && (
              <p>{body}</p>
            )
          }
        </div>
        <div className="comment-timestamp" />
      </div>
    </div>
  );
}

export default Activity;
