import PropTypes from 'prop-types';
import React from 'react';

export default function Clear({ onClick, children }) {
  return (
    <button
      className="ct-flex ct-h-8 ct-items-center ct-font-medium ct-text-xxs ct-leading-3 ct-text-blue ct-uppercase ct-px-2.5 ct-py-2.5 hover:ct-underline"
      type="button"
      onClick={onClick}
    >
      {children}
    </button>
  );
}

Clear.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.string.isRequired,
};
