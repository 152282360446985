import React from 'react';

class Search extends React.Component {
  constructor(props) {
    super(props);
    this.timer = null;

    this.state = {
      valueSearch: props.currentFilters.query || '',
    };
  }

  componentDidMount() {
    this.searchInput.focus();
    if (window.innerWidth <= 1023) {
      this.searchInput.blur();
    }
  }

  onSearchValue = (e) => {
    const { onQueryFilter } = this.props;
    if (this.timer) clearTimeout(this.timer);

    this.setState({ valueSearch: e.target.value.replace('%', '') }, () => {
      this.timer = setTimeout(() => {
        const { valueSearch } = this.state;
        onQueryFilter({ value: valueSearch });
      }, 500);
    });
  }

  onClearValue = () => {
    const { onQueryFilter } = this.props;

    this.setState({ valueSearch: '' }, () => {
      onQueryFilter({ value: '' });
    });
  }

  render() {
    const { valueSearch } = this.state;
    return (
      <div className="control icon-search input-search left-icon">
        <input
          ref={(input) => { this.searchInput = input; }}
          className="input-s width-2"
          value={valueSearch}
          placeholder={I18n.t('purchase_orders.invoices.search')}
          type="text"
          onChange={this.onSearchValue}
        />
        { valueSearch !== '' ? <button type="reset" onClick={this.onClearValue} className="icon-close" /> : '' }
      </div>
    );
  }
}

export default Search;
