import React from 'react';

const Loupe = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 11">
    <path
      fill="currentColor"
      d="M10.647 10.66 6.78 6.794a3.24 3.24 0 0 1-1.03.595 3.64 3.64 0 0 1-1.25.214c-1.059 0-1.956-.368-2.691-1.103A3.627 3.627 0 0 1 .706 3.837c0-1.04.368-1.927 1.103-2.662C2.544.44 3.436.072 4.485.072c1.04 0 1.924.368 2.655 1.103a3.64 3.64 0 0 1 1.095 2.662 3.668 3.668 0 0 1-.823 2.323l3.882 3.853-.647.648ZM4.485 6.72c.795 0 1.471-.283 2.03-.846a2.789 2.789 0 0 0 .838-2.037c0-.794-.28-1.473-.838-2.037a2.752 2.752 0 0 0-2.03-.845c-.803 0-1.487.281-2.051.845a2.776 2.776 0 0 0-.846 2.037c0 .794.282 1.473.846 2.037a2.793 2.793 0 0 0 2.051.845Z"
    />
  </svg>
);

export default Loupe;
