import uuid from 'uuid/v4';

class PasteItemHeader {
  constructor(value, fieldsMap) {
    this.fieldsMap = fieldsMap;

    this.uuid = uuid();
    this.value = value ? value.trim() : value;
    this.setFieldAndLabel();
  }

  setFieldAndLabel() {
    this.field = Object.keys(this.fieldsMap).find(fieldMapKey => this.fieldsMap[fieldMapKey].columns.includes(this.value.toLowerCase()));
    this.label = this.isEmpty() ? '' : this.fieldsMap[this.field].label;
  }

  change(value) {
    this.value = value;
    this.setFieldAndLabel();
  }

  clearField() {
    this.field = null;
    this.label = '';
  }


  isEmpty() {
    return !this.field;
  }
}

export default PasteItemHeader;
