import { createContext, useContext } from "react";

import PaymentPlanStore from "@/stores/PaymentPlanStore";

export class PurchaseOrderStore {
  constructor() {
    this.paymentPlanStore = new PaymentPlanStore();
  }
}

export const PurchaseOrderContext = createContext(new PurchaseOrderStore());
export const usePurchaseOrderContext = () => useContext(PurchaseOrderContext);
