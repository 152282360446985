import React, { useState } from 'react';
import MoreComponent from './index';
import { storybookOptions } from '../../inputs/selects/constants';

export default {
  component: More,
  title: 'Filters/More',
  args: {
    title: 'More filters',
  },
  argTypes: {
    title: {
      control: 'text',
    },
  },
};

export const More = (args) => {
  const [values, setValues] = useState([]);

  return (
    <MoreComponent
      {...args}
      value={values}
      onChange={setValues}
      options={storybookOptions}
    />
  );
};
