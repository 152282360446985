import React from 'react';

function LeftPositionActions({ children }) {
  return (
    <div className="filters t-under-hide">
      { children }
    </div>
  );
}

export default LeftPositionActions;
