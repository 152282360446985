import Model from './model';

class DepartmentModel extends Model {
  constructor({
    id, name, number, ...attributes
  }) {
    super(attributes);
    this.id = parseInt(id);
    this.name = name;
    this.number = number;
  }
}

export default DepartmentModel;
