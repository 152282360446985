export const urlParamsSetup = () => {
  const params = URI(window.location.href).query(true);

  const filters = {};

  Object.keys(params).map((key) => {
    const value = Array.isArray(params[key]) ? params[key] : [params[key]];

    return filters[key.replace('[', '').replace(']', '')] = value;
  });

  const order = filters.sortby ? { by: filters.sortby.join(''), type: filters.sorttype.join('') } : {};
  const { view } = filters;
  const { page } = filters;
  delete filters.page;
  delete filters.sortby;
  delete filters.sorttype;
  delete filters.page;
  delete filters.view;

  return {
    filters, order, page, view,
  };
};

export const initCurrentSupplierFilter = ({ filters }) => {
  if (filters.supplier_id) {
    const supplier_id = parseInt(filters.supplier_id[0]);
    return { id: supplier_id, value: supplier_id, filter_param: 'supplier_id' };
  }
  return {};
};

export const initCurrentCategoryFilter = ({ filters }) => {
  if (filters.supplier_item_category_id) {
    const category_id = parseInt(filters.supplier_item_category_id[0]);
    return { id: category_id, value: category_id, filter_param: 'supplier_item_category_id' };
  }
  return {};
};

export const initCurrentGroupFilter = ({ filters }) => {
  if (filters.supplier_item_group_id) {
    const group_id = parseInt(filters.supplier_item_group_id[0]);
    return { id: group_id, value: group_id, filter_param: 'supplier_item_group_id' };
  }
  return {};
};

export const initCurrentStateFilter = ({ filters }) => {
  if (filters.state) {
    const state = filters.state[0];
    return {
      id: state, name: state, value: state, filter_param: 'state',
    };
  }
  return {};
};
