import uuid from 'uuid/v4';

class AccountModel {
  constructor(params) {
    this.id = params?.id || '';
    this.number = params?.number || '';
    this.name = params?.name;
    this.externalId = params?.id || '';
    this.uuid = uuid();
    this.dispValues = [`${this.number} ${this.name}`];
    this.type = params?.type;
    this.taxCodeExternalId = params?.tax_code_external_id;
    this.lastTaxCodeId = params?.last_tax_code_id;
    this.deletedAt = params?.deleted_at;
  }
}

export default AccountModel;
