import React from 'react';
import AccountingsService from '../../../services/accountings_service';
import * as toast from '../../shared/toast';

class InvoiceDetach extends React.Component {
  onConfirm = () => {
    const { invoices, filter, onToggleDetach } = this.props;
    const invoiceIds = invoices.map(invoice => invoice.id);
    const params = {
      budgetId: null, categoryId: null, purchaseOrderId: null, invoiceIds,
    };

    AccountingsService.invoicesBulkUpdate(params).then(() => {
      filter();
      onToggleDetach();
      toast.successToast(I18n.t('commons.messages.changes_saved'));
    }).catch(() => {
      toast.errorToast(I18n.t('commons.messages.error'));
    });
  }

  render() {
    const { onToggleDetach, invoices } = this.props;
    const invoicesNumber = invoices.length;

    return (
      <div style={{ display: 'block' }} className="modal searching-modal centered modal-s">
        <div className="modal-wrapper">
          <div className="modal-window window-inside window">
            <div className="window-content">
              <p>{I18n.t('purchase_orders.invoices.detach_invoices_alert', { number: invoicesNumber })}</p>
            </div>
            <div className="window-footer modal-footer">
              <div className="items">
                <label className="button button-transparent button-mute inverse link" onClick={onToggleDetach}>{I18n.t('purchase_orders.invoices.cancel')}</label>
                <div className="button button-danger" onClick={this.onConfirm}>{I18n.t('purchase_orders.invoices.detach')}</div>
              </div>
            </div>
          </div>
        </div>
        <label className="modal-backdrop" onClick={onToggleDetach} />
      </div>
    );
  }
}

export default InvoiceDetach;
