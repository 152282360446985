import * as Api from '../components/shared/api';
import PurchaseOrderModel from '../models/purchase_order_model';

class PurchaseOrderService {
  static find = purchaseOrderId => (
    Api.makeGetRequest(`/api/purchase_orders/${purchaseOrderId}`)
      .then(response => (new PurchaseOrderModel(response.data.purchase_order)))
  )
}

export default PurchaseOrderService;
