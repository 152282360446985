import React from 'react';
import Search from '@/components/accountings/shared/search';
import BudgetsService from '../../../services/budgets_service';
import AccountingsService from '../../../services/accountings_service';
import BudgetModel from '../../../models/budget_model';
import * as toast from '../../shared/toast';
import InvoiceOverrideModal from './invoice_override_modal';
import Table from '../../shared/table';
import TableHead from './budgets/table_head';
import BudgetRow from './budgets/budget_row';
import Loader from '../../loader';

class BudgetCategoryAttach extends Table {
  constructor(props) {
    super(props);

    this.order = {};
    this.overrideInvoices = props.invoices.filter(invoice => invoice.purchaseOrderId || invoice.budgetId);

    this.state = {
      budgets: [],
      selectedBudget: new BudgetModel({}),
      categoryId: null,
      openOverrideConfirmationModal: false,
      isLoading: false,
      searchFilter: '',
    };
  }

  fetchBudgets = () => {
    const { props: { invoices }, state: { searchFilter } } = this;
    this.setState({ isLoading: true });
    BudgetsService.all(searchFilter)
      .then((budgets) => {
        if (!!budgets && budgets.length !== 0) {
          this.setState({ budgets, isLoading: false });
        } else {
          this.setState({ isLoading: false });
        }
      });
    this.setState({
      budgetId: invoices[0]?.budgetId,
      categoryId: invoices[0]?.categoryId,
    });
  }

  componentDidMount() {
    this.fetchBudgets();
  }

  onSearchFilter = (search) => {
    this.setState({ searchFilter: search.value }, () => this.fetchBudgets());
  }

  onCategoryChange = (category) => {
    const { budgets } = this.state;
    const selectedBudget = budgets.find(budget => (budget.id === category.budgetId));
    const budgetId = selectedBudget ? selectedBudget.id : null;

    this.setState({
      categoryId: category.id,
      budgetId,
    });
  }

  onSaveConfirmation = () => {
    if (this.overrideInvoices.length > 0) {
      this.setState({
        openOverrideConfirmationModal: true,
      });
    } else {
      this.onSave();
    }
  }

  onSave = () => {
    const {
      state: { budgetId, categoryId },
      props: { invoices, filter, onToggleAttachBudgetCategorySettings },
    } = this;
    const invoiceIds = invoices.map(invoice => invoice.id);

    if (budgetId) {
      AccountingsService.invoicesBulkUpdate({ budgetId, categoryId, invoiceIds }).then(() => {
        filter();
        onToggleAttachBudgetCategorySettings();
        toast.successToast(I18n.t('commons.messages.changes_saved'));
      }).catch(() => {
        toast.errorToast(I18n.t('commons.messages.error'));
      });
    } else {
      toast.errorToast(I18n.t('purchase_orders.invoices.select_budget_first'));
    }
  }

  headers = () => (
    [
      { name: 'budget', translation: 'budgets.partials.budgets_body.budget' },
      { name: 'category', translation: 'budgets.partials.budgets_body.category' },
      { name: 'not_invoiced', class: 'number price', translation: 'budgets.partials.budgets_body.not_invoiced' },
      { name: 'invoiced', class: 'number price', translation: 'budgets.partials.budgets_body.invoiced' },
      { name: 'committed', class: 'number price', translation: 'budgets.partials.budgets_body.committed' },
      { name: 'total_amount', class: 'number price', translation: 'budgets.partials.budgets_body.amount' },
      { name: 'remaining', class: 'number price', translation: 'budgets.partials.budgets_body.remaining' },
    ]
  )

  render() {
    const {
      props: {
        onToggleAttachBudgetCategorySettings,
      },
      state: {
        budgets, budgetId, categoryId, openOverrideConfirmationModal,
        isLoading,
      },
    } = this;
    const dotsNumber = this.headers().length;
    const htmlClasses = isLoading ? ' has-loading' : '';

    return (
      <div className="modal centered modal-table sub-modal active">
        {openOverrideConfirmationModal
          ? (
            <InvoiceOverrideModal
              onConfirm={this.onSave}
              onReject={onToggleAttachBudgetCategorySettings}
              invoicesNumber={this.overrideInvoices.length}
            />
          )
          : null}
        <div className="modal-wrapper">
          <div className="modal-window window window-form">
            <div className="window-header modal-header">
              <div className="window-header-wrapper">
                <h1 className="margin-0">
                  Select Budget/Category
                </h1>
              </div>
              <Search onQueryFilter={this.onSearchFilter} currentFilters={{}} />
            </div>
            <div className={`window-content ${htmlClasses}`}>
              <Loader />
              <div className="table-fluid table-column-9">
                {this._renderInputs(dotsNumber, 'attach')}
                <div className="table-header with-filters">
                  <div className="table-nav">
                    {this._renderLabels(dotsNumber, 'attach')}
                    {this._renderDots(dotsNumber, 'attach')}
                  </div>
                </div>
                <table className="table-selectable with-expandable">
                  <TableHead
                    headers={this.headers()}
                    order={this.order}
                    onOrder={this.onOrder}
                  />
                  {budgets.map(budget => (
                    <BudgetRow
                      budget={budget}
                      key={budget.id}
                      onCategoryChange={this.onCategoryChange}
                      selectedBudgetId={budgetId}
                      selectedCategoryId={categoryId}
                    />
                  ))}
                </table>
              </div>
            </div>
            <div className="window-footer modal-footer">
              <div className="items items-end center">
                <label
                  className="button inverse button-mute hide-on-complete"
                  htmlFor="transaction-modal-close"
                  onClick={onToggleAttachBudgetCategorySettings}
                >
                  {I18n.t('purchase_orders.invoices.cancel')}
                </label>
                <button
                  className="button button-primary"
                  onClick={this.onSaveConfirmation}
                >
                  {I18n.t('purchase_orders.invoices.attach')}
                </button>
              </div>
            </div>
          </div>
        </div>
        <label className="modal-backdrop" htmlFor="attach-modal-close" onClick={onToggleAttachBudgetCategorySettings} />
      </div>
    );
  }
}

export default BudgetCategoryAttach;
