import React, { useState } from 'react';
import SingleDateFieldComponent from './index';

export default {
  component: SingleDateField,
  title: 'Form/Inputs/SingleDateField',
  args: {
    label: 'Date',
    error: '',
    placeholder: 'Enter date',
    required: false,
    disabled: false,
  },
  argTypes: {
    label: {
      control: 'text',
    },
    error: {
      control: 'text',
    },
    placeholder: {
      control: 'text',
    },
    required: {
      control: 'boolean',
    },
    disabled: {
      control: 'boolean',
    },
  },
};

export const SingleDateField = (args) => {
  const [value, setValue] = useState();

  return (
    <div style={{ width: '250px' }}>
      <SingleDateFieldComponent
        value={value}
        onChange={setValue}
        name="storybook-date-field"
        {...args}
      />
    </div>
  );
};
