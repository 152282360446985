export default {
  TOP_LEFT: 'after:ct-top-[-3px] after:ct-left-[5px]',
  LEFT_TOP: 'after:ct-left-[-3px] after:ct-top-[5px]',
  LEFT_BOTTOM: 'after:ct-left-[-3px] after:ct-bottom-[5px]',
  BOTTOM_LEFT: 'after:ct-bottom-[-3px] after:ct-left-[5px]',
  TOP_RIGHT: 'after:ct-top-[-3px] after:ct-right-[5px]',
  RIGHT_TOP: 'after:ct-right-[-3px] after:ct-top-[5px]',
  RIGHT_BOTTOM: 'after:ct-right-[-3px] after:ct-bottom-[5px]',
  BOTTOM_RIGHT: 'after:ct-bottom-[-3px] after:ct-right-[5px]',
};
