import React, { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import SearchComponent from './index';

export default {
  component: Search,
  title: 'Filters/Search',
  args: {
    placeholder: 'Search',
  },
  argTypes: {
    placeholder: {
      control: 'text',
    },
  },
};

export const Search = (args) => {
  const [value, setValue] = useState('');
  const [debouncedValue] = useDebounce(value, 500);

  useEffect(() => {}, [debouncedValue]);

  return <SearchComponent value={value} onChange={setValue} {...args} />;
};
