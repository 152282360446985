import uuid from 'uuid/v4';
import CurrencyModel from './currency_model';

class PurchaseOrderNumberModel {
  constructor({ po_number = null, supplier_id = null, currency = null }) {
    this.id = po_number;
    this.poNumber = po_number;
    this.uuid = uuid();
    this.supplierId = supplier_id;
    this.currency = new CurrencyModel(currency);

    this.dispValues = [po_number];
  }
}

export default PurchaseOrderNumberModel;
