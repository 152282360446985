class AddressContactModel {
  constructor({
    id, name, email, phone, default: defaultReacord,
  }) {
    this.id = parseInt(id);
    this.name = name;
    this.email = email;
    this.phone = phone;
    this.default = defaultReacord;
  }
}

export default AddressContactModel;
