import React from "react";

export default function Button({ text = String, onClick, primary, disabled }) {
  const bgColor = () => {
    if (primary) return "ct-bg-orange ct-text-white";

    return "ct-text-gray ct-bg-white";
  };

  const disabledClass = () => {
    if (disabled) return "ct-cursor-not-allowed ct-bg-opacity-50"

    return "ct-cursor-pointer"
  }

  return (
    <button
      className={`ct-py-3 ct-px-7 ct-rounded-[5px] ct-flex ct-text-sm ct-w-fit ${bgColor()} ${disabledClass()} ct-uppercase`}
      disabled={disabled}
      onClick={onClick}
    >
      {text}
    </button>
  );
}
