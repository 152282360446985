import React from "react";
import PropTypes from "prop-types";
import Dropdown from "@/icons/Dropdown";

const ArrowButton = ({ onClick, className }) => (
  <button
    onClick={onClick}
    className={`ct-flex ct-items-center ct-justify-center ct-h-4 ct-w-4 ${className}`}
  >
    <Dropdown className="ct-h-2 ct-w-auto ct-text-gray-cold" />
  </button>
);

export default ArrowButton;

ArrowButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};
