import React from 'react';
import classNames from 'classnames';

class DropdownField extends React.Component {
  render() {
    const {
      props: {
        entities, selectedEntity, rowIndex, onChange, editable,
      },
    } = this;

    return (
      <div className={classNames('select', 'input-table', 'min-width-1', 'input-s', { readonly: editable })}>
        <input autoComplete="off" type="checkbox" />
        <div className="select-content">
          {
            <label
              onClick={() => onChange(rowIndex, { field: '' })}
              htmlFor=""
              className={classNames({ checked: selectedEntity.isEmpty() })}
            >
              { I18n.translate('purchase_orders.form.paste_items.dropdown_field.not_recognized') }
            </label>
          }
          {
            entities.map((entity => (
              <label
                key={`${entity.uuid}_label`}
                onClick={() => onChange(rowIndex, entity)}
                htmlFor=""
                className={classNames({ checked: entity.field === selectedEntity.field })}
              >
                {
                  entity.label
                }
              </label>
            )))
          }
          <div className="placeholder">
            { I18n.translate('purchase_orders.form.paste_items.dropdown_field.select_column_name') }
          </div>
        </div>
      </div>
    );
  }
}

export default DropdownField;
