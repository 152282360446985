import React from 'react';
import IntegrationContactModel from '../../models/integration_contact_model';
import IntegrationService from '../../services/integrations_service';
import * as toast from '../shared/toast';
import Loader from '../loader';

class IntegrationContactForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      integrationContact: new IntegrationContactModel({ name: props.name, email: props.email }),
      errors: {},
      isLoading: false,
    };
  }

  prepareIntegrationContactFromEvent = (e) => {
    const { integrationContact } = this.state;
    const { name, value } = e.target;
    integrationContact[name] = value;
    return integrationContact;
  }

  onChange = (e) => {
    const integrationContact = this.prepareIntegrationContactFromEvent(e);
    this.setState({ integrationContact });
  }

  onSubmit = (e) => {
    e.preventDefault();
    const { integrationContact } = this.state;
    this.setState({ isLoading: true });
    IntegrationService.sendIntegrationContact(integrationContact.toParams())
      .then(() => {
        this.setState({
          isLoading: false,
          integrationContact: new IntegrationContactModel({}),
          errors: {},
        });
        toast.successToast(I18n.t('settings.integrations.off.form.success'));
      })
      .catch((error) => {
        if (error.response.status === 422) {
          this.setState({ errors: error.response.data.errors });
        } else {
          toast.errorToast(I18n.t('commons.messages.error'));
        }
        this.setState({ isLoading: false });
      });
  }

  render() {
    const { errors, isLoading, integrationContact } = this.state;
    const htmlClasses = isLoading ? ' has-loading' : '';
    const htmlDisabledClasses = isLoading ? ' disabled' : '';
    return (
      <form className={`cell-10 tile-content form-section-content${htmlClasses}`} onSubmit={this.onSubmit}>
        <Loader />
        <div className="grid space-2">
          <div className="row">
            <div className="cell-14 offset-2">
              <h2>
                {I18n.t('settings.integrations.off.form.title')}
                :
              </h2>
            </div>
          </div>
          <div className="row">
            <div className={`cell-8 offset-3 ${errors.name ? 'has-error' : ''}`}>
              <div className="as-input required">
                <span className="label">Name</span>
                <input type="text" name="name" onChange={this.onChange} value={integrationContact.name} />
                <span className="hint error">{errors.name}</span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className={`cell-8 offset-3 ${errors.email ? 'has-error' : ''}`}>
              <div className="as-input required">
                <span className="label">Email</span>
                <input type="text" name="email" onChange={this.onChange} value={integrationContact.email} />
                <span className="hint error">{errors.email}</span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className={`cell-8 offset-3 ${errors.phone ? 'has-error' : ''}`}>
              <div className="as-input required">
                <span className="label">Phone</span>
                <input type="text" name="phone" onChange={this.onChange} value={integrationContact.phone} />
                <span className="hint error">{errors.phone}</span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className={`cell-8 offset-3 ${errors.message ? 'has-error' : ''}`}>
              <div className="as-input required">
                <span className="label">Information</span>
                <textarea name="message" onChange={this.onChange} value={integrationContact.message} />
                <span className="hint error">{errors.message}</span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="cell-10 offset-3 t-cell-16 text-right">
              <button type="submit" className={`button button-primary min-width-1${htmlDisabledClasses}`}>{I18n.t('settings.integrations.off.form.send')}</button>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default IntegrationContactForm;
