import Model from './model';

class AccountModel extends Model {
  constructor({
    id, name, number, deleted_at, ...attributes
  }) {
    super(attributes);
    this.id = parseInt(id);
    this.name = name;
    this.number = number || '';
    this.deletedAt = deleted_at;
  }
}

export default AccountModel;
