import React from 'react';
import OrderedTitleComponent from './index';

export default {
  component: OrderedTitle,
  title: 'Design System/OrderedTitle',
  args: {
    order: 1,
    children: 'Title',
  },
  argTypes: {
    order: {
      control: 'number',
    },
    children: {
      control: 'text',
    },
  },
};

export const OrderedTitle = args => <OrderedTitleComponent {...args} />;
