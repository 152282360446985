import React from 'react';
import SelectUpTo from '@/components/item_list/select_up_to';
import PaginateWithDots from '../../item_list/paginate_with_dots';

function Footer({
  onPageChange, per, numberPages, currentPage, filterCompleted, onPerChange, transferred,
}) {
  return (
    <div className="tile-footer">
      {
        transferred ? (
          <a className="link" onClick={() => filterCompleted()}>
            {I18n.t('invoices.footer.show_transferred')}
          </a>
        ) : (
          <a className="link" onClick={() => filterCompleted()}>
            {I18n.t('invoices.footer.hide_transferred')}
          </a>
        )
      }
      <div className="last">
        <SelectUpTo onPerChange={onPerChange} selected={per} />
        <PaginateWithDots onPageChange={onPageChange} numberPages={numberPages} selected={currentPage} />
      </div>
    </div>
  );
}

export default Footer;
