import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import Button from '@/components/uiElements/Button';
import { BUTTON_VARIANT } from '@/components/uiElements/Button/constants';
import useOutsideClick from '@/hooks/useOutsideClick';
import { useTranslation } from 'react-i18next';
import defaultModalPropTypes from './constants';

export default function Modal({
  heading, children, isOpen, handleClose, content, subHeading, actions, className, onClose = () => {}, closeButton, storybook,
  itemsCenter = true,
}) {
  const modalRef = useRef();
  const { t } = useTranslation('actions');
  const modalRoot = document.querySelector('#modal-root-2');
  const DEFAULT_WIDTH = 'ct-w-[446px]';

  const closeModal = () => {
    handleClose();
    onClose();
  };

  const handleEscape = (e) => {
    if (e.key === 'Escape') closeModal();
  };

  useEffect(() => {
    window.addEventListener('keydown', handleEscape);
    return () => window.removeEventListener('keydown', handleEscape);
  }, [isOpen]);

  useOutsideClick(modalRef, closeModal);

  const modal = (
    <div className={`ct-flex ct-justify-center ${itemsCenter ? 'ct-items-center' : 'ct-overflow-auto'} ct-w-screen ct-h-screen ct-bg-gray-modal-backdrop`}>
      {
        content
          ? <div ref={modalRef} className={`ct-inline-flex ct-flex-col ct-rounded-md ct-overflow-hidden ct-shadow-modal ct-bg-white ct-p-5 ct-h-fit ${DEFAULT_WIDTH} ${className}`}><div ref={modalRef}>{content}</div></div>
          : (
            <div
              ref={modalRef}
              className={`ct-flex ct-flex-col ct-rounded-md ct-overflow-hidden ct-shadow-modal ct-bg-white ct-p-5 ct-h-fit ct-max-h-[calc(100vh-40px)] ${DEFAULT_WIDTH} ${className}`}
            >
              <header className="ct-mb-4">
                <h3 className="ct-w-full ct-text-left ct-text-2xl ct-text-black-title">
                  {heading}
                </h3>
              </header>
              {subHeading && <aside>{subHeading}</aside>}
              {children && <main className="ct-overflow-y-auto">{children}</main>}
              <footer className="ct-flex ct-justify-end ct-gap-2.5 ct-mr-5 ct-mt-11">
                {closeButton || (
                <Button
                  variant={BUTTON_VARIANT.TERTIARY}
                  onClick={closeModal}
                >
                  {t('cancel')}
                </Button>
                ) }
                {actions}
              </footer>
            </div>
          )
      }
    </div>
  );

  if (storybook) return modal;

  if (!isOpen) return null;

  return createPortal(modal, modalRoot);
}

Modal.propTypes = {
  ...defaultModalPropTypes,
  heading: PropTypes.string,
  children: PropTypes.node,
  subHeading: PropTypes.node,
  actions: PropTypes.node,
  content: PropTypes.node,
  className: PropTypes.string,
  onClose: PropTypes.func,
  closeButton: PropTypes.node,
};

Modal.defaultProps = {
  heading: '',
  children: null,
  subHeading: null,
  actions: null,
  content: null,
  className: '',
  onClose: () => {},
  closeButton: null,
};
