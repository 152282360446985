import Info from '@/icons/Info';
import Hourglass from '@/icons/Hourglass';
import Checkmark from '@/icons/Checkmark';
import React from 'react';
import Warning from '@/icons/Warning';

export const BANNER_TYPE = {
  INFO: 'ct-bg-blue-light',
  SUCCESS: 'ct-bg-green-success',
  WARNING: 'ct-bg-orange',
  ERROR: 'ct-bg-red-reject',
};

export const BANNER_ICON = {
  INFO: <Info className="ct-w-4 ct-h-auto" />,
  SUCCESS: <Checkmark className="ct-w-4 ct-h-auto" />,
  HOURGLASS: <Hourglass className="ct-w-auto ct-h-4" />,
  WARNING: <Warning className="ct-w-4 ct-h-auto" />,
};
