import React from 'react';

function TabLabel({
  activeClass, onPageActive, type, textValue,
}) {
  return (
    <label className={activeClass} onClick={() => { onPageActive(type); }}>
      <h2 value={type}>
        <span>
          {textValue}
        </span>
      </h2>
    </label>
  );
}

export default TabLabel;
