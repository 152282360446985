import React from 'react';

export default function InvoiceOrderRow({
  invoice, onAttachToPurchaseOrder, onAttachToBudget, onRemove, onBulkSelect, onToggleDetach, onToggleTransactionModal, companyCurrency, onRestore,
}) {
  const onToggle = () => onBulkSelect(invoice);
  const invoiceSelected = invoice.selected || false;
  return (
    <div className="tr">
      <div className="td min-width-0">
        <label className="has-checkbox">
          <input className="count-selected" type="checkbox" checked={invoiceSelected} onChange={onToggle} />
          <span className="checkbox input-s margin-right-0" />
        </label>
      </div>
      <div className="td has-ellipsis" data-ellipsis={invoice.humanizedSource}>
        <div className="text-ellipsis w-5">
          {invoice.humanizedSource}
        </div>
      </div>
      <div className="td has-ellipsis" data-ellipsis={invoice.formattedDocDate()}>
        <div className="text-ellipsis w-5">{invoice.formattedDocDate()}</div>
      </div>
      <div className="td has-ellipsis" data-ellipsis={invoice.period}>
        <div className="text-ellipsis w-5">{invoice.period}</div>
      </div>
      <div className="td has-ellipsis link" data-ellipsis={invoice.fullSupplierName()}>
        <a className="link text-ellipsis w-10" href={`/suppliers/${invoice.supplierId}`}>
          {invoice.fullSupplierName()}
        </a>
      </div>
      <div className="td has-ellipsis" data-ellipsis={invoice.poNumber || invoice.purchaseOrderNumber}>
        <div className="text-ellipsis w-5">{invoice.poNumber || invoice.purchaseOrderNumber}</div>
      </div>
      <div className="td has-ellipsis" data-ellipsis={invoice.supplierInvoiceNumber}>
        <div className="text-ellipsis w-5">{invoice.supplierInvoiceNumber}</div>
      </div>
      <div className="td has-ellipsis" data-ellipsis={invoice.number || invoice.docNumber}>
        <div className="text-ellipsis w-5">{invoice.number || invoice.docNumber}</div>
      </div>
      <div className="td has-ellipsis" data-ellipsis={invoice.fullAccountName()}>
        <div className="text-ellipsis w-7">{invoice.fullAccountName()}</div>
      </div>
      <div className="td has-ellipsis" data-ellipsis={invoice.department}>
        <div className="text-ellipsis w-5">{invoice.department}</div>
      </div>
      <div className="td has-ellipsis" data-ellipsis={invoice.project}>
        <div className="text-ellipsis w-5">{invoice.project}</div>
      </div>
      <div className="td has-ellipsis" data-ellipsis={invoice.description}>
        <div className="text-ellipsis w-7">{invoice.description}</div>
      </div>
      <div className="td has-ellipsis" data-ellipsis={invoice.supplierInvoiceReference}>
        <div className="text-ellipsis w-7">{invoice.supplierInvoiceReference}</div>
      </div>
      <div className="td has-ellipsis" data-ellipsis={invoice.budgetNumber}>
        <div className="text-ellipsis w-7">{invoice.budgetNumber}</div>
      </div>
      <div className="td has-ellipsis" data-ellipsis={invoice.budgetName}>
        <div className="text-ellipsis w-7">{invoice.budgetName}</div>
      </div>
      <div className="td number bold" data-currency={invoice.transactionCurrency !== companyCurrency ? invoice.humanizedCurrencyValue : ''}>{invoice.humanizedValue}</div>
      <div className="td mark">
        { invoice.isAssigned()
          ? (
            <React.Fragment>
              <p className={`color-${invoice.automaticallyMatched ? 'blue' : 'green'} small`}>
                { invoice.isAssignedToBudget()
                  ? (
                    <React.Fragment>
                      {`${I18n.t('purchase_orders.invoices.linked')}:`}
                    </React.Fragment>
                  ) : invoice.automaticallyMatched ? (
                    <React.Fragment>
                      {`${I18n.t('purchase_orders.invoices.auto_matched')}:`}
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      {`${I18n.t('purchase_orders.invoices.matched')}:`}
                    </React.Fragment>
                  )
                }
              </p>
              <p>{invoice.purchaseOrderNumber || (invoice.categoryNumber ? `${invoice.categoryNumber} ${invoice.categoryName}` : invoice.categoryName) }</p>
              <p className="small">{invoice.budgetName}</p>
            </React.Fragment>
          )
          : null
          }
      </div>
      <div className="td mark actions">
        {invoice.potentialPurchaseOrders && !invoice.isAssigned()
          ? (
            <a className="has-tooltip left-middle color-blue" data-tooltip={I18n.t('purchase_orders.invoices.same_user_po')}>
              <i className="icon-notification_important" onClick={() => onAttachToPurchaseOrder([invoice], invoice.supplierId)} />
            </a>
          ) : null
        }
        { invoice.purchaseOrderId ? <a className="icon-po color-blue" href={`/purchase_orders/${invoice.purchaseOrderId}.pdf`} target="blank" /> : null }
        { invoice.attachmentUrl ? <a className="icon-inv color-mute" href={invoice.attachmentUrl} target="blank" /> : null }
        <InvoiceOrderRowActions
          onAttachToPurchaseOrder={onAttachToPurchaseOrder}
          onRestore={onRestore}
          onAttachToBudget={onAttachToBudget}
          onRemove={onRemove}
          invoice={invoice}
          onToggleDetach={() => (onToggleDetach([invoice]))}
          onToggleTransactionModal={onToggleTransactionModal}
        />
      </div>
    </div>
  );
}

function InvoiceOrderRowActions({
  invoice, onAttachToPurchaseOrder, onAttachToBudget, onRemove, onToggleDetach, onToggleTransactionModal, onRestore,
}) {
  const onEditModalOpen = () => {
    onToggleTransactionModal(invoice.id);
  };

  if (invoice.isAssigned()) {
    return (
      <React.Fragment>
        <a className={`icon-check color-${invoice.automaticallyMatched ? 'blue' : 'green'}`} onClick={onToggleDetach} />
      </React.Fragment>
    );
  }

  if (invoice.deletedAt) {
    return (
      <React.Fragment>
        <div className="has-popup">
          <input type="checkbox" />
          <a className="icon-add_circle color-mute" />
          <ul className="popup menu bottom-right">
            <li className="item" onClick={() => { onRestore([invoice]); }}>
              <label className="anchor" href="javascript: void(0);">{I18n.t('purchase_orders.invoices.restore')}</label>
            </li>
          </ul>
        </div>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <div className="has-popup">
        <input type="checkbox" />
        <a className="icon-add_circle color-mute" />
        <ul className="popup menu bottom-right">
          <li className="item" onClick={() => { onAttachToPurchaseOrder([invoice]); }}>
            <label className="anchor" htmlFor="attach-transaction-modal">{I18n.t('purchase_orders.invoices.attach_to_po')}</label>
          </li>
          <li className="item" onClick={() => { onAttachToBudget([invoice]); }}>
            <label className="anchor" htmlFor="attach-budget-modal">{I18n.t('purchase_orders.invoices.attach_to_budget')}</label>
          </li>
          { !invoice.isInvoice() ? (
            <li className="item" onClick={() => (onRemove([invoice]))}>
              <a className="anchor">{I18n.t('purchase_orders.invoices.remove')}</a>
            </li>
          ) : null }
          { invoice.isManual() ? (
            <li className="item" onClick={onEditModalOpen}>
              <a className="anchor" href="javascript: void(0);">{I18n.t('purchase_orders.invoices.edit')}</a>
            </li>
          ) : null }
        </ul>
      </div>
    </React.Fragment>
  );
}
