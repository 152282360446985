import PropTypes from 'prop-types';
import React from 'react';

export default function OrderedTitle({ order, children }) {
  return (
    <div className="ct-flex ct-items-center ct-gap-2.5">
      {order && (
        <span className="ct-flex ct-items-center ct-justify-center ct-rounded-full ct-w-7 ct-h-7 ct-bg-blue ct-text-sm ct-leading-none ct-font-bold ct-text-white ct-select-none">
          {order}
        </span>
      )}
      <h6 className="ct-text-black-title ct-text-lg ct-text-normal">
        {children}
      </h6>
    </div>
  );
}

OrderedTitle.propTypes = {
  children: PropTypes.string.isRequired,
  number: PropTypes.number,
};
