import useOutsideClick from '@/hooks/useOutsideClick';
import PropTypes from 'prop-types';
import InputBase from '@/components/uiElements/inputs';
import React, { forwardRef, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { dateFieldBasePropTypes } from './constants';
import '@/components/uiElements/inputs/dates/index.scss';

function DateFieldBase(
  {
    name,
    label,
    error,
    warning,
    placeholder,
    disabled,
    required,
    inputValue,
    onChange,
    dateComponent,
    disableRanges,
    noInput,
    ...rest
  },
  ref,
) {
  const wrapperRef = useRef();
  const { t } = useTranslation('forms', { keyPrefix: 'default.dateField' });
  const [isVisible, setIsVisible] = useState(noInput || false);

  useOutsideClick(wrapperRef, () => setIsVisible(noInput || false));
  return (
    <div
      className={`ct-relative ct-w-full ct-flex ct-flex-col ${
        disableRanges ? 'disable-ranges' : ''
      } ${noInput ? 'no-input' : ''}`}
      ref={wrapperRef}
    >
      {!noInput && (
        <InputBase
          name={name}
          label={label}
          error={error}
          disabled={disabled}
          required={required}
          isFocused={isVisible}
          warning={warning}
        >
          <input
            type="text"
            className="custom"
            name={name}
            readOnly={!onChange}
            onFocus={() => setIsVisible(true)}
            onChange={e => onChange(e.currentTarget.value)}
            ref={ref}
            value={inputValue || ''}
            required={required}
            disabled={disabled}
            placeholder={t('placeholder')}
            autoComplete="off"
            {...rest}
          />
        </InputBase>
      )}

      {isVisible && dateComponent}
    </div>
  );
}

export default forwardRef(DateFieldBase);

DateFieldBase.propTypes = {
  ...dateFieldBasePropTypes,
  inputValue: PropTypes.string,
  dateComponent: PropTypes.element.isRequired,
};
