import React from 'react';
import FilterDropdown from '@/components/common/filters/filter_dropdown';

const filterNames = { suppliers: 'supplier_id' };

function Filters({
  filterActions, filters, selectedFilters, onFilter, onRemoveFilter, defaultValues,
}) {
  const getDefaultFilter = (actionName) => {
    if (!defaultValues) return null;
    const defaultValue = defaultValues[actionName];
    const filterName = filterNames[actionName];
    if (!(filterName && selectedFilters[filterName] && selectedFilters[filterName].some(filterValue => filterValue?.toString() === defaultValue?.toString()))) return null;
    if (!filters[actionName].some(value => value.id === defaultValue)) return null;

    return defaultValue;
  };

  return (
    <>
      {filterActions.map(actionName => (
        <FilterDropdown
          key={actionName}
          actionName={actionName}
          filters={filters}
          onFilter={onFilter}
          onRemoveFilter={onRemoveFilter}
          defaultValue={getDefaultFilter(actionName)}
        />
      ))}
    </>
  );
}

export default Filters;
