import React, { useState } from 'react';
import * as Toast from '@/components/shared/toast';
import FileFormatValidator from '@/components/shared/file_format_validator';
import SupplierInvoicesService from '@/services/SupplierInvoicesService';
import Loader from '@/components/loader';

function InvoiceUploadModal({ onToggleInvoiceUploadModal, openInvoiceUploadModal }) {
  const [fileToImport, setFileToImport] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const onFileChange = ({ target: { files } }) => {
    const [file] = files;
    if (file.size > 2000000) {
      Toast.errorToast(I18n.t('purchase_orders.form.deliveries.supplier_invoice_to_big_file_size'));
    } else if (FileFormatValidator.valid(file, /(\.jpg|\.png|\.pdf)$/i)) {
      Toast.errorToast(I18n.t('purchase_orders.form.deliveries.wrong_file_format'));
    } else if (file.name.split('.').length > 2) {
      Toast.errorToast(I18n.t('purchase_orders.form.deliveries.invalid_file_name'));
    } else {
      setFileToImport(file);
    }
  };

  const importFile = () => {
    if (fileToImport === 0) {
      Toast.errorToast(I18n.t('purchase_orders.form.deliveries.missing_file'));
    } else {
      setIsLoading(true);
      SupplierInvoicesService.create({ fileToImport })
        .then((data) => {
          window.location.href = `/supplier_invoices/${data.supplier_invoice_id}/edit`;
        })
        .catch(({ response: { data } }) => {
          Toast.errorToast(Object.values(data).flatten()[0]);
          setIsLoading(false);
        });
    }
  };

  const htmlClass = isLoading ? 'has-loading' : '';

  return (
    <div
      className={
        `modal modal-m centered ${openInvoiceUploadModal ? 'active' : ''} ${htmlClass}`
      }
    >
      <div className="modal-wrapper">
        <Loader />
        <div className="modal-window window">
          <div className="window-header modal-header modal-header-auto">
            <h1>{I18n.t('invoices.import_modal.new_invoice')}</h1>
          </div>
          <div className="window-content">
            <div className="grid space-1 vertical-start">
              <div className="row">
                <div className="cell">
                  <p className="mute">
                    <b>{I18n.t('invoices.import_modal.under_header')}</b>
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="cell">
                  <label className="label small">
                    {I18n.t('invoices.import_modal.upload_invoice')}
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="cell">
                  <div className="as-input required file-button has-progress">
                    <label>
                      <input
                        type="file"
                        id="receive_all_file"
                        onChange={onFileChange}
                        name="file"
                      />
                      <div className="button block button-primary file-name no-shadow">
                        {I18n.t('purchase_orders.form.select_file')}
                      </div>
                    </label>
                    {
                      fileToImport
                        ? (
                          <div className="row">
                            <span className="state-info">{fileToImport.name}</span>
                          </div>
                        ) : null
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="window-footer modal-footer modal-footer-space">
            <div className="items">
              <label
                className="button button-transparent button-mute inverse link"
                onClick={onToggleInvoiceUploadModal}
              >
                {I18n.t('commons.actions.close')}
              </label>
              <button className="button button-primary" onClick={importFile}>
                {I18n.t('commons.actions.save')}
              </button>
            </div>
          </div>
        </div>
      </div>
      <label className="modal-backdrop" onClick={onToggleInvoiceUploadModal} />
    </div>
  );
}

export default InvoiceUploadModal;
