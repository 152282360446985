import React from 'react';
import PropTypes from 'prop-types';

class Textarea extends React.Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    className: PropTypes.string.isRequired,
    onKeyPress: PropTypes.func,
    disabled: PropTypes.bool,
    onBlur: PropTypes.func,
    style: PropTypes.object,
    extraHeight: PropTypes.number,
    isNumber: PropTypes.bool,
  }

  static defaultProps = {
    onKeyPress: undefined,
    onBlur: undefined,
    disabled: false,
    style: {},
    extraHeight: 0,
    isNumber: false,
  }

  constructor(props) {
    super(props);
    this.textInput = React.createRef();
    const defaultState = {
      height: '1.35em',
      width: 'auto',
    };
    const { style } = props;
    this.state = {
      style: { ...defaultState, ...style },
      active: false,
    };
  }

  componentDidMount() {
    this.setStyle(this.textInput.current);
  }

  componentDidUpdate(_, prevState) {
    const { active } = this.state;

    if (!prevState.active && active) {
      this.textInput.current.select();
    }
  }

  onBlur = () => {
    const { onBlur } = this.props;
    if (onBlur) {
      this.setState({ active: false }, () => { onBlur(); });
    }
  }

  onKeyPress = (e) => {
    const { onKeyPress } = this.props;
    if (onKeyPress) {
      onKeyPress(e);
      if (e.key == 'Enter') {
        const style = {
          height: '1.35em',
        };
        this.setState({ style });
      }
    }
  }

  onFocus = () => {
    this.setState({ active: true });
  }

  onChange = (e) => {
    const { target } = e;
    const { onChange } = this.props;
    onChange(e);
    this.setStyle(target);
  }

  setStyle = (target) => {
    const {
      extraHeight, isNumber, displayValue, value,
    } = this.props;
    const { style } = this.state;
    const defaultStyle = {
      overflow: 'auto',
      height: '1.35em',
    };
    this.setState({ style: { ...style, ...defaultStyle } }, () => {
      let newStyle = {
        height: `${target.scrollHeight + extraHeight}px`,
        overflow: 'hidden',
      };
      if (isNumber) {
        const valueToDisplay = displayValue || value;
        const numberStyle = {
          width: `${valueToDisplay.toString().length * 7.1}px`,
          maxWidth: `${valueToDisplay.toString().length * 7.1}px`,
          minWidth: '100%',
          textOverflow: 'clip',
        };
        newStyle = { ...newStyle, ...numberStyle };
      }

      this.setState({ style: { ...style, ...newStyle } });
    });
  }

  render() {
    const {
      state: { style, active },
      props: {
        placeholder, value, displayValue, className, disabled, name, id,
      },
    } = this;

    return (
      <textarea
        ref={this.textInput}
        type="text"
        name={name}
        id={id || null}
        disabled={disabled}
        className={className}
        placeholder={placeholder}
        onChange={this.onChange}
        value={(!active && displayValue) ? displayValue : value}
        onKeyDown={this.onKeyPress}
        onBlur={this.onBlur}
        onFocus={this.onFocus}
        style={style}
      />
    );
  }
}

export default Textarea;
