import uuid from 'uuid/v4';
import Model from './model';

class CustomFieldSettingModel extends Model {
  constructor({
    categories, ...attributes
  }) {
    super(attributes);
    this.uuid = uuid();
  }
}

export default CustomFieldSettingModel;
