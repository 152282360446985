import React from 'react';

class SupplierInput extends React.Component {
  constructor(props) {
    super(props);

    const { supplier } = props;
    this.state = {
      supplier,
    };
  }

  onChange = (e) => {
    const { onChangeSupplier } = this.props;
    onChangeSupplier(e);
  }

  render() {
    const { state: { supplier }, props: { invoiceSupplierId } } = this;

    return (
      <React.Fragment>
        <input id={`transaction_supplier_${supplier.id}`} name="supplierId" type="radio" value={supplier.id} checked={supplier.id == invoiceSupplierId} onChange={this.onChange} />
        <label htmlFor={`transaction_supplier_${supplier.id}`}>
          <span>{supplier.name}</span>
        </label>
      </React.Fragment>
    );
  }
}

export default SupplierInput;
