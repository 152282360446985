import Model from './model';

class HeaderModel extends Model {
  constructor({ name, ...attributes }) {
    super(attributes);
    this.name = name;
    this.sortDirection = '';
  }

  setSortDirection = () => {
    const { sortDirection } = this;
    if (sortDirection === '' || sortDirection === 'desc') {
      this.sortDirection = 'asc';
    } else {
      this.sortDirection = 'desc';
    }
    return this;
  }

  clean = () => {
    this.sortDirection = '';
    return this;
  }
}

export default HeaderModel;
