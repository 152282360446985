import React from 'react';

function Loader() {
  return (
    <div className="loading">
      <div className="rolling">
        <div />
      </div>
    </div>
  );
}

export default Loader;
