import React from 'react';

class AccountFilter extends React.Component {
  render() {
    const {
      props: {
        accounts,
        accountIds,
        manageAccountId,
      },
    } = this;
    return (
      <div className="row">
        <div className="cell-16 t-cell-16">
          <div className="as-input">
            <span className="label">{I18n.t('settings.integrations.new.setup.account')}</span>
            <div className="control select-arrow">
              <span className="select">
                <input type="checkbox" />
                <div className="select-content">
                  { accounts.map(account => (
                    <React.Fragment key={`account-${account.id}-${accountIds.includes(account.id)}`}>
                      <input
                        id={`account_${account.id}`}
                        value={account.id}
                        onChange={manageAccountId}
                        name="account"
                        type="checkbox"
                        checked={accountIds.includes(account.id)}
                      />
                      <label htmlFor={`account_${account.id}`}>{`${account.number} ${account.name}`}</label>
                    </React.Fragment>
                  )) }
                  <div className="placeholder" data-placeholder={I18n.t('settings.integrations.new.setup.select_account')} data-selected={I18n.t('purchase_orders.form.selected')} />
                </div>
              </span>
              <span />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AccountFilter;
