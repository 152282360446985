import React from "react";
import PaginationComponent from "./index";

export default {
  component: Pagination,
  title: "Tables/Pagination",
  args: {
    label: "Rows per page",
  },
  argTypes: {
    label: {
      control: "text",
    },
  },
};

export const Pagination = (args) => <PaginationComponent {...args} />;
