import React, { useEffect } from 'react';

const FilterRadioInput = ({
  currentName, updateCurrentName, filterInput, onFilter, onRemoveStateFilter, additionalIdParam,
  increaseSelected, decreaseSelected,
}) => {
  const { id, name, actionName } = filterInput;

  const checkedSelected = () => {
    if (isChecked()) {
      increaseSelected();
    }
  };

  useEffect(() => {
    checkedSelected();
  }, []);

  const isChecked = () => name === currentName;

  const onClick = () => {
    if (isChecked()) {
      onRemoveStateFilter();
      updateCurrentName(null);
      decreaseSelected();
    } else {
      updateCurrentName(name);
      onFilter(filterInput.toParams());
      increaseSelected();
    }
  };

  return (
    <React.Fragment>
      <input
        autoComplete="off"
        id={`${additionalIdParam}filter_project_${id}`}
        name={actionName}
        type="radio"
        checked={isChecked()}
      />
      <label onClick={onClick}>{name}</label>
    </React.Fragment>
  );
};

export default FilterRadioInput;
