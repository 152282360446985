class PaymentTermModel {
  constructor({
    id, name, value,
  }) {
    this.id = parseInt(id);
    this.name = name;
    this.value = value;
  }
}

export default PaymentTermModel;
