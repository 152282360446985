import React from 'react';
import TableCellComponent from '.';

export default {
  component: TableCell,
  title: 'Tables/Regular/TableCell',
  args: {
    children: 'Example table cell text',
    bold: false,
    textAlign: 'left',
    heading: false,
    sort: false,
  },
  argTypes: {
    children: {
      control: 'text',
    },
    textAlign: {
      control: 'radio',
      options: ['left', 'center', 'right'],
    },
    bold: {
      control: 'boolean',
    },
    heading: {
      control: 'boolean',
    },
    sort: {
      control: 'boolean',
    },
  },
};

export const TableCell = ({ children, ...rest }) => (
  <table style={{ width: '100%' }}>
    <tbody style={{ width: '100%' }}>
      <tr>
        <TableCellComponent {...rest}>{children}</TableCellComponent>
      </tr>
    </tbody>
  </table>
);
