import React from 'react';
import { parseDate } from '@/tools/index';
import * as api from '@/components/shared/api';
import * as toast from '@/components/shared/toast';
import SupplierInvoiceCommentModel from '@/models/SupplierInvoiceCommentModel';

export default function SupplierInvoiceFile({ file, supplierInvoice, updateSupplierInvoice }) {
  const {
    id, name, url, createdAt,
  } = file;

  function removeFile() {
    api.makeDeleteRequest(`/api/supplier_invoice_files/${id}?supplier_invoice_id=${supplierInvoice.id}`).then((response) => {
      const { data } = response;

      const newActivity = new SupplierInvoiceCommentModel(data.activity);
      const newSupplierInvoice = supplierInvoice;

      newSupplierInvoice.comments = [newActivity, ...supplierInvoice.comments];
      newSupplierInvoice.files = newSupplierInvoice.files.filter(newFile => newFile.id != id);

      updateSupplierInvoice(newSupplierInvoice, toast.successToast(I18n.t('attachments.messages.deleted')));
    });
  }

  return (
    <li className="item">
      <div className="anchor">
        <div className="has-popup">
          <input type="checkbox" />
          <a className="more">
            <i className="icon-more" />
          </a>
          <ul className="popup bottom-right menu">
            <li className="item">
              <a className="anchor" onClick={removeFile}>
                <i className="icon-delete_forever" />
                <span>{I18n.t('purchase_orders.form.remove')}</span>
              </a>
            </li>
          </ul>
        </div>
        <i className="icon-internal_file" />
        <a href={url} target="_blank">
          {name}
        </a>
        <span>{parseDate(createdAt)}</span>
      </div>
    </li>
  );
}
