export const supplierItemsHeaders = [
  { name: 'supplier', translation: 'supplier_items.supplier' },
  { name: 'item_number', translation: 'supplier_items.item_number' },
  { name: 'product_number', translation: 'supplier_items.product_number' },
  { name: 'global_id', translation: 'supplier_items.global_id' },
  { name: 'description', translation: 'supplier_items.description' },
  { name: 'unit_measure', translation: 'supplier_items.unit_measure' },
  { name: 'moq', translation: 'supplier_items.moq', class: 'number' },
  { name: 'gross_price', translation: 'supplier_items.gross_price', class: 'number price' },
  { name: 'discount', translation: 'supplier_items.discount', class: 'number' },
  { name: 'net_price', translation: 'supplier_items.net_price', class: 'number price' },
  { name: 'currency', translation: 'supplier_items.currency' },
];

export const globalProductsHeaders = [
  { name: 'global_product_number', translation: 'supplier_items.product_number' },
  { name: 'description', translation: 'supplier_items.description' },
  { name: 'unit_measure', translation: 'supplier_items.unit_measure' },
  { name: 'moq', translation: 'supplier_items.moq', class: 'number' },
  { name: 'gross_price', translation: 'supplier_items.gross_price', class: 'number price' },
  { name: 'discount', translation: 'supplier_items.discount', class: 'number' },
  { name: 'net_price', translation: 'supplier_items.net_price', class: 'number price' },
  { name: 'currency', translation: 'supplier_items.currency' },
];
