import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import Sort from '@/icons/Sort';

export default function TableCell({
  children,
  bold,
  textAlign,
  heading,
  className = '',
  sort,
  ...rest
}) {
  const [arrowState, setArrowState] = useState(null);
  const fontWeight = bold || heading ? 'ct-font-bold' : 'ct-font-normal';
  const align = textAlign ? `ct-text-${textAlign}` : 'ct-text-left';
  const cursor = sort && heading ? 'ct-cursor-pointer' : 'ct-cursor-default';

  return heading ? (
    <th
      className={`ct-text-gray ct-uppercase ct-text-xxs ct-leading-3 ct-align-middle ct-select-none ${cursor} ${fontWeight} ${className}`}
      onClick={sort && heading ? () => setArrowState(!arrowState) : undefined}
      {...rest}
    >
      <span className="ct-flex ct-justify-between ct-items-center ct-gap-2.5">
        <span className={`ct-block ct-w-full ${align}`}>{children}</span>
        {arrowState !== null && (
          <button
            className="ct-flex ct-flex-col ct-items-center ct-gap-[1px] [&>svg]:ct-w-1.5 [&>svg]:ct-h-auto [&>svg]:ct-text-gray-cold"
            onClick={() => setArrowState(!arrowState)}
          >
            <Sort
              className={`ct-rotate-180 ${arrowState ? 'ct-opacity-30' : ''}`}
            />
            <Sort className={`${!arrowState ? 'ct-opacity-30' : ''}`} />
          </button>
        )}
      </span>
    </th>
  ) : (
    <td
      className={`ct-text-black-cell ct-text-xs custom ct-leading-4.5 ct-align-middle ct-m-0 ${fontWeight} ${align} ${className}`}
      {...rest}
    >
      {children}
    </td>
  );
}

TableCell.propTypes = {
  children: PropTypes.node,
  heading: PropTypes.bool,
  textAlign: PropTypes.oneOf(['left', 'center', 'right']),
  bold: PropTypes.bool,
  className: PropTypes.string,
  sort: PropTypes.bool,
};
