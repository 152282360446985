import PropTypes from 'prop-types';
import Loupe from '@/icons/Loupe';
import Reset from '@/icons/Reset';
import React, { forwardRef } from 'react';

function Search({ value, onChange, placeholder }, ref) {
  return (
    <div className="ct-w-36 ct-h-9 ct-flex ct-items-center ct-justify-evenly ct-rounded ct-border-gray-input-placeholder ct-border ct-bg-white">
      <span className="[&>svg]:ct-w-3 [&>svg]:ct-h-3 [&>svg]:ct-text-gray-cold">
        <Loupe />
      </span>
      <input
        ref={ref}
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={e => onChange(e.currentTarget.value)}
        className="ct-text-xs ct-bg-transparent ct-w-20 ct-outline-none ct-text-black"
      />
      <button
        onClick={() => onChange('')}
        className="ct-bg-transparent [&>svg]:ct-w-3 [&>svg]:ct-h-3 [&>svg]:ct-text-gray-cold-light"
      >
        <Reset />
      </button>
    </div>
  );
}

export default forwardRef(Search);

Search.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
};
