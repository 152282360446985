import React from 'react';
import TOOLTIP_POSITION from './constants';
import TooltipComponent from './index';

export default {
  component: Tooltip,
  title: 'Design System/Tooltip',
  args: {
    text: 'Sample tooltip text!',
    direction: 'BOTTOM_LEFT',
  },
  argTypes: {
    text: {
      control: 'text',
    },
    direction: {
      control: 'radio',
      options: Object.keys(TOOLTIP_POSITION),
    },
  },
};

export const Tooltip = args => <TooltipComponent isOpen {...args} />;
