import React from 'react';

export default function ExportButton() {
  const exportUrl = (format, all_items) => {
    const url = new URL(`${window.location.origin}/api/supplier_items.${format}${window.location.search}`);
    if (all_items) url.searchParams.delete('view');

    return url;
  };

  return (
    <div className="has-popup">
      <input type="checkbox" />
      <a className="button button-primary button-s" href="" onClick={e => e.preventDefault()}>
        {I18n.t('commons.filters.export')}
      </a>
      <ul className="popup menu top-left min-width-1">
        <li className="item">
          <a className="anchor" href={exportUrl('csv', true)}>
            {I18n.t('supplier_items.import_modal.all_items_csv')}
          </a>
        </li>
        <li className="item">
          <a className="anchor" href={exportUrl('xlsx', true)}>
            {I18n.t('supplier_items.import_modal.all_items_xlsx')}
          </a>
        </li>
        <li className="item">
          <a className="anchor" href={exportUrl('csv')}>
            {I18n.t('supplier_items.import_modal.csv')}
          </a>
        </li>
        <li className="item">
          <a className="anchor" href={exportUrl('xlsx')}>
            {I18n.t('supplier_items.import_modal.xlsx')}
          </a>
        </li>
      </ul>
    </div>
  );
}
