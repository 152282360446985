import * as Api from '../components/shared/api';

class RateService {
  static findBy = (currencyFrom, currencyTo) => (
    Api.makeGetRequest(`/rate/${currencyFrom}/${currencyTo}`).then(response => (
      Number(response.data.value.toFixed(5))
    ))
  )

  static findForCurrentCompany = currencyFrom => (
    Api.makeGetRequest(`/rate/${currencyFrom}`).then(response => (
      Number(response.data.value.toFixed(5))
    ))
  )
}

export default RateService;
