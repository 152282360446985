import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';

function DateFilterInput({
  dateFrom, dateTo, minDateFrom, minDateTo, label, dateFormat, timeZoneOffset, onDateFilter, clearDate,
}) {
  const dateFromProps = (Array.isArray(dateFrom)) ? dateFrom[0] : dateFrom;
  const dateToProps = (Array.isArray(dateTo)) ? dateTo[0] : dateTo;
  const selected = dateFromProps || dateToProps;
  const filterLabel = label || I18n.t('purchase_orders.dates');
  const filtersRendered = useRef(false);
  const [state, setState] = useState({
    dateFromProps,
    dateToProps,
    dateFrom: dateFromProps && dateFormat ? moment(dateFromProps, dateFormat) : null,
    dateTo: dateToProps && dateFormat ? moment(dateToProps, dateFormat) : null,
    minDateFrom: minDateFrom ? moment(minDateFrom) : null,
    minDateTo: minDateTo ? moment(minDateTo) : null,
    selected,
    filterLabel,
  });

  const resolveDateFilter = () => {
    if (state.dateFrom && state.dateTo) {
      onDateFilter(
        {
          dateTo: moment(state.dateTo).format(dateFormat),
          dateFrom: moment(state.dateFrom).format(dateFormat),
        },
      );
    }
  };

  useEffect(() => {
    if (filtersRendered.current) {
      resolveDateFilter();
    }
    filtersRendered.current = true;
  }, [state.dateFrom, state.dateTo]);

  const onDateFromChange = (value) => {
    setState(prevState => ({ ...prevState, dateFrom: value, selected: true }));
  };

  const onDateToChange = (value) => {
    setState(prevState => ({ ...prevState, dateTo: value, selected: true }));
  };

  const resetDates = () => {
    setState(prevState => ({
      ...prevState,
      dateFrom: null,
      dateTo: null,
      selected: false,
    }));

    clearDate();
  };

  const formatDate = (date, format) => (date ? date.format(format) : null);

  return (
    <span className={`select ${selected ? 'selected' : ''} input-xs filter-select can-unselect has-datepicker react-datapicker picker-attached`}>
      <input autoComplete="off" type="checkbox" />
      <div className="select-content">
        <div className="label">{filterLabel}</div>
        <DatePicker
          placeholderText={I18n.t('purchase_orders.date_from')}
          selected={state.dateFrom}
          minDate={state.minDateFrom}
          maxDate={state.dateTo}
          onChange={onDateFromChange}
          value={formatDate(state.dateFrom, dateFormat) || state.dateFromProps}
          name="dateFrom"
          dateFormat={dateFormat}
          autoComplete="off"
          utcOffset={timeZoneOffset}
        />
        <DatePicker
          placeholderText={I18n.t('purchase_orders.date_to')}
          selected={state.dateTo}
          minDate={state.dateFrom || state.minDateTo}
          onChange={onDateToChange}
          value={formatDate(state.dateTo, dateFormat) || state.dateToProps}
          name="dateTo"
          dateFormat={dateFormat}
          autoComplete="off"
          utcOffset={timeZoneOffset}
        />
        <div className="placeholder" data-placeholder={state.filterLabel} />
        <button className="button button-s date-clear" onClick={resetDates}>
          {`x ${I18n.t('purchase_orders.clear')}`}
        </button>
      </div>
    </span>
  );
}

export default DateFilterInput;
