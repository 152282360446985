import React from 'react';
import Comment from './comment';
import CommentInput from './common/comment_input';
import InternalFiles from './internalFiles';

function SideBar({
  supplierInvoice, updateSupplierInvoice, currentUser, toggleSideBarOpen, saveSupplierInvoiceComments, integrationType,
}) {
  const { comments } = supplierInvoice;

  return (
    <div className="form-section with-sidebar">
      <div className="form-section-wrapper form-sections sidebar">
        <InternalFiles toggleSideBarOpen={toggleSideBarOpen} currentUser={currentUser} supplierInvoice={supplierInvoice} updateSupplierInvoice={updateSupplierInvoice} />
        <input className="mobile-modal-open" id="internal-comment-modal" name="comment-modal" type="checkbox" />
        <div className="mobile-modal">
          <div className="mobile-modal-wrapper">
            <div className="form-section fill mobile-window">
              <div className="form-section-wrapper-header mobile-window-header mobile-modal-header">
                <span>
                  <i className="icon-question_answer" />
                  Internal comments
                </span>
              </div>
              <div className="form-section-content chat">
                <div className="comments">
                  {comments.map(comment => (
                    <Comment key={comment.uuid} comment={comment} integrationType={integrationType} />
                  ))}
                </div>
                <CommentInput
                  updateSupplierInvoice={updateSupplierInvoice}
                  currentUser={currentUser}
                  supplierInvoice={supplierInvoice}
                  saveSupplierInvoiceComments={saveSupplierInvoiceComments}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SideBar;
