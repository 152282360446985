import React from 'react';

class Paginate extends React.Component {
  renderPages = () => {
    const { numberPages, selected, onPageChange } = this.props;

    const pages = [];

    for (let i = 1; i <= numberPages; i += 1) {
      const paginate = () => onPageChange(i);

      pages.push(
        <li className={i === selected ? 'page current active' : ''} onClick={paginate} data-ref={`${i}`} key={`paginate-page-${i}`}>
          <a>{i}</a>
        </li>,
      );
    }

    return pages;
  }

  onPrev = () => {
    const { selected, onPageChange } = this.props;

    if (selected === 1) return;
    onPageChange(selected - 1);
  }

  onNext = () => {
    const { selected, numberPages, onPageChange } = this.props;

    if (selected === numberPages) return;
    onPageChange(selected + 1);
  }

  render() {
    const { selected, numberPages } = this.props;

    return (
      <div className="pagination">
        <ul>
          <li className={selected <= 1 ? 'disabled' : ''} onClick={this.onPrev}>
            <a className="prev" />
          </li>
          {this.renderPages()}
          <li className={selected >= numberPages ? 'disabled' : ''} onClick={this.onNext}>
            <a className="next" />
          </li>
        </ul>
      </div>
    );
  }
}

export default Paginate;
