import { useEffect, useRef, useState } from 'react';

const useTooltip = () => {
  const triggerRef = useRef();
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  useEffect(() => {
    if (!triggerRef.current) return;
    triggerRef.current.addEventListener('pointerenter', handleOpen);
    triggerRef.current.addEventListener('pointerleave', handleClose);

    return () => {
      triggerRef?.current?.removeEventListener('pointerenter', handleOpen);
      triggerRef?.current?.removeEventListener('pointerleave', handleClose);
    };
  }, [triggerRef]);

  return [isOpen, triggerRef];
};

export default useTooltip;
