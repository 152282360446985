import React from 'react';
import IntegrationsService from '@/services/integrations_service';
import IntegrationHeader from './integrations/integration_header';
import Loader from './loader';
import { INTEGRATION_TYPES } from '../config/constants';

class Integration extends React.Component {
  constructor(props) {
    super(props);

    const {
      tripletexConnected,
      powerofficeConnected,
      quickbooksConnected,
      xeroConnected,
      sage50Connected,
      tfsoConnected,
      sageAccountingZaConnected,
      disconnectionProcessActive,
      companyId,
    } = props;

    this.state = {
      tripletexConnected,
      powerofficeConnected,
      quickbooksConnected,
      xeroConnected,
      sage50Connected,
      tfsoConnected,
      sageAccountingZaConnected,
      disconnectionProcessActive,
      companyId,
      isLoading: true,
    };
  }

  componentDidMount = () => {
    const { disconnectionProcessActive } = this.state;
    if (disconnectionProcessActive) this.subscribeCompany();

    IntegrationsService
      .authorized().then(({ authorized, active }) => {
        const activeUnathorized = active && !authorized;
        if (activeUnathorized) {
          this.setState({
            tripletexConnected: false,
            powerofficeConnected: false,
            quickbooksConnected: false,
            sage50Connected: false,
            tfsoConnected: false,
            xeroConnected: false,
          }, () => {
            this.tripletexComponent.forceDisconnect();
            this.quickbooksComponent.forceDisconnect();
            this.powerofficeComponent.forceDisconnect();
            this.xeroComponent.forceDisconnect();
            this.sage50Component.forceDisconnect();
            this.tfsoComponent.forceDisconnect();
            this.sageAccountingZaComponent.forceDisconnect();
          });
        }
        this.toggleUnauthenticatedInfo(activeUnathorized);
        this.setState({ isLoading: false });
      });
  }

  toggleUnauthenticatedInfo = (show) => {
    $('#unauthenticated-integration').attr('checked', !show);
  }

  onDisconnect = () => {
    this.subscribeCompany();
    this.setState({
      tripletexConnected: false,
      powerofficeConnected: false,
      quickbooksConnected: false,
      xeroConnected: false,
      sage50Connected: false,
      tfsoConnected: false,
      sageAccountingZaConnected: false,
    }, () => {
      this.tripletexComponent.forceDisconnect();
      this.quickbooksComponent.forceDisconnect();
      this.powerofficeComponent.forceDisconnect();
      this.xeroComponent.forceDisconnect();
      this.sage50Component.forceDisconnect();
      this.tfsoComponent.forceDisconnect();
      this.sageAccountingZaComponent.forceDisconnect();
    });
  }

  powerofficeConnect = () => {
    this.setState({
      powerofficeConnected: true,
    }, () => {
      this.toggleUnauthenticatedInfo(false);
      this.tripletexComponent.hide();
      this.quickbooksComponent.hide();
      this.xeroComponent.hide();
      this.sage50Component.hide();
      this.tfsoComponent.hide();
      this.sageAccountingZaComponent.hide();
    });
  }

  quickbooksConnect = () => {
    this.setState({
      quickbooksConnected: true,
    }, () => {
      this.toggleUnauthenticatedInfo(false);
      this.tripletexComponent.hide();
      this.powerofficeComponent.hide();
      this.xeroComponent.hide();
      this.sage50Component.hide();
      this.tfsoComponent.hide();
      this.sageAccountingZaComponent.hide();
    });
  }

  tripletexConnect = () => {
    this.setState({
      tripletexConnected: true,
    }, () => {
      this.toggleUnauthenticatedInfo(false);
      this.powerofficeComponent.hide();
      this.quickbooksComponent.hide();
      this.xeroComponent.hide();
      this.sage50Component.hide();
      this.tfsoComponent.hide();
      this.sageAccountingZaComponent.hide();
    });
  }

  xeroConnect = () => {
    this.setState({
      xeroConnected: true,
    }, () => {
      this.toggleUnauthenticatedInfo(false);
      this.tripletexComponent.hide();
      this.powerofficeComponent.hide();
      this.quickbooksComponent.hide();
      this.sage50Component.hide();
      this.tfsoComponent.hide();
      this.sageAccountingZaComponent.hide();
    });
  }

  sage50Connect = () => {
    this.setState({
      sage50Connected: true,
    }, () => {
      this.toggleUnauthenticatedInfo(false);
      this.tripletexComponent.hide();
      this.powerofficeComponent.hide();
      this.quickbooksComponent.hide();
      this.xeroComponent.hide();
      this.tfsoComponent.hide();
      this.sageAccountingZaComponent.hide();
    });
  }

  tfsoConnect = () => {
    this.setState({
      tfsoConnected: true,
    }, () => {
      this.toggleUnauthenticatedInfo(false);
      this.tripletexComponent.hide();
      this.powerofficeComponent.hide();
      this.quickbooksComponent.hide();
      this.xeroComponent.hide();
      this.sage50Component.hide();
      this.sageAccountingZaComponent.hide();
    });
  }

  sageAccountingZaConnect = () => {
    this.setState({
      sageAccountingZaConnected: true,
    }, () => {
      this.toggleUnauthenticatedInfo(false);
      this.tripletexComponent.hide();
      this.powerofficeComponent.hide();
      this.quickbooksComponent.hide();
      this.xeroComponent.hide();
      this.sage50Component.hide();
      this.tfsoComponent.hide();
    });
  }

  subscribeCompany() {
    const { companyId } = this.state;
    this.subscription = App.cable.subscriptions.create(
      { channel: 'CompanyChannel', company: companyId },
      {
        connected: () => {},
        received: () => {
          this.subscription.unsubscribe();
        },
      },
    );
  }

  render() {
    const {
      tripletexLastUpdatedAt,
      powerofficeLastUpdatedAt,
      quickbooksLastUpdatedAt,
      quickBooksIntegrationWay,
      xeroLastUpdatedAt,
      xeroIntegrationWay,
      sage50IntegrationWay,
      sage50LastUpdatedAt,
      tfsoIntegrationWay,
      tfsoLastUpdatedAt,
      sageAccountingZaIntegrationWay,
      sageAccountingZaLastUpdatedAt,
    } = this.props;

    const {
      powerofficeConnected,
      tripletexConnected,
      quickbooksConnected,
      xeroConnected,
      sage50Connected,
      tfsoConnected,
      sageAccountingZaConnected,
      isLoading,
    } = this.state;

    const hasLoading = isLoading ? ' has-loading' : '';

    return (
      <div className="body-content-wrapper">
        <legend>{I18n.t('settings.integrations.new.accounting_system_integration')}</legend>
        <div className={`grid space-3${hasLoading}`}>
          <Loader />
          <div className="cell-16">
            <IntegrationHeader
              connected={tripletexConnected}
              isAnyOtherConnected={powerofficeConnected || quickbooksConnected || xeroConnected || sage50Connected || sageAccountingZaConnected || tfsoConnected}
              updatedAt={tripletexLastUpdatedAt}
              integrationsDisconnectionSuccess={this.onDisconnect}
              integrationConnectionSuccess={this.tripletexConnect}
              integrationType="tripletex"
              integrationName={I18n.t('settings.integrations.tripletex')}
              ref={(tripletexComponent) => { this.tripletexComponent = tripletexComponent; }}
            />
          </div>
          <div className="cell-16">
            <IntegrationHeader
              connected={powerofficeConnected}
              isAnyOtherConnected={tripletexConnected || quickbooksConnected || xeroConnected || sage50Connected || sageAccountingZaConnected || tfsoConnected}
              updatedAt={powerofficeLastUpdatedAt}
              integrationsDisconnectionSuccess={this.onDisconnect}
              integrationConnectionSuccess={this.powerofficeConnect}
              integrationType={INTEGRATION_TYPES.POWER_OFFICE}
              integrationName={I18n.t('settings.integrations.power_office')}
              ref={(powerofficeComponent) => { this.powerofficeComponent = powerofficeComponent; }}
            />
          </div>
          <div className="cell-16">
            <IntegrationHeader
              connected={quickbooksConnected}
              isAnyOtherConnected={tripletexConnected || powerofficeConnected || xeroConnected || sage50Connected || sageAccountingZaConnected || tfsoConnected}
              updatedAt={quickbooksLastUpdatedAt}
              integrationsDisconnectionSuccess={this.onDisconnect}
              integrationConnectionSuccess={this.quickbooksConnect}
              integrationType="quickbooks"
              integrationName={I18n.t('settings.integrations.quickbooks')}
              integrationWay={quickBooksIntegrationWay}
              ref={(quickbooksComponent) => {
                this.quickbooksComponent = quickbooksComponent;
              }}
            />
          </div>
          <div className="cell-16">
            <IntegrationHeader
              connected={xeroConnected}
              isAnyOtherConnected={tripletexConnected || powerofficeConnected || quickbooksConnected || sage50Connected || sageAccountingZaConnected || tfsoConnected}
              updatedAt={xeroLastUpdatedAt}
              integrationsDisconnectionSuccess={this.onDisconnect}
              integrationConnectionSuccess={this.xeroConnect}
              integrationType="xero"
              integrationName={I18n.t('settings.integrations.xero')}
              integrationWay={xeroIntegrationWay}
              ref={(xeroComponent) => {
                this.xeroComponent = xeroComponent;
              }}
            />
          </div>
          <div className="cell-16">
            <IntegrationHeader
              connected={sage50Connected}
              isAnyOtherConnected={tripletexConnected || powerofficeConnected || quickbooksConnected || xeroConnected || sageAccountingZaConnected || tfsoConnected}
              updatedAt={sage50LastUpdatedAt}
              integrationsDisconnectionSuccess={this.onDisconnect}
              integrationConnectionSuccess={this.sage50Connect}
              integrationType="sage50"
              integrationName={I18n.t('settings.integrations.sage')}
              integrationWay={sage50IntegrationWay}
              ref={(sage50Component) => {
                this.sage50Component = sage50Component;
              }}
            />
          </div>
          <div className="cell-16">
            <IntegrationHeader
              connected={tfsoConnected}
              isAnyOtherConnected={tripletexConnected || powerofficeConnected || quickbooksConnected || xeroConnected || sage50Connected || sageAccountingZaConnected}
              updatedAt={tfsoLastUpdatedAt}
              integrationsDisconnectionSuccess={this.onDisconnect}
              integrationConnectionSuccess={this.tfsoConnect}
              integrationType="tfso"
              integrationName={I18n.t('settings.integrations.tfso')}
              integrationWay={tfsoIntegrationWay}
              ref={(tfsoComponent) => {
                this.tfsoComponent = tfsoComponent;
              }}
            />
          </div>
          <div className="cell-16">
            <IntegrationHeader
              connected={sageAccountingZaConnected}
              isAnyOtherConnected={tripletexConnected || powerofficeConnected || quickbooksConnected || xeroConnected || sage50Connected || tfsoConnected}
              updatedAt={sageAccountingZaLastUpdatedAt}
              integrationsDisconnectionSuccess={this.onDisconnect}
              integrationConnectionSuccess={this.sageAccountingZaConnect}
              integrationType="sage_accounting_za"
              integrationName={I18n.t('settings.integrations.sage_accounting_za')}
              integrationWay={sageAccountingZaIntegrationWay}
              ref={(sageAccountingZaComponent) => {
                this.sageAccountingZaComponent = sageAccountingZaComponent;
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Integration;
