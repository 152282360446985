import React from 'react';

const Lock = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 10 14"
    className="ct-h-3 ct-w-auto"
  >
    <path
      fill="currentColor"
      d="M8.75 4.778h-.6V3.583a2.986 2.986 0 1 0-5.972 0v1.195h-.6A1.2 1.2 0 0 0 .39 5.972v5.972a1.2 1.2 0 0 0 1.194 1.194H8.75a1.2 1.2 0 0 0 1.194-1.194V5.972A1.2 1.2 0 0 0 8.75 4.778Zm-3.583 5.375a1.194 1.194 0 1 1 0-2.387 1.194 1.194 0 0 1 0 2.387Zm1.851-5.375h-3.7V3.583a1.851 1.851 0 0 1 3.7 0v1.195Z"
    />
  </svg>
);

export default Lock;
