import React, { forwardRef } from 'react';
import { PropTypes } from 'prop-types';
import _ from 'lodash';

const Table = ({
  body, header, footer, keepHeader, ...rest
}, ref) => {
  const isHeaderVisible = (header && !_.isEmpty(body)) || keepHeader;

  return (
    <table ref={ref} className="ct-w-full" {...rest}>
      {isHeaderVisible && <thead>{header}</thead>}
      <tbody>{body}</tbody>
      {footer && <tfoot>{footer}</tfoot>}
    </table>
  );
};

export default forwardRef(Table);

Table.propTypes = {
  body: PropTypes.node.isRequired,
  header: PropTypes.element,
  footer: PropTypes.element,
  keepHeader: PropTypes.bool,
};
