import React, { useState, useEffect, useRef } from 'react';
import SupplierItemsModalService from '../../../services/supplier_items_modal_service';
import SupplierItemModel from '../../../models/supplier_item_model';
import HeaderModel from '../../../models/header_model';
import FilterBar from './supplier_items_modal/filter_bar';
import TableHead from './supplier_items_modal/table_head';
import SupplierItemRow from './supplier_items_modal/supplier_item_row';
import Loader from '../../loader';
import * as Swipe from '../../shared/swipe';

function SupplierItemsModal({
  purchaseOrderSupplierId, onToggleSupplierItemModal, addItemsToPoItemList, purchaseOrderCurrency,
}) {
  let urlParams = {
    filters: { supplier_id: [purchaseOrderSupplierId] },
    order: {},
    page: 1,
    itemsType: (Number.isNaN(purchaseOrderSupplierId) ? null : 'supplier_items'),
  };
  let ableToLoadMore = true;
  const pageRendered = useRef(false);
  const [state, setState] = useState({
    supplierItems: [],
    categoriesFilterOptions: [],
    currentCategoryFilter: {},
    groupsFilterOptions: [],
    currentGroupFilter: {},
    currentItemsTypeFilter: urlParams.itemsType,
    allSelected: false,
    selectedItems: [],
    isLoading: false,
    sortHeader: null,
    headers: [
      { name: 'item_number', translation: 'supplier_items.item_number', class: 'pointer' },
      { name: 'product_number', translation: 'supplier_items.product_number', class: 'pointer' },
      { name: 'global_id', translation: 'supplier_items.global_id', class: 'pointer' },
      { name: 'description', translation: 'supplier_items.description', class: 'pointer' },
      { name: 'unit_measure', translation: 'supplier_items.unit_measure', class: 'pointer' },
      { name: 'moq', translation: 'supplier_items.moq', class: 'number pointer' },
      { name: 'gross_price', translation: 'supplier_items.gross_price', class: 'number price pointer' },
      { name: 'discount', translation: 'supplier_items.discount', class: 'number pointer' },
      { name: 'net_price', translation: 'supplier_items.net_price', class: 'number price pointer' },
      { name: 'currency', translation: 'supplier_items.currency', class: 'pointer' },
    ].map(header => new HeaderModel(header)),
  });

  useEffect(() => {
    setUrlFilterParams();
    const finalQuery = newUrlQueries();

    setState(prevState => ({ ...prevState, isLoading: true }));
    SupplierItemsModalService.index(finalQuery)
      .then(({
        supplier_items: supplierItems,
        possible_filters: {
          categories, groups, pages,
        },
      }) => {
        setState(prevState => ({
          ...prevState,
          supplierItems: supplierItems.map(item => new SupplierItemModel(item)),
          categoriesFilterOptions: categories,
          groupsFilterOptions: groups,
          pages,
          isLoading: false,
        }));
        const scrollElement = document.querySelector('.window-content.supplier-items');
        scrollElement.addEventListener('scroll', onScroll, false);
        Swipe.triggerSwipeEvent();
      });
    pageRendered.current = true;
  }, []);

  useEffect(() => {
    if (pageRendered.current) {
      updateItemsList();
    }
  }, [state.currentGroupFilter, state.currentCategoryFilter, state.currentItemsTypeFilter]);

  useEffect(() => {
    if (pageRendered.current && state.sortHeader) {
      sortBy(state.sortHeader);
    }
  }, [state.headers]);

  const onSort = (sortHeader) => {
    const { headers } = state;
    const newHeaders = headers.map((header) => {
      if (header.name === sortHeader.name) {
        return header.setSortDirection();
      }
      return header.clean();
    });

    setState(prevState => ({
      ...prevState,
      headers: newHeaders,
      sortHeader,
    }));
  };

  const onChangeBulkAction = (supplierItemId) => {
    const { supplierItems, selectedItems } = state;
    const clickedItem = supplierItems.find(item => item.id === supplierItemId);
    const alreadySelectedItem = selectedItems.find(({ id }) => id === supplierItemId);
    const newSupplierItems = alreadySelectedItem ? selectedItems.filter(({ id }) => id !== supplierItemId) : [...selectedItems, clickedItem];
    setState(prevState => ({ ...prevState, selectedItems: newSupplierItems }));
  };

  const onSelectAllBulkAction = () => {
    const { allSelected, supplierItems, selectedItems } = state;
    const newSelectedItems = allSelected ? [] : [...new Set([...selectedItems, ...supplierItems])];
    setState(prevState => ({
      ...prevState,
      selectedItems: newSelectedItems,
      allSelected: !allSelected,
    }));
  };

  const onSearchQuery = (value) => {
    urlParams.filters.query = [value];
    updateItemsList();
  };

  const onScroll = ({ target: { scrollTop, scrollHeight } }) => {
    if (!ableToLoadMore) return;

    if (scrollTop + 1000 >= scrollHeight) {
      ableToLoadMore = false;
      infiniteScrollPaginate();
    }
  };

  const infiniteScrollPaginate = () => {
    setState(prevState => ({ ...prevState, isLoading: true }));

    const { supplierItems } = state;
    const { page } = urlParams;
    urlParams.page = page + 1;

    const finalQuery = newUrlQueries();

    SupplierItemsModalService.index(finalQuery)
      .then(({ supplier_items: items }) => {
        setState(prevState => ({
          ...prevState,
          supplierItems: [...supplierItems, ...items.map(item => new SupplierItemModel(item))],
          isLoading: false,
        }));
        const scrollElement = document.querySelector('.window-content.supplier-items');

        scrollElement.addEventListener('scroll', onScroll, false);
        ableToLoadMore = items.length > 0;
      });
  };

  const sortBy = (sortByHeader) => {
    const order = {
      by: sortByHeader.name,
      type: sortByHeader.sortDirection,
    };

    urlParams = { ...urlParams, order };
    updateItemsList();
  };

  const updateItemsList = () => {
    setState(prevState => ({ ...prevState, isLoading: true }));
    setUrlFilterParams();

    const finalQuery = newUrlQueries();

    SupplierItemsModalService.index(finalQuery)
      .then(({ supplier_items: items }) => {
        setState(prevState => ({
          ...prevState,
          supplierItems: items.map(item => new SupplierItemModel(item)),
          isLoading: false,
        }));
        const scrollElement = document.querySelector('.window-content.supplier-items');

        scrollElement.scrollTop = 0;
        scrollElement.addEventListener('scroll', onScroll, false);
        ableToLoadMore = items.length > 0;
      });
  };

  const setUrlFilterParams = () => {
    const { currentCategoryFilter, currentGroupFilter, currentItemsTypeFilter } = state;
    delete urlParams.filters.supplier_item_category_id;
    delete urlParams.filters.supplier_item_group_id;
    delete urlParams.filters.supplier_id;
    delete urlParams.itemsType;
    delete urlParams.page;

    if (currentCategoryFilter.id) {
      urlParams.filters.supplier_item_category_id = [currentCategoryFilter.id];
    }
    if (currentGroupFilter.id) {
      urlParams.filters.supplier_item_group_id = [currentGroupFilter.id];
    }
    if (purchaseOrderSupplierId && currentItemsTypeFilter == 'supplier_items') {
      urlParams.filters.supplier_id = [purchaseOrderSupplierId];
    }
    if (currentItemsTypeFilter) {
      urlParams.itemsType = currentItemsTypeFilter;
    }
    urlParams.page = 1;
  };

  const newUrlQueries = () => {
    const {
      filters, order, page, itemsType,
    } = urlParams;

    const mappedFilters = [];
    for (const [key, value] of Object.entries(filters)) {
      if (key === 'state' || key === 'query') {
        mappedFilters.push(`${key}=${value}`);
      } else {
        value.forEach(val => mappedFilters.push(`${key}[]=${val}`));
      }
    }

    const queries = [
      mappedFilters.join('&'),
      order.by ? `sort[by]=${order.by}&sort[type]=${order.type}` : '',
      page ? `page=${page}` : '',
      itemsType ? `view=${itemsType}` : '',
    ];

    return `?${queries.join('&')}`;
  };

  const setCategoryFilter = (category) => {
    const { currentCategoryFilter } = state;
    if (currentCategoryFilter.id === category.id) {
      setState(prevState => ({ ...prevState, currentCategoryFilter: {} }));
    } else {
      setState(prevState => ({ ...prevState, currentCategoryFilter: category }));
    }
  };

  const setGroupFilter = (group) => {
    const { currentGroupFilter } = state;
    if (currentGroupFilter.id === group.id) {
      setState(prevState => ({ ...prevState, currentGroupFilter: {} }));
    } else {
      setState(prevState => ({ ...prevState, currentGroupFilter: group }));
    }
  };

  const setItemsTypeFilter = (itemsType) => {
    const { currentItemsTypeFilter } = state;
    if (currentItemsTypeFilter === itemsType) {
      setState(prevState => ({ ...prevState, currentItemsTypeFilter: '' }));
    } else {
      setState(prevState => ({ ...prevState, currentItemsTypeFilter: itemsType }));
    }
  };

  const addItems = () => {
    const { selectedItems } = state;

    addItemsToPoItemList(selectedItems);
  };

  const _renderInputs = (number, prefix = '') => (
    Array.from({ length: number }, (_v, k) => k + 1).map(i => (
      <input id={`${prefix}_column_${i}`} name={`${prefix}_table`} key={`${prefix}input_column_${i}`} defaultChecked={i === 1} type="radio" readOnly />
    ))
  );

  const _renderLabels = (number, prefix = '') => (
    Array.from({ length: number }, (_v, k) => k + 1).map(i => (
      <label htmlFor={`${prefix}_column_${i}`} key={`${prefix}label_column_${i}`} />
    ))
  );

  const _renderDots = (number, prefix = '') => (
    Array.from({ length: number }, (_v, k) => k + 1).map(i => (
      <div className="dot" key={`${prefix}dot_column_${i}`} />
    ))
  );

  const _renderColls = (number, prefix = '') => (
    Array.from({ length: number }, (_v, k) => k + 1).map(i => (
      <div className="col" key={`${prefix}col_column_${i}`} />
    ))
  );

  const {
    categoriesFilterOptions, currentCategoryFilter, allSelected,
    groupsFilterOptions, currentGroupFilter, headers, supplierItems, isLoading,
    selectedItems, currentItemsTypeFilter,
  } = state;

  const dotsNumber = headers.length;
  const htmlClasses = isLoading ? ' has-loading' : '';

  return (
    <div className="modal sub-modal modal-table active">
      <div className="modal-wrapper">
        <div className="modal-window window window-form">
          <div className="window-header modal-header window-header-auto window-header-blue">
            <div className="window-header-wrapper blue" />
            {
              selectedItems.some(item => item.currency !== purchaseOrderCurrency)
                ? (
                  <div className="toast error toast-light">
                    <i className="icon-warning" />
                    {I18n.t('purchase_orders.form.items.different_currency')}
                  </div>
                ) : null
            }
            <FilterBar
              setCategoryFilter={setCategoryFilter}
              categoriesFilterOptions={categoriesFilterOptions}
              currentCategoryFilter={currentCategoryFilter}
              setGroupFilter={setGroupFilter}
              groupsFilterOptions={groupsFilterOptions}
              currentGroupFilter={currentGroupFilter}
              currentItemsTypeFilter={currentItemsTypeFilter}
              setItemsTypeFilter={setItemsTypeFilter}
              onSearchQuery={onSearchQuery}
            />
          </div>
          <div className={`window-content supplier-items ${htmlClasses}`}>
            <div className="table-fluid table-column-9">
              <Loader />
              {_renderInputs(dotsNumber)}
              <div className="table-header with-filters">
                <div className="table-nav">
                  {_renderLabels(dotsNumber)}
                  {_renderDots(dotsNumber)}
                </div>
              </div>
              <table>
                <div className="colgroup">
                  {_renderColls(dotsNumber)}
                </div>
                <TableHead
                  headers={headers}
                  onSelectAllBulkAction={onSelectAllBulkAction}
                  allSelected={allSelected}
                  onSort={onSort}
                />
                <tbody>
                  {
                    supplierItems.map(supplierItem => (
                      <SupplierItemRow
                        key={supplierItem.id}
                        supplierItem={supplierItem}
                        selectedItems={selectedItems}
                        onChangeBulkAction={onChangeBulkAction}
                      />
                    ))
                  }
                </tbody>
              </table>
            </div>
          </div>
          <div className="window-footer modal-footer">
            <div className="items items-end center">
              <label className="button inverse button-mute hide-on-complete" onClick={onToggleSupplierItemModal}>
                {I18n.t('commons.actions.cancel')}
              </label>
              <a
                className=" button button-primary hide-on-complete"
                onClick={addItems}
              >
                {I18n.t('commons.actions.add_select')}
              </a>
            </div>
          </div>
        </div>
      </div>
      <label className="modal-backdrop" onClick={onToggleSupplierItemModal} />
    </div>
  );
}

export default SupplierItemsModal;
