import React, { useState } from 'react';
import uuid from 'uuid/v4';
import { humanizedPrice } from '../shared/number_converter';

const totalReceivedItems = (items) => {
  let sum = 0;
  items.map(({ pass }) => sum += Number(pass));
  return sum;
};

export default function ItemList({
  purchaseOrder: {
    id, items, exchangeRate,
  }, totalItemsValue, totalItemsCurrencyValue, companyCurrency,
}) {
  const [itemsListVisible, setItemsListVisible] = useState(true);

  const toggleItemList = () => setItemsListVisible(!itemsListVisible);

  return (
    <div className="table-collapse">
      <div className="table-header">
        <span>{I18n.t('purchase_order_preview.item_list')}</span>
        <span className={`icon-keyboard_arrow_${itemsListVisible ? 'up' : 'down'}`} onClick={toggleItemList} />
      </div>
      {itemsListVisible && (
        <table className="table-tiny">
          <thead>
            <tr>
              <th>{I18n.t('purchase_order_preview.item_number')}</th>
              <th>{I18n.t('purchase_order_preview.description')}</th>
              <th>{I18n.t('purchase_order_preview.category')}</th>
              <th className="number price">{I18n.t('purchase_order_preview.quantity')}</th>
              <th className="price number">{I18n.t('purchase_order_preview.value')}</th>
              <th className="price number">{I18n.t('purchase_order_preview.currency_value', { currency: companyCurrency })}</th>
              <th className="mark border-left">{I18n.t('purchase_order_preview.received')}</th>
            </tr>
          </thead>
          <tbody>
            {items.slice(0, 3).map(item => (
              <tr key={uuid()}>
                <td>{item.sku}</td>
                <td className="has-ellipsis w-20" data-ellipsis={item.name}><div className="text-ellipsis">{item.name}</div></td>
                <td className="has-ellipsis w-20" data-ellipsis={item.category}><div className="text-ellipsis">{item.category}</div></td>
                <td className="number price">{item.quantity}</td>
                <td className="number price">{humanizedPrice(item.quantity * item.price)}</td>
                <td className="number price">{humanizedPrice(item.quantity * item.price * exchangeRate)}</td>
                <td className="number price mark border-left">{humanizedPrice(item.pass)}</td>
              </tr>
            ))}
          </tbody>
          <tbody>
            {items.length > 3 && (
              <tr>
                <td colSpan="7" className="view-more">
                  <a className="link" href={`/purchase_orders/${id}/edit`} target="blank">
                    {`${items.length - 3} more View all`}
                  </a>
                </td>
              </tr>
            )
            }
          </tbody>
          <tfoot>
            <tr>
              <th colSpan="4" className="td number">{I18n.t('purchase_order_preview.total_value')}</th>
              <td className="number price bold total">{humanizedPrice(totalItemsValue)}</td>
              <td className="number price bold total">{humanizedPrice(totalItemsCurrencyValue)}</td>
              <td className="number price bold total mark border-left">{humanizedPrice(totalReceivedItems(items))}</td>
            </tr>
          </tfoot>
        </table>
      )}
    </div>
  );
}
