import React, { useState, useEffect } from 'react';
import Button from '../../../shared/buttons/button';
import ModalHeader from '../../../shared/modal/modal_header';
import ModalFooter from '../../../shared/modal/modal_footer';
import ModalWrapper from '../../../shared/modal/modal_wrapper';

function DisconfirmConfirmationModal({
  isOpen, confirm, close,
}) {
  const [open, setOpen] = useState(isOpen);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <ModalWrapper
      type="centered"
      active={open}
      closeModal={close}
      withLoader={false}
      size="modal-s"
    >
      <ModalHeader additionalClass="window-header-auto padding-bottom-0">
        <h2>{I18n.t('purchase_orders.form.order_disconfirm_confirmation_title')}</h2>
      </ModalHeader>
      <ModalFooter withItems>
        <Button onClick={close}>
          {I18n.t('planned_purchases.modals.cancel')}
        </Button>
        <Button primary onClick={confirm}>
          {I18n.t('purchase_orders.form.save')}
        </Button>
      </ModalFooter>
    </ModalWrapper>
  );
}
export default DisconfirmConfirmationModal;
