import React from 'react';

const Cross = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" {...props}>
    <path
      fill="currentColor"
      d="m.602 10.508 4.243-4.242L.602 2.023 2.017.61 6.259 4.85 10.502.61l1.414 1.414-4.243 4.243 4.243 4.242-1.414 1.415L6.259 7.68l-4.242 4.243-1.415-1.415Z"
    />
  </svg>
);

export default Cross;
