import React from 'react';
import FilterInput from '@/components/accountings/shared/filter_input';
import FilterRadioInput from '@/components/accountings/shared/filter_radio_input';
import FilterModel from '@/models/filter_model';

export default function FilterRow({
  data, index, style,
}) {
  const {
    items, actionName, onFilter, onRemoveFilter, onStateFilter, onRemoveStateFilter, currentName, updateCurrentName,
    increaseSelected, decreaseSelected, defaultValue,
  } = data;
  const filterInput = items[index];

  if (actionName === 'invoice_states') {
    return (
      <FilterRadioInput
        key={`${actionName}-${filterInput.id}`}
        filterInput={new FilterModel(filterInput)}
        actionName={actionName}
        onFilter={onStateFilter}
        onRemoveStateFilter={onRemoveStateFilter}
        increaseSelected={increaseSelected}
        decreaseSelected={decreaseSelected}
        additionalIdParam="web"
        inputType="radio"
        currentName={currentName}
        updateCurrentName={updateCurrentName}
      />
    );
  }

  return (
    <FilterInput
      style={style}
      key={`${actionName}-${filterInput.id}`}
      filterInput={new FilterModel(filterInput)}
      onFilter={onFilter}
      onRemoveFilter={onRemoveFilter}
      increaseSelected={increaseSelected}
      decreaseSelected={decreaseSelected}
      isDefault={[].concat(defaultValue).includes(filterInput.id)}
    />
  );
}
