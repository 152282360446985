import React from 'react';

class ProjectFilter extends React.Component {
  render() {
    const {
      props: {
        projectIds,
        manageProjectId,
        projects,
        onChangeSearchProject,
      },
    } = this;

    return (
      <div className="row">
        <div className="cell-16 t-cell-16">
          <div className="as-input">
            <span className="label">{I18n.t('settings.integrations.new.setup.project')}</span>
            <div className="control select-arrow">
              <span className="select">
                <input type="checkbox" />
                <div className="control">
                  <input
                    type="text"
                    tabIndex="-1"
                    onChange={onChangeSearchProject}
                    placeholder={I18n.t('purchase_orders.form.type_to_search')}
                  />
                </div>
                <div className="select-content">
                  { projects.map(project => (
                    <React.Fragment key={`project-${project.id}-${projectIds.includes(project.id)}`}>
                      <input
                        id={`project_${project.id}`}
                        value={project.id}
                        onChange={manageProjectId}
                        name="project"
                        type="checkbox"
                        checked={projectIds.includes(project.id)}
                      />
                      <label htmlFor={`project_${project.id}`}>{`${project.number} ${project.name}`}</label>
                    </React.Fragment>
                  )) }
                  <div className="placeholder" data-placeholder={I18n.t('settings.integrations.new.setup.select_project')} data-selected={I18n.t('purchase_orders.form.selected')} />
                </div>
              </span>
              <span />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProjectFilter;
