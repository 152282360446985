import React from 'react';
import { isEmpty } from 'lodash';

import BudgetsService from '@/services/budgets_service';
import PasteLink from '@/components/paste_link';

export default function PasteBudgetLink({ budgetId }) {
  const placeholder = 'Number\tName\tGroupNumber\tGroupName\tAmount\n'
    + 'A1\tComputers\tG1\tIT\t123.56\n'
    + 'A2\tServers\tG1\tIT\t56.78\n'
    + 'A3\tOther\t\t\t12.3\n';

  const fieldsMap = {
    name: {
      columns: ['budget name', 'budgetname', 'budget_name', 'name'],
      label: I18n.t('paste_items.budget.name'),
      constraints: { presence: { allowEmpty: false } },
    },
    number: {
      columns: ['budget number', 'budgetnumber', 'budget_number', 'number'],
      label: I18n.t('paste_items.budget.number'),
    },
    group_name: {
      columns: ['group name', 'groupname', 'group_name'],
      label: I18n.t('paste_items.budget.group_name'),
      constraints: (_value, attributes) => {
        if (isEmpty(attributes.group_number)) return null;
        return {
          presence: { allowEmpty: false },
        };
      },
    },
    group_number: {
      columns: ['group number', 'groupnumber', 'group_number'],
      label: I18n.t('paste_items.budget.group_number'),
    },
    amount: {
      columns: ['price', 'amount', 'value'],
      label: I18n.t('paste_items.budget.amount'),
      constraints: { presence: { allowEmpty: false }, numericality: true },
    },
  };

  const importBudget = categories => BudgetsService.importAll(budgetId, categories);

  return (
    <React.Fragment>
      <PasteLink
        type="budget_categories"
        templateUrl="/example_budget_categories_file.xlsx"
        placeholder={placeholder}
        fieldsMap={fieldsMap}
        onImport={importBudget}
        afterImport="reload"
      >
        <a className="link">
          { I18n.t('purchase_orders.form.paste_items.import') }
        </a>
      </PasteLink>
    </React.Fragment>
  );
}
