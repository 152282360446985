import React from 'react';
import PropTypes from 'prop-types';

export default function TopBarText({ children }) {
  return (
    <span className="ct-text-xs ct-text-black-title ct-leading-3.5">
      {children}
    </span>
  );
}

TopBarText.propTypes = {
  children: PropTypes.node,
};
