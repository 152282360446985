import React from 'react';
import Button from '../../common/button';

function DefaultButtons({
  saveSupplierInvoice, saveAndCloseSupplierInvoice, deleteSupplierInvoice, isSavePrimaryButton,
  deleted, restoreSupplierInvoice,
}) {
  return (
    <React.Fragment>
      {
        deleted ? (
          <a
            className="button inverse button-warning"
            onClick={restoreSupplierInvoice}
          >
            {I18n.t('commons.actions.restore')}
          </a>

        ) : (
          <a
            className="button inverse button-warning"
            onClick={deleteSupplierInvoice}
          >
            {I18n.t('commons.actions.destroy')}
          </a>
        )
      }
      <a
        className="button inverse button-mute"
        onClick={saveAndCloseSupplierInvoice}
      >
        {I18n.t('commons.actions.save_and_close')}
      </a>
      <Button onClick={() => saveSupplierInvoice()} primary={isSavePrimaryButton}>
        {I18n.t('commons.actions.save')}
      </Button>
    </React.Fragment>
  );
}

export default DefaultButtons;
