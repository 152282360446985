import React, { forwardRef } from 'react';
import Close from '@/icons/Close';
import PropTypes from 'prop-types';

const TopBar = ({
  title, number, onClose, children,
}, ref) => (
  <header ref={ref} className="ct-fixed ct-z-30 ct-top-0 ct-flex ct-items-center ct-justify-between ct-p-5 ct-pl-9 ct-bg-white ct-shadow-header ct-w-full">
    <h4 className="ct-text-xl ct-leading-4.5 ct-text-black-title ct-font-medium">
      {title}
      {number && (
      <>
        :
        {' '}
        <span className="ct-text-gray-title">{number}</span>
      </>
      )}
    </h4>
    <div className="ct-flex ct-items-center ct-justify-end ct-gap-9">
      {children}
      <button
        className="ct-flex ct-items-center ct-justify-center ct-w-4 ct-h-4"
        onClick={onClose}
      >
        <Close className="ct-w-3.5 ct-h-3.5 ct-text-gray-disabled" />
      </button>
    </div>
  </header>
);

export default forwardRef(TopBar);

TopBar.propTypes = {
  title: PropTypes.string.isRequired,
  number: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};
