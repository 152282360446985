import React from 'react';

const Comment = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path
      fill="#FBAF5D"
      d="M20 2H4a1.997 1.997 0 00-1.99 2L2 22l4-3.999h14a2.007 2.007 0 002-2V4a2.007 2.007 0 00-2-2zm-7 12h-2v-2h2v2zm0-4h-2V6h2v4z"
    />
  </svg>
);

export default Comment;
