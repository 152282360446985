import React from 'react';
import PropTypes from 'prop-types';

class ParentErrorModal extends React.Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    close: PropTypes.func,
  }

  constructor(props) {
    super(props);

    this.delete = this.delete.bind(this);
    this.escFunction = this.escFunction.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.escFunction, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.escFunction, false);
  }

  escFunction(event) {
    const { close } = this.props;
    if (event.key === 'Escape') {
      close();
    }
  }

  delete(e) {
    const { props } = this;
    e.preventDefault();
    props.delete();
    props.close();
  }

  render() {
    const { isOpen, close, message } = this.props;
    return (
      [
        <input key={0} autoComplete="off" className="modal-open" type="radio" value="on" checked={isOpen} readOnly />,
        <div key={1} className="modal sub-modal centered modal-s">
          <form className="modal-wrapper" onSubmit={this.delete}>
            <div className="modal-window window-inside window">
              <div className="window-content">
                <div className="grid space-1 gutter-1">
                  <div className="row">
                    <div className="cell-16">
                      <div className="as-input required">
                        <h2>{I18n.t('purchase_orders.form.parent_po')}</h2>
                        <p>
                          {message}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="window-footer modal-footer">
                <div className="items">
                  <label className="button button-transparent button-mute inverse link" onClick={close}>{I18n.t('purchase_orders.form.cancel')}</label>
                </div>
              </div>
            </div>
          </form>
          <label className="modal-backdrop" onClick={close} />
        </div>,
      ]
    );
  }
}
export default ParentErrorModal;
