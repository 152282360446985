import React from 'react';

const Info = props => (
  <svg viewBox="0 0 16 17" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fill="currentColor"
      d="M7.045 12.22h1.317V7.479H7.045v4.74Zm.604-6.013c.219 0 .399-.074.539-.221.14-.148.21-.331.21-.55 0-.244-.07-.44-.21-.587a.71.71 0 0 0-.54-.222.71.71 0 0 0-.539.222c-.14.147-.21.343-.21.587 0 .219.07.402.21.55a.71.71 0 0 0 .54.221Zm0 10.021a7.001 7.001 0 0 1-2.944-.626 7.685 7.685 0 0 1-2.404-1.715A8.14 8.14 0 0 1 .079 8.25a8.13 8.13 0 0 1 2.213-5.647A7.75 7.75 0 0 1 4.696.888 6.972 6.972 0 0 1 7.649.252c1.06 0 2.05.212 2.97.636.92.424 1.72.993 2.396 1.706a8.178 8.178 0 0 1 1.618 2.524c.402.97.603 2.014.603 3.132a8.044 8.044 0 0 1-.603 3.112 8.213 8.213 0 0 1-1.627 2.534 7.65 7.65 0 0 1-2.396 1.706 7.044 7.044 0 0 1-2.961.626Zm.018-1.522c1.694 0 3.136-.63 4.324-1.889 1.188-1.259 1.783-2.788 1.783-4.586 0-1.786-.595-3.305-1.783-4.558-1.188-1.253-2.636-1.879-4.342-1.879-1.695 0-3.139.626-4.334 1.879C2.121 4.926 1.524 6.451 1.524 8.25c0 1.786.597 3.308 1.791 4.567 1.195 1.26 2.645 1.889 4.352 1.889Z"
    />
  </svg>
);

export default Info;
