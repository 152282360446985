class TrackingOptionModel {
  constructor({
    id, name, entity_tracking_option_id, tracking_category_id,
  }) {
    this.id = parseInt(id);
    this.name = name;
    this.destroy = 0;
    this.entityTrackingOptionId = entity_tracking_option_id;
    this.trackingCategoryId = tracking_category_id;
  }
}

export default TrackingOptionModel;
