import React from 'react';
import STATUS_VARIANT from './constants';
import StatusComponent from './index';

export default {
  component: Status,
  title: 'Design System/Status',
  args: {
    text: 'Status',
    variant: 'DRAFT',
  },
  argTypes: {
    text: {
      control: 'text',
    },
    variant: {
      control: 'radio',
      options: Object.keys(STATUS_VARIANT),
    },
  },
};

export const Status = ({ ...args }) => (
  <StatusComponent {...args} variant={STATUS_VARIANT[args.variant]} />
);
