import React from 'react';
import Button from '../../common/button';

function AttachInfoModal({ activeModal, setActiveModal }) {
  const active = activeModal ? 'active' : '';

  const closeModal = () => {
    setActiveModal(false);
  };

  return (
    <div className={`modal sub-modal centered modal-s ${active}`}>
      <div className="modal-wrapper">
        <div className="modal-window window-inside window">
          <div className="window-content">
            <h1>
              Invoice is not valid
            </h1>
            <p className="mute margin-bottom-5">
              To unlock transaction attaching you have to provide all required information for document info section and save form.
            </p>
          </div>
          <div className="window-footer modal-footer">
            <div className="items items-end">
              <Button className="button button-s button-transparent inverse button-mute" onClick={closeModal}>Close</Button>
            </div>
          </div>
        </div>
      </div>
      <label className="modal-backdrop" onClick={closeModal} htmlFor="send_to_approval-modal-close" />
    </div>
  );
}

export default AttachInfoModal;
