import React from 'react';
import moment from 'moment';
import Loader from '../loader';
import IntegrationType from './shared/integration_type';
import IntegrationsService from '../../services/integrations_service';
import * as toast from '../shared/toast';
import ConfirmationPostPopup from './shared/confirmation_post_popup';
import AutomaticMatchingModel from '../../models/automatic_matching_model';
import IntegrationFilteringModel from '../../models/integration_filtering_model';
import CreditAccountInput from './credit_account_input';
import CostDimensions from './cost_dimensions';
import AccountModel from '../../models/account_model';
import CompanySetup from './shared/company_setup';
import { INTEGRATION_TYPES } from '../../config/constants';

class IntegrationPostSetup extends React.Component {
  constructor(props) {
    super(props);

    IntegrationsService
      .settings({ integrationType: props.integrationType })
      .then(({
        integrationFiltering, automaticMatching, active, jobProcessing, id, creditAccount,
        fetchedMoreThenOnce, tenant,
      }) => {
        if (jobProcessing) this.subscribeIntegration(id);

        this.setState({
          integrationFiltering,
          automaticMatching,
          creditAccount,
          active,
          isProcessing: jobProcessing,
          id,
          isSettingsLoading: false,
          fetchedMoreThenOnce,
          tenant,
        });
      });

    this.state = {
      integrationFiltering: new IntegrationFilteringModel({}),
      automaticMatching: new AutomaticMatchingModel({}),
      active: false,
      updatedAt: props.updatedAt,
      openStartModal: false,
      transactionsCount: 0,
      isProcessing: false,
      isFounding: false,
      isFiltersLoading: true,
      isSettingsLoading: true,
      creditAccount: new AccountModel({}),
      isLoading: false,
      errors: {},
    };
  }

  componentWillUnmount() {
    if (this.subscription) this.subscription.unsubscribe();
  }

  onFiltersLoad = () => {
    this.setState({ isFiltersLoading: false });
  }

  openStartModal = () => {
    this.setState({
      openStartModal: true,
    });
  }

  closeStartModal = () => {
    this.setState({
      openStartModal: false,
    });
  }

  onIntegrationFilteringChange = (e) => {
    const { integrationFiltering } = this.state;
    const { name, value } = e.target;
    integrationFiltering[name] = value;

    this.setState({
      integrationFiltering,
    });
  }

  onClosePurchaseOrderToggle = (e) => {
    const { automaticMatching } = this.state;
    const { name } = e.target;
    const capitalizedName = name.charAt(0).toUpperCase() + name.slice(1);

    this.setState({
      automaticMatching: automaticMatching[`set${capitalizedName}`](),
    });
  }

  onChangeCreditAccount = (creditAccount) => {
    this.setState({ creditAccount });
  }

  onCompanyChange = ({ target: { name, value } }) => {
    const { integrationFiltering } = this.state;
    integrationFiltering[name] = value;

    this.setState({
      integrationFiltering,
    }, () => this.onSave(false));
  }

  onAutomaticMatchingChange = (e) => {
    const { automaticMatching } = this.state;
    const { name, value } = e.target;
    automaticMatching[name] = value;

    this.setState({
      automaticMatching,
    });
  }

  onEnableTrackingToggle = (e) => {
    const { automaticMatching } = this.state;
    const { name } = e.target;
    automaticMatching[name] = !automaticMatching[name];

    this.setState({
      automaticMatching,
    }, () => this.onSave());
  }

  onAutomaticMatchingToggle = (e) => {
    const { automaticMatching } = this.state;
    const { name } = e.target;
    automaticMatching[name] = !automaticMatching[name];

    this.setState({
      automaticMatching,
    });
  }

  onSave = (displayToast = true) => {
    const { props: { integrationType }, state: { automaticMatching, integrationFiltering } } = this;

    IntegrationsService
      .saveSettings({
        integrationType,
        automaticMatching,
        integrationFiltering,
      }).then(() => {
        this.setState({ errors: {} });
        if (displayToast) {
          toast.successToast(I18n.t('settings.integrations.new.setup.changes_saved'));
        }
      }).catch((error) => {
        this.setState({ errors: error.response.data });
        toast.errorToast(I18n.t('settings.integrations.new.setup.error_save'));
      });
  }

  onSync = () => {
    const { props: { edit, integrationType }, state: { automaticMatching, integrationFiltering, creditAccount: { name } } } = this;
    this.setState({ openStartModal: true, isFounding: true });

    IntegrationsService
      .authorized().then(({ authorized }) => {
        if (authorized) {
          IntegrationsService.synchronizeCount({
            integrationType,
            automaticMatching,
            integrationFiltering,
            creditAccountName: name,
          }).then(({ transactionsCount }) => {
            this.setState({ isFounding: false, transactionsCount });
          });
        } else {
          toast.errorToast(I18n.t('settings.integrations.common.unauthorized'));
          toast.warningBox(I18n.t('settings.integrations.new.setup.connection_not_established'));
          this.setState({ isProcessing: false });
          edit();
        }
      });
  }

  onConfirmedSync = () => {
    const {
      state: {
        automaticMatching, integrationFiltering, creditAccount: { name }, id,
      },
      props: {
        edit, integrationType,
      },
    } = this;

    this.setState({ openStartModal: false, isProcessing: true });

    IntegrationsService
      .authorized().then(({ authorized }) => {
        if (authorized) {
          this.subscribeIntegration(id);

          IntegrationsService
            .synchronize({
              integrationType,
              automaticMatching,
              integrationFiltering,
              creditAccountName: name,
              fetchedMoreThenOnce: true,
            }).catch(() => {
              this.setState({ isProcessing: false });
              toast.errorToast(I18n.t('settings.integrations.new.setup.error_save'));
            });
        } else {
          toast.errorToast(I18n.t('settings.integrations.common.unauthorized'));
          toast.warningBox(I18n.t('settings.integrations.new.setup.connection_not_established'));
          this.setState({ isProcessing: false });
          edit();
        }
      });
  }

  manageDepartmentId = (e) => {
    const { value } = e.target;
    const { integrationFiltering } = this.state;
    this.setState({
      integrationFiltering: integrationFiltering.manageDepartmentId(value),
    });
  }

  manageProjectId = (e) => {
    const { value } = e.target;
    const { integrationFiltering } = this.state;
    this.setState({
      integrationFiltering: integrationFiltering.manageProjectId(value),
    });
  }

  manageAccountId = (e) => {
    const { value } = e.target;
    const { integrationFiltering } = this.state;
    this.setState({
      integrationFiltering: integrationFiltering.manageAccountId(value),
    });
  }

  setIsLoading = (value) => {
    this.setState({ isLoading: value });
  }

  defaultDisabledClass = () => {
    const {
      state: { creditAccount: { name }, integrationFiltering: { externalCompanyId } },
      props: { integrationType },
    } = this;

    if (integrationType === INTEGRATION_TYPES.QUICKBOOKS) {
      return (name === undefined || name === null) ? ' disabled' : '';
    }
    if (integrationType === INTEGRATION_TYPES.SAGE_ACCOUNTING_ZA) {
      return externalCompanyId ? '' : ' disabled';
    }
  }

  _renderSyncButton = () => {
    const {
      state: { isProcessing, fetchedMoreThenOnce },
    } = this;

    const htmlClasses = isProcessing ? ' processing' : '';
    const disabledClass = this.defaultDisabledClass();

    if (fetchedMoreThenOnce) {
      return (
        <a onClick={this.onSync} className={`button button-info will-processing${htmlClasses}`}>
          <i className="icon-refresh" />
          &nbsp;
          {I18n.t('commons.actions.refresh')}
        </a>
      );
    }
    return (
      <a onClick={this.onSync} className={`button button-info ${htmlClasses}${disabledClass}`}>{I18n.t('commons.actions.start_download')}</a>
    );
  }

  formatDateTime = date => moment(date).format('DD/MM/YYYY')

  subscribeIntegration(id) {
    const { integrationType } = this.props;
    this.subscription = App.cable.subscriptions.create(
      { channel: 'IntegrationsChannel', integration: id },
      {
        connected: () => {
          IntegrationsService
            .settings({ integrationType })
            .then(({ jobProcessing }) => {
              this.setState({ isProcessing: jobProcessing });
            });
        },
        received: (data) => {
          if (data.action == 'refresh_all') {
            if (data.authorized) {
              this.setState({
                isProcessing: false,
                updatedAt: new Date(),
              });
              toast.successToast(I18n.t('settings.integrations.common.synchronized'));
            } else {
              this.setState({
                isProcessing: false,
              });
              toast.errorToast(I18n.t('settings.integrations.common.unauthorized'));
            }
            this.subscription.unsubscribe();
          }
        },
      },
    );
  }

  render() {
    const {
      props: {
        edit, updatedAt, hide, integrationType, openDisconnectModal,
        changeIntegrationScope, integrationScope, integrationName,
      },
      state: {
        openStartModal, isFounding, creditAccount, isLoading, automaticMatching, tenant,
        integrationFiltering: { externalCompanyId },
      },
    } = this;

    const htmlClass = isLoading ? ' has-loading' : '';

    return (
      <React.Fragment>
        { openStartModal
          ? (
            <ConfirmationPostPopup
              loading={isFounding}
              confirmButtonType="info"
              confirmButtonText={I18n.t('settings.integrations.new.connect.start_modal.download')}
              onReject={this.closeStartModal}
              onConfirm={this.onConfirmedSync}
            />
          )
          : null }
        <div className={htmlClass}>
          <div className="tile-content form-section-content">
            <Loader />
            <div className="grid gutter-1 space-3">
              <div className="row">
                <div className="cell-16">
                  <p className="items-end items font-m mute">
                    <a className="link icon-edit" onClick={edit}>{I18n.t('settings.integrations.new.setup.edit')}</a>
                  </p>
                </div>
              </div>
              { integrationType === INTEGRATION_TYPES.SAGE_ACCOUNTING_ZA ? (
                <CompanySetup
                  externalCompanyId={externalCompanyId}
                  onChange={this.onCompanyChange}
                  setIsLoading={this.setIsLoading}
                  integrationType={integrationType}
                />
              ) : (
                <IntegrationType
                  changeIntegrationScope={changeIntegrationScope}
                  integrationScope={integrationScope}
                  integrationType={integrationType}
                  tenant={tenant}
                />
              )}
              { integrationType === INTEGRATION_TYPES.QUICKBOOKS ? (
                <React.Fragment>
                  <CostDimensions
                    automaticMatching={automaticMatching}
                    onClick={this.onEnableTrackingToggle}
                  />
                  <CreditAccountInput
                    creditAccount={creditAccount}
                    onChangeCreditAccount={this.onChangeCreditAccount}
                    integrationType={integrationType}
                    setIsLoading={this.setIsLoading}
                  />
                </React.Fragment>
              ) : null
              }
            </div>
          </div>
          <div className="tile-footer form-section-footer tile-footer-top">
            <span />
            <span className="items items-end">
              <a onClick={hide} className="button button-transparent button-mute inverse link">{I18n.t('commons.actions.cancel')}</a>
              { integrationType === INTEGRATION_TYPES.QUICKBOOKS || integrationType === INTEGRATION_TYPES.XERO
                ? (
                  <a className="button inverse button-mute not-upper" onClick={openDisconnectModal}>
                    {`DISCONNECT ${integrationName}`}
                  </a>
                )
                : null
              }
              {this._renderSyncButton()}
              { updatedAt ? (
                <small className="mute display-block text-right">
                  <i className="link icon-refresh" />
                &nbsp;
                  {`${I18n.t('settings.integrations.new.last_updated')}: `}
                  {this.formatDateTime(updatedAt)}
                </small>
              ) : null }
            </span>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default IntegrationPostSetup;
