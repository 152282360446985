import React from 'react';
import logo from '../../../assets/images/logo-small.png';
import NewCompany from './xero/newCompany';

const SignWithXero = ({
  company, xeroToken, countries, currencies,
}) => (
  <>
    <div className="cell-auto">
      <div className="icon-logo">
        <img src={logo} />
      </div>
    </div>
    <div className="cell-fill">
      <div className="tile-header no-line with-steps">
        <div className="head">
          <h1>{I18n.t('registrations.common.create_your_account')}</h1>
          <p>{`${I18n.t('registrations.xero.new.step1')}: ${I18n.t('registrations.xero.new.company_details')}`}</p>
        </div>
        <div className="steps">
          <div className="step current" />
        </div>
      </div>
      <NewCompany company={company} countries={countries} xeroToken={xeroToken} currencies={currencies} />
    </div>
  </>
);

export default SignWithXero;
