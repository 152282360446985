import React from "react";
import Tippy from "@tippyjs/react";
import { roundArrow } from "tippy.js";
import "tippy.js/dist/svg-arrow.css";
import { ExclamationCircle } from "react-bootstrap-icons";

export default function DisabledOptionTooltip({ method }) {
  const { t } = I18n;

  return (
    <Tippy
      placement="right"
      arrow={roundArrow}
      content={
        <div className="ct-p-2 ct-text-white ct-text-xxs ct-bg-[#171717]">
          {t(`frontend.payment_plan.components.edit_modal.disabled_${method}`)}
        </div>
      }
    >
      <ExclamationCircle color="#EB5757" fontSize={14} />
    </Tippy>
  );
}
