import React from 'react';
import DropdownField from '../common/dropdown_field';

function PoNumberFieldWrapper(props) {
  const { prefilSupplierInvoiceFields } = props;
  const updateSupplierInvoicePO = (fieldName, lookedEntity) => {
    prefilSupplierInvoiceFields(fieldName, lookedEntity);
  };

  return (
    <DropdownField {...props} updateSupplierInvoice={updateSupplierInvoicePO} />
  );
}

export default PoNumberFieldWrapper;
