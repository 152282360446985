import React, { useEffect, useState } from 'react';
import * as URI from 'urijs';

const FilterInput = ({
  isDefault = false, filterInput, increaseSelected, pushSelectedPill, onRemoveFilter, onFilter,
  style, decreaseSelected,
}) => {
  const [checked, setChecked] = useState(isDefault || false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);
    const params = URI(window.location.href).query(true);

    const { name, value } = filterInput.toParams();
    const stringValue = value?.toString();

    if (!params[name] && !params[`${name}[]`]) return;

    const isArrayInclude = Array.isArray(params[`${name}[]`]) && params[`${name}[]`].includes(stringValue);
    const isValueEquals = params[name] === stringValue || params[`${name}[]`] === stringValue;

    if (isArrayInclude || isValueEquals) {
      setChecked(true);
      increaseSelected();
      if (pushSelectedPill) {
        pushSelectedPill(filterInput);
      }
    }
  }, []);

  const onFilterChange = () => {
    checked ? onRemoveFilter(filterInput.toParams()) : onFilter(filterInput.toParams());

    setChecked(!checked);
    increaseOrDecreaseSelected(!checked);
  };

  const increaseOrDecreaseSelected = (status) => {
    status ? increaseSelected() : decreaseSelected();
  };

  useEffect(() => {
    if (!loaded) return;

    if (isDefault !== checked) {
      setChecked(isDefault);
      increaseOrDecreaseSelected(isDefault);
    }
  }, [isDefault]);

  return (
    <React.Fragment>
      <label style={style} className={`${checked ? 'checked' : ''}`} onClick={onFilterChange}>{filterInput.name}</label>
    </React.Fragment>
  );
};
export default FilterInput;
