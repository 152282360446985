import { makePostRequest } from '../components/shared/api';

class SupplierService {
  static create = (body) => {
    makePostRequest('/suppliers', body).then(response => response.data);
  }

  static importAll = params => makePostRequest('/api/import/suppliers/', { suppliers: params })
    .then(response => response.data)
}

export default SupplierService;
