import Model from './model';
import SupplierModel from './supplier_model';

class SupplierItemModel extends Model {
  constructor({ supplier, ...attributes }) {
    super(attributes);
    if (supplier) {
      this.supplier = new SupplierModel(supplier);
      this.supplierId = supplier.id;
      this.discount;
    }
    this.bulkActionsSelected = false;
  }

  changedBulkAction = () => {
    this.bulkActionsSelected = !this.bulkActionsSelected;
    return this;
  }

  selectAllBulkAction = (value) => {
    this.bulkActionsSelected = !value;
    return this;
  }

  convertedNumber = (name, numberFormat) => {
    const value = this[name];
    if (!value) return 0;
    if (typeof value !== 'string') return value;
    return this.convertNumber(value, numberFormat);
  }

  convertNumber = (value, numberFormat) => {
    const { thousands_separator: thousandSeparator } = numberFormat;
    if (thousandSeparator == ',') return Number(value.replace(',', ''));
    if (thousandSeparator == ' ') return Number(value.replace(' ', '').replace(',', '.'));
    return Number(value.replace('.', '').replace(',', '.'));
  }

  toParams = (itemType, numberFormat) => {
    const params = {
      supplier_id: this.supplierId || null,
      number: this.number || null,
      product_number: this.productNumber || null,
      global_number: this.globalNumber || null,
      supplier_item_group: this.group || null,
      supplier_item_category: this.category || null,
      description: this.description || null,
      unit_measure: this.unitMeasure || null,
      currency: this.currency || null,
      gross_price: this.convertedNumber('grossPrice', numberFormat) || null,
      discount: this.convertedNumber('discount', numberFormat) || null,
      minimum_order_quantity: this.convertedNumber('minimumOrderQuantity', numberFormat) || null,
      item_type: itemType || null,
    };
    if (this.id) params.id = this.id;

    return params;
  }
}

export default SupplierItemModel;
