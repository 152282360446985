import React from 'react';

class InvoiceTableHead extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sort: props.order.by,
      sortType: props.order.type || 'asc',
    };
  }

  onSort = (e) => {
    const name = e.target.getAttribute('data-name');
    const { state: { sortType, sort }, props: { onOrder } } = this;
    let newSortType = sortType;
    if (sort === name) {
      newSortType = sortType === 'desc' ? 'asc' : 'desc';
    }

    this.setState({ sort: name, sortType: newSortType }, () => {
      onOrder(this.state);
    });
  }

  _renderSortArrow = (name) => {
    const { sort, sortType } = this.state;

    if (sort !== name) return;

    return (
      <a className={`sort ${sortType}`} />
    );
  }

  render() {
    const { headers, onBulkSelectAll, allInvoicesAttached } = this.props;

    return (
      <div className="thead">
        <div className="tr">
          <div className="th min-width-0">
            <label className="has-checkbox">
              <input className="count-selected" type="checkbox" checked={allInvoicesAttached} onChange={onBulkSelectAll} />
              <span className="checkbox input-s margin-right-0" />
            </label>
          </div>
          <div className="th">{I18n.t('purchase_orders.partials.invoices_body.source')}</div>
          {headers.map(header => (
            <div key={header.name} className={`th ${header.class}`} data-name={header.name} onClick={this.onSort}>
              {I18n.t(header.translation)}
              {this._renderSortArrow(header.name)}
            </div>
          ))}
          <div className="th mark">
            {I18n.t('purchase_orders.partials.invoices_body.attached_to')}
          </div>
          <div className="th mark" />
        </div>
      </div>
    );
  }
}

export default InvoiceTableHead;
