import React from 'react';
import PropTypes from 'prop-types';
import IntegrationsService from '../../services/integrations_service';
import * as toast from '../shared/toast';
import Loader from '../loader';
import { INTEGRATION_TYPES } from '../../config/constants';

class OauthIntegrationConnect extends React.Component {
  state = {
    employeeToken: '',
    redirectionUrl: '',
    isLoading: false,
    isUrlLoading: false,
  }

  static propTypes = {
    employeeToken: PropTypes.string,
  }

  componentDidMount() {
    const { integrationType } = this.props;
    this.setState({ isUrlLoading: true });
    IntegrationsService
      .redirectionUrl({ integrationType })
      .then(({ redirectionUrl }) => {
        this.setState({ redirectionUrl, isUrlLoading: false });
      });
  }

  onChange = (e) => {
    const { target } = e;

    this.setState({
      [target.name]: target.value,
    });
  }

  onSubmit = () => {
    const { integrationType, connect } = this.props;
    this.setState({ isLoading: true });
    IntegrationsService.saveConnection({
      integrationType,
      ...this.state,
    }).then(() => {
      this.setState({ isLoading: false });
      connect();
    }).catch(() => {
      this.setState({ isLoading: false });
      toast.errorToast(
        I18n.t('settings.integrations.new.connect.connect_error'),
      );
    });
  }

  render() {
    const { integrationType } = this.props;
    const { redirectionUrl, isLoading, isUrlLoading } = this.state;
    const htmlClasses = isLoading ? ' disabled' : '';
    const loadClass = isUrlLoading ? 'has-loading' : '';

    return (
      <React.Fragment>
        <div className={loadClass}>
          <Loader />
          <div className={`tile-content form-section-content${htmlClasses}`}>
            <article className="quickbooks">
              <h1>{I18n.t(`settings.integrations.new.connect.${integrationType}_connection`)}</h1>
              <p>
                {I18n.t(`settings.integrations.new.connect.${integrationType}_info_1`)}
                <br />
                {I18n.t(`settings.integrations.new.connect.${integrationType}_info_2`)}
                <br />
                {I18n.t(`settings.integrations.new.connect.${integrationType}_info_3`)}
              </p>
              {
                (() => {
                  switch (integrationType) {
                    case INTEGRATION_TYPES.QUICKBOOKS:
                      return <a className="button-quickbooks" href={redirectionUrl} />;
                    case INTEGRATION_TYPES.XERO:
                      return <a className="button-xero-connect" href={redirectionUrl} />;
                    default:
                      return (
                        <a className="button button-primary" href={redirectionUrl}>
                          {I18n.t('settings.integrations.new.connect.connect')}
                        </a>
                      );
                  }
                })()}
            </article>
          </div>
          <div className="tile-footer" />
        </div>
      </React.Fragment>
    );
  }
}

export default OauthIntegrationConnect;
