import InputBase from '@/components/uiElements/inputs';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import INPUT_TYPE from './constants';

function TextField(
  {
    name,
    label,
    error,
    placeholder,
    type = 'text',
    disabled,
    required,
    table,
    button,
    warning,
    value,
    onChange,
    hasPreview,
    extraOnChangeFunc,
  },
  ref,
) {
  return (
    <InputBase
      name={name}
      label={label}
      error={error}
      disabled={disabled}
      required={required}
      table={table}
      button={button}
      warning={warning}
      hasPreview={hasPreview}
      value={value}
    >
      <input
        className="custom"
        name={name}
        ref={ref}
        required={required}
        type={type}
        disabled={disabled}
        placeholder={placeholder}
        value={value || ''}
        onChange={(e) => {
          if (extraOnChangeFunc !== undefined) {
            extraOnChangeFunc();
          }
          onChange(e);
        }}
        onClick={e => e.target.select()}
      />
    </InputBase>
  );
}

export default forwardRef(TextField);

TextField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.oneOf(Object.values(INPUT_TYPE)),
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  table: PropTypes.bool,
  button: PropTypes.object,
  warning: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  extraOnChangeFunc: PropTypes.func,
  hasPreview: PropTypes.bool,
};
