import React from 'react';

function Error({ error }) {
  const { identificator, messages, link } = error;

  return (
    <>
      <a href={link}><p>{identificator}</p></a>
      <ul style={{ fontSize: '1.4rem' }}>
        {messages.map(message => (
          <li>{message}</li>
        ))}
      </ul>
    </>
  );
}
export default Error;
