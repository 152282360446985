import React from 'react';

export default function BulkActions({
  onToggleAttachBudgetCategorySettings, onToggleAttachPoSettings, filters,
  invoices, onToggleBulkActions, onRemove, onToggleDetach, onRestore,
}) {
  const selectedInvoices = invoices.length;

  const showOnlyRestoreAction = () => {
    if ((Array.isArray(filters.state) && filters.state.includes('deleted')) || filters.state === 'deleted') return true;
    return false;
  };

  return (
    <div className="table-bulk" style={{ display: 'flex' }}>
      <div className="actions">
        <div className="items items-divided">
          <span>
            <span className="selected" data-selected="selected">
              {selectedInvoices}
              {' '}
              {I18n.t('purchase_orders.invoices.selected')}
            </span>
          </span>
          <span>
            <span className="select input-s select-transparent display-inline-block">
              <input type="checkbox" />
              <div className="select-content">
                { showOnlyRestoreAction()
                  ? <label onClick={() => onRestore()}>{I18n.t('purchase_orders.invoices.restore')}</label>
                  : (
                    <React.Fragment>
                      <label onClick={onToggleAttachPoSettings}>{I18n.t('purchase_orders.invoices.attach_to_po')}</label>
                      <label onClick={onToggleAttachBudgetCategorySettings}>{I18n.t('purchase_orders.invoices.attach_to_budget')}</label>
                      <label onClick={() => onRemove()}>{I18n.t('purchase_orders.invoices.remove')}</label>
                      <label onClick={() => onToggleDetach()}>{I18n.t('purchase_orders.invoices.detach')}</label>
                    </React.Fragment>
                  )
                }
                <div className="placeholder" data-placeholder={I18n.t('purchase_orders.invoices.bulk_action')} />
              </div>
            </span>
          </span>
        </div>
      </div>
      <label onClick={onToggleBulkActions} className="icon-close" htmlFor="table-bulk" />
    </div>
  );
}
