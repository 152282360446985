import React from 'react';

function Actions({ children }) {
  return (
    <div className="table-actions">
      { children }
    </div>
  );
}

export default Actions;
