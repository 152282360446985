import uuid from 'uuid/v4';
import PasteItemRowValue from './paste_item_row_value';


class PasteItemRow {
  constructor(values, headers) {
    this.uuid = uuid();
    this.headers = headers;
    this.values = values.map((value, index) => new PasteItemRowValue(value, this.headers[index], this));
    const missingValues = this.headers.length - this.values.length;
    for (let i = 0; i < missingValues; i += 1) {
      const startHeader = this.headers.length - missingValues + i;
      this.values.push(new PasteItemRowValue('', this.headers[startHeader], this));
    }
  }

  getValues(selected) {
    if (selected) {
      return this.values.filter(value => !value.header.isEmpty());
    }
    return this.values;
  }

  isValid = () => !this.values.map(value => value.isValid()).includes(false)

  toOrderItem = () => {
    const orderItem = {};
    this.values.forEach((rowValue) => {
      orderItem[rowValue.header.field] = rowValue.value;
    });
    return orderItem;
  }
}

export default PasteItemRow;
