import React from 'react';

function ModalFooter({ children, withItems }) {
  if (withItems) {
    return (
      <div className="window-footer modal-footer modal-footer-space">
        <div className="items">
          {children}
        </div>
      </div>
    );
  }

  return (
    <div className="window-footer modal-footer modal-footer-space">
      {children}
    </div>
  );
}

export default ModalFooter;
