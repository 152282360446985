import React from 'react';

export default function EllipsisCell({ url, text, className }) {
  return (
    <a href={url} className={`td has-ellipsis ${className || ''}`} data-ellipsis={text}>
      <div className="text-ellipsis w-7">
        {text}
      </div>
    </a>
  );
}
