import React from 'react';
import PropTypes from 'prop-types';
import * as api from '../../shared/api';
import * as toast from '../../shared/toast';

class SupplierContactModal extends React.Component {
  state = {
    name: '',
    email: '',
    phone: '',
    errors: {},
  }

  static propTypes = {
    supplierId: PropTypes.number,
    name: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    isOpen: PropTypes.bool,
    handleClose: PropTypes.func,
    handleAdd: PropTypes.func,
  }

  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.submit = this.submit.bind(this);
    this.close = this.close.bind(this);
    this.clear = this.clear.bind(this);
    this.prepareBody = this.prepareBody.bind(this);
    this.escFunction = this.escFunction.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.escFunction, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.escFunction, false);
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  escFunction(event) {
    const { handleClose } = this.props;
    if (event.key === 'Escape') {
      handleClose();
    }
  }

  prepareBody() {
    const { name, email, phone } = this.state;
    return {
      supplier_contact: {
        name,
        email,
        phone,
      },
    };
  }

  submit(e) {
    const { supplierId, handleAdd } = this.props;
    e.preventDefault();
    api.makePostRequest(`/suppliers/${supplierId}/contacts`, this.prepareBody())
      .then((response) => {
        this.clear();
        this.close();
        handleAdd(response.data.supplier_contact);
        toast.successToast(I18n.t('supplier_contacts.messages.created'));
      })
      .catch((error) => {
        this.setState({ errors: error.response.data });
      });
  }

  clear() {
    this.setState({
      name: '',
      email: '',
      phone: '',
    });
  }

  close() {
    const { handleClose } = this.props;
    handleClose();
  }

  render() {
    const {
      state: {
        errors, email, name,
      },
      props: {
        isOpen, handleClose,
      },
    } = this;

    return (
      [<input key={0} autoComplete="off" className="modal-open" type="radio" value="on" checked={isOpen} />,
        <div key={1} className="modal sub-modal centered modal-s">
          <form className="modal-wrapper" onSubmit={this.submit}>
            <div className="modal-window window-inside window">
              <div className="window-header modal-header">
                {I18n.t('purchase_orders.form.add_new_supplier_contact')}
              </div>
              <div className="window-content">
                <div className="grid space-1 gutter-1">
                  <div className="row">
                    <div className={`cell-16 ${errors.name ? 'has-error' : ''}`}>
                      <div className="as-input required">
                        <span className="label">{I18n.t('purchase_orders.form.name')}</span>
                        <input placeholder="Enter name" id="supplier_contact_name" name="name" type="text" value={name} onChange={this.onChange} />
                        { errors.name
                          ? <div className="hint">{errors.name.join(', ')}</div> : ''
                      }
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className={`cell-16 ${errors.email ? 'has-error' : ''}`}>
                      <div className="as-input required">
                        <span className="label">{I18n.t('purchase_orders.form.email')}</span>
                        <input placeholder="Enter email" id="supplier_contact_email" name="email" type="text" value={email} onChange={this.onChange} />
                        { errors.email
                          ? <div className="hint">{errors.email.join(', ')}</div> : ''
                      }
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className={`cell-16 ${errors.phone ? 'has-error' : ''}`}>
                      <div className="as-input">
                        <span className="label">{I18n.t('purchase_orders.form.phone')}</span>
                        <input placeholder="Enter phone number" id="supplier_contact_phone" name="phone" type="text" onChange={this.onChange} />
                        { errors.phone
                          ? <div className="hint">{errors.phone.join(', ')}</div> : ''
                      }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="window-footer modal-footer">
                <div className="items">
                  <label className="button button-transparent button-mute inverse link" onClick={handleClose}>{I18n.t('purchase_orders.form.cancel')}</label>
                  <button className="button button-primary">{I18n.t('purchase_orders.form.save')}</button>
                </div>
              </div>
            </div>
          </form>
          <label className="modal-backdrop" onClick={handleClose} />
        </div>]
    );
  }
}

export default SupplierContactModal;
