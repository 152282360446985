import React from 'react';
import classNames from 'classnames';

import Loader from '@/components/loader';
import ModalContent from '@/components/shared/modal/modal_content';
import DropdownField from './dropdown_field';


class PasteItemsStep2 extends React.Component {
  render() {
    const {
      props: {
        pasteItems: {
          getHeaders, getRows, getSelectedHeaders, getUnselectedHeaders,
        }, onChangeRowValue, onChangeColumn, onlyValid, onRemoveRow,
      },
    } = this;

    const unselectedHeaders = getUnselectedHeaders();
    const selectedHeaders = getSelectedHeaders();
    const rows = getRows(onlyValid);

    return (
      <ModalContent additionalClass="with-table">
        <Loader />
        <p>
          <strong>
            {
              I18n.t(
                'purchase_orders.form.paste_items.step2.description', {
                  rows_length: rows.length,
                  selected_columns_length: selectedHeaders.length,
                  unselected_columns_length: unselectedHeaders.length,
                },
              )
            }
          </strong>
          <br />
          { !onlyValid ? I18n.t('purchase_orders.form.paste_items.step2.assign_column') : '' }
        </p>
        <div className="table-fluid inside-window release-table table-copy-past">
          <table>
            <thead>
              <tr>
                {getHeaders(onlyValid).map((header, rowIndex) => (
                  <th key={header.uuid} className={classNames({ 'has-error': header.isEmpty() })}>
                    <DropdownField
                      entities={header.isEmpty() ? unselectedHeaders : unselectedHeaders.concat([header])}
                      selectedEntity={header}
                      rowIndex={rowIndex}
                      onChange={onChangeColumn}
                      editable={onlyValid}
                    />
                  </th>
                ))}
                { onRemoveRow
                  && (
                    <th />
                  )
                }
              </tr>
            </thead>
            <tbody>
              {rows.map((row, rowIndex) => (
                <tr key={row.uuid} className={classNames({ 'is-invalid': !row.isValid() })}>
                  {row.getValues(onlyValid).map((rowValue, valueIndex) => (
                    <td
                      key={rowValue.uuid}
                      className={classNames({ 'has-error': rowValue.header.isEmpty(), 'is-invalid': !rowValue.isValid() })}
                    >
                      <textarea
                        className="input-table"
                        value={rowValue.value}
                        onChange={onChangeRowValue.bind(this, rowIndex, valueIndex)}
                        placeholder={rowValue.header.label}
                      />
                      { !rowValue.isValid()
                        && (
                          <span>{ rowValue.validate() }</span>
                        )
                      }
                    </td>
                  ))}
                  { onRemoveRow
                    && (
                      <td className="actions">
                        <a onClick={onRemoveRow.bind(this, rowIndex)} className="remove on-hover">
                          <i className="icon-close" />
                        </a>
                      </td>
                    )
                  }
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </ModalContent>
    );
  }
}

export default PasteItemsStep2;
