import Cross from "@/icons/Cross";
import Checkmark from "@/icons/Checkmark";

export const TABLE_IMAGE_SIZE = {
  SMALL:
    "ct-w-5 [&~span]:ct-bottom-[-1px] [&~span]:ct-right-[-1px] [&~span]:ct-w-2 [&~span]:ct-h-2 [&~span>svg]:ct-w-1 [&~span>svg]:ct-h-1",
  MEDIUM:
    "ct-w-5.5 [&~span]:ct-bottom-[-2px] [&~span]:ct-right-[-2px] [&~span]:ct-w-2.5 [&~span]:ct-h-2.5 [&~span>svg]:ct-w-1.5 [&~span>svg]:ct-h-1.5",
  LARGE:
    "ct-w-6 [&~span]:ct-bottom-[-2px] [&~span]:ct-right-[-2px] [&~span]:ct-w-2.5 [&~span]:ct-h-2.5 [&~span>svg]:ct-w-1.5 [&~span>svg]:ct-h-1.5",
};

export const TABLE_IMAGE_STATE = {
  REJECTED: {
    color: "ct-bg-red",
    icon: <Cross />,
  },
  APPROVED: {
    color: "ct-bg-green",
    icon: <Checkmark />,
  },
};
