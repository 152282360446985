import React, { useEffect, useState } from 'react';
import RegistrationsService from '../../services/registrations_service';
import { INTEGRATION_TYPES } from '../../config/constants';

function XeroSignup() {
  const [xeroRedirectionUrl, setXeroRedirectionUlr] = useState(null);

  useEffect(() => {
    RegistrationsService
      .redirectionUrl({ integrationType: INTEGRATION_TYPES.XERO })
      .then(({ redirectionUrl }) => {
        setXeroRedirectionUlr(redirectionUrl);
      });
  }, []);

  return (
    <>
      <p className="margin-bottom-5 margin-top-8 text-center">
        {I18n.t('registrations.xero.new.info')}
      </p>
      <p className="margin-bottom-8 text-center">
        {xeroRedirectionUrl && <a className="button-xero-connect" href={xeroRedirectionUrl} />}
      </p>
    </>
  );
}
export default XeroSignup;
