import React, { useState } from 'react';
import Activity from '../purchase_order/activities/activity';

export default function CommentsSection({ activities }) {
  const [commentsSectionVisible, setCommentsSectionVisible] = useState(true);

  const toggleCommentsSection = () => setCommentsSectionVisible(!commentsSectionVisible);

  return (
    <div className="table-collapse">
      <div className="table-header">
        <span>{I18n.t('purchase_order_preview.comments')}</span>
        <span className={`icon-keyboard_arrow_${commentsSectionVisible ? 'up' : 'down'}`} onClick={toggleCommentsSection} />
      </div>
      {commentsSectionVisible && (
        <div className="form-section-content chat">
          <div className="comments">
            { activities.map(activity => (
              <Activity
                activity={activity}
                key={`${activity.id}`}
              />
            )) }
          </div>
        </div>
      )
      }
    </div>
  );
}
