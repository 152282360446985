import PropTypes from 'prop-types';
import React from 'react';
import Add from '@/icons/Add';
import { iconClasses } from '@/components/uiElements/filters/constants';
import MultiSelect from '@/components/uiElements/inputs/selects/MultiSelect';
import FilterBase from '@/components/uiElements/filters';

export default function More({ title, ...rest }) {
  return (
    <FilterBase
      inputElement={(
        <MultiSelect
          noInput
          noPortal
          id="more-filters"
          name="more-filters"
          {...rest}
        />
      )}
    >
      <Add className={iconClasses} />
      {title}
    </FilterBase>
  );
}

More.propTypes = {
  title: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};
