import React, { useState } from 'react';
import * as URI from 'urijs';
import FilterDropdown from '@/components/common/filters/filter_dropdown';
import TransactionsUploadModal from '@/components/purchase_order/modals/transactions_upload_modal';
import DateFilterInput from './date_filter_input';
import IntegrationRefresh from '../../integrations/shared/integration_refresh';
import Search from './search';

const PurchaseOrderFilters = ({
  filterActions, filters, currentFilters, onStateFilter, onFilter,
  onRemoveFilter, onDateFilter, onQueryFilter, clearDate, integrationRefesh,
  onRemoveStateFilter, openAutoMatchModal = () => { }, isProcessing, isIntegrated = false,
  onRefresh = () => { }, exportEnable = true, baseExportPath = 'invoices', refreshInvoices,
  importTransactions = false, dateFormat, timeZoneOffset,
}) => {
  const [currentName, setCurrentName] = useState(URI(window.location.href).query(true).state || 'open');
  const [openTransactionsUploadModal, setOpenTransactionsModal] = useState(false);

  const importFile = format => `${window.location.origin}/api/accountings/${baseExportPath}.${format}${window.location.search}`;

  return (
    <div className="table-actions">
      <div className="filters t-under-hide">
        {filterActions.map(actionName => (
          ((!integrationRefesh && actionName !== 'projects') || integrationRefesh) && (
            <FilterDropdown
              key={actionName}
              actionName={actionName}
              filters={filters}
              onFilter={onFilter}
              onRemoveFilter={onRemoveFilter}
              onStateFilter={onStateFilter}
              onRemoveStateFilter={onRemoveStateFilter}
              currentName={currentName}
              updateCurrentName={setCurrentName}
              defaultValue={currentFilters[actionName]}
            />
          )))}
        <DateFilterInput
          onDateFilter={onDateFilter}
          onRemoveFilter={onRemoveFilter}
          clearDate={clearDate}
          dateFrom={currentFilters.date_from}
          dateTo={currentFilters.date_to}
          label={I18n.t('purchase_orders.date_raised')}
          dateFormat={dateFormat}
          timeZoneOffset={timeZoneOffset}
        />
      </div>
      <div className="right-actions">
        <div className="items items-divided">
          <Search onQueryFilter={onQueryFilter} currentFilters={currentFilters} />
          <React.Fragment>
            {importTransactions && (
              <div>
                <a className="button button-primary button-s" href="javascript: void(0)" onClick={() => setOpenTransactionsModal(!openTransactionsUploadModal)}>
                  {I18n.t('commons.filters.import')}
                </a>
              </div>
            )}
            {exportEnable && (
              <div className="has-popup">
                <input type="checkbox" />
                <a className="button button-primary button-s" href="javascript: void(0)">
                  {I18n.t('commons.filters.export')}
                </a>
                <ul className="popup menu top-left min-width-1">
                  <li className="item">
                    <a className="anchor" href={importFile('csv')}>
                      CSV
                    </a>
                  </li>
                  <li className="item">
                    <a className="anchor" href={importFile('xlsx')}>
                      XLSX
                    </a>
                  </li>
                </ul>
              </div>
            )}
            {integrationRefesh ? (
              <>
                {isIntegrated && (
                  <div className="control link">
                    <button
                      onClick={openAutoMatchModal}
                      className="button transparent button-mute inverse button-icon button-s will-processing"
                    >
                      <i className="icon-notification-settings" />
                    </button>
                  </div>
                )}
                <IntegrationRefresh
                  onRefresh={onRefresh}
                  isProcessing={isProcessing}
                  isIntegrated={isIntegrated}
                  linkClass="no-divide"
                />
              </>
            ) : null}
            {openTransactionsUploadModal
              ? (
                <TransactionsUploadModal
                  openTransactionsUploadModal={openTransactionsUploadModal}
                  onToggleTransactionsUploadModal={() => setOpenTransactionsModal(!openTransactionsUploadModal)}
                  refreshInvoices={refreshInvoices}
                />
              )
              : null
            }
          </React.Fragment>
        </div>
      </div>
    </div>
  );
};

export default PurchaseOrderFilters;
