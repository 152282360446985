import React, { useState } from 'react';
import RangeDateFieldComponent from './index';

export default {
  component: RangeDateField,
  title: 'Form/Inputs/RangeDateField',
  args: {
    label: 'Date range',
    error: '',
    placeholder: 'Enter date',
    disableRanges: false,
    required: false,
    disabled: false,
  },
  argTypes: {
    label: {
      control: 'text',
    },
    error: {
      control: 'text',
    },
    placeholder: {
      control: 'text',
    },
    disableRanges: {
      control: 'boolean',
    },
    required: {
      control: 'boolean',
    },
    disabled: {
      control: 'boolean',
    },
  },
};

export const RangeDateField = (args) => {
  const [value, setValue] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);

  return (
    <div style={{ width: '250px' }}>
      <RangeDateFieldComponent
        value={value}
        onChange={setValue}
        name="storybook-date-field"
        {...args}
      />
    </div>
  );
};
