/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { HookableComponent } from 'react-hookable-component';
import { NumericFormat } from 'react-number-format';
import FileFormatValidator from '../../shared/file_format_validator';

import { withLimit } from '../../shared/utils';

import * as Toast from '../../shared/toast';

import CurrencyInput from './currency_input';
import InvoicesService from '../../../services/invoices_service';
import RateService from '../../../services/rate_service';
import SupplierInput from './supplier_input';

import SupplierModel from '../../../models/supplier_model';
import Loader from '../../loader';
import InvoiceModel from '../../../models/invoice_model';

import { usePurchaseOrderContext } from '../../../pages/purchaseOrder/context';

class TransactionModal extends HookableComponent {
  static propTypes = {
    onToggleTransactionModal: PropTypes.func.isRequired,
    companyCurrency: PropTypes.string.isRequired,
    invoiceId: PropTypes.number,
    purchaseOrderId: PropTypes.number,
    purchaseOrderSupplierId: PropTypes.number,
    purchaseOrderCurrency: PropTypes.string.isRequired,
    amountFormat: PropTypes.shape({ delimiter: PropTypes.string, thousandSeparator: PropTypes.string, precision: PropTypes.number }),
  };

  static defaultProps = {
    invoiceId: null,
    purchaseOrderId: null,
    purchaseOrderSupplierId: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      invoice: new InvoiceModel({}),
      suppliers: [],
      currencies: [],
      isLoading: true,
      exchangeRate: 1,
      errors: {},
      searchValue: '',
      typingTimeout: 0,
      displayCurrencyValue: 0,
      displayValue: 0,
    };
  }

  componentDidMount = () => {
    const updateStates = ({
      invoice, suppliers, currencies, exchangeRate,
    }) => {
      const { purchaseOrderSupplierId, companyCurrency, purchaseOrderCurrency } = this.props;
      if (!invoice.supplierId) {
        invoice.supplierId = purchaseOrderSupplierId;
      }

      if (!invoice.transactionCurrency) {
        invoice.transactionCurrency = purchaseOrderCurrency || companyCurrency;
        this.setExchangeRateAndCalculateFor(invoice);
      }

      this.mainSuppliers = suppliers;

      this.setState({
        invoice,
        suppliers: withLimit(suppliers),
        currencies,
        exchangeRate,
        isLoading: false,
        displayCurrencyValue: invoice.currencyValue,
        displayValue: invoice.value,
      });
    };

    const { invoiceId } = this.props;

    if (invoiceId) {
      InvoicesService.show(invoiceId).then(updateStates);
    } else {
      InvoicesService.new().then(updateStates);
    }
  };

  prepareInvoiceFromEvent = (e) => {
    const { invoice } = this.state;
    const { name, value } = e.target;
    invoice[name] = value;
    return invoice;
  };

  onChange = (e) => {
    const invoice = this.prepareInvoiceFromEvent(e);
    this.setState({ invoice });
  };

  onDateChange = (date, dateName) => {
    const { invoice } = this.state;
    invoice[dateName] = date;
    this.setState({ invoice });
  };

  onChangeCurrency = (e) => {
    const invoice = this.prepareInvoiceFromEvent(e);
    this.setExchangeRateAndCalculateFor(invoice);
  };

  onChangeSearchSupplier = (e) => {
    const { typingTimeout, searchValue } = this.state;
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    this.setState({
      searchValue: e.target.value,
      typingTimeout: setTimeout(() => {
        const {
          state: { invoice },
        } = this;
        this.setState({
          suppliers: withLimit(
            this.mainSuppliers.filter(
              supplier => supplier.id === invoice.supplierId || supplier.name.toLowerCase().includes(searchValue.toLowerCase()),
            ),
          ),
        });
      }, 350),
    });
  };

  onCurrencyValueChange = (values) => {
    const { exchangeRate, invoice } = this.state;
    const { value, formattedValue } = values;
    const newValue = value * exchangeRate;
    invoice.value = newValue || null;
    invoice.currencyValue = value;
    this.setState({ invoice, displayCurrencyValue: formattedValue, displayValue: newValue });
  };

  onValueChange = (values) => {
    const { value, formattedValue } = values;
    const { invoice } = this.state;
    invoice.value = value;
    this.setState({ invoice, displayValue: formattedValue });
  };

  calculateValue = () => {
    const { invoice, exchangeRate } = this.state;
    const value = invoice.currencyValue * exchangeRate;

    invoice.value = value || null;
    this.setState({ invoice, displayValue: value * 100 });
  };

  setExchangeRateAndCalculateFor = (invoice) => {
    const { companyCurrency } = this.props;
    RateService.findBy(invoice.transactionCurrency, companyCurrency).then((value) => {
      this.setState({ exchangeRate: value, invoice }, () => {
        this.calculateValue();
      });
    });
  };

  setFile = (e) => {
    const { invoice } = this.state;
    [invoice.attachment] = e.target.files;
    invoice.attachmentName = invoice.attachment.name;
    if (invoice.attachment.size > 20000000) {
      Toast.errorToast(I18n.t('purchase_orders.form.deliveries.to_big_file_size'));
    } else if (
      FileFormatValidator.valid(
        invoice.attachment,
        /(\.jpg|\.jpeg|\.gif|\.png|\.pdf|\.xlsx|\.xls|\.doc|\.docx|\.txt|\.rar|\.zip|\.eml|\.msg|\.csv)$/i,
      )
    ) {
      Toast.errorToast(I18n.t('purchase_orders.form.deliveries.wrong_file_format'));
    } else {
      this.setState({ invoice });
    }
  };

  create = (e, actionAfter) => {
    e.preventDefault();
    const { purchaseOrderId, onToggleTransactionModal } = this.props;
    const { invoice } = this.state;
    if (purchaseOrderId) {
      invoice.purchaseOrderId = purchaseOrderId;
      this.setState({ invoice });
    }
    new InvoicesService().create(invoice.toFileParams()).then(({ invoice: createdInvoice, errors }) => {
      if (createdInvoice) {
        onToggleTransactionModal(createdInvoice.id, true);
        actionAfter();
      } else if (errors.base?.length > 0) Toast.errorToast(errors.base.join(' '));
      else this.setState({ errors });
    });
  };

  update = (e, actionAfter) => {
    e.preventDefault();
    const {
      state: {
        invoice: { toFileParams, id },
      },
      props: { onToggleTransactionModal },
    } = this;
    new InvoicesService().update(toFileParams(), id).then(({ invoice, errors }) => {
      if (invoice) {
        onToggleTransactionModal(invoice.id, true);
        actionAfter();
      } else if (errors.base?.length > 0) Toast.errorToast(errors.base.join(' '));
      else this.setState({ errors });
    });
  };

  render() {
    const {
      state: {
        invoice, suppliers, currencies, errors, isLoading, displayCurrencyValue, displayValue,
      },
      props: {
        onToggleTransactionModal, invoiceId, companyCurrency, dateFormat, amountFormat,
      },
    } = this;

    const { paymentPlanStore } = usePurchaseOrderContext();
    const refreshPaymentPlan = paymentPlanStore.refreshRecord;
    const { delimiter, separator } = amountFormat;

    const htmlClasses = isLoading ? ' has-loading' : '';

    return (
      <React.Fragment>
        <input
          autoComplete="off"
          className="modal-open"
          id="new-transaction-modal"
          name="transaction-modal"
          type="radio"
          defaultChecked
          readOnly
        />
        <div className="modal new-transaction-modal centered sub-modal modal-l">
          <div className="modal-wrapper">
            <div className="modal-window window">
              <div className="window-header modal-header modal-header-auto">
                <h1>{I18n.t('purchase_orders.new_transaction.new_transaction')}</h1>
              </div>
              <div className={`window-content ${htmlClasses}`}>
                <Loader />
                <div className="grid space-3 gutter-5 vertical-start">
                  <div className="cell-8 grid space-2 t-cell-16">
                    <div className="row">
                      <div className="cell">
                        <div className="as-input">
                          <span className="label">{I18n.t('purchase_orders.new_transaction.date')}</span>
                          <DatePicker
                            selected={invoice.defaultDate('docDate', dateFormat)}
                            onChange={date => this.onDateChange(date, 'docDate')}
                            readOnly={false}
                            dateFormat={dateFormat}
                            locale={I18n.locale}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="cell">
                        <div className="as-input">
                          <span className="label">{I18n.t('purchase_orders.new_transaction.due_date')}</span>
                          <DatePicker
                            selected={invoice.defaultDate('dueDate', dateFormat)}
                            onChange={date => this.onDateChange(date, 'dueDate')}
                            readOnly={false}
                            dateFormat={dateFormat}
                            locale={I18n.locale}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="cell">
                        <div className="as-input">
                          <span className="label">{I18n.t('purchase_orders.new_transaction.invoice_number')}</span>
                          <input
                            type="text"
                            name="invoiceNumber"
                            value={invoice.invoiceNumber}
                            onChange={this.onChange}
                            placeholder={I18n.t('purchase_orders.new_transaction.enter_invoice_number')}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="cell">
                        <div className="as-input">
                          <span className="label">{I18n.t('purchase_orders.new_transaction.supplier')}</span>
                          <span className="select react-only">
                            <input type="checkbox" />
                            <div className="control icon-search">
                              <input
                                type="text"
                                tabIndex="-1"
                                onChange={this.onChangeSearchSupplier}
                                placeholder={I18n.t('purchase_orders.form.type_to_search')}
                              />
                            </div>
                            <div className="select-content">
                              {suppliers.map(supplier => (
                                <SupplierInput
                                  onChangeSupplier={this.onChange}
                                  key={supplier.id}
                                  supplier={new SupplierModel(supplier)}
                                  invoiceSupplierId={invoice.supplierId}
                                />
                              ))}
                              <div
                                className="placeholder"
                                data-placeholder={I18n.t('purchase_orders.new_transaction.select_supplier')}
                                data-selected={I18n.t('purchase_orders.form.selected')}
                              />
                            </div>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className={`cell ${errors.currency ? 'has-error' : ''}`}>
                        <div className="as-input required">
                          <span className="label">{I18n.t('purchase_orders.new_transaction.currency')}</span>
                          <span className="select">
                            <input type="checkbox" />
                            <div className="select-content">
                              {currencies.map(currency => (
                                <CurrencyInput
                                  onChangeCurrency={this.onChangeCurrency}
                                  key={currency}
                                  currency={currency}
                                  transactionCurrency={invoice.transactionCurrency || companyCurrency}
                                />
                              ))}
                              <div
                                className="placeholder"
                                data-placeholder={I18n.t('purchase_orders.new_transaction.select_currency')}
                                data-selected={I18n.t('purchase_orders.form.selected')}
                              />
                            </div>
                          </span>
                          {errors.currency ? (
                            <div className="hint">{errors.currency.join(', ')}</div>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className={`cell ${errors.currency_value_cents ? 'has-error' : ''}`}>
                        <div className="as-input required">
                          <span className="label">{I18n.t('purchase_orders.new_transaction.value')}</span>
                          <NumericFormat
                            name="currencyValue"
                            onClick={e => e.target.select()}
                            decimalScale={2}
                            allowedDecimalSeparators={[',', '.']}
                            fixedDecimalScale
                            value={displayCurrencyValue || ''}
                            onValueChange={values => this.onCurrencyValueChange(values)}
                            thousandSeparator={delimiter || ' '}
                            placeholder={`0${separator}00`}
                            decimalSeparator={separator || '.'}
                          />
                          {errors.currency_value_cents ? (
                            <div className="hint">{errors.currency_value_cents.join(', ')}</div>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className={`cell ${errors.value_cents ? 'has-error' : ''}`}>
                        <div className="as-input required">
                          <span className="label">
                            {I18n.t('purchase_orders.new_transaction.value_in_company_currency')}
                          </span>
                          <div className="control currency" data-addon={companyCurrency}>
                            <NumericFormat
                              name="value"
                              onClick={e => e.target.select()}
                              decimalScale={2}
                              allowedDecimalSeparators={[',', '.']}
                              fixedDecimalScale
                              value={displayValue || ''}
                              onValueChange={values => this.onValueChange(values)}
                              thousandSeparator={delimiter || ' '}
                              placeholder={`0${separator}00`}
                              decimalSeparator={separator || '.'}
                            />
                            {errors.value_cents ? <div className="hint">{errors.value_cents.join(', ')}</div> : ''}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="cell-8 grid space-2 t-cell-16">
                    <div className="row">
                      <div className="cell-8 tp-cell-16">
                        <div className="as-input">
                          <span className="label">{I18n.t('purchase_orders.new_transaction.gl_account')}</span>
                          <input
                            type="text"
                            name="accountName"
                            value={invoice.accountName}
                            onChange={this.onChange}
                            placeholder={I18n.t('purchase_orders.new_transaction.enter_gl_account')}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="cell">
                        <div className="as-input">
                          <span className="label">{I18n.t('purchase_orders.new_transaction.project')}</span>
                          <input
                            type="text"
                            name="project"
                            value={invoice.project}
                            onChange={this.onChange}
                            placeholder={I18n.t('purchase_orders.new_transaction.enter_project')}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className={`cell ${errors.description ? 'has-error' : ''}`}>
                        <div className="as-input">
                          <span className="label">{I18n.t('purchase_orders.new_transaction.description')}</span>
                          <textarea
                            name="description"
                            value={invoice.description}
                            onChange={this.onChange}
                            placeholder={I18n.t('purchase_orders.new_transaction.enter_description')}
                          />
                          {errors.description ? <div className="hint">{errors.description.join(', ')}</div> : ''}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className={`cell ${errors.attachment ? 'has-error' : ''}`}>
                        <div className="as-input">
                          <span className="label">{I18n.t('purchase_orders.new_transaction.attach_document')}</span>
                          <label className="file-button controll grid has-progress">
                            <div className="button block button-primary file-name no-shadow cell offset-2 offset-right-2">
                              {I18n.t('purchase_orders.new_transaction.select_file')}
                            </div>
                            <input className="file-upload" type="file" onChange={this.setFile} />
                            {invoice.attachmentName ? (
                              <p className="state-info  offset-2 cell">{invoice.attachmentName}</p>
                            ) : null}
                            {errors.attachment ? <div className="hint">{errors.attachment.join(', ')}</div> : ''}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="window-footer modal-footer modal-footer-space">
                <div className="items">
                  <label
                    className="button button-transparent button-mute inverse link"
                    onClick={onToggleTransactionModal}
                  >
                    {I18n.t('purchase_orders.new_transaction.cancel')}
                  </label>
                  <button
                    type="button"
                    className="button button-primary"
                    onClick={e => (invoiceId ? this.update(e, refreshPaymentPlan) : this.create(e, refreshPaymentPlan))
                    }
                  >
                    {I18n.t('purchase_orders.new_transaction.save')}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <label className="modal-backdrop" onClick={onToggleTransactionModal} />
        </div>
      </React.Fragment>
    );
  }
}

export default TransactionModal;
