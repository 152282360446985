import React from 'react';
import PropTypes from 'prop-types';
import Activity from './activities/activity';
import Comments from './comments';

class Activities extends React.Component {
  static propTypes = {
    activities: PropTypes.array,
    editable: PropTypes.bool,
  }

  constructor(props) {
    super(props);

    this.updateActivities = this.updateActivities.bind(this);
    this.addActivity = this.addActivity.bind(this);

    const { activities } = props;
    this.state = {
      activities,
    };
  }

  addActivity(activity) {
    const { activities } = this.state;
    this.setState({ activities: [activity].concat(activities) });
  }

  updateActivities(activities) {
    this.setState({ activities });
  }

  render() {
    const { activities } = this.state;
    return (
      <div className="form-section-content chat">
        <div className="comments">
          { activities.map(activity => (
            <Activity
              activity={activity}
              key={`${activity.id}`}
            />
          )) }
        </div>
        <Comments updateActivities={this.updateActivities} addActivity={this.addActivity} />
      </div>
    );
  }
}

export default Activities;
