import React from "react";

import TextField from "./index";

export default {
  title: "Form/TextField",
  component: TextField,
  argTypes: {
    
  },
};

const Template = (args) => <TextField {...args} />;

export const Default = Template.bind({});

TextField.args = {};
