import React from 'react';

class TableHead extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sort: props.order.by,
      sortType: props.order.type || 'asc',
    };
  }

  onSort = (e) => {
    if (e.target.getAttribute('data-sortable') === 'false') return;
    const name = e.target.getAttribute('data-name');

    const { state: { sortType, sort }, props: { onOrder } } = this;
    let newSortType = sortType;
    if (sort === name) {
      newSortType = sortType === 'desc' ? 'asc' : 'desc';
    }

    this.setState({ sort: name, sortType: newSortType }, () => {
      onOrder(this.state);
    });
  }

  _renderSortArrow = (name) => {
    const { sort, sortType } = this.state;

    if (sort !== name) return;

    return (
      <a className={`sort ${sortType}`} />
    );
  }

  _renderTranslation = (header) => {
    const translation = header.translation ? I18n.t(header.translation, header.attributes) : '';

    return header.displayName ? header.displayName : translation;
  }

  renderHeaderClass = (className) => {
    if (className) return `th ${className}`;
    return 'th';
  }

  render() {
    const { props: { headers, onBulkSelectAll, allPurchaseOrdersInvoiced }, renderHeaderClass } = this;

    return (
      <div className="thead">
        <div className="tr">
          { !!onBulkSelectAll
            && (
            <div className="th min-width-0">
              <label className="has-checkbox">
                <input className="count-selected" type="checkbox" checked={allPurchaseOrdersInvoiced} onChange={onBulkSelectAll} />
                <span className="checkbox input-s margin-right-0" />
              </label>
            </div>
            )
          }
          { headers.map(header => (
            <div
              key={header.name}
              className={renderHeaderClass(header.class)}
              data-name={header.name}
              data-sortable={header.sortable !== undefined ? header.sortable : true}
              onClick={this.onSort}
            >
              {this._renderTranslation(header)}
              {this._renderSortArrow(header.name)}
            </div>
          )) }
        </div>
      </div>
    );
  }
}

export default TableHead;
