import React from 'react';

export default function AttachPurchaseOrderSetup({
  onToggle, onChange, matchViaPoNumber, matchViaDescription,
  matchViaProjectSupplier, matchViaSupplierPercentageValue,
  supplierPercentageValue, matchViaSupplierNumberValue,
  supplierNumberValue,
}) {
  return (
    <div className="checkbox-list short-list">
      <p>
        <label className="has-checkbox item">
          <input
            type="checkbox"
            checked={matchViaPoNumber}
            name="matchViaPoNumber"
            value={1}
            onChange={onToggle}
          />
          <span className="checkbox input-s" />
        </label>
        {I18n.t('settings.integrations.new.setup.po_number')}
      </p>
      <p>
        <label className="has-checkbox item">
          <input
            type="checkbox"
            checked={matchViaDescription}
            name="matchViaDescription"
            value={1}
            onChange={onToggle}
          />
          <span className="checkbox input-s" />
        </label>
        {I18n.t('settings.integrations.new.setup.po_description')}
      </p>
      <p>
        <label className="has-checkbox item">
          <input
            type="checkbox"
            checked={matchViaProjectSupplier}
            name="matchViaProjectSupplier"
            value={1}
            onChange={onToggle}
          />
          <span className="checkbox input-s" />
        </label>
        {I18n.t('settings.integrations.new.setup.po_project_supplier')}
      </p>
      <p>
        <label className="has-checkbox item">
          <input
            type="checkbox"
            name="matchViaSupplierPercentageValue"
            onChange={onToggle}
            value={1}
            checked={matchViaSupplierPercentageValue}
          />
          <span className="checkbox input-s" />
        </label>
        {I18n.t('settings.integrations.new.setup.supplier_value')}
        <span className="control" data-addon="%">
          <input
            className="width-06 input-s"
            type="text"
            value={supplierPercentageValue}
            disabled={!matchViaSupplierPercentageValue}
            onChange={onChange}
            name="supplierPercentageValue"
          />
        </span>
        {I18n.t('settings.integrations.new.setup.of_po_value')}
      </p>
      <p>
        <label className="has-checkbox item">
          <input
            type="checkbox"
            name="matchViaSupplierNumberValue"
            value={1}
            onClick={onToggle}
            checked={matchViaSupplierNumberValue}
          />
          <span className="checkbox input-s" />
        </label>
        {I18n.t('settings.integrations.new.setup.supplier_value')}
        <span className="control">
          <input
            className="width-07 input-s"
            type="text"
            value={supplierNumberValue}
            onChange={onChange}
            disabled={!matchViaSupplierNumberValue}
            name="supplierNumberValue"
          />
        </span>
        {`${I18n.t('settings.integrations.new.setup.of_po_value')} *`}
      </p>
    </div>
  );
}
