import { SortableElement } from 'react-sortable-hoc';
import React from 'react';
import DescriptionLine from './description_line';

const SortableLine = SortableElement(({
  onDescriptionLineChange, descriptionLine, onDeleteDescriptionLine,
  saveDraft, editable, calculationMethod, showDelivery,
}) => (
  <DescriptionLine
    descriptionLine={descriptionLine}
    onDescriptionLineChange={onDescriptionLineChange}
    onDeleteDescriptionLine={onDeleteDescriptionLine}
    saveDraft={saveDraft}
    editable={editable}
    calculationMethod={calculationMethod}
    showDelivery={showDelivery}
  />
));

export default SortableLine;
