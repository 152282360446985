export default {
  DRAFT: 'ct-bg-status-draft-bg ct-text-status-draft-text',
  PLANNED: 'ct-bg-status-planned-bg ct-text-status-planned-text',
  SENT_FOR_APPROVAL:
    'ct-bg-status-sent-for-approval-bg ct-text-status-sent-for-approval-text',
  APPROVED: 'ct-bg-status-approved-bg ct-text-status-approved-text',
  REJECTED: 'ct-bg-status-rejected-bg ct-text-status-rejected-text',
  SENT_TO_SUPPLIER:
    'ct-bg-status-sent-to-supplier-bg ct-text-status-sent-to-supplier-text',
  CONFIRMED: 'ct-bg-status-confirmed-bg ct-text-status-confirmed-text',
  INVOICED: 'ct-bg-status-invoiced-bg ct-text-status-invoiced-text',
  RECEIVED: 'ct-bg-status-received-bg ct-text-status-received-text',
  COMPLETED: 'ct-bg-status-completed-bg ct-text-status-completed-text',
  DELETED: 'ct-bg-status-deleted-bg ct-text-status-deleted-text',
};
