export const OPTION_SIZE = {
  SMALL: '6px 16px',
  MEDIUM: '8px 22px',
  LARGE: '12px 22px',
  MULTI: '6px 10px',
};

const fontSetup = (provided, optionSize) => ({
  ...provided,
  fontSize: '12px',
  lineHeight: '15px',
  padding: optionSize,
});

export const applyStyles = (
  optionSize = OPTION_SIZE.SMALL,
  noInput = false,
  table,
  error,
) => ({
  control: (provided, { isFocused, menuIsOpen, selectProps: { noIcon, value, className } }) => ({
    ...provided,
    fontSize: '14px',
    lineHeight: '20px',
    border: 'none',
    boxShadow: 'none',
    margin: 0,
    width: '100%',
    minHeight: '36px',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    display: noInput ? 'none' : 'flex',
    backgroundColor:
        (isFocused || menuIsOpen) && !table && !noIcon
          ? 'rgba(242, 243, 247, 0.3)'
          : 'transparent',

    '&:hover': (className.includes('has-preview') && value && !menuIsOpen) && {
      position: 'relative',
      zIndex: 10,

      '.select__value-container': {
        marginLeft: table && '-8px',
        padding: '8px',
        overflow: 'visible',
        position: 'absolute',
        minWidth: '100%',
        width: 'fit-content',
        backgroundColor: 'white',
        boxShadow: '0.391px 0.921px 4px rgba(21, 16, 60, 0.22)',

        '& > div': {
          overflow: 'visible',
        },

        '& > div > div > span': {
          display: 'block',
          height: '100%',
          width: 'fit-content',
          overflow: 'normal',
          textOverflow: 'clip',
          whiteSpace: 'nowrap',
        },
      },

      '.select__indicators': {
        display: 'none',
      },
    },
  }),
  valueContainer: provided => ({
    ...provided,
    margin: 0,
    padding: !table ? '8px' : '8px 0',
  }),
  singleValue: provided => ({
    ...provided,
    margin: 0,
  }),
  input: provided => ({
    ...provided,
    margin: 0,
    padding: 0,
    display: 'flex',
  }),
  placeholder: provided => ({
    ...provided,
    margin: 0,
    padding: 0,
    color: table && error ? '#FF4049' : '#9AA6C3',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    margin: 0,
    padding: 0,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  clearIndicator: provided => ({
    ...provided,
    padding: '8px',
    cursor: 'pointer',
    svg: {
      width: '14px',
      height: '14px',

      path: {
        fill: '#C7CEDE',
      },
    },
  }),
  dropdownIndicator: provided => ({
    ...provided,
    margin: 0,
    padding: !table ? '8px' : '8px 0',
    cursor: 'pointer',

    svg: {
      width: '9px',
      height: 'auto',

      path: {
        fill: '#000000',
      },
    },
  }),
  menuPortal: provided => ({
    ...provided,
    zIndex: 10000,
  }),
  menu: provided => ({
    ...provided,
    width: 'fit-content',
    minWidth: '100%',
    margin: '3px 0 0',
    padding: 0,
    overflow: 'hidden',
    borderRadius: '2px',
    boxShadow: '0.391px 0.921px 4px rgba(21, 16, 60, 0.22)',
  }),
  menuList: provided => ({
    ...provided,
    padding: 0,
  }),
  loadingMessage: provided => fontSetup(provided, optionSize),
  noOptionsMessage: provided => fontSetup(provided, optionSize),
  option: (provided, { isSelected, selectProps }) => {
    const { isMulti } = selectProps;
    const isSingleSelected = isSelected && !isMulti;

    return {
      ...fontSetup(provided, optionSize),
      display: 'flex',
      alignItems: 'center',
      overflow: 'hidden',
      justifyContent: selectProps.isMulti ? 'flex-start' : 'space-between',
      gap: '10px',
      backgroundColor: isSingleSelected ? ' #408CF8' : '#FFFFFF',
      cursor: 'pointer',

      '&:nth-of-type(even)': {
        backgroundColor: isSingleSelected ? '#408CF8' : '#F6F7FB',
      },

      ':hover': {
        backgroundColor: '#E0ECFD',
        span: {
          color: '#434343',
        },
        'svg path': {
          fill: isSingleSelected ? '#408CF8' : '#ffffff',
        },
      },

      span: {
        display: 'block',
        width: 'fit-content',
        whiteSpace: 'nowrap',
        color: isSingleSelected ? '#ffffff' : '#434343',
      },
    };
  },
});
