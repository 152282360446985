import Model from './model';
import PlannedPaymentModel from './PlannedPaymentModel';

class PaymentPlanModel extends Model {
  constructor({ plannedPayments = [], ...attributes }) {
    super(attributes);

    this.plannedPayments = plannedPayments.map(plannedPayment => new PlannedPaymentModel(plannedPayment)) || [];
  }
}

export default PaymentPlanModel;
