import React from 'react';
import PropTypes from 'prop-types';
import * as toast from '../../shared/toast';
import IntegrationsService from '../../../services/integrations_service';
import AutomaticMatchingModel from '../../../models/automatic_matching_model';
import IntegrationFilteringModel from '../../../models/integration_filtering_model';
import AttachPurchaseOrderSetup from '../../integrations/shared/attach_purchase_order_setup';
import ModalHeader from '../../shared/modal/modal_header';
import ModalFooter from '../../shared/modal/modal_footer';
import ModalWrapper from '../../shared/modal/modal_wrapper';
import ModalContent from '../../shared/modal/modal_content';

class AutoMatchModal extends React.Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    matchViaPoNumber: PropTypes.bool,
    matchViaDescription: PropTypes.bool,
    matchViaProjectSupplier: PropTypes.bool,
    matchViaSupplierPercentageValue: PropTypes.bool,
    matchViaSupplierNumberValue: PropTypes.bool,
    close: PropTypes.func,
  }

  constructor(props) {
    super(props);

    this.state = {
      automaticMatching: new AutomaticMatchingModel({}),
      integrationFiltering: new IntegrationFilteringModel({}),
    };

    IntegrationsService
      .settings({ integrationType: props.integrationType })
      .then(({ integrationFiltering, automaticMatching }) => {
        this.setState({ integrationFiltering, automaticMatching });
      });
  }

  componentDidMount() {
    document.addEventListener('keydown', this.escFunction, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.escFunction, false);
  }

  escFunction = (event) => {
    const { close } = this.props;
    if (event.key === 'Escape') {
      close();
    }
  }

  onToggle = (e) => {
    const { automaticMatching } = this.state;
    const { name } = e.target;
    automaticMatching[name] = !automaticMatching[name];

    this.setState({
      automaticMatching,
    });
  }

  onChange = (e) => {
    const { automaticMatching } = this.state;
    const { name, value } = e.target;
    automaticMatching[name] = value;

    this.setState({
      automaticMatching,
    });
  }

  onSave = () => {
    const {
      props: {
        integrationType,
        close,
        onRefresh,
      },
      state: {
        integrationFiltering,
        automaticMatching,
      },
    } = this;

    IntegrationsService
      .saveSettings({
        integrationType,
        automaticMatching,
        integrationFiltering,
      }).then(() => {
        this.setState({ errors: {} });
        toast.successToast(I18n.t('settings.integrations.new.setup.changes_saved'));
        onRefresh();
        close();
      }).catch((error) => {
        this.setState({ errors: error.response.data });
        toast.errorToast(I18n.t('settings.integrations.new.setup.error_save'));
      });
  }

  render() {
    const { close } = this.props;
    const {
      automaticMatching: {
        matchViaPoNumber,
        matchViaDescription,
        matchViaProjectSupplier,
        matchViaSupplierPercentageValue,
        matchViaSupplierNumberValue,
        supplierNumberValue,
        supplierPercentageValue,
      },
    } = this.state;

    return (
      <ModalWrapper
        type="centered modal-l"
        active
        closeModal={close}
        windowType="window-inside"
      >
        <ModalHeader
          additionalClass="window-header-auto padding-bottom-0"
        >
          <span>
            <h1>Set matching criterias</h1>
            <p className="mute">
              Transactions will be automatically matched in the order and according to the activated rules below:
            </p>
          </span>
        </ModalHeader>
        <ModalContent>
          <p className="mute">
            Match Transactions if:
          </p>
          <AttachPurchaseOrderSetup
            onChange={this.onChange}
            onToggle={this.onToggle}
            matchViaPoNumber={matchViaPoNumber}
            matchViaDescription={matchViaDescription}
            matchViaProjectSupplier={matchViaProjectSupplier}
            matchViaSupplierPercentageValue={matchViaSupplierPercentageValue}
            matchViaSupplierNumberValue={matchViaSupplierNumberValue}
            supplierNumberValue={supplierNumberValue}
            supplierPercentageValue={supplierPercentageValue}
          />
        </ModalContent>
        <ModalFooter>
          <div className="items">
            <label className="button button-transparent button-mute inverse link" onClick={close}>{I18n.t('purchase_orders.form.cancel')}</label>
            <label className="button button-primary" onClick={this.onSave}>{I18n.t('commons.actions.save')}</label>
          </div>
        </ModalFooter>
      </ModalWrapper>
    );
  }
}
export default AutoMatchModal;
