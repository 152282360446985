import * as Api from '../components/shared/api';

class RegistrationsService {
  static redirectionUrl = ({ integrationType }) => (
    Api.makeGetRequest(
      `/registrations/${integrationType}/redirection_url`,
    )
      .then(response => ({
        redirectionUrl: response.data.url,
      }))
  )

  static createCompany = body => (
    Api.makePostRequest(
      '/registrations/xero/create_company', body,
    )
      .then(response => ({
        errors: response.data.errors,
        path: response.data.path,
      }))
  )
}

export default RegistrationsService;
