import React from 'react';
import { BUTTON_VARIANT } from './constants';
import ButtonComponent from './index';

export default {
  component: Button,
  title: 'Design System/Buttons/Button',
  args: {
    children: 'Click me!',
    variant: BUTTON_VARIANT.PRIMARY,
    disabled: false,
  },
  argTypes: {
    children: {
      control: 'text',
    },
    variant: {
      options: Object.values(BUTTON_VARIANT),
      control: 'radio',
    },
    disabled: {
      control: 'boolean',
    },
  },
};

export const Button = ({ ...args }) => (
  <ButtonComponent
    variant={args.variant}
    disabled={args.disabled}
    onClick={() => window.alert('Clicked!')} // eslint-disable-line no-alert
  >
    {args.children}
  </ButtonComponent>
);
