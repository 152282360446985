import React from 'react';
import Comment from '@/icons/Comment';


const ReceiveGoodRow = ({
  purchaseOrder, onChangeBulkAction, anyOutdated, selectedPurchaseOrdersIds, onMarkAsReceived, markAsreceivedDisabled,
}) => {
  const onRedirect = (e) => {
    e.preventDefault();
    const supplierItemUrl = `/purchase_orders/${purchaseOrder.id}/edit?list=receive`;

    window.history.pushState({}, 'Receive Goods', '/purchase_orders/receive_goods');
    window.location.href = supplierItemUrl;
  };
  const receivedPercentageTooltip = `${purchaseOrder.received_percentage > 100.0 ? 100.0 : Number.parseFloat(purchaseOrder.received_percentage).toFixed(2)}% received`;

  return (
    <div className="tr" style={{ cursor: 'pointer' }}>
      <div className="td has-child">
        <label className="has-checkbox as-td">
          <input
            className="count-selected"
            type="checkbox"
            checked={selectedPurchaseOrdersIds.includes(purchaseOrder.id)}
            onChange={() => onChangeBulkAction(purchaseOrder.id)}
          />
          <span className="checkbox input-s" />
        </label>
        <div className="as-td has-ellipsis" data-ellipsis={purchaseOrder.po_number}>
          <div className="text-ellipsis w-7">
            <span onClick={onRedirect}>
              {purchaseOrder.po_number}
            </span>
          </div>
        </div>
      </div>
      <div onClick={onRedirect} className="td has-ellipsis" data-ellipsis={purchaseOrder.raised_date}>
        <div className="text-ellipsis w-7">{purchaseOrder.raised_date}</div>
      </div>
      <div onClick={onRedirect} className="td has-ellipsis" data-ellipsis={(purchaseOrder.supplier_name || I18n.t('purchase_orders.not_selected_yet'))}>
        <div className="text-ellipsis w-7">{purchaseOrder.supplier_name || I18n.t('purchase_orders.not_selected_yet')}</div>
      </div>
      <div onClick={onRedirect} className="td has-ellipsis" data-ellipsis={(purchaseOrder.budget_name || I18n.t('purchase_orders.not_selected_yet'))}>
        <div className="text-ellipsis w-10">{purchaseOrder.budget_name || I18n.t('purchase_orders.not_selected_yet')}</div>
      </div>
      <div onClick={onRedirect} className="td has-ellipsis" data-ellipsis={(purchaseOrder.delivery_address_name || I18n.t('purchase_orders.not_selected_yet'))}>
        <div className="text-ellipsis w-10">{purchaseOrder.delivery_address_name || I18n.t('purchase_orders.not_selected_yet')}</div>
      </div>
      <div onClick={onRedirect} className="td has-ellipsis" data-ellipsis={purchaseOrder.description}>
        <div className="text-ellipsis w-10">{purchaseOrder.description}</div>
      </div>
      <div onClick={onRedirect} className="td number price bold">{purchaseOrder.total_amount_monetize}</div>
      <div onClick={onRedirect} className="td center">{purchaseOrder.delivery_date}</div>
      <div onClick={onRedirect} className="td center">{purchaseOrder.confirmed_delivery_date}</div>
      <div onClick={onRedirect} className="td has-charts short">
        <div className="chart-inside has-tooltip">
          <div className="charts">
            <div
              className={`chart ${['completed', 'received'].includes(purchaseOrder.state) ? 'received' : 'goods'}`}
              style={{ width: `${Number.parseFloat(purchaseOrder.received_percentage).toFixed(2)}%` }}
            >
              <span />
            </div>
            <div
              className="chart has-tooltip null"
              style={{ width: `${100 - Number.parseFloat(purchaseOrder.received_percentage).toFixed(2)}%` }}
            >
              <span />
            </div>
          </div>
          <div className="tooltip">
            {receivedPercentageTooltip}
          </div>
        </div>
      </div>
      <div onClick={onRedirect} className="td has-status">
        <span className={`status capitalize ${purchaseOrder.state}`}>
          {I18n.t(`purchase_orders.statuses.${purchaseOrder.state}`)}
        </span>
      </div>
      <div className="td">
        <span className="avatar avatar has-tooltip">
          <img src={purchaseOrder.user_avatar} />
          <span className="tooltip center">
            {purchaseOrder.user_full_name}
          </span>
        </span>
      </div>
      <div className="td center">
        {
          purchaseOrder.comments_count > 0 && (
            <div className="has-tooltip">
              <Comment style={{ width: '24px', height: '24px' }} />
              <div className="tooltip center">
                {purchaseOrder.comments_count}
              </div>
            </div>
          )
        }
      </div>
      {
        anyOutdated && (
          <div className="td actions">
            {
              purchaseOrder.outdated && (
                <span className="edit has-tooltip">
                  <i className="icon-warning" />
                  <span className="tooltip right">
                    {I18n.t('errors.purchase_order.outdated_title')}
                  </span>
                </span>
              )
            }
          </div>
        )
      }
      <div className={`td actions sticky-right hoverable ${markAsreceivedDisabled ? 'disabled' : ''}`}>
        <a onClick={() => onMarkAsReceived(purchaseOrder.id)} className="edit">
          <i className="icon-check" />
        </a>
      </div>
    </div>
  );
};

export default ReceiveGoodRow;
