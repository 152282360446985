import React from 'react';
import useModal from '@/hooks/useModal';
import DeleteModalComponent from '@/components/modals/DeleteModal';


export const DeleteModal = (args) => {
  const [isOpen,, handleClose] = useModal();
  return <DeleteModalComponent {...args} isOpen={isOpen} handleClose={handleClose} handleDelete={() => {}} storybook />;
};

export default {
  component: DeleteModal,
  title: 'Modals',
  args: {
    name: 'invoice',
  },
  argTypes: {
    name: {
      control: 'text',
    },
  },
};
