import React, { useEffect, useState } from 'react';
import { FixedSizeList as List } from 'react-window';
import memoize from 'memoize-one';
import measureText from '@/components/shared/measure_text';
import FilterRow from '@/components/common/filters/filter_row';

export default function FilterWindowDropdown({
  actionName, filters, onFilter, onRemoveFilter, onStateFilter, onRemoveStateFilter, currentName, updateCurrentName,
  defaultValue,
}) {
  const [height, setHeight] = useState(300);
  const [width, setWidth] = useState(500);
  const [selected, setSelected] = useState(0);

  useEffect(() => {
    setWidth(calculateWidth());
    setHeight(calculateHeight());
  });

  const increaseSelected = () => {
    setSelected(currentSelected => currentSelected + 1);
  };

  const decreaseSelected = () => {
    setSelected(currentSelected => currentSelected - 1);
  };

  const createItemData = memoize((
    items, filterActionName, onFilterCallback, onRemoveFilterCallback,
    onStateFilterCallback, onRemoveStateFilterCallback, stateCurrentName, updateCurrentNameCallback,
    increaseSelectedFunc, decreaseSelectedFunc, filterDefaultValue,
  ) => ({
    items,
    actionName: filterActionName,
    onFilter: onFilterCallback,
    onRemoveFilter: onRemoveFilterCallback,
    onStateFilter: onStateFilterCallback,
    onRemoveStateFilter: onRemoveStateFilterCallback,
    currentName: stateCurrentName,
    updateCurrentName: updateCurrentNameCallback,
    increaseSelected: increaseSelectedFunc,
    decreaseSelected: decreaseSelectedFunc,
    defaultValue: filterDefaultValue,
    itemsCount: items.length,
  }));
  const itemData = createItemData(
    filters[actionName] || [], actionName, onFilter, onRemoveFilter,
    onStateFilter, onRemoveStateFilter, currentName, updateCurrentName,
    increaseSelected, decreaseSelected, defaultValue,
  );

  const calculateHeight = () => {
    const { items } = itemData;
    if (items.length > 11) {
      return 242;
    }
    return 22 * items.length;
  };

  const calculateWidth = () => {
    const { items } = itemData;
    if (items.length === 0) return width;
    const max = items.reduce((itemA, itemB) => (itemA.name.length > itemB.name.length ? itemA : itemB));

    return measureText(max.name?.toUpperCase()).width;
  };

  const getPlaceholder = () => {
    if (selected > 0) {
      return `${selected} ${I18n.t('purchase_orders.form.selected')}`;
    }

    if (actionName === 'invoice_states') {
      return 'states';
    }
    return actionName;
  };

  return (
    <span
      key={actionName}
      className={`select input-xs filter-select lazy-scroll can-unselect ${actionName === 'invoice_states' ? '' : 'count-selected'} ${selected === 0 ? '' : 'selected'}`}
    >
      <input type="checkbox" />
      <div className="select-content">
        <List
          itemCount={itemData.items.length}
          itemSize={22}
          height={height}
          width={width}
          itemData={itemData}
        >
          {FilterRow}
        </List>
        <div
          className="placeholder"
          data-selected={I18n.t('purchase_orders.form.selected')}
          data-placeholder={getPlaceholder()}
        />
      </div>
    </span>
  );
}
