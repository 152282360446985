import React from 'react';
import defaultSwitchPropTypes from './constants';

export default function Switch({
  value, onClick, disabled = false, ...rest
}) {
  return (
    <button
      {...rest}
      className="ct-flex ct-relative ct-items-center ct-w-fit ct-h-4 ct-rounded-md disabled:ct-opacity-50 focus-visible:ct-shadow-focus"
      type="button"
      onClick={onClick}
      disabled={disabled}
    >
      <div
        className={`ct-w-8 ct-h-2.5 ct-opacity-50 ct-transition-all ct-rounded-md ${
          value ? 'ct-bg-blue' : 'ct-bg-gray-switch'
        }`}
      />
      <span
        className={`ct-w-4 ct-h-4 ct-rounded-full ct-absolute ct-transition-all ct-shadow-switch ${
          value ? 'ct-bg-blue ct-left-4' : 'ct-bg-white ct-left-0'
        }`}
      />
    </button>
  );
}

Switch.propTypes = defaultSwitchPropTypes;
