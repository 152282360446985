import React from 'react';

function TableHead({
  headers, onSelectAllBulkAction, allBulkActionSelected, onSort,
}) {
  return (
    <div className="thead">
      <div className="tr">
        { headers.map(header => (
          <div
            key={header.name}
            className={`th ${header.class || ''}`}
            data-name={header.name}
            onClick={() => { if (!['supplier', 'global_product_number'].includes(header.name)) onSort(header); }}
          >
            {
            ['supplier', 'global_product_number'].includes(header.name)
              ? (
                <label className="has-checkbox">
                  <input
                    className="count-selected"
                    type="checkbox"
                    checked={allBulkActionSelected}
                    onChange={() => onSelectAllBulkAction()}
                  />
                  <span className="checkbox input-s" />
                </label>
              ) : null
            }
            <span onClick={() => { if (['supplier', 'global_product_number'].includes(header.name)) onSort(header); }}>
              {I18n.t(header.translation)}
            </span>
            {
              header.sortDirection !== ''
                ? (
                  <a className={`sort ${header.sortDirection}`} onClick={() => { if (header.name === 'supplier') onSort(header); }} />
                ) : null
            }
          </div>
        )) }
        <div className="th sticky-right" />
      </div>
    </div>
  );
}

export default TableHead;
