import React from 'react';

function PaginateWithDots({
  numberPages, selected, onPageChange,
}) {
  const renderPages = () => {
    const pages = [];

    for (const i of pagination(selected, numberPages)) {
      const paginate = () => onPageChange(i);

      pages.push(
        <li className={i === selected ? 'page current active' : ''} key={`paginate-page-${i}`}>
          <a onClick={paginate} tabIndex="-1" data-ref={`${i}`}>{i}</a>
        </li>,
      );
    }

    return pages;
  };

  const pagination = (c, m) => {
    const current = c;
    const last = m;
    const delta = 2;
    const left = current - delta;
    const right = current + delta + 1;
    const range = [];
    const rangeWithDots = [];
    let l;

    for (let i = 1; i <= last; i += 1) {
      // eslint-disable-next-line no-mixed-operators
      if (i == 1 || i == last || i >= left && i < right) {
        range.push(i);
      }
    }

    for (const i of range) {
      if (l) {
        if (i - l === 2) {
          rangeWithDots.push(l + 1);
        } else if (i - l !== 1) {
          rangeWithDots.push('...');
        }
      }
      rangeWithDots.push(i);
      l = i;
    }

    return rangeWithDots;
  };

  const onPrev = () => {
    if (selected === 1) return;
    onPageChange(selected - 1);
  };

  const onNext = () => {
    if (selected === numberPages) return;
    onPageChange(selected + 1);
  };

  return (
    <div className="pagination">
      <ul>
        <li className={selected === 1 || numberPages === 0 ? 'disabled' : ''}>
          <a className="prev" onClick={onPrev} />
        </li>
        {renderPages()}
        <li className={selected === numberPages || numberPages === 0 ? 'disabled' : ''}>
          <a className="next" onClick={onNext} />
        </li>
      </ul>
    </div>
  );
}

export default PaginateWithDots;
