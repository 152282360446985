import React from 'react';

import ModalContent from '../../../shared/modal/modal_content';

class PasteItemsStep1 extends React.Component {
  render() {
    const {
      props: {
        pasteItems: { pasteValue }, onChangePasteValue, placeholder, type,
        templateUrl,
      },
    } = this;

    return (
      <ModalContent additionalClass="with-textarea">
        <p>
          <strong>{I18n.t(`purchase_orders.form.paste_items.step1.${type}.description`)}</strong>
          { templateUrl
            ? (
              <span>
                <br />
                <strong>
                  {I18n.t(`purchase_orders.form.paste_items.step1.${type}.template.description`)}
                  <a className="link" href={templateUrl}>
                    {' '}
                    {I18n.t(`purchase_orders.form.paste_items.step1.${type}.template.link`)}
                  </a>
                  .
                </strong>
              </span>
            ) : null
          }
        </p>
        <textarea
          placeholder={placeholder}
          value={pasteValue}
          onChange={onChangePasteValue}
        />
      </ModalContent>
    );
  }
}

export default PasteItemsStep1;
