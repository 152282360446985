import React from 'react';
import SelectCheckmark from '@/icons/SelectCheckmark';
import Close from '@/icons/Close';
import TableComponent from './Table';
import TableCell from './TableCell';
import TableRow from './TableRow';
import TableImage from './TableImage';
import TableButton from './TableButton';
import ProgressBar from '../../ProgressBar';
import TABLE_ROW_SIZE from './TableRow/constants';
import Status from '../../Status';
import STATUS_VARIANT from '../../Status/constants';
import { TABLE_IMAGE_SIZE } from './TableImage/constants';

export default {
  component: Table,
  title: 'Tables/Regular/Table',
  args: {
    rowSize: 'SMALL',
    imageSize: 'SMALL',
  },
  argTypes: {
    rowSize: {
      control: 'radio',
      options: Object.keys(TABLE_ROW_SIZE),
    },
    imageSize: {
      control: 'radio',
      options: Object.keys(TABLE_IMAGE_SIZE),
    },
  },
};

export const Table = ({ ...args }) => (
  <div style={{ width: '100%' }}>
    <TableComponent
      header={(
        <TableRow size={TABLE_ROW_SIZE[args.rowSize]} disableHover>
          <TableCell heading sort>
            Number
          </TableCell>
          <TableCell heading sort>
            Date
          </TableCell>
          <TableCell heading>Company</TableCell>
          <TableCell heading>Name</TableCell>
          <TableCell heading>Progress</TableCell>
          <TableCell heading textAlign="right">
            Value
          </TableCell>
          <TableCell heading>Status</TableCell>
        </TableRow>
      )}
      body={(
        <TableRow
          size={TABLE_ROW_SIZE[args.rowSize]}
          actions={(
            <>
              <TableButton
                onClick={() => window.alert('Clicked!')} // eslint-disable-line no-alert
              >
                <SelectCheckmark />
              </TableButton>
              <TableButton
                onClick={() => window.alert('Clicked!')} // eslint-disable-line no-alert
              >
                <Close />
              </TableButton>
            </>
          )}
        >
          <TableCell>2018-0012</TableCell>
          <TableCell>01.12.2020</TableCell>
          <TableCell>T&T Construction</TableCell>
          <TableCell>New budget</TableCell>
          <TableCell>
            <ProgressBar value={50} max={100} strict />
          </TableCell>
          <TableCell bold textAlign="right">
            1250,00
          </TableCell>
          <TableCell>
            <Status variant={STATUS_VARIANT.DRAFT} text="Draft" />
          </TableCell>
          <TableCell>
            <TableImage
              src="https://www.cnet.com/a/img/resize/995d4d237a8244ec255b27a6a35476c6c6622fcd/hub/2019/09/25/8be7b304-eba7-4094-a9c9-243422e2ddc3/doge-meme.jpg?auto=webp&fit=crop&height=675&width=1200"
              size={TABLE_IMAGE_SIZE[args.imageSize]}
            />
          </TableCell>
        </TableRow>
      )}
      footer={(
        <TableRow size={TABLE_ROW_SIZE.SMALL} disableHover>
          <TableCell bold colSpan={5}>
            Summary
          </TableCell>
          <TableCell bold textAlign="right">
            1250,00
          </TableCell>
          <TableCell />
        </TableRow>
      )}
    />
  </div>
);
