import { makeAutoObservable, runInAction, action } from "mobx";
import * as Api from "@/components/shared/api";
import PaymentPlanModel from "@/models/PaymentPlanModel";
import camelcaseKeys from "camelcase-keys";
import snakecaseKeys from "snakecase-keys";

export default class PaymentPlanStore {
  isLoading = false;
  collection = [];
  record = new PaymentPlanModel({});

  constructor() {
    makeAutoObservable(this, {
      refreshRecord: action.bound,
    });
  }

  fetchRecord(id) {
    this.isLoading = true;

    return Api.makeGetRequest(`/api/payment_plans/${id}`).then(({ data }) => {
      runInAction(() => {
        const camelCasedData = camelcaseKeys(data, { deep: true });
        this.record = new PaymentPlanModel(camelCasedData.paymentPlan);

        this.isLoading = false;
      });
    });
  }

  refreshRecord() {
    this.fetchRecord(this.record.id);
  }

  update(id, data) {
    this.isLoading = true;

    const convertedData = snakecaseKeys({ paymentPlan: data }, { deep: true });

    return Api.makePutRequest(`/api/payment_plans/${id}`, convertedData).then(({ data }) => {
      runInAction(() => {
        const camelCasedData = camelcaseKeys(data, { deep: true });
        this.record = new PaymentPlanModel(camelCasedData.paymentPlan);

        this.isLoading = false;
      });
    });
  }
}
