import React, { useState } from 'react';
import SelectComponent from './index';
import { OPTION_SIZE } from '../styles';
import { storybookOptions } from '../constants';

export default {
  component: Select,
  title: 'Form/Inputs/Select',
  args: {
    label: 'Select',
    error: '',
    placeholder: 'Select option',
    optionSize: 'SMALL',
    noInput: false,
    isSearchable: false,
    required: false,
    disabled: false,
    buttonText: '',
    warning: '',
  },
  argTypes: {
    label: {
      control: 'text',
    },
    error: {
      control: 'text',
    },
    placeholder: {
      control: 'text',
    },
    optionSize: {
      control: 'radio',
      options: Object.keys(OPTION_SIZE).filter(key => key !== 'MULTI'),
    },
    noInput: {
      control: 'boolean',
    },
    isSearchable: {
      control: 'boolean',
    },
    required: {
      control: 'boolean',
    },
    disabled: {
      control: 'boolean',
    },
    buttonText: {
      control: 'text',
    },
    warning: {
      control: 'text',
    },
  },
};

export const Select = ({ buttonText, optionSize, ...rest }) => {
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const loadOptions = (inputValue) => {
    setIsLoading(true);
    setTimeout(() => {
      setOptions(
        storybookOptions.filter(option => option.value
          .toLocaleLowerCase()
          .includes(inputValue.toLocaleLowerCase())),
      );
      setIsLoading(false);
    }, 2000);
  };

  return (
    <div style={{ width: '250px' }}>
      <SelectComponent
        {...rest}
        name="storybook-select"
        loadOptions={loadOptions}
        optionSize={OPTION_SIZE[optionSize]}
        button={{ onClick: () => {}, text: buttonText }}
        options={options}
        isLoading={isLoading}
      />
    </div>
  );
};
