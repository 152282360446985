import DatePicker from 'react-datepicker';
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import FileFormatValidator from '@/components/shared/file_format_validator';
import Button from '../../shared/buttons/button';
import ModalContent from '../../shared/modal/modal_content';
import ModalHeader from '../../shared/modal/modal_header';
import ModalFooter from '../../shared/modal/modal_footer';
import ModalWrapper from '../../shared/modal/modal_wrapper';
import DisconfirmConfirmationModal from './confirmation_modal/disconfirm_confirmation_modal';
import * as Toast from '../../shared/toast';

function ConfirmationModal({
  isOpen, purchaseOrder, confirm, close, remove, dateFormat,
}) {
  const [open, setOpen] = useState(isOpen);
  const [confirmationInformation, setConfirmationInformation] = useState({
    confirmedDeliveryDate: moment(purchaseOrder.confirmedDeliveryDate || purchaseOrder.deliveryDate || new Date()),
    confirmationNote: purchaseOrder.confirmationNote,
    confirmationFile: { name: purchaseOrder.confirmationFileName, url: purchaseOrder.confirmationFileUrl },
  });
  const { confirmationFile } = confirmationInformation;
  const [disconfirmConfirmationModal, setDisconfirmConfirmationModal] = useState(false);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  function resetConfirmationFile() {
    setConfirmationInformation({ ...confirmationInformation, confirmationFile: '' });
  }

  const onConfirm = () => {
    confirm(confirmationInformation);
  };

  const onFileChange = ({ target: { files } }) => {
    const [file] = files;
    if (file.size > 20000000) {
      Toast.errorToast(I18n.t('purchase_orders.form.deliveries.to_big_file_size'));
    } else if (FileFormatValidator.valid(file, /(\.jpg|\.jpeg|\.gif|\.png|\.pdf|\.xlsx|\.xls|\.doc|\.docx|\.txt|\.rar|\.zip|\.eml|\.msg|\.csv)$/i)) {
      Toast.errorToast(I18n.t('purchase_orders.form.deliveries.wrong_file_format'));
    } else if (file.name.split('.').length > 2) {
      Toast.errorToast(I18n.t('purchase_orders.form.deliveries.invalid_file_name'));
    } else {
      setConfirmationInformation({ ...confirmationInformation, confirmationFile: file });
    }
  };

  return (
    <ModalWrapper
      type="centered"
      active={open}
      closeModal={close}
      withLoader={false}
      size="modal-s"
    >
      {
        disconfirmConfirmationModal
          && (
          <DisconfirmConfirmationModal
            isOpen={disconfirmConfirmationModal}
            confirm={remove}
            close={() => setDisconfirmConfirmationModal(false)}
          />
          )
      }
      <ModalHeader additionalClass="window-header-auto padding-bottom-0">
        <h2>{I18n.t('purchase_orders.form.order_confirmation')}</h2>
      </ModalHeader>
      <ModalContent>
        <div className="grid margin-top-3 grid-form vertical-start space-2">
          <div className="row">
            <div className="cell control">
              <span className="label display-inline-block">{I18n.t('purchase_orders.form.confirmed_delivery_date')}</span>
              <div className="display-inline-block margin-left-3">
                <DatePicker
                  selected={confirmationInformation.confirmedDeliveryDate}
                  dateFormat={dateFormat}
                  locale={I18n.locale}
                  onChange={date => setConfirmationInformation({ ...confirmationInformation, confirmedDeliveryDate: date })}
                  className="input input-xs no-border"
                  placeholderText={I18n.t('purchase_orders.form.confirmed_delivery_date')}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="cell">
              <textarea
                className="input"
                name="confirmation_note"
                onChange={e => setConfirmationInformation({ ...confirmationInformation, confirmationNote: e.target.value })}
                defaultValue={purchaseOrder.confirmationNote}
                placeholder={I18n.t('purchase_orders.form.confirmation_note')}
              />
            </div>
          </div>

          <div className="row">
            <div className="cell">
              <p>{I18n.t('purchase_orders.form.file_upload_info')}</p>
              <div className="as-input required file-button has-progress">
                <label className="drop-zone">
                  <i className="icon-upload_file" />
                  <span className="mute">
                    {I18n.translate('supplier_invoices.edit.modal.drag_and_drop')}
                    <br />
                    {I18n.translate('supplier_invoices.edit.modal.or')}
                  </span>
                  <input
                    onChange={onFileChange}
                    type="file"
                    name="file"
                    multiple="multiple"
                  />
                  <div className="button button-s block button-primary file-name no-shadow">{I18n.t('purchase_orders.form.select_file')}</div>
                </label>
                <div className={confirmationFile?.name ? 'row' : 'row hide'}>
                  <a href={confirmationFile?.url} className="file-name state-info" target="_blank">{confirmationFile?.name || ''}</a>
                  <button type="reset" onClick={() => resetConfirmationFile()} className="icon-close remove" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalContent>
      <ModalFooter>
        <div className="items" style={{ flexFlow: 'wrap-reverse', justifyContent: 'end' }}>
          <Button onClick={close}>
            {I18n.t('planned_purchases.modals.cancel')}
          </Button>
          <Button primary onClick={onConfirm}>
            {I18n.t('purchase_orders.form.save')}
          </Button>
          {purchaseOrder.confirmationState == 'confirmed'
            && (
            <Button onClick={() => setDisconfirmConfirmationModal(true)}>
              {I18n.t('purchase_orders.form.remove_order_confirmation')}
            </Button>
            )
          }
        </div>
      </ModalFooter>
    </ModalWrapper>
  );
}
export default ConfirmationModal;
