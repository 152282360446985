import React, { useState } from "react";
import PropTypes from "prop-types";
import ReactSelect from "react-select";
import { delta, rowsPerPage } from "./constants";
import { applyStyles } from "@/components/uiElements/inputs/selects/styles";
import {
  DropdownIndicator,
  Option,
  ValueContainer,
} from "@/components/uiElements/inputs/selects/constants";
import ArrowButton from "./components/ArrowButton";
import PageButton from "./components/PageButton";

const Pagination = ({ label }) => {
  const [{ activePage, items, totalPages }, setPaginationState] = useState({
    activePage: 1,
    items: 10,
    totalPages: 20,
  });

  const handlePageChange = (newPage) => {
    if (typeof newPage === "number" && newPage > 0 && newPage <= totalPages)
      setPaginationState((prevState) => ({
        ...prevState,
        activePage: newPage,
      }));
  };
  const handleChangeItemsCount = (value) => {
    setPaginationState((prevState) => ({ ...prevState, activePage: 1 }));
    setPaginationState((prevState) => ({ ...prevState, items: value }));
  };
  const renderButtons = () => {
    const returnedButtons = [];
    const range = [];
    const left = activePage - delta;
    const right = activePage + delta + 1;
    const rangeWithDots = [];
    let helper = 0;

    // Generating numeric range
    for (let i = 1; i <= totalPages; i++) {
      if (i === 1 || i === totalPages || (i >= left && i < right))
        range.push(i);
    }

    // Generating range with dots
    for (let i of range) {
      if (helper) {
        if (i - helper === 2) rangeWithDots.push(helper + 1);
        else if (i - helper !== 1) rangeWithDots.push("...");
      }
      rangeWithDots.push(i);
      helper = i;
    }

    // Generating buttons as JSX.Element
    rangeWithDots.forEach((item, index) => {
      returnedButtons.push(
        <PageButton
          key={`pagination-button-${item}-${index}`}
          onClick={() => handlePageChange(item)}
          active={item === activePage}
          disabled={totalPages === 1}
        >
          {item}
        </PageButton>
      );
    });
    if (totalPages > 1) {
      if (activePage !== 1)
        returnedButtons.unshift(
          <ArrowButton
            key="pagination-prev-button"
            onClick={() => handlePageChange(activePage - 1)}
            className="ct-rotate-[90deg]"
          />
        );
      if (activePage !== totalPages)
        returnedButtons.push(
          <ArrowButton
            key="pagination-next-button"
            onClick={() => handlePageChange(activePage + 1)}
            className="ct-rotate-[-90deg]"
          />
        );
    }

    return returnedButtons;
  };

  return (
    <div className="ct-flex ct-items-center ct-gap-4 ct-w-fit">
      <div className="ct-flex ct-items-center ct-gap-2">
        <span className="ct-text-xs ct-text-gray-cold">
          {label ? `${label}: ` : ""}
        </span>
        <ReactSelect
          placeholder=""
          isSearchable={false}
          menuPortalTarget={document.querySelector("body")}
          menuPlacement="top"
          noIcon
          value={rowsPerPage.find((option) => option.value === items)}
          onChange={({ value }) => handleChangeItemsCount(value)}
          options={rowsPerPage}
          styles={applyStyles()}
          components={{
            DropdownIndicator,
            Option,
            ValueContainer,
          }}
        />
      </div>
      <div className="ct-flex ct-gap-0.5">{renderButtons()}</div>
    </div>
  );
};

export default Pagination;

Pagination.propTypes = {
  label: PropTypes.string.isRequired,
};
