import React from 'react';
import { DateTime } from 'luxon';
import TopBarComponent from './index';
import TopBarText from './TopBarText';

export default {
  component: TopBar,
  title: 'Design System/TopBar',
  args: {
    title: 'New PO',
    number: '20001',
    lastSave: DateTime.fromJSDate(new Date()).toMillis(),
    created: DateTime.fromJSDate(new Date()).toMillis(),
  },
  argTypes: {
    title: {
      control: 'text',
    },
    number: {
      control: 'text',
    },
    lastSave: {
      control: 'date',
    },
    created: {
      control: 'date',
    },
  },
};

export const TopBar = (args) => {
  const { lastSave, created, ...rest } = args;
  return (
    <TopBarComponent
      {...rest}
      onClose={() => window.alert('Closed!')} // eslint-disable-line no-alert
    >
      <TopBarText>
        {`Created: ${DateTime.fromMillis(created).toLocaleString(
          DateTime.DATE_SHORT,
        )}`}
      </TopBarText>
      <TopBarText>
        {`Last save: ${DateTime.fromMillis(lastSave).toLocaleString(
          DateTime.DATE_SHORT,
        )}`}
      </TopBarText>
    </TopBarComponent>
  );
};
