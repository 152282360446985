export const successToast = (message) => {
  window.Toast.success(message);
};

export const warningToast = (message) => {
  window.Toast.warning(message);
};

export const errorToast = (message) => {
  window.Toast.error(message);
};

export const warningBox = (message) => {
  window.Toast.warningBox(message);
};

export const clearWarningBox = () => {
  window.Toast.clearWarningBox();
};
