import uuid from 'uuid/v4';

class DeliveryCommentModel {
  constructor({
    id, body, user, created_at,
  }) {
    this.id = id || null;
    this.body = body;
    this.user = { firstName: user.firstName || user.first_name, lastName: user.lastName || user.last_name };
    this.createdAt = created_at || '';
    this.uuid = uuid();
  }
}

export default DeliveryCommentModel;
