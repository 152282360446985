import React from 'react';

const Hourglass = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 16" {...props}>
    <path
      fill="currentColor"
      d="M.625.542v4.625h.008l-.008.008L3.708 8.25.625 11.334l.008.008H.625v4.617h9.25v-4.617h-.008l.008-.008L6.792 8.25l3.083-3.075-.008-.008h.008V.542H.625Zm7.708 11.177v2.7H2.167v-2.7L5.25 8.636l3.083 3.083ZM5.25 7.865 2.167 4.782v-2.7h6.167v2.7L5.25 7.865Z"
    />
  </svg>
);

export default Hourglass;
