import React from 'react';

function TableHead({ headers }) {
  return (
    <thead>
      <tr>
        { headers.map(head => <th key={head.name} className={head.className}>{head.name}</th>)}
      </tr>
    </thead>
  );
}

export default TableHead;
