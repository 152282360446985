import {
  makePostFileRequest,
} from '../components/shared/api';

class TransactionsService {
  static upload = ({ fileToImport }) => {
    const formData = new FormData();

    if (fileToImport) formData.append('invoice[import_file]', fileToImport);

    return makePostFileRequest('/api/accountings/invoices/upload', formData)
      .then(response => response.data);
  }
}

export default TransactionsService;
