import * as toast from '@/components/shared/toast';
import * as Api from '../components/shared/api';

class ItemListService {
  static index = params => (
    Api.makeGetRequest(`/api/supplier_items${params}`).then(response => response.data)
  )

  static destroyAll = (supplierItemsIds, filterParams) => {
    const params = { supplier_items_ids: supplierItemsIds };
    return Api.makePutRequest(`/api/supplier_items/destroy_all${filterParams}`, params).then(response => response.data.supplier_items);
  }

  static restoreAll = (supplierItemsIds, filterParams) => {
    const params = { supplier_items_ids: supplierItemsIds };
    return Api.makePutRequest(`/api/supplier_items/restore_all${filterParams}`, params).then(response => response.data.supplier_items);
  }

  static create = params => Api.makePostRequest('/api/supplier_items', { supplier_item: params })
    .then((response) => {
      toast.successToast(I18n.t('commons.messages.changes_saved'));
      return { success: true, supplierItem: response.data.supplier_item };
    })
    .catch((error) => {
      let errors = {};
      if (error.response) {
        switch (error.response.status) {
          case 422:
            ({ response: { data: errors } } = error);
            toast.errorToast(I18n.t('commons.messages.error'));
            break;
          case 401:
            toast.errorToast(I18n.t('commons.messages.unauthorized'));
            break;
          case 500:
            toast.errorToast(I18n.t('commons.messages.error'));
            break;
          default:
            toast.errorToast(I18n.t('commons.messages.error'));
            Raven.captureException(error);
        }
      } else {
        toast.errorToast(I18n.t('commons.messages.error'));
        Raven.captureException(error);
      }
      return { success: false, errors };
    })

  static update = (params) => {
    const { id, ...supplierItemParams } = params;
    return Api.makePutRequest(`/api/supplier_items/${id}`, { supplier_item: supplierItemParams })
      .then((response) => {
        toast.successToast(I18n.t('commons.messages.changes_saved'));
        return { success: true, supplierItem: response.data.supplier_item };
      })
      .catch((error) => {
        let errors = {};
        if (error.response) {
          switch (error.response.status) {
            case 422:
              ({ response: { data: errors } } = error);
              toast.errorToast(I18n.t('commons.messages.error'));
              break;
            case 401:
              toast.errorToast(I18n.t('commons.messages.unauthorized'));
              break;
            case 500:
              toast.errorToast(I18n.t('commons.messages.error'));
              break;
            default:
              toast.errorToast(I18n.t('commons.messages.error'));
              Raven.captureException(error);
          }
        } else {
          toast.errorToast(I18n.t('commons.messages.error'));
          Raven.captureException(error);
        }
        return { success: false, errors };
      });
  }

  static delete = supplierId => Api.makeDeleteRequest(`/api/supplier_items/${supplierId}`)
    .then(() => {
      toast.successToast(I18n.t('commons.messages.changes_saved'));
      return { success: true };
    })
    .catch((error) => {
      let errors = {};
      if (error.response) {
        switch (error.response.status) {
          case 422:
            ({ response: { data: errors } } = error);
            toast.errorToast(I18n.t('commons.messages.error'));
            break;
          case 401:
            toast.errorToast(I18n.t('commons.messages.unauthorized'));
            break;
          case 500:
            toast.errorToast(I18n.t('commons.messages.error'));
            break;
          default:
            toast.errorToast(I18n.t('commons.messages.error'));
            Raven.captureException(error);
        }
      } else {
        toast.errorToast(I18n.t('commons.messages.error'));
        Raven.captureException(error);
      }
      return { success: false, errors };
    })

  static import = ({ file, supplierId }) => {
    const formData = new FormData();

    formData.append('supplier_item_file[file]', file);
    formData.append('supplier_item_file[supplier_id]', supplierId);

    return Api.makePostFileRequest('/api/supplier_items/import', formData)
      .then(response => response.data);
  }
}

export default ItemListService;
