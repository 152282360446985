import React from "react";

export default function Icon({ hintText = '', iconName, linkTo, className }) {
  return (
    <a href={linkTo} className={`${className} ${hintText ? 'has-tooltip' : ''}`} tabIndex='-1' >
      <i className={iconName} />
      <span className="tooltip center">{hintText}</span>
    </a>
  );
}
