import React from 'react';

export default function BudgetCategoryRow({
  category, selectedCategoryId, selectedBudgetId, onCategoryChange,
}) {
  const selected = category.id === selectedCategoryId && category.budgetId === selectedBudgetId ? 'selected' : '';

  const updateCategory = () => {
    onCategoryChange(category);
  };

  return (
    <tr className={`${selected}`}>
      <td className="actions">
        <label className="has-checkbox">
          <span className="checkbox input-s" onClick={updateCategory} />
        </label>
        <i className="arrow" />
      </td>
      <td />
      <td>
        {category.number ? `${category.number} - ${category.name}` : category.name}
      </td>
      <td className="number price bold">
        {category.notInvoiced}
      </td>
      <td className="number price bold">
        {category.invoiced}
      </td>
      <td className="number price bold">
        {category.committed}
      </td>
      <td className="number price bold">
        {category.amount}
      </td>
      <td className="number price bold">
        {category.remaining}
      </td>
    </tr>
  );
}
