import * as api from '@/components/shared/api';
import * as toast from '@/components/shared/toast';

export function CreateSupplierMessage(purchaseOrderId, supplierMessageData, updateSupplierMessage) { // eslint-disable-line
  return api.makePostRequest(`/purchase_orders/${purchaseOrderId}/supplier_message`, { supplier_message: supplierMessageData })
    .then((response) => {
      updateSupplierMessage(response.data.supplier_message);
      toast.successToast(I18n.t('supplier_messages.messages.saved'));
      return { success: true, errors: {} };
    })
    .catch((error) => {
      let errors = {};
      if (error.response) {
        switch (error.response.status) {
          case 422:
            errors = error.response.data;
            break;
          case 401:
            toast.errorToast(I18n.t('commons.messages.unauthorized'));
            break;
          case 500:
            toast.errorToast(I18n.t('commons.messages.error'));
            break;
          default:
            toast.errorToast(I18n.t('commons.messages.error'));
            Raven.captureException(error);
        }
      } else {
        toast.errorToast(I18n.t('commons.messages.error'));
        Raven.captureException(error);
      }
      return { success: false, errors };
    });
}
