import React from 'react';
import { NumericFormat } from 'react-number-format';
import DeliveryCommentModel from '../../../../models/delivery_comment_model';
import * as swipe from '../../../shared/swipe';

class DeliveryItem extends React.Component {
  constructor(props) {
    super(props);

    const { item } = props;
    this.state = {
      rowExpanded: false,
      newDeliveryComment: new DeliveryCommentModel({ body: '', user: props.currentUser }),
      displayGoodItemsCount: Number(item.goodItemsCount),
      displayBadItemsCount: Number(item.badItemsCount),
    };
  }

  componentDidMount = () => {
    swipe.triggerSwipeEvent();
  }

  expandRow = () => {
    this.setState(prevState => ({ rowExpanded: !prevState.rowExpanded }));
  }

  // Because of how NumericField from React is working and that we change other input values we are losing position of the cursor
  // NumericField returns value (number) and formattedValue (string) and if we display value (number) formatting and position of the cursor is lost
  // Before we stored position of the cursor because normal inputs returns event on onChange event
  // So we store and show the user formatted value in different attribute and we keep the calc logic as before in base attribute (that was before)
  onChangeItem = (values, name) => {
    const { value, formattedValue } = values;
    const { item } = this.props;
    item[name] = value;
    this.setState({ [`display${name[0].toUpperCase() + name.slice(1)}`]: formattedValue }); // Because of the above here we need to format name of the attribute from 'name' to 'displayName'
    this.updateItem(item);
  }

  onCommentChange = ({ target: { value } }) => {
    const { newDeliveryComment } = this.state;
    newDeliveryComment.body = value;
    this.setState({ newDeliveryComment });
  }

  onKeyPress = (e) => {
    const { state: { newDeliveryComment }, props: { item, currentUser, editableDelivery } } = this;
    if (e.key === 'Enter' && editableDelivery) {
      item.comments = [...item.comments, newDeliveryComment];
      this.setState({ newDeliveryComment: new DeliveryCommentModel({ body: '', user: currentUser }) });

      this.updateItem(item);
    }
  }

  onSendButtonClick = () => {
    const { state: { newDeliveryComment }, props: { item, currentUser, editableDelivery } } = this;

    item.comments = [...item.comments, newDeliveryComment];
    if (editableDelivery) {
      this.setState({ newDeliveryComment: new DeliveryCommentModel({ body: '', user: currentUser }) });
      this.updateItem(item);
    }
  }

  updateItem = (newItem) => {
    const { updateDeliveries, delivery } = this.props;
    const newItems = delivery.items.map((item) => {
      if (item.uuid === newItem.uuid) {
        return newItem;
      }
      return item;
    });

    delivery.items = newItems;

    updateDeliveries(delivery);
  }

  render() {
    const {
      state: {
        rowExpanded, newDeliveryComment, displayGoodItemsCount, displayBadItemsCount,
      },
      props: {
        item, exchangeRate, editableDelivery, numberFormat,
      },
    } = this;
    const { thousands_separator: thousandSeparator, decimal_mark: decimalMark, placeholder } = numberFormat;
    const amountFormat = { delimiter: thousandSeparator, separator: decimalMark, precision: 2 };
    const itemTotal = I18n.toNumber(item.total, amountFormat);
    const itemQuantity = I18n.toNumber(item.quantity, amountFormat);

    return (
      <React.Fragment>
        <tr className={`fluid-not-mobile td-with-sub ${rowExpanded ? 'expanded' : ''}`}>
          <td className="not-sticky">{item.sku}</td>
          <td>{item.name}</td>
          <td className="colored-pass fluid-not-mobile">
            <NumericFormat
              name="goodItemsCount"
              onClick={e => e.target.select()}
              decimalScale={2}
              allowedDecimalSeparators={[',', '.']}
              fixedDecimalScale
              value={displayGoodItemsCount || ''}
              onValueChange={values => this.onChangeItem(values, 'goodItemsCount')}
              thousandSeparator={thousandSeparator || ' '}
              placeholder={placeholder}
              decimalSeparator={decimalMark || '.'}
            />
          </td>
          <td className="colored-fail fluid-not-mobile">
            <NumericFormat
              name="badItemsCount"
              onClick={e => e.target.select()}
              decimalScale={2}
              allowedDecimalSeparators={[',', '.']}
              fixedDecimalScale
              value={displayBadItemsCount || ''}
              onValueChange={values => this.onChangeItem(values, 'badItemsCount')}
              thousandSeparator={thousandSeparator || ' '}
              placeholder={placeholder}
              decimalSeparator={decimalMark || '.'}
            />
          </td>
          <td className="bold">{`${itemTotal}/${itemQuantity}`}</td>
          <td className="mute price number">{I18n.toNumber(item.price, amountFormat)}</td>
          <td className="mute">{item.unit}</td>
          <td className="mute">{item.updatedAt}</td>
          <td className="number price">
            {I18n.toNumber(item.price * item.goodItemsCount, amountFormat)}
          </td>
          <td className="number price">
            {I18n.toNumber(Math.round(item.price * item.goodItemsCount * exchangeRate * 100) / 100, amountFormat)}
          </td>
          <td className="actions">
            {
              item.comments.length === 0
                ? (
                  <a className="anchor mute" onClick={this.expandRow}>
                    <i className="icon-add_comment" />
                  </a>
                )
                : (
                  <a className="anchor link">
                    <i className="icon-add_comment_alt" />
                    {item.comments.length}
                  </a>
                )
            }
          </td>
          <td className="actions with-arrow">
            <a onClick={this.expandRow} className="anchor arrow" />
          </td>
        </tr>
        <tr className="td-sub fluid-not-mobile">
          <td colSpan={11}>
            <div className="td-rows">
              {
                item.comments.map(comment => (
                  <div key={comment.uuid} className="td-row">
                    <i className="icon-dialog" />
                    <div className="row-value">
                      <label className="mute">
                        {`${comment.user.firstName} ${comment.user.lastName} - ${comment.createdAt}`}
                      </label>
                      <p>
                        {comment.body}
                      </p>
                    </div>
                  </div>
                ))
              }
              {editableDelivery
                ? (
                  <div className="td-row as-input">
                    <i className="icon-dialog" />
                    <input
                      className="input-table"
                      type="text"
                      placeholder={I18n.t('purchase_orders.form.deliveries.add_comment')}
                      disabled={!editableDelivery}
                      value={newDeliveryComment.body}
                      onChange={this.onCommentChange}
                      onKeyPress={this.onKeyPress}
                      onFocus={(e) => { e.target.select(); }}
                    />
                    <button
                      className="icon-send button button-transparent no-shadow"
                      type="send"
                      onClick={this.onSendButtonClick}
                    />
                  </div>
                ) : null
              }
            </div>
          </td>
          <td />
        </tr>
        <tr className={`td-with-sub fluid-only-mobile ${rowExpanded ? 'expanded' : ''}`}>
          <td className="not-sticky">{item.sku}</td>
          <td>{item.name}</td>
          <td className="bold">{`${itemTotal}/${itemQuantity}`}</td>
          <td className="mute price number">{I18n.toNumber(item.price, amountFormat)}</td>
          <td className="mute">{item.unit}</td>
          <td className="mute">{item.updatedAt}</td>
          <td className="number price">
            {I18n.toNumber(item.price * item.goodItemsCount, amountFormat)}
          </td>
          <td className="number price">
            {I18n.toNumber(Math.round(item.price * item.goodItemsCount * exchangeRate * 100) / 100, amountFormat)}
          </td>
          <td className="actions">
            {
              item.comments.length === 0
                ? (
                  <a className="anchor mute" onClick={this.expandRow}>
                    <i className="icon-add_comment" />
                  </a>
                )
                : (
                  <a className="anchor link">
                    <i className="icon-add_comment_alt" />
                    {item.comments.length}
                  </a>
                )
            }
          </td>
          <td className="colored-pass fluid-only-mobile fluid-always-display">
            <NumericFormat
              name="goodItemsCount"
              onClick={e => e.target.select()}
              decimalScale={2}
              allowedDecimalSeparators={[',', '.']}
              fixedDecimalScale
              value={displayGoodItemsCount || ''}
              onValueChange={values => this.onChangeItem(values, 'goodItemsCount')}
              thousandSeparator={thousandSeparator || ' '}
              placeholder={placeholder}
              decimalSeparator={decimalMark || '.'}
            />
          </td>
          <td className="colored-fail fluid-only-mobile fluid-always-display">
            <NumericFormat
              name="badItemsCount"
              onClick={e => e.target.select()}
              decimalScale={2}
              allowedDecimalSeparators={[',', '.']}
              fixedDecimalScale
              value={displayBadItemsCount || ''}
              onValueChange={values => this.onChangeItem(values, 'badItemsCount')}
              thousandSeparator={thousandSeparator || ' '}
              placeholder={placeholder}
              decimalSeparator={decimalMark || '.'}
            />
          </td>
          <td className="actions with-arrow not-sticky">
            <a onClick={this.expandRow} className="anchor arrow" />
          </td>
        </tr>
        <tr className="td-sub fluid-only-mobile">
          <td colSpan={12} className="not-sticky">
            <div className="td-rows">
              {
                item.comments.map(comment => (
                  <div key={comment.uuid} className="td-row">
                    <i className="icon-dialog" />
                    <div className="row-value">
                      <label className="mute">
                        {`${comment.user.firstName} ${comment.user.lastName} - ${comment.createdAt}`}
                      </label>
                      <p>
                        {comment.body}
                      </p>
                    </div>
                  </div>
                ))
              }
              {editableDelivery
                ? (
                  <div className="td-row as-input">
                    <i className="icon-dialog" />
                    <input
                      className="input-table"
                      type="text"
                      placeholder={I18n.t('purchase_orders.form.deliveries.add_comment')}
                      disabled={!editableDelivery}
                      value={newDeliveryComment.body}
                      onChange={this.onCommentChange}
                      onKeyPress={this.onKeyPress}
                      onFocus={(e) => { e.target.select(); }}
                    />
                    <button
                      className="icon-send button button-transparent no-shadow"
                      type="send"
                      onClick={this.onSendButtonClick}
                    />
                  </div>
                ) : null
              }
            </div>
          </td>
        </tr>
      </React.Fragment>
    );
  }
}

export default DeliveryItem;
