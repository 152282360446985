class ArchivePurchaseOrderModel {
  constructor(params) {
    this.id = params.id;
    this.poNumber = params.po_number;
    this.parentPoNumber = params.parent_po_number;
    this.description = params.description;
    this.deliveryDate = params.delivery_date;
    this.customerName = params.customer_name;
    this.type = params.type;
    this.salesOrder = params.sales_order;
    this.createdAt = params.created_at;
    this.accountName = params.account_name;
    this.supplierId = params.supplier_id;
    this.supplierNumber = params.supplier_number;
    this.supplierName = params.supplier_name;
    this.budgetName = params.budget_name;
    this.budgetNumber = params.budget_number;
    this.projectId = params.project_id;
    this.projectNumber = params.project_number;
    this.projectName = params.project_name;
    this.departmentId = params.department_id;
    this.departmentName = params.department;
    this.totalAmountInPoCurrency = params.total_amount_in_po_currency;
    this.totalAmount = params.total_amount;
    this.notInvoiced = params.not_invoiced;
    this.invoiced = params.invoiced;
    this.deviation = params.deviation;
    this.committed = params.committed;
    this.projectName = params.project_name;
    this.currency = params.currency;
    this.status = params.status;
    this.avatars = params.avatars;
    this.userAvatar = params.user_avatar;
    this.userFullName = params.user_full_name;
    this.approveActions = params.approve_actions;
    this.customFields = params.custom_fields;
    this.confirmationState = params.confirmation_state;
    this.userAvatar = params.user.avatar_url;
    this.userFullName = params.user.full_name;
    this.raisedDate = params.raised_date;
    this.confirmedDeliveryDate = params.confirmed_delivery_date || '';
    this.expectedDeliveryDate = params.expected_delivery_date || '';
    this.outdated = params.outdated;
    this.commentsCount = params.comments_count;
  }
}

export default ArchivePurchaseOrderModel;
