import * as Api from '../components/shared/api';
import ProjectModel from '../models/project_model';

class IntegrationsProjectsService {
  constructor(integrationType) {
    this.integrationType = integrationType;
  }

  all = () => Api.makeGetRequest(`/integrations/projects?integration_type=${this.integrationType}`)
    .then(response => response.data.map(value => new ProjectModel(value)))
}

export default IntegrationsProjectsService;
