import React from 'react';
import TextFieldComponent from './index';
import INPUT_TYPE from './constants';

export default {
  component: TextField,
  title: 'Form/Inputs/TextField',
  args: {
    label: 'Name',
    error: '',
    placeholder: 'Enter name',
    type: INPUT_TYPE.TEXT,
    required: false,
    disabled: false,
    buttonText: '',
    warning: '',
  },
  argTypes: {
    label: {
      control: 'text',
    },
    error: {
      control: 'text',
    },
    placeholder: {
      control: 'text',
    },
    type: {
      control: 'radio',
      options: Object.values(INPUT_TYPE),
    },
    required: {
      control: 'boolean',
    },
    disabled: {
      control: 'boolean',
    },
    buttonText: {
      control: 'text',
    },
    warning: {
      control: 'text',
    },
  },
};

export const TextField = ({ buttonText, ...rest }) => (
  <div style={{ width: '250px' }}>
    <TextFieldComponent
      name="storybook-text-field"
      button={{ onClick: () => {}, text: buttonText }}
      {...rest}
    />
  </div>
);
