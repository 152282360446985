import * as Api from '../components/shared/api';
import IntegrationModel from '../models/account_model';

class IntegrationsAccountsService {
  constructor(integrationType) {
    this.integrationType = integrationType;
  }

  all = () => Api.makeGetRequest(`/integrations/accounts?integration_type=${this.integrationType}`)
    .then(response => (
      response.data.map(value => new IntegrationModel(value))
    ))
}

export default IntegrationsAccountsService;
