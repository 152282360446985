import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withLimit } from '../shared/utils';
import CompanyAddress from './company_info/company_address';
import DeliveryAddress from './company_info/delivery_address';
import InvoicingAddress from './company_info/invoicing_address';
import AddressContactsService from '../../services/address_contacts_service';
import AddressContacts from './company_info/address_contacts';
import AddressContactModel from '../../models/address_contact';

class CompanyInfo extends React.Component {
  static propTypes = {
    addresses: PropTypes.array,
    companyAddresses: PropTypes.array,
    deliveryAddresses: PropTypes.array,
    invoicingAddresses: PropTypes.array,
    purchaseOrder: PropTypes.object,
    descriptionError: PropTypes.array,
    companyAddressContactError: PropTypes.array,
    deliveryAddressContactError: PropTypes.array,
    currentCompany: PropTypes.object,
  }

  constructor(props) {
    super(props);

    this.addAddress = this.addAddress.bind(this);
    this.onChange = this.onChange.bind(this);
    this.saveDraft = this.saveDraft.bind(this);
    this.onParentActiveChange = this.onParentActiveChange.bind(this);
    this.onParentChange = this.onParentChange.bind(this);
    this.onDetachParent = this.onDetachParent.bind(this);

    const { addresses, purchaseOrder } = props;

    this.mainParents = props.parents;

    this.state = {
      addresses,
      companyAddressContacts: purchaseOrder.company_address_contacts
        ? purchaseOrder.company_address_contacts
          .map(address => new AddressContactModel(address))
        : [],
      deliveryAddressContacts: purchaseOrder.delivery_address_contacts
        ? purchaseOrder.delivery_address_contacts
          .map(address => new AddressContactModel(address))
        : [],
      companyAddressId: purchaseOrder.company_address_id,
      deliveryAddressId: purchaseOrder.delivery_address_id,
      invoiceAddressId: purchaseOrder.invoice_address_id,
      purchaseOrderCompanyAddressContact: new AddressContactModel({
        id: purchaseOrder.company_address_contact_id,
        name: purchaseOrder.company_address_contact_name,
        email: purchaseOrder.company_address_contact_email,
        phone: purchaseOrder.company_address_contact_phone,
      }),
      purchaseOrderDeliveryAddressContact: new AddressContactModel({
        id: purchaseOrder.delivery_address_contact_id,
        name: purchaseOrder.delivery_address_contact_name,
        email: purchaseOrder.delivery_address_contact_email,
        phone: purchaseOrder.delivery_address_contact_phone,
      }),
      companyAddresses: [],
      deliveryAddresses: [],
      invoicingAddresses: [],
      parentActive: purchaseOrder.parent_active,
      parentId: purchaseOrder.parent_id,
      description: purchaseOrder.description,
      searchParentValue: '',
      parents: withLimit(props.parents, purchaseOrder.parent_id),
    };
  }

  componentDidMount() {
    const { addresses } = this.state;
    this.setState({
      companyAddresses: _.filter(addresses, x => x.category == 'company'),
      deliveryAddresses: _.filter(addresses, x => x.category == 'delivery'),
      invoicingAddresses: _.filter(addresses, x => x.category == 'invoice'),
    });
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onParentActiveChange() {
    const { parentActive } = this.state;
    const {
      handleParentActiveChanged,
      saveDraft,
      permissions: { parent_po_editable },
    } = this.props;

    if (parent_po_editable) {
      handleParentActiveChanged();
    }

    this.setState({
      parentActive: !parentActive,
    }, () => { saveDraft(); });
  }

  onParentChange(e) {
    const { saveDraft } = this.props;
    this.setState({
      parentId: e.target.value,
    }, () => { saveDraft(); });
  }

  onDetachParent() {
    const { saveDraft } = this.props;
    this.setState({
      parentId: null,
    }, () => { saveDraft(); });
  }

  handleAddCompanyAddressContact = (newAddressContact, addressId) => {
    this.updateCompanyAddressContactsList(addressId, newAddressContact);
  }

  handleAddDeliveryAddressContact = (newAddressContact, addressId) => {
    this.updateDeliveryAddressContactsList(addressId, newAddressContact);
  }

  onPurchaseOrderCompanyAddressChange = (e) => {
    const { saveDraft } = this.props;
    this.setState({
      companyAddressId: e.target.value,
    }, () => {
      const { companyAddressId } = this.state;
      this.updateCompanyAddressContactsList(companyAddressId);
      saveDraft();
    });
  }

  onPurchaseOrderCompanyAddressContactChange = (addressContact) => {
    const { props: { saveDraft }, state: { purchaseOrderCompanyAddressContact } } = this;
    const newPurchaseOrderCompanyAddressContact = addressContact.id !== purchaseOrderCompanyAddressContact.id
      ? new AddressContactModel(addressContact)
      : new AddressContactModel({ id: null });
    this.setState({
      purchaseOrderCompanyAddressContact: newPurchaseOrderCompanyAddressContact,
    }, () => {
      saveDraft();
    });
  }

  onPurchaseOrderDeliveryAddressChange = (e) => {
    const { saveDraft } = this.props;
    this.setState({
      deliveryAddressId: e.target.value,
    }, () => {
      const { deliveryAddressId } = this.state;
      this.updateDeliveryAddressContactsList(deliveryAddressId);
      saveDraft();
    });
  }

  onPurchaseOrderDeliveryAddressContactChange = (addressContact) => {
    const { props: { saveDraft }, state: { purchaseOrderDeliveryAddressContact } } = this;
    const newPurchaseOrderDeliveryAddressContact = addressContact.id !== purchaseOrderDeliveryAddressContact.id
      ? new AddressContactModel(addressContact)
      : new AddressContactModel({ id: null });
    this.setState({
      purchaseOrderDeliveryAddressContact: newPurchaseOrderDeliveryAddressContact,
    }, () => {
      saveDraft();
    });
  }

  onPurchaseOrderInvoiceAddressChange = (e) => {
    const { saveDraft } = this.props;
    this.setState({
      invoiceAddressId: e.target.value,
    }, () => {
      saveDraft();
    });
  }

  onChangeSearchParent = (e) => {
    const { typingTimeout } = this.state;
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    this.setState({
      searchParentValue: e.target.value,
      typingTimeout: setTimeout(() => {
        const { state: { searchParentValue }, props: { purchaseOrder } } = this;
        this.setState({
          parents: withLimit(this.mainParents.filter(
            parent => (
              parent.id === purchaseOrder.parent_id
              || `${parent.po_number} - ${parent.description}`.toLowerCase().includes(searchParentValue.toLowerCase())
            ),
          )),
        });
      }, 350),
    });
  }

  updateCompanyAddressContactsList = (addressId, newAddressContact = null) => {
    const { saveDraft } = this.props;
    AddressContactsService.index(addressId)
      .then((addressContacts) => {
        const addressContactModels = addressContacts.map(addressContact => (
          new AddressContactModel(addressContact)
        ));
        this.setState({
          companyAddressContacts: addressContactModels,
          purchaseOrderCompanyAddressContact: newAddressContact || this.findDefault(addressContactModels),
        }, () => saveDraft());
      });
  }

  updateDeliveryAddressContactsList = (addressId, newAddressContact = null) => {
    const { saveDraft } = this.props;
    AddressContactsService.index(addressId)
      .then((addressContacts) => {
        const addressContactModels = addressContacts.map(addressContact => (
          new AddressContactModel(addressContact)
        ));
        this.setState({
          deliveryAddressContacts: addressContactModels,
          purchaseOrderDeliveryAddressContact: newAddressContact || this.findDefault(addressContactModels),
        }, () => saveDraft());
      });
  }

  findDefault = (addressContacts) => {
    const defaultAddressContact = addressContacts.find(addressContact => (
      addressContact.default
    ));
    if (defaultAddressContact) {
      return defaultAddressContact;
    }
    return new AddressContactModel({
      id: null, name: null, email: null, phone: null,
    });
  }

  addAddress(address) {
    const {
      props: { saveDraft },
      state: { addresses, deliveryAddresses, invoicingAddresses },
      updateDeliveryAddressContactsList,
    } = this;

    return this.setState({
      addresses: addresses.concat([
        address,
      ]),
    }, () => {
      switch (address.category) {
        case 'delivery':
          this.setState({
            deliveryAddresses: deliveryAddresses.concat([address]),
            deliveryAddressId: address.id,
          }, () => {
            saveDraft();
            updateDeliveryAddressContactsList(address.id);
          });
          break;
        case 'invoice':
          this.setState({
            invoicingAddresses: invoicingAddresses.concat([address]),
            invoiceAddressId: address.id,
          }, () => { saveDraft(); });
          break;
        default:
          break;
      }
    });
  }

  saveDraft() {
    const { saveDraft } = this.props;
    saveDraft();
  }

  render() {
    const {
      props: {
        purchaseOrder,
        isTutorialPending, companyAddressError,
        deliveryAddressError, invoiceAddressError,
        parentError, descriptionError, companyAddressContactError,
        deliveryAddressContactError, permissions: { parent_po_editable },
        currentCompany: { parentPoEnabled },
      },
      state: {
        companyAddresses, deliveryAddresses, companyAddressContacts,
        deliveryAddressContacts, invoicingAddresses, purchaseOrderCompanyAddressContact,
        purchaseOrderDeliveryAddressContact, parentActive, parentId, description, parents,
        companyAddressId, deliveryAddressId, invoiceAddressId,
      },
    } = this;

    return (
      <div className="grid space-2">
        <CompanyAddress
          addresses={companyAddresses}
          addressId={companyAddressId}
          purchaseOrder={purchaseOrder}
          title={I18n.t('purchase_orders.form.company_address')}
          handleAdd={this.addAddress}
          canAdd={false}
          editable={purchaseOrder.editable}
          error={companyAddressError}
          onChange={this.onPurchaseOrderCompanyAddressChange}
        />
        <AddressContacts
          type="company"
          purchaseOrder={purchaseOrder}
          addressId={companyAddressId}
          addressContacts={companyAddressContacts}
          addressContactError={companyAddressContactError}
          handleAddAddressContact={this.handleAddCompanyAddressContact}
          purchaseOrderAddressContact={purchaseOrderCompanyAddressContact}
          onPurchaseOrderAddressContactChange={this.onPurchaseOrderCompanyAddressContactChange}
        />
        <DeliveryAddress
          isTutorialPending={isTutorialPending}
          addresses={deliveryAddresses}
          addressId={deliveryAddressId}
          purchaseOrder={purchaseOrder}
          title={I18n.t('purchase_orders.form.delivery_address')}
          handleAdd={this.addAddress}
          canAdd
          editable={purchaseOrder.editable}
          error={deliveryAddressError}
          onChange={this.onPurchaseOrderDeliveryAddressChange}
        />
        <AddressContacts
          type="delivery"
          purchaseOrder={purchaseOrder}
          addressId={deliveryAddressId}
          addressContacts={deliveryAddressContacts}
          addressContactError={deliveryAddressContactError}
          handleAddAddressContact={this.handleAddDeliveryAddressContact}
          purchaseOrderAddressContact={purchaseOrderDeliveryAddressContact}
          onPurchaseOrderAddressContactChange={this.onPurchaseOrderDeliveryAddressContactChange}
        />
        <InvoicingAddress
          isTutorialPending={isTutorialPending}
          addresses={invoicingAddresses}
          addressId={invoiceAddressId}
          purchaseOrder={purchaseOrder}
          title={I18n.t('purchase_orders.form.invoicing_address')}
          handleAdd={this.addAddress}
          canAdd
          editable={purchaseOrder.editable}
          error={invoiceAddressError}
          onChange={this.onPurchaseOrderInvoiceAddressChange}
        />
        {
          parentPoEnabled
            ? (
              <div className="row">
                <div className="cell-14">
                  <label className="label" htmlFor="parent_active">{I18n.t('purchase_orders.form.set_as_parent_po')}</label>
                </div>
                <div className="cell-2">
                  <label className="radio-switch" style={parent_po_editable ? { 'pointer-events': 'all' } : null}>
                    <input id="parent-active" disabled={!purchaseOrder.editable && !parent_po_editable} type="checkbox" name="parentActive" checked={parentActive} onChange={this.onParentActiveChange} />
                    <span />
                  </label>
                </div>
              </div>
            ) : null
        }
        {
          !parentActive && parentPoEnabled
            ? (
              <div className="row">
                <div className={`cell-16 ${parentError ? 'has-error' : ''}`}>
                  <div className="as-input reduce">
                    {parentId
                      ? (
                        <label tabIndex="-1" className="add-edit-trigger"><i className="icon-close" onClick={this.onDetachParent} /></label>
                      ) : null
                    }
                    <span className="label">{I18n.t('purchase_orders.form.parent_po')}</span>
                    <span className={`select input-r add-edit${!purchaseOrder.editable ? ' disabled' : ''} ${parentId ? 'selected' : ''}`}>
                      <input type="checkbox" />
                      <div className="control icon-search">
                        <input type="text" tabIndex="-1" onChange={this.onChangeSearchParent} placeholder={I18n.t('purchase_orders.form.type_to_search')} />
                      </div>
                      <div className="select-content">
                        {parents.map(parent => (
                          <React.Fragment key={`purchase_order_parent_${parent.id}`}>
                            <input
                              id={`purchase_order_parent_${parent.id}`}
                              name="parent"
                              type="radio"
                              value={parent.id}
                              checked={parent.id == parentId}
                              onChange={this.onParentChange}
                            />
                            <label className={`${parent.id == parentId ? 'checked' : ''}`} htmlFor={`purchase_order_parent_${parent.id}`}>{parent.description ? `${parent.po_number} - ${parent.description}` : parent.po_number}</label>
                          </React.Fragment>
                        ))}
                        <div className="placeholder" data-placeholder={I18n.t('purchase_orders.form.select_parent_po')} data-selected={I18n.t('purchase_orders.form.selected')} />
                      </div>
                      {parentError ? <div className="hint error">{parentError}</div> : ''}
                    </span>
                  </div>
                </div>
              </div>
            ) : null
        }
        <div className="row">
          <div className={`cell-16 ${descriptionError ? 'has-error' : ''}`}>
            <div className={classNames('as-input', 'reduce', { required: purchaseOrder.custom_po_enabled })}>
              <span className="label">{I18n.t('purchase_orders.form.description')}</span>
              <input className="output input-r" type="text" disabled={!purchaseOrder.editable} name="description" placeholder={I18n.t('purchase_orders.form.add_description')} value={description || ''} onChange={this.onChange} onBlur={this.saveDraft} onFocus={(e) => { e.target.select(); }} />
              {descriptionError ? <div className="hint error">{descriptionError}</div> : ''}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CompanyInfo;
