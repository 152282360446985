import React, { useState } from 'react';
import { withUUID } from '@/components/shared/utils';
import * as api from '@/components/shared/api';
import * as toast from '@/components/shared/toast';
import FileFormatValidator from '@/components/shared/file_format_validator';
import SupplierInvoiceCommentModel from '@/models/SupplierInvoiceCommentModel';
import SupplierInvoiceFileModel from '@/models/SupplierInvoiceFileModel';

export default function InternalFileModal({
  isOpen, closeModal, supplierInvoice, updateSupplierInvoice,
}) {
  const [files, setFiles] = useState([]);
  const [processing, setProcessing] = useState(false);

  function onFileChange(e) {
    const newFiles = Array.from(e.target.files);
    const error = newFiles.some((file) => {
      if (file.size > 20000000) {
        toast.errorToast(I18n.t('purchase_orders.form.deliveries.to_big_file_size'));
        return true;
      }

      if (file.name.split('.').length > 2) {
        toast.errorToast(I18n.t('purchase_orders.form.deliveries.invalid_file_name'));
        return true;
      }

      if (FileFormatValidator.valid(file, /(\.xlsx|\.xls|\.csv)$/i)) {
        toast.errorToast(I18n.t('purchase_orders.form.deliveries.wrong_file_format'));
        return true;
      }
    });

    if (!error) setFiles(withUUID(newFiles));

    e.target.value = null;
  }

  function submit(e) {
    e.preventDefault();
    setProcessing(true);

    const formData = new FormData();
    formData.append('supplier_invoice_id', supplierInvoice.id);

    files.forEach((file) => {
      formData.append('supplier_invoice_files[][attachment]', file);
    });

    setFiles([]);

    api
      .makePostFileRequest('/api/supplier_invoice_files/create_all', formData)
      .then((response) => {
        const { data } = response;

        const newActivities = data.activities.map(activity => new SupplierInvoiceCommentModel(activity));
        const newFiles = data.files.map(file => new SupplierInvoiceFileModel(file));

        const newSupplierInvoice = supplierInvoice;

        newSupplierInvoice.comments = [...newActivities, ...supplierInvoice.comments];
        newSupplierInvoice.files = [...newFiles, ...supplierInvoice.files];

        updateSupplierInvoice(newSupplierInvoice, toast.successToast(I18n.t('attachments.messages.added')));
        clearFormState();
      })
      .catch((error) => {
        setProcessing(false);
        const responseError = error.response.data.errors[0];

        if (responseError) toast.errorToast(Object.values(responseError).flatten()[0]);
      });
  }

  function clearFormState() {
    setFiles([]);
    setProcessing(false);
    closeModal();
  }

  function resetFormState(file) {
    setFiles(files.filter(stateFile => stateFile.uuid != file.uuid));
    setProcessing(false);
  }

  return [
    <input key={0} autoComplete="off" className="modal-open" type="radio" value="on" checked={isOpen} />,
    <div key={1} className="modal sub-modal centered modal-s">
      <form className="modal-wrapper" onSubmit={submit}>
        <div className="modal-window window-inside window">
          <div className="window-header padding-bottom-2 modal-header window-header-auto">
            <h2>{I18n.t('supplier_invoices.edit.internal_file_modal.title')}</h2>
          </div>
          <div className="window-content">
            <div className="grid vertical-start">
              <div className="row">
                <div className="cell">
                  <p className="mute">{I18n.t('supplier_invoices.edit.internal_file_modal.internal_file_format_info')}</p>
                </div>
              </div>
              <div className="row">
                <div className="cell">
                  <div className="as-input required file-button has-progress">
                    <label className="drop-zone">
                      <i className="icon-upload_file" />
                      <span className="mute">
                        {I18n.translate('supplier_invoices.edit.modal.drag_and_drop')}
                        <br />
                        {I18n.translate('supplier_invoices.edit.modal.or')}
                      </span>
                      <input type="file" onChange={onFileChange} name="file" multiple="multiple" />
                      <div
                        className={`button button-s block button-primary file-name no-shadow${
                          processing ? ' hide' : ''
                        }`}
                      >
                        {I18n.t('purchase_orders.form.select_file')}
                      </div>
                    </label>
                    <div className={files.length !== 0 ? 'progress animate' : 'progress hide'}>
                      <div style={{ width: `${processing ? '100%' : '0'}` }} />
                    </div>
                    {files.map(file => (
                      <div key={file.uuid} className={file ? 'row' : 'row hide'}>
                        <span className="file-name state-info">{file.name || ''}</span>
                        {!processing && (
                          <button type="reset" onClick={() => resetFormState(file)} className="icon-close remove" />
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="window-footer modal-footer">
            <div className="items">
              <label className="button button-transparent button-mute inverse link" onClick={() => clearFormState()}>
                {I18n.t('commons.actions.close')}
              </label>
              {!processing && files.length !== 0 && (
                <input type="submit" value={I18n.t('purchase_orders.form.upload')} className="button button-primary" />
              )}
            </div>
          </div>
        </div>
      </form>
      <label className="modal-backdrop" onClick={() => clearFormState()} />
    </div>,
  ];
}
