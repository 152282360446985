import React from 'react';
import TableImageComponent from '.';
import { TABLE_IMAGE_SIZE, TABLE_IMAGE_STATE } from './constants';

export default {
  component: TableImage,
  title: 'Tables/Regular/TableImage',
  args: {
    src: 'https://www.cnet.com/a/img/resize/995d4d237a8244ec255b27a6a35476c6c6622fcd/hub/2019/09/25/8be7b304-eba7-4094-a9c9-243422e2ddc3/doge-meme.jpg?auto=webp&fit=crop&height=675&width=1200',
    alt: 'Alternative text',
    title: 'Example table image',
    size: 'SMALL',
    state: 'EMPTY',
  },
  argTypes: {
    src: {
      control: 'text',
    },
    alt: {
      control: 'text',
    },
    title: {
      control: 'text',
    },
    size: {
      control: 'radio',
      options: Object.keys(TABLE_IMAGE_SIZE),
    },
    state: {
      control: 'radio',
      options: ['EMPTY', ...Object.keys(TABLE_IMAGE_STATE)],
    },
  },
};

export const TableImage = ({ size, state, ...rest }) => (
  <TableImageComponent
    size={TABLE_IMAGE_SIZE[size]}
    state={TABLE_IMAGE_STATE[state] || ''}
    {...rest}
  />
);
