const numberFormatter = (value, numberFormat = {}) => {
  if (value !== '-') {
    const { decimal_mark: decimalMark, thousands_separator: thousandSeparator } = numberFormat;
    const parsedValue = I18n.toNumber(value, { delimiter: thousandSeparator || ' ', separator: decimalMark || '.', precision: 2 });
    const newValue = parsedValue == 'NaN' || Number.isNaN(parsedValue)
      ? Number(0).toFixed(2)
      : parsedValue;

    return newValue;
  }
  return value;
};

export const toNumber = (value, numberFormat = {}) => {
  if (Number.isNaN(Number(value))) {
    const { decimal_mark: decimalMark, thousands_separator: thousandSeparator } = numberFormat;
    return Number(value.replaceAll(thousandSeparator, '').replace(decimalMark, '.'));
  }
  return Number(value);
};

export default numberFormatter;
