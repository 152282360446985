import React from 'react';
import moment from 'moment';
import Loader from '../loader';
import IntegrationFiltering from './shared/integration_filtering';
import IntegrationType from './shared/integration_type';
import AutomaticMatching from './shared/automatic_matching';
import IntegrationsService from '../../services/integrations_service';
import * as toast from '../shared/toast';
import ConfirmationPopup from './shared/confirmation_popup';
import AutomaticMatchingModel from '../../models/automatic_matching_model';
import IntegrationFilteringModel from '../../models/integration_filtering_model';
import { INTEGRATION_TYPES } from '../../config/constants';

class IntegrationSetup extends React.Component {
  constructor(props) {
    super(props);

    IntegrationsService
      .settings({ integrationType: props.integrationType })
      .then(({
        integrationFiltering, automaticMatching, active, jobProcessing, id,
      }) => {
        if (jobProcessing) this.subscribeIntegration(id);

        this.setState({
          integrationFiltering,
          automaticMatching,
          active,
          isProcessing: jobProcessing,
          id,
          isSettingsLoading: false,
        });
      });

    this.state = {
      integrationFiltering: new IntegrationFilteringModel({}),
      automaticMatching: new AutomaticMatchingModel({}),
      active: false,
      updatedAt: props.updatedAt,
      openStartModal: false,
      transactionsCount: 0,
      isProcessing: false,
      isFounding: false,
      isFiltersLoading: true,
      isSettingsLoading: true,
      errors: {},
    };
  }

  componentWillUnmount() {
    if (this.subscription) this.subscription.unsubscribe();
  }

  isLoading = () => {
    const { isFiltersLoading, isSettingsLoading } = this.state;

    return isFiltersLoading || isSettingsLoading;
  }

  onFiltersLoad = () => {
    this.setState({ isFiltersLoading: false });
  }

  openStartModal = () => {
    this.setState({
      openStartModal: true,
    });
  }

  closeStartModal = () => {
    this.setState({
      openStartModal: false,
    });
  }

  onIntegrationFilteringChange = (e) => {
    const { integrationFiltering } = this.state;
    const { name, value } = e.target;
    integrationFiltering[name] = value;

    this.setState({
      integrationFiltering,
    });
  }

  onClosePurchaseOrderToggle = (e) => {
    const { automaticMatching } = this.state;
    const { name } = e.target;
    const capitalizedName = name.charAt(0).toUpperCase() + name.slice(1);

    this.setState({
      automaticMatching: automaticMatching[`set${capitalizedName}`](),
    });
  }

  onAutomaticMatchingChange = (e) => {
    const { automaticMatching } = this.state;
    const { name, value } = e.target;
    automaticMatching[name] = value;

    this.setState({
      automaticMatching,
    });
  }

  onAutomaticMatchingToggle = (e) => {
    const { automaticMatching } = this.state;
    const { name } = e.target;
    automaticMatching[name] = !automaticMatching[name];

    this.setState({
      automaticMatching,
    });
  }

  onSave = () => {
    const { props: { integrationType }, state: { automaticMatching, integrationFiltering } } = this;

    IntegrationsService
      .saveSettings({
        integrationType,
        automaticMatching,
        integrationFiltering,
      }).then(() => {
        this.setState({ errors: {} });
        toast.successToast(I18n.t('settings.integrations.new.setup.changes_saved'));
      }).catch((error) => {
        this.setState({ errors: error.response.data });
        toast.errorToast(I18n.t('settings.integrations.new.setup.error_save'));
      });
  }

  onSync = () => {
    const { props: { edit, integrationType }, state: { automaticMatching, integrationFiltering } } = this;

    this.setState({ openStartModal: true, isFounding: true });

    IntegrationsService
      .authorized().then(({ authorized }) => {
        if (authorized) {
          IntegrationsService.synchronizeCount({
            integrationType,
            automaticMatching,
            integrationFiltering,
          }).then(({ transactionsCount }) => {
            this.setState({ isFounding: false, transactionsCount });
          });
        } else {
          toast.errorToast(I18n.t('settings.integrations.common.unauthorized'));
          toast.warningBox(I18n.t('settings.integrations.new.setup.connection_not_established'));
          this.setState({ isProcessing: false });
          edit();
        }
      });
  }

  onConfirmedSync = () => {
    const { state: { automaticMatching, integrationFiltering, id }, props: { edit, integrationType } } = this;

    this.setState({ openStartModal: false, isProcessing: true });

    IntegrationsService
      .authorized().then(({ authorized }) => {
        if (authorized) {
          this.subscribeIntegration(id);

          IntegrationsService
            .synchronize({
              integrationType,
              automaticMatching,
              integrationFiltering,
            }).catch(() => {
              this.setState({ isProcessing: false });
              toast.errorToast(I18n.t('settings.integrations.new.setup.error_save'));
            });
        } else {
          toast.errorToast(I18n.t('settings.integrations.common.unauthorized'));
          toast.warningBox(I18n.t('settings.integrations.new.setup.connection_not_established'));
          this.setState({ isProcessing: false });
          edit();
        }
      });
  }

  manageDepartmentId = (e) => {
    const { value } = e.target;
    const { integrationFiltering } = this.state;
    this.setState({
      integrationFiltering: integrationFiltering.manageDepartmentId(value),
    });
  }

  manageProjectId = (e) => {
    const { value } = e.target;
    const { integrationFiltering } = this.state;
    this.setState({
      integrationFiltering: integrationFiltering.manageProjectId(value),
    });
  }

  manageAccountId = (e) => {
    const { value } = e.target;
    const { integrationFiltering } = this.state;
    this.setState({
      integrationFiltering: integrationFiltering.manageAccountId(value),
    });
  }

  _renderSyncButton = () => {
    const { isProcessing, updatedAt } = this.state;
    const htmlClasses = isProcessing ? ' processing' : '';

    if (updatedAt) {
      return (
        <a onClick={this.onSync} className={`button button-info will-processing${htmlClasses}`}>
          <i className="icon-refresh" />
          &nbsp;
          {I18n.t('commons.actions.refresh')}
        </a>
      );
    }
    return (
      <a onClick={this.onSync} className={`button button-info ${htmlClasses}`}>{I18n.t('commons.actions.start_download')}</a>
    );
  }

  formatDateTime = date => moment(date).format('DD/MM/YYYY')

  startModalContent = transactionsCount => (
    <React.Fragment>
      <p>{I18n.t('settings.integrations.new.connect.start_modal.description_line_1', { transactions_count: transactionsCount })}</p>
      <p>{I18n.t('settings.integrations.new.connect.start_modal.description_line_2')}</p>
    </React.Fragment>
  )

  startModalLoadingContent = () => (
    <React.Fragment>
      <p>
        {I18n.t('settings.integrations.new.connect.start_modal.description_line_1', { transactions_count: '' })}
        <span className="animated-dots" />
      </p>
      <p>{I18n.t('settings.integrations.new.connect.start_modal.description_line_2')}</p>
    </React.Fragment>
  )

  subscribeIntegration(id) {
    const { integrationType } = this.props;
    this.subscription = App.cable.subscriptions.create(
      { channel: 'IntegrationsChannel', integration: id },
      {
        connected: () => {
          IntegrationsService
            .settings({ integrationType })
            .then(({ jobProcessing }) => {
              this.setState({ isProcessing: jobProcessing });
            });
        },
        received: (data) => {
          if (data.action == 'refresh_all') {
            if (data.authorized) {
              this.setState({
                isProcessing: false,
                updatedAt: new Date(),
              });
              toast.successToast(I18n.t('settings.integrations.common.synchronized'));
            } else {
              this.setState({
                isProcessing: false,
              });
              toast.errorToast(I18n.t('settings.integrations.common.unauthorized'));
            }
            this.subscription.unsubscribe();
          }
        },
      },
    );
  }

  render() {
    const {
      props: {
        edit, updatedAt, hide, integrationType, openDisconnectModal, changeIntegrationScope, integrationScope,
      },
      state: {
        integrationFiltering, automaticMatching, openStartModal, isFounding, transactionsCount, errors,
      },
    } = this;

    const htmlClasses = this.isLoading() ? ' has-loading' : '';

    return (
      <React.Fragment>
        { openStartModal
          ? (
            <ConfirmationPopup
              content={this.startModalContent(transactionsCount)}
              loadingContent={this.startModalLoadingContent()}
              loading={isFounding}
              confirmButtonType="info"
              confirmButtonText={I18n.t('settings.integrations.new.connect.start_modal.download')}
              onReject={this.closeStartModal}
              onConfirm={this.onConfirmedSync}
            />
          )
          : null }
        <div className={`${htmlClasses}`}>
          <div className="tile-content form-section-content">
            <Loader />
            <div className="grid gutter-1 space-3">
              <div className="row">
                <div className="cell-16">
                  <p className="items-end items font-m mute">
                    <a className="link icon-edit" onClick={edit}>{I18n.t('settings.integrations.new.setup.edit')}</a>
                  </p>
                </div>
              </div>
              {
                integrationType === INTEGRATION_TYPES.QUICKBOOKS
                  ? (
                    <div className="row">
                      <IntegrationType
                        changeIntegrationScope={changeIntegrationScope}
                        integrationScope={integrationScope}
                      />
                    </div>
                  )
                  : null
              }
              <div className="row">
                <IntegrationFiltering
                  key={`${integrationType}Filter`}
                  integrationType={integrationType}
                  {...integrationFiltering}
                  onIntegrationFilteringChange={this.onIntegrationFilteringChange}
                  onLoad={this.onFiltersLoad}
                  edit={edit}
                  manageDepartmentId={this.manageDepartmentId}
                  manageProjectId={this.manageProjectId}
                  manageAccountId={this.manageAccountId}
                  errors={errors}
                />
                <AutomaticMatching
                  key={`${integrationType}Matching`}
                  {...automaticMatching}
                  onAutomaticMatchingChange={this.onAutomaticMatchingChange}
                  onAutomaticMatchingToggle={this.onAutomaticMatchingToggle}
                  onClosePurchaseOrderToggle={this.onClosePurchaseOrderToggle}
                />
              </div>
            </div>
          </div>
          <div className="tile-footer form-section-footer tile-footer-top">
            <span />
            <span className="items items-end">
              <a onClick={hide} className="button button-transparent button-mute inverse link">{I18n.t('commons.actions.cancel')}</a>
              { integrationType === INTEGRATION_TYPES.QUICKBOOKS
                ? <a className="button inverse button-mute not-upper" onClick={openDisconnectModal}>DISCONNECT QuickBooks</a>
                : null
              }
              <a onClick={this.onSave} className="button button-primary">{I18n.t('settings.integrations.new.setup.changes_saved')}</a>
              {this._renderSyncButton()}
              { updatedAt ? (
                <small className="mute display-block text-right">
                  <i className="link icon-refresh" />
                &nbsp;
                  {`${I18n.t('settings.integrations.new.last_updated')}: `}
                  {this.formatDateTime(updatedAt)}
                </small>
              ) : null }
            </span>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default IntegrationSetup;
