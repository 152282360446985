const measureText = (value) => {
  let lDiv = document.createElement('div');

  document.body.appendChild(lDiv);

  lDiv.style = 'padding-right: var(--filter-item-padding-right);padding-left: var(--select-xs-item-padding-left);min-width: var(--select-xs-item-min-width);';
  lDiv.style.fontSize = 'var(--filter-item-font-size)';
  lDiv.style.position = 'absolute';
  lDiv.style.left = -1000;
  lDiv.style.top = -1000;

  lDiv.innerHTML = value;

  const lResult = {
    width: lDiv.clientWidth,
    height: lDiv.clientHeight,
  };

  document.body.removeChild(lDiv);
  lDiv = null;

  return lResult;
};

export default measureText;
