import React from 'react';
import classNames from 'classnames';
import { withLimit } from '../../shared/utils';
import AddressContactModal from './address_contacts_modal';

class AddressContact extends React.Component {
  constructor(props) {
    super(props);

    const { addressContacts } = props;

    this.allAddressContacts = addressContacts;

    this.state = {
      isModalOpen: false,
      addressContacts: withLimit(addressContacts || []),
    };
  }

  componentDidUpdate(prevProps) {
    const { props: { addressContacts } } = this;
    if (JSON.stringify(addressContacts) !== JSON.stringify(prevProps.addressContacts)) {
      this.addAddressContacts(addressContacts);
    }
  }

  addAddressContacts = (addressContacts) => {
    this.allAddressContacts = addressContacts;
    this.setState({ addressContacts: withLimit(addressContacts) });
  }

  checkInput = (addressContact) => {
    const { purchaseOrderAddressContact } = this.props;
    if ((purchaseOrderAddressContact.id == null
      && addressContact.default == true) || purchaseOrderAddressContact.id == addressContact.id) {
      return true;
    }
    return false;
  }

  openModal = () => {
    this.setState({ isModalOpen: true });
  }

  closeModal = () => {
    this.setState({ isModalOpen: false });
  }

  onChangeSearchAddressContact = (e) => {
    const { typingTimeout } = this.state;
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    this.setState({
      searchValue: e.target.value,
      typing: false,
      typingTimeout: setTimeout(() => {
        const {
          state: { searchValue },
          props: { purchaseOrderAddressContact },
          allAddressContacts,
        } = this;
        this.setState({
          addressContacts: withLimit(allAddressContacts.filter(
            addressContact => addressContact.id === purchaseOrderAddressContact.id
              || addressContact.name.toLowerCase().includes(searchValue.toLowerCase())
              || addressContact.email.toLowerCase().includes(searchValue.toLowerCase())
              || addressContact.phone.toLowerCase().includes(searchValue.toLowerCase()),
          )),
        });
      }, 350),
    });
  }

  render() {
    const {
      state: { isModalOpen, addressContacts },
      props: {
        purchaseOrder, handleAddAddressContact,
        onPurchaseOrderAddressContactChange, type, addressContactError,
        purchaseOrderAddressContact, addressId,
      },
    } = this;

    return (
      <React.Fragment>
        <div className="row">
          <div className={`cell-16 ${addressContactError ? 'has-error' : ''}`}>
            <div className={classNames('as-input', 'reduce', { required: purchaseOrder.custom_po_enabled })}>
              { addressId ? (
                <label tabIndex="-1" className="add-edit-trigger"><i className="icon-add" onClick={this.openModal} /></label>
              ) : null}
              <span className="label">
                {I18n.t(`purchase_orders.form.${type}_contact_person`)}
              </span>
              <span className={`select can-unselect input-r add-edit${(!purchaseOrder.editable || !addressId) ? ' disabled' : ''} ${purchaseOrderAddressContact.id ? ' selected' : ''}`}>
                <input type="checkbox" />
                <div className="control">
                  <input type="text" tabIndex="-1" onChange={this.onChangeSearchAddressContact} placeholder={I18n.t('purchase_orders.form.type_to_search')} />
                </div>
                <div className="select-content">
                  {addressContacts.map(addressContact => (
                    <React.Fragment key={addressContact.id}>
                      <label
                        className={`${this.checkInput(addressContact) ? 'checked' : ''}`}
                        key={`label-${addressContact.id}`}
                        onClick={() => {
                          onPurchaseOrderAddressContactChange(addressContact);
                        }}
                      >
                        <span>{addressContact.name}</span>
                        <span>
                          {`${addressContact.email} ${addressContact.phone}`}
                        </span>
                      </label>
                    </React.Fragment>
                  ))}
                  <div
                    className="placeholder"
                    data-placeholder={
                      I18n.t(`purchase_orders.form.select_${type}_contact_person`)
                    }
                  />
                </div>
                {addressContactError ? <div className="hint error">{addressContactError}</div> : ''}
              </span>
            </div>
          </div>
        </div>
        <AddressContactModal
          handleAddAddressContact={handleAddAddressContact}
          addressId={addressId}
          isOpen={isModalOpen}
          closeModal={this.closeModal}
          type={type}
        />
      </React.Fragment>
    );
  }
}

export default AddressContact;
