import PropTypes from 'prop-types';
import React from 'react';

const Actions = ({ children }) => {
  const shadow = 'after:ct-block after:ct-w-full after:ct-h-2 after:ct-absolute after:ct-z-20 after:ct-top-0 after:ct-right-0 after:ct-shadow-header after:ct-rotate-180 after:ct-translate-y-[100%]';

  return (
    <section className={`ct-sticky ct-bg-white ct-z-20 ct-bottom-0 ct-flex ct-items-center ct-justify-end ct-gap-2.5 ct-w-full ct-pt-10 ct-pb-8 ct-px-16 ${shadow}`}>
      {children}
    </section>
  );
};

export default Actions;

Actions.propTypes = {
  children: PropTypes.node.isRequired,
};
