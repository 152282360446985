import React from 'react';
import * as toast from '@/components/shared/toast';
import ArchiveService from '../../../services/archive_service';

const sendToEmail = (format, type) => {
  let params = window.location.search;
  if (params.length == 0) {
    params = '?export_to_mail=true';
  } else {
    params += '&export_to_mail=true';
  }
  if (type) { params += `&type=${type}`; }
  ArchiveService.downloadPurchaseOrders(`.${format}${params}`).then(({ message }) => {
    toast.warningToast(message);
  });
};

const RenderUsExport = (inlineDownload, exportUrl) => {
  if (inlineDownload) {
    return (
      <li className="item">
        <a className="anchor" href={exportUrl('csv', 'us')}>
          {I18n.t('purchase_orders.export.csv_us')}
        </a>
      </li>
    );
  }

  return (
    <li className="item">
      <a className="anchor" onClick={() => sendToEmail('csv', 'us')} href="">
        {I18n.t('purchase_orders.export.csv_us')}
      </a>
    </li>
  );
};
export default function ExportButton({
  exportUrl, usExportFormat, buttonName, inlineDownload = true,
}) {
  return (
    <div className="has-popup">
      <input type="checkbox" />
      <a className="button button-primary button-s" href="" onClick={e => e.preventDefault()}>
        {buttonName || I18n.t('commons.filters.export')}
      </a>
      <ul className="popup menu top-left min-width-1">
        <li className="item">
          {inlineDownload
            ? (
              <a className="anchor" href={exportUrl('csv')}>
                {I18n.t('purchase_orders.export.csv')}
              </a>
            ) : (
              <a className="anchor" onClick={() => sendToEmail('csv')} href="#">
                {I18n.t('purchase_orders.export.csv')}
              </a>
            )
          }
        </li>
        {usExportFormat
          ? (
            RenderUsExport(inlineDownload, exportUrl)
          ) : (
            null
          )
        }
        <li className="item">
          {inlineDownload
            ? (
              <a className="anchor" href={exportUrl('xlsx')}>
                {I18n.t('purchase_orders.export.xlsx')}
              </a>
            ) : (
              <a className="anchor" onClick={() => sendToEmail('xlsx')} href="#">
                {I18n.t('purchase_orders.export.xlsx')}
              </a>
            )
          }
        </li>
      </ul>
    </div>
  );
}
