import React from 'react';
import PropTypes from 'prop-types';
import * as api from '../../shared/api';
import * as toast from '../../shared/toast';
import { INTEGRATION_TYPES } from '../../../config/constants';

class SupplierModal extends React.Component {
  static propTypes = {
    name: PropTypes.string,
    vat_number: PropTypes.string,
    street: PropTypes.string,
    address_line2: PropTypes.string,
    address_line3: PropTypes.string,
    zip_code: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    country: PropTypes.string,
    email: PropTypes.string,
    phone_number: PropTypes.string,
    delivery_terms: PropTypes.string,
    supplier_number: PropTypes.string,
    payment_term_id: PropTypes.number,
    isOpen: PropTypes.bool,
    handleClose: PropTypes.func,
    handleAdd: PropTypes.func,
  }

  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.submit = this.submit.bind(this);
    this.close = this.close.bind(this);
    this.clear = this.clear.bind(this);
    this.prepareBody = this.prepareBody.bind(this);
    this.escFunction = this.escFunction.bind(this);

    this.state = {
      name: '',
      vat_number: '',
      street: '',
      address_line2: '',
      address_line3: '',
      zip_code: '',
      city: '',
      state: '',
      country: '',
      email: '',
      phone_number: '',
      currency: props.companyCurrency,
      delivery_terms: '',
      supplier_number: '',
      payment_term_id: null,
      pdf_language: '',
      errors: {},
    };
  }

  componentDidMount() {
    document.addEventListener('keydown', this.escFunction, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.escFunction, false);
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  escFunction(event) {
    const { handleClose } = this.props;
    if (event.key === 'Escape') {
      handleClose();
    }
  }

  prepareBody() {
    const { source } = this.props;
    const {
      name, vat_number, street, address_line2, address_line3,
      zip_code, city, state, country, email, phone_number,
      currency, delivery_terms, supplier_number, payment_term_id, pdf_language,
    } = this.state;

    return {
      supplier: {
        name,
        vat_number,
        street,
        address_line2,
        address_line3,
        zip_code,
        city,
        state,
        country,
        email,
        phone_number,
        currency,
        delivery_terms,
        supplier_number,
        payment_term_id,
        pdf_language,
        source: source || 'manual',
      },
    };
  }

  submit(e) {
    const { handleAdd } = this.props;
    e.preventDefault();
    api.makePostRequest('/suppliers', this.prepareBody())
      .then((response) => {
        this.clear();
        this.close();
        handleAdd(response.data.supplier);
        toast.successToast(I18n.t('suppliers.messages.created'));
      })
      .catch((error) => {
        if (error.response.data.base) {
          toast.errorToast(error.response.data.base);
        }
        this.setState({ errors: error.response.data });
      });
  }

  clear() {
    const { companyCurrency } = this.props;
    this.setState({
      name: '',
      vat_number: '',
      street: '',
      address_line2: '',
      address_line3: '',
      zip_code: '',
      city: '',
      state: '',
      country: '',
      email: '',
      phone_number: '',
      currency: companyCurrency,
      delivery_terms: '',
      supplier_number: '',
      payment_term_id: null,
      pdf_language: '',
    });
    this.setState({ errors: {} });
  }

  close() {
    const { handleClose } = this.props;
    handleClose();
  }

  render() {
    const {
      state: {
        errors, name, supplier_number, vat_number, street, address_line2,
        address_line3, zip_code, city, state, country, email, phone_number,
        currency, delivery_terms, payment_term_id, pdf_language,
      },
      props: {
        isOpen, currencies, handleClose, source, modalPosition, paymentTerms,
      },
    } = this;

    return (
      <React.Fragment>
        <input key={0} autoComplete="off" className="modal-open" type="radio" value="on" checked={isOpen} readOnly />
        <div key={1} className={`modal sub-modal centered ${modalPosition || ''}`}>
          <form className="modal-wrapper" onSubmit={this.submit}>
            <div className="modal-window window-inside window">
              <div className="window-header modal-header">
                {I18n.t('purchase_orders.form.add_supplier')}
              </div>
              <div className="window-content">
                <div className="grid space-3 gutter-5 vertical-start">
                  <div className="cell-8 grid space-2 t-cell-16 gutter-2">
                    <div className="row">
                      <div className={`cell-16 ${errors.name ? 'has-error' : ''}`}>
                        <div className="as-input required">
                          <span className="label">{I18n.t('suppliers.partials.form.name')}</span>
                          <input placeholder={I18n.t('suppliers.partials.form.enter_name')} id="supplier_name" name="name" type="text" value={name} onChange={this.onChange} />
                          { errors.name
                            ? <div className="hint">{errors.name.join(', ')}</div> : ''
                        }
                        </div>
                      </div>
                    </div>
                    {
                      source === INTEGRATION_TYPES.XERO
                        ? null
                        : (
                          <div className="row">
                            <div className={`cell-16 ${errors.supplier_number ? 'has-error' : ''}`}>
                              <div className="as-input">
                                <span className="label">{I18n.t('suppliers.partials.form.supplier_number')}</span>
                                <input placeholder={I18n.t('suppliers.partials.form.enter_supplier_number')} id="supplier_supplier_number" name="supplier_number" type="text" value={supplier_number} onChange={this.onChange} />
                                { errors.supplier_number
                                  ? <div className="hint">{errors.supplier_number.join(', ')}</div> : ''
                              }
                              </div>
                            </div>
                          </div>
                        )
                    }
                    <div className="row">
                      <div className={`cell-16 ${errors.vat_number ? 'has-error' : ''}`}>
                        <div className="as-input">
                          <span className="label">{I18n.t('suppliers.partials.form.vat_number')}</span>
                          <input placeholder={I18n.t('suppliers.partials.form.enter_vat_number')} id="supplier_vat_number" name="vat_number" type="text" value={vat_number} onChange={this.onChange} />
                          { errors.vat_number
                            ? <div className="hint">{errors.vat_number.join(', ')}</div> : ''
                        }
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className={`cell-16 ${errors.street ? 'has-error' : ''}`}>
                        <div className="as-input required">
                          <span className="label">{I18n.t('purchase_orders.form.address')}</span>
                          <input placeholder={I18n.t('suppliers.partials.form.enter_delivery_address')} id="supplier_street" name="street" type="text" value={street} onChange={this.onChange} />
                          { errors.street
                            ? <div className="hint">{errors.street.join(', ')}</div> : ''
                        }
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className={`cell-16 ${errors.address_line2 ? 'has-error' : ''}`}>
                        <div className="as-input">
                          <span className="label">{I18n.t('suppliers.partials.form.address_line2')}</span>
                          <input placeholder={I18n.t('suppliers.partials.form.enter_address_line2')} id="supplier_address_line2" name="address_line2" type="text" value={address_line2} onChange={this.onChange} />
                          { errors.address_line2
                            ? <div className="hint">{errors.address_line2.join(', ')}</div> : ''
                        }
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className={`cell-16 ${errors.address_line3 ? 'has-error' : ''}`}>
                        <div className="as-input">
                          <span className="label">{I18n.t('suppliers.partials.form.address_line3')}</span>
                          <input placeholder={I18n.t('suppliers.partials.form.enter_address_line3')} id="supplier_address_line3" name="address_line3" type="text" value={address_line3} onChange={this.onChange} />
                          { errors.address_line3
                            ? <div className="hint">{errors.address_line3.join(', ')}</div> : ''
                        }
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className={`cell-7 ${errors.zip_code ? 'has-error' : ''}`}>
                        <div className="as-input required">
                          <span className="label">{I18n.t('purchase_orders.form.zip_code')}</span>
                          <input placeholder={I18n.t('suppliers.partials.form.enter_zip_code')} id="supplier_zip_code" name="zip_code" type="text" value={zip_code} onChange={this.onChange} />
                          { errors.zip_code
                            ? <div className="hint">{errors.zip_code.join(', ')}</div> : ''
                        }
                        </div>
                      </div>
                      <div className={`cell-9 ${errors.city ? 'has-error' : ''}`}>
                        <div className="as-input required">
                          <span className="label">{I18n.t('purchase_orders.form.city')}</span>
                          <input placeholder={I18n.t('suppliers.partials.form.enter_city')} id="supplier_city" name="city" type="text" value={city} onChange={this.onChange} />
                          { errors.city
                            ? <div className="hint">{errors.city.join(', ')}</div> : ''
                        }
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className={`cell-16 ${errors.state ? 'has-error' : ''}`}>
                        <div className="as-input">
                          <span className="label">{I18n.t('suppliers.partials.form.state')}</span>
                          <input placeholder={I18n.t('suppliers.partials.form.enter_state')} id="supplier_state" name="state" type="text" value={state} onChange={this.onChange} />
                          { errors.state
                            ? <div className="hint">{errors.state.join(', ')}</div> : ''
                        }
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="cell-8 grid space-2 t-cell-16">
                    <div className="row">
                      <div className={`cell-16 ${errors.country ? 'has-error' : ''}`}>
                        <div className="as-input required">
                          <span className="label">{I18n.t('purchase_orders.form.country')}</span>
                          <input placeholder={I18n.t('suppliers.partials.form.enter_country')} id="supplier_country" name="country" type="text" value={country} onChange={this.onChange} />
                          { errors.country
                            ? <div className="hint">{errors.country.join(', ')}</div> : ''
                        }
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className={`cell-16 ${errors.email ? 'has-error' : ''}`}>
                        <div className="as-input">
                          <span className="label">{I18n.t('purchase_orders.form.email')}</span>
                          <input placeholder={I18n.t('suppliers.partials.form.enter_email')} id="supplier_email" name="email" type="text" value={email} onChange={this.onChange} />
                          { errors.email
                            ? <div className="hint">{errors.email.join(', ')}</div> : ''
                        }
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className={`cell-16 ${errors.phone_number ? 'has-error' : ''}`}>
                        <div className="as-input">
                          <span className="label">{I18n.t('suppliers.partials.form.phone_number')}</span>
                          <input placeholder={I18n.t('suppliers.partials.form.enter_phone_number')} id="supplier_phone_number" name="phone_number" type="text" value={phone_number} onChange={this.onChange} />
                          { errors.phone_number
                            ? <div className="hint">{errors.phone_number.join(', ')}</div> : ''
                        }
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className={`cell-16 ${errors.currency ? 'has-error' : ''}`}>
                        <div className="as-input required">
                          <span className="label">{I18n.t('purchase_orders.form.currency')}</span>
                          <span className="select react-only">
                            <input type="checkbox" readOnly defaultChecked={false} />
                            <div className="select-content">
                              { currencies.map(mappedCurrency => (
                                [
                                  <input key={`input-${mappedCurrency}`} id={`supplier_curreny_${mappedCurrency}`} name="currency" type="radio" checked={currency == mappedCurrency} value={mappedCurrency} onChange={this.onChange} />,
                                  <label key={`label-${mappedCurrency}`} htmlFor={`supplier_curreny_${mappedCurrency}`}>
                                    {mappedCurrency}
                                  </label>,
                                ]
                              )) }
                              <div className="placeholder" data-placeholder={I18n.t('purchase_orders.form.select_currency')} data-selected={I18n.t('purchase_orders.form.selected')} />
                            </div>
                          </span>
                          { errors.currency
                            ? <div className="hint">{errors.currency.join(', ')}</div> : ''
                      }
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className={`cell-16 ${errors.payment_term_id ? 'has-error' : ''}`}>
                        <div className="as-input required">
                          <span className="label">{I18n.t('purchase_orders.form.payment_terms')}</span>
                          <span className="select">
                            <input type="checkbox" readOnly defaultChecked={false} />
                            <div className="select-content">
                              { paymentTerms.map(paymentTerm => (
                                [
                                  <input key={`input-${paymentTerm.id}`} id={`supplier_payment_term_${paymentTerm.id}`} name="payment_term_id" type="radio" checked={payment_term_id == paymentTerm.id} value={paymentTerm.id} onChange={this.onChange} />,
                                  <label key={`label-${paymentTerm.id}`} htmlFor={`supplier_payment_term_${paymentTerm.id}`}>
                                    {`${paymentTerm.name} - ${paymentTerm.value} days`}
                                  </label>,
                                ]
                              )) }
                              <div className="placeholder" data-placeholder={I18n.t('purchase_orders.form.select_payment_terms')} data-selected={I18n.t('purchase_orders.form.selected')} />
                            </div>
                          </span>
                          { errors.payment_term_id
                            ? <div className="hint">{errors.payment_term_id.join(', ')}</div> : ''
                          }
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className={`cell-16 ${errors.delivery_terms ? 'has-error' : ''}`}>
                        <div className="as-input">
                          <span className="label">{I18n.t('purchase_orders.form.delivery_terms')}</span>
                          <input placeholder={I18n.t('suppliers.partials.form.enter_delivery_terms')} id="supplier_delivery_terms" name="delivery_terms" type="text" value={delivery_terms} onChange={this.onChange} />
                          { errors.delivery_terms
                            ? <div className="hint">{errors.delivery_terms.join(', ')}</div> : ''
                        }
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className={`cell-16 ${errors.pdf_language ? 'has-error' : ''}`}>
                        <div className="as-input">
                          <span className="label">{I18n.t('purchase_orders.form.pdf_language')}</span>
                          <span className="select react-only">
                            <input type="checkbox" readOnly defaultChecked={false} />
                            <div className="select-content">
                              {
                                ['en', 'nb'].map(language => (
                                  <React.Fragment key={language}>
                                    <input
                                      key={`input-${language}`}
                                      id={`supplier_language_${language}`}
                                      name="pdf_language"
                                      type="radio"
                                      checked={pdf_language == language}
                                      value={language}
                                      onChange={this.onChange}
                                    />
                                    <label
                                      key={`label-${language}`}
                                      htmlFor={`supplier_language_${language}`}
                                    >
                                      {language}
                                    </label>
                                  </React.Fragment>
                                ))
                              }
                              <div
                                className="placeholder"
                                data-placeholder={I18n.t('purchase_orders.form.select_pdf_language')}
                                data-selected={I18n.t('purchase_orders.form.selected')}
                              />
                            </div>
                          </span>
                          {errors.pdf_language
                            ? <div className="hint">{errors.pdf_language.join(', ')}</div> : ''
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="window-footer modal-footer">
                <div className="items">
                  <label className="button button-transparent button-mute inverse link" onClick={handleClose}>{I18n.t('purchase_orders.form.cancel')}</label>
                  <button className="button button-primary">{I18n.t('purchase_orders.form.save')}</button>
                </div>
              </div>
            </div>
          </form>
          <label className="modal-backdrop" onClick={handleClose} />
        </div>
      </React.Fragment>
    );
  }
}

export default SupplierModal;
