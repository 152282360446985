import uuid from 'uuid/v4';

class ProjectModel {
  constructor(params) {
    this.id = params?.id || '';
    this.name = params?.name;
    this.number = params?.number || '';
    this.uuid = uuid();
    this.dispValues = [this.name];
    this.deletedAt = params?.deleted_at;
  }
}

export default ProjectModel;
