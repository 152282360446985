import uuid from 'uuid/v4';
import CurrencyModel from './currency_model';

class SupplierModel {
  constructor({
    id, supplier_number, name, zip_code, city, currency, deleted_at,
  }) {
    this.id = id;
    this.supplierNumber = supplier_number;
    this.name = name;
    this.uuid = uuid();
    this.currency = new CurrencyModel(currency);
    this.deletedAt = deleted_at;
    this.zipCode = zip_code || '';
    this.city = city || '';
    this.dispValues = [`${name}`, `${this.zipCode} ${this.city}`];
  }
}

export default SupplierModel;
