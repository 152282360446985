import React, { useEffect, useState } from 'react';
import IntegrationsAccountsService from '../../services/integrations_accounts_service';

function CreditAccountInput({
  creditAccount, onChangeCreditAccount, integrationType, setIsLoading,
}) {
  const [accounts, setAccounts] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    new IntegrationsAccountsService(integrationType).all().then((fetchedAccounts) => {
      setAccounts(fetchedAccounts);
      setIsLoading(false);
    });
  }, []);

  const onChange = ({ target: { value } }) => {
    const newAccount = accounts.find(account => account.id == value);
    onChangeCreditAccount(newAccount);
  };

  return (
    <div className="cell-5 offset-1 offset-right-1 t-cell-16 t-offcet-right-0 t-offset-0">
      <div className="grid space-2">
        <div className="row">
          <div className="cell-16 t-cell-16">
            <div className="as-input required">
              <span className="label">{I18n.t('settings.integrations.new.setup.credit_account')}</span>
              <span className="select">
                <input type="checkbox" />
                <div className="select-content">
                  { accounts.map(account => (
                    <React.Fragment key={`account-${account.id}}`}>
                      <input
                        id={`account_${account.id}`}
                        value={account.id}
                        onChange={onChange}
                        name="account"
                        type="radio"
                        checked={creditAccount.name === account.name}
                      />
                      <label htmlFor={`account_${account.id}`}>{`${account.number} ${account.name}`}</label>
                    </React.Fragment>
                  )) }
                  <div className="placeholder" data-placeholder={I18n.t('settings.integrations.new.setup.select_account')} data-selected={I18n.t('purchase_orders.form.selected')} />
                </div>
              </span>
              <span />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreditAccountInput;
