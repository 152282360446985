import React from 'react';
import ProgressBarComponent from './index';

export default {
  component: ProgressBar,
  title: 'Design System/ProgressBar',
  args: {
    value: 40,
    max: 100,
    strict: false,
  },
  argTypes: {
    value: {
      control: 'number',
    },
    max: {
      control: 'number',
    },
    strict: {
      control: 'boolean',
    },
  },
};

export const ProgressBar = args => <ProgressBarComponent {...args} />;
