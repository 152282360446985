import React from 'react';
import DropdownsService from '../../../services/dropdowns_service';

class DropdownTableField extends React.Component {
  constructor(props) {
    super(props);

    const { value } = this.setValue();

    this.state = {
      mainEntities: props.entities,
      entities: props.entities,
      value,
      inputValue: '',
    };
  }

  componentDidMount() {
    const {
      fieldName, type, setIsLoading, integrationType,
    } = this.props;


    if (type === 'fetch') {
      setIsLoading(true);

      DropdownsService.getEnt(fieldName, '', integrationType)
        .then((response) => {
          this.setState(
            {
              entities: response,
            },
          );
          setIsLoading(false);
        });
    }
  }

  componentDidUpdate(prevProps) {
    const { defaultValue } = this.props;

    if (defaultValue && prevProps.defaultValue !== defaultValue) {
      this.onDefaultValueChange();
    }
  }

  onDefaultValueChange = () => {
    const {
      props: {
        transaction,
        fieldName,
        defaultValue,
        updateDropdownSupplierInvoice,
      },
      state: {
        entities,
      },
    } = this;

    const selected = entities.find(ent => (ent.externalId === defaultValue) || (ent.id === defaultValue));

    if (selected) {
      const newTransaction = transaction;
      newTransaction[fieldName] = selected;

      this.setState({ value: selected.id });
      updateDropdownSupplierInvoice(newTransaction);
    }
  }

  onInputValue = ({ target: { value } }) => {
    const { mainEntities } = this.state;
    const { fieldName, type, integrationType } = this.props;
    if (this.timer) clearTimeout(this.timer);
    this.setState({ inputValue: value }, () => {
      this.timer = setTimeout(() => {
        const { inputValue } = this.state;

        if (type === 'fetch') {
          DropdownsService.getEnt(fieldName, value, integrationType)
            .then((response) => {
              this.setState({ entities: response, inputValue });
            });
        } else {
          this.setState({
            entities: mainEntities.filter(
              entity => (
                entity.name.toLowerCase().includes(value.toLowerCase())
              ),
            ),
          });
        }
      }, 500);
    });
  };

  setValue = () => {
    const {
      transaction, fieldName, entities,
    } = this.props;
    const selected = entities.find(ent => transaction[fieldName].find(transactionEnt => transactionEnt.id === ent.id));
    return {
      value: (selected && selected.id) || transaction[fieldName].id || 'default',
    };
  }

  onChange = (selectedValue) => {
    const {
      props: { updateDropdownSupplierInvoice, transaction, fieldName },
      state: { entities, value },
    } = this;
    const newTransaction = transaction;
    const selected = entities.find(ent => ent.id === parseInt(selectedValue));
    const deselected = parseInt(selectedValue) === parseInt(value);

    if (fieldName === 'trackingOptions') {
      if (!newTransaction[fieldName].find(ent => ent.id === selectedValue)) {
        newTransaction[fieldName].push(selected);
      }
      newTransaction[fieldName] = transaction[fieldName].map((ent) => {
        const newEnt = ent;
        if (entities.some(el => el.id === ent.id && el.id !== selected.id)) {
          newEnt.destroy = 1;
          return newEnt;
        }
        if (newEnt.id === selected.id) newEnt.destroy = deselected ? 1 : 0;
        return newEnt;
      });
    } else {
      newTransaction[fieldName] = selected;
    }

    this.setState({ value: parseInt(deselected ? '' : selectedValue) });
    updateDropdownSupplierInvoice(newTransaction);
  }

  removeDuplicates = myArr => myArr.filter(
    (obj, pos, arr) => arr.map(mapObj => mapObj?.id).indexOf(obj?.id) === pos,
  )

  checked = (ent) => {
    const { entities } = this.state;
    return entities.some(entId => ent.id === entId);
  }


  render() {
    const {
      props: {
        dataTh, placeholder, editable, errors, fieldName, transaction, className, preview,
      },
      state: {
        entities, value, inputValue,
      },
    } = this;

    const hasErrorClass = errors && errors[fieldName] ? 'has-error' : '';

    const allEntities = this.removeDuplicates([transaction[fieldName], ...entities]).filter(ent => ent?.id && ent.id !== '');
    const selectedEntities = allEntities.find(ent => ent.id === value);
    const label = selectedEntities?.dispValues?.join(' ');

    return (
      <td data-th={dataTh} className={`${className} ${hasErrorClass} ${preview ? 'has-preview' : ''} `}>
        <span>
          <span className={`select auto-position auto-content-width input-table input-r ${editable ? '' : 'disabled'} ${preview && label ? 'to-preview' : ''}`}>
            <input type="checkbox" defaultChecked={false} />
            <div className="control">
              <input
                placeholder={I18n.t('commons.actions.enter')}
                tabIndex="-1"
                type="text"
                onChange={this.onInputValue}
                value={inputValue}
              />
            </div>
            <div className="select-content">
              {
                allEntities.map((ent => (
                  <label
                    key={`${ent.id}_${fieldName}_label`}
                    onClick={() => this.onChange(ent.id)}
                    className={ent.id === value ? 'checked' : ''}
                  >
                    {
                      ent.dispValues?.map(dispValue => <span key={dispValue}>{dispValue}</span>)
                    }
                  </label>
                )))
              }
              <div className="placeholder" data-placeholder={placeholder} />
            </div>
          </span>
          {preview && label
            ? (<div className="preview">{label}</div>) : null
          }
          {hasErrorClass
            ? (
              <div className="error">
                {errors[fieldName].map(er => (
                  <div key={er} className="hint error">{er}</div>
                ))}
              </div>
            ) : null
          }
        </span>
      </td>
    );
  }
}

export default DropdownTableField;
