import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from '@/icons/Dropdown';
import Cross from '@/icons/Cross';
import FilterBase from '@/components/uiElements/filters';
import {
  iconClasses,
  generateFilters,
} from '@/components/uiElements/filters/constants';

export default function Filter({
  title, selected, onRemove, ...rest
}) {
  const removeFilter = (e) => {
    e.stopPropagation();
    onRemove();
  };

  return (
    <FilterBase {...rest}>
      <span>
        <button type="button">{title}</button>
        {!_.isEmpty(selected) && (
          <span className="ct-font-bold">
            &nbsp;
            {typeof selected !== 'string'
              ? generateFilters(selected)
              : selected}
          </span>
        )}
      </span>

      <button
        className={`${onRemove && 'group-hover:ct-hidden'}`}
        type="button"
      >
        <Dropdown className={iconClasses} />
      </button>

      {onRemove && (
        <button
          type="button"
          className="ct-hidden group-hover:ct-block"
          onClick={removeFilter}
        >
          <Cross className={iconClasses} />
        </button>
      )}
    </FilterBase>
  );
}

Filter.propTypes = {
  title: PropTypes.string.isRequired,
  selected: PropTypes.oneOfType(['array', 'string']).isRequired,
  inputElement: PropTypes.element.isRequired,
  onRemove: PropTypes.func,
};
