import React from "react";

import DateTimePicker from "./index";

export default {
  title: "Form/DateTimePicker",
  component: DateTimePicker,
  argTypes: {
    startDate: new Date(),
    endDate: new Date(),
    showSelectionPreview: true,
    moveRangeOnFirstSelection: false,
    months: 2,
    direction: "horizontal",
    inputRanges: [],
  },
};

const Template = (args) => <DateTimePicker {...args} />;

export const CustomRanges = Template.bind({});

CustomRanges.args = {
  showSelectionPreview: true,
  moveRangeOnFirstSelection: false,
  months: 2,
  direction: "horizontal",
  inputRanges: [],
};
