import React from 'react';
import MultiSelectComponent from './index';
import { storybookOptions } from '../constants';

export default {
  component: MultiSelect,
  title: 'Form/Inputs/MultiSelect',
  args: {
    label: 'Select multiple options',
    error: '',
    placeholder: 'Select options',
    isSearchable: false,
    required: false,
    disabled: false,
  },
  argTypes: {
    label: {
      control: 'text',
    },
    error: {
      control: 'text',
    },
    placeholder: {
      control: 'text',
    },
    isSearchable: {
      control: 'boolean',
    },
    required: {
      control: 'boolean',
    },
    disabled: {
      control: 'boolean',
    },
  },
};

export const MultiSelect = args => (
  <div style={{ width: '250px' }}>
    <MultiSelectComponent
      {...args}
      name="storybook-multi-select"
      options={storybookOptions}
    />
  </div>
);
