import * as Api from '../components/shared/api';
import AccountModel from '../models/account_model';
import AutomaticMatchingModel from '../models/automatic_matching_model';
import IntegrationFilteringModel from '../models/integration_filtering_model';
import { INTEGRATION_TYPES } from '../config/constants';

class IntegrationsService {
  static saveConnection = ({ integrationType, employeeToken }) => {
    const params = {
      integration: {
        integration_type: integrationType,
        employee_token: employeeToken,
      },
    };

    const quickbooksParams = {
      integration: {
        token: '',
        refresh_token: '',
      },
    };

    if (integrationType === INTEGRATION_TYPES.QUICKBOOKS) {
      return Api.makePostRequest('/settings/integrations', quickbooksParams);
    }
    return Api.makePostRequest('/settings/integrations', params);
  }

  static saveCredentialsConnection = ({ integrationType, email, password }) => {
    const params = {
      integration: {
        email,
        password,
      },
    };
    return Api.makePostRequest(`/settings/integrations/save_${integrationType}_settings`, params);
  }

  static disconnect = () => Api.makeDeleteRequest('/settings/integrations_settings')
    .then(response => (
      { integrationType: response.data.integration_type }
    ))

  static saveSettings = ({
    integrationType, automaticMatching, integrationFiltering,
  }) => {
    const params = {
      integrations_settings: {
        ...automaticMatching.toParams(),
        ...integrationFiltering.toParams(),
        integration_type: integrationType,
      },
    };

    return Api.makePostRequest('/settings/integrations_settings', params);
  }

  static saveSettingsOnlyWay = ({
    integrationType, integrationWay,
  }) => {
    const params = {
      integrations_settings: {
        integration_type: integrationType,
        integration_way: integrationWay,
      },
    };

    return Api.makePostRequest('/settings/integrations_settings', params);
  }

  static synchronize = ({
    integrationType, automaticMatching, integrationFiltering, creditAccountName,
    fetchedMoreThenOnce,
  }) => {
    const params = {
      integrations_settings: {
        ...automaticMatching.toParams(),
        ...integrationFiltering.toParams(),
        credit_account_name: creditAccountName,
        integration_type: integrationType,
        fetched_more_then_once: fetchedMoreThenOnce,
      },
    };

    return Api.makePostRequest('/integrations/synchronizations', params)
      .then(response => ({
        id: response.data.id,
      }));
  }

  static synchronizeStart = () => Api.makePostRequest('/integrations/synchronizations/start')
    .then(response => ({
      id: response.data.id,
    }))

  static synchronizeCount = ({
    integrationType, automaticMatching, integrationFiltering, creditAccountName,
  }) => {
    const params = {
      integrations_settings: {
        ...automaticMatching.toParams(),
        ...integrationFiltering.toParams(),
        credit_account_name: creditAccountName,
        integration_type: integrationType,
      },
    };

    return Api.makePostRequest('/integrations/synchronizations_count', params)
      .then(response => ({
        transactionsCount: response.data.transactions_count,
      }));
  }

  static synchronizeRefresh = ({ integrationType, automaticMatching, integrationFiltering }) => {
    const params = {
      integrations_settings: {
        ...automaticMatching.toParams(),
        ...integrationFiltering.toParams(),
        integration_type: integrationType,
      },
    };

    return Api.makePutRequest('/integrations/synchronizations', params);
  }

  static settings = ({ integrationType }) => Api.makeGetRequest(`/settings/integrations_settings?integration_type=${integrationType}`)
    .then((response) => {
      const { data } = response;

      return ({
        automaticMatching: new AutomaticMatchingModel(data.integrations_setting),
        integrationFiltering: new IntegrationFilteringModel(data.integrations_setting),
        creditAccount: new AccountModel({ name: data.integrations_setting.credit_account_name }),
        active: data.integrations_setting.active,
        jobProcessing: data.job_processing,
        id: data.integrations_setting.id,
        fetchedMoreThenOnce: data.integrations_setting.fetched_more_then_once,
        tenant: data.integrations_setting.tenant,
      });
    })

  static active = () => Api.makeGetRequest('/settings/integrations_settings/active')
    .then((response) => {
      const { data } = response;

      return ({
        jobProcessing: data.job_processing,
        id: data.id,
      });
    })

  static authorized = () => (
    Api.makeGetRequest('/settings/integrations_settings/authorized')
      .then((response) => {
        const { data } = response;

        return ({
          authorized: data.authorized,
          active: data.active,
        });
      })
  )

  static connectionKeys = ({ integrationType }) => (
    Api.makeGetRequest(
      `/settings/integrations?integration_type=${integrationType}`,
    )
      .then(response => ({
        employeeToken: response.data.employee_token,
        email: response.data.email,
      }))
  )

  static redirectionUrl = ({ integrationType }) => (
    Api.makeGetRequest(
      `/settings/integrations?integration_type=${integrationType}`,
    )
      .then(response => ({
        redirectionUrl: response.data.redirect_url,
      }))
  )

  static sendIntegrationContact = params => (
    Api.makePostRequest('/api/integration_contacts', params)
  )
}

export default IntegrationsService;
