import { createContext, useContext } from "react";

import CashFlowStore from "@/stores/CashFlowStore";

export class CashFlowBudgetStore {
  constructor() {
    this.cashFlowStore = new CashFlowStore();
  }
}

export const CashFlowBudgetContext = createContext(new CashFlowBudgetStore());
export const useCashFlowBudgetContext = () => useContext(CashFlowBudgetContext);
