import * as Api from '../components/shared/api';
import DeliveryModel from '../models/delivery_model';

class DeliveriesService {
  static new = purchaseOrderId => (
    Api.makeGetRequest(`/purchase_orders/${purchaseOrderId}/deliveries/new`)
      .then(response => (
        new DeliveryModel(response.data.delivery)
      ))
  )

  static updateAll = (purchaseOrderId, newDeliveries, currentUser) => {
    const data = new FormData();

    newDeliveries.map((delivery, deliveryIndex) => {
      if (delivery.id) data.append(`deliveries_attributes[${deliveryIndex}[id]]`, delivery.id);
      if (delivery.userId) data.append(`deliveries_attributes[${deliveryIndex}[user_id]]`, delivery.userId);
      if (delivery.deliveryDate) data.append(`deliveries_attributes[${deliveryIndex}[delivery_date]]`, delivery.deliveryDate);
      if (delivery._destroy === 1) data.append(`deliveries_attributes[${deliveryIndex}[_destroy]]`, delivery.number);
      data.append(`deliveries_attributes[${deliveryIndex}[number]]`, delivery.number);
      if (delivery.files.length !== 0) {
        delivery.files.map((file, fileIndex) => {
          if (file instanceof File) {
            data.append(`deliveries_attributes[${deliveryIndex}[files_attributes[${fileIndex}[attachment]]]]`, file);
            data.append(`deliveries_attributes[${deliveryIndex}[files_attributes[${fileIndex}[category]]]]`, 'internal');
            data.append(`deliveries_attributes[${deliveryIndex}[files_attributes[${fileIndex}[purchase_order_id]]]]`, purchaseOrderId);
          }
        });
      }

      delivery.items.map((item, itemIndex) => {
        if (item.id) data.append(`deliveries_attributes[${deliveryIndex}[items_attributes[${itemIndex}[id]]]]`, item.id);
        if (delivery.id) data.append(`deliveries_attributes[${deliveryIndex}[items_attributes[${itemIndex}[delivery_id]]]]`, item.deliveryId);
        data.append(`deliveries_attributes[${deliveryIndex}[items_attributes[${itemIndex}[good_items_count]]]]`, item.goodItemsCount);
        data.append(`deliveries_attributes[${deliveryIndex}[items_attributes[${itemIndex}[bad_items_count]]]]`, item.badItemsCount);
        data.append(`deliveries_attributes[${deliveryIndex}[items_attributes[${itemIndex}[purchase_order_item_id]]]]`, item.purchaseOrderItemId);

        item.comments.map((comment, comment_index) => {
          data.append(`deliveries_attributes[${deliveryIndex}[items_attributes[${itemIndex}[comments_attributes[${comment_index}[body]]]]]]`, comment.body);
          data.append(`deliveries_attributes[${deliveryIndex}[items_attributes[${itemIndex}[comments_attributes[${comment_index}[user_id]]]]]]`, currentUser.id);
          if (comment.id) data.append(`deliveries_attributes[${deliveryIndex}[items_attributes[${itemIndex}[comments_attributes[${comment_index}[id]]]]]]`, comment.id);
        });
      });
    });


    return Api.makePostFileRequest(`/purchase_orders/${purchaseOrderId}/deliveries/update_all`,
      data)
      .then((response) => {
        const { deliveries, items } = response.data;
        return { deliveries: deliveries.map(delivery => new DeliveryModel(delivery)), items };
      });
  }
}

export default DeliveriesService;
