import React from 'react';
import * as Toast from '../../../shared/toast';
import FileFormatValidator from '../../../shared/file_format_validator';

class DeliveryDocumentRow extends React.Component {
  onFileChange = ({ target: { files } }) => {
    const { delivery, updateDeliveries } = this.props;
    const [file] = files;
    if (file.size > 20000000) {
      Toast.errorToast(I18n.t('purchase_orders.form.deliveries.to_big_file_size'));
    } else if (FileFormatValidator.valid(file)) {
      Toast.errorToast(I18n.t('purchase_orders.form.deliveries.wrong_file_format'));
    } else if (file.name.split('.').length > 2) {
      Toast.errorToast(I18n.t('purchase_orders.form.deliveries.invalid_file_name'));
    } else {
      delivery.files.push(files[0]);
      updateDeliveries(delivery);
    }
  }

  onDelete = () => {
    const { props: { deleteDelivery, delivery } } = this;
    delivery._destroy = 1;
    deleteDelivery(delivery);
  }

  render() {
    const { props: { delivery, editableDelivery } } = this;
    return (
      <React.Fragment>
        <tr className="tfoot fluid-not-mobile">
          <td colSpan={7} className="not-sticky fluid-always-display">
            <div className="td-rows">
              {
                delivery.files.map(file => (
                  <div className="td-row" key={file.name}>
                    <a className="link" href={file.url} target="_blank">
                      <i className="icon-attach_file" />
                      {file.name}
                    </a>
                    <span className="mute">
                      {file.created_at}
                    </span>
                  </div>
                ))
              }
            </div>
          </td>
          <td colSpan={5} className="fluid-always-display not-sticky">
            { editableDelivery
              ? (
                <div className="items items-divided items-end">
                  <a className="link">
                    <input
                      type="file"
                      id={`delivery-doc-${delivery.uuid}`}
                      onChange={this.onFileChange}
                      name="file"
                      style={{ display: 'none' }}
                    />
                    <label htmlFor={`delivery-doc-${delivery.uuid}`}>
                      {I18n.t('purchase_orders.form.deliveries.upload_delivery_document')}
                    </label>
                  </a>
                  <a className="anchor mute">
                    <i
                      onClick={this.onDelete}
                      className="icon-delete"
                    />
                  </a>
                </div>
              ) : null
            }
          </td>
        </tr>
        { delivery.files.length > 0
          ? (
            <tr className="tfoot fluid-only-mobile">
              <td colSpan={12} className="not-sticky fluid-always-display">
                <div className="td-rows">
                  {
                    delivery.files.map(file => (
                      <div className="td-row" key={file.name}>
                        <a className="link" href={file.url} target="_blank">
                          <i className="icon-attach_file" />
                          {file.name}
                        </a>
                        <span className="mute">
                          {file.created_at}
                        </span>
                      </div>
                    ))
                  }
                </div>
              </td>
            </tr>
          ) : null
        }
        <tr className="tfoot fluid-only-mobile">
          <td colSpan={12} className="fluid-always-display not-sticky">
            { editableDelivery
              ? (
                <div className="items items-divided items-end">
                  <a className="link">
                    <input
                      type="file"
                      id={`delivery-doc-${delivery.uuid}`}
                      onChange={this.onFileChange}
                      name="file"
                      style={{ display: 'none' }}
                    />
                    <label htmlFor={`delivery-doc-${delivery.uuid}`}>
                      {I18n.t('purchase_orders.form.deliveries.upload_delivery_document')}
                    </label>
                  </a>
                  <a className="anchor mute">
                    <i
                      onClick={this.onDelete}
                      className="icon-delete"
                    />
                  </a>
                </div>
              ) : null
            }
          </td>
        </tr>
      </React.Fragment>
    );
  }
}

export default DeliveryDocumentRow;
