import React from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import IntegrationsDepartmentsService from '../../../services/integrations_departments_service';
import IntegrationsProjectsService from '../../../services/integrations_projects_service';
import IntegrationsAccountsService from '../../../services/integrations_accounts_service';
import * as toast from '../../shared/toast';
import ProjectFilter from './integration_filtering/project_filter';
import AccountFilter from './integration_filtering/account_filter';
import { INTEGRATION_TYPES } from '../../../config/constants';

class IntegrationFiltering extends React.Component {
  state = {
    departments: [],
    projects: [],
    accounts: [],
    searchProjectValue: '',
    typingTimeout: 0,
  }

  componentDidMount() {
    const { integrationType } = this.props;

    const promiseDepartaments = new IntegrationsDepartmentsService(integrationType).all().then(departments => this.setState({ departments }));
    const promiseProjects = new IntegrationsProjectsService(integrationType).all().then(projects => this.setState({ projects, mainProjects: projects }));
    const promiseAccounts = new IntegrationsAccountsService(integrationType).all().then(accounts => this.setState({ accounts }));

    const { onLoad, edit } = this.props;
    if (integrationType === INTEGRATION_TYPES.QUICKBOOKS) {
      Promise.all([promiseDepartaments, promiseAccounts]).then(() => onLoad()).catch((error) => {
        if (error.response.status === 422) {
          toast.warningBox(I18n.t('settings.integrations.new.setup.connection_not_established'));
          onLoad();
          edit();
        }
      });
    } else {
      Promise.all([promiseDepartaments, promiseProjects]).then(() => onLoad()).catch((error) => {
        if (error.response.status === 422) {
          toast.warningBox(I18n.t('settings.integrations.new.setup.connection_not_established'));
          onLoad();
          edit();
        }
      });
    }
  }

  onDatePickerStartDateChange = (value) => {
    let newValue = value;
    const { onIntegrationFilteringChange } = this.props;
    if ([null, '', undefined].includes(value)) newValue = moment();

    const buildEventValues = { target: { value: newValue, name: 'startDate' } };

    onIntegrationFilteringChange(buildEventValues);
  }

  onOnlyMatchedChange = () => {
    const { onIntegrationFilteringChange, onlyMatched } = this.props;
    const buildEventValues = { target: { value: !onlyMatched, name: 'onlyMatched' } };

    onIntegrationFilteringChange(buildEventValues);
  }

  onChangeSearchProject = (e) => {
    const { typingTimeout, mainProjects } = this.state;
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    this.setState({
      searchProjectValue: e.target.value,
      typingTimeout: setTimeout(() => {
        const { searchProjectValue } = this.state;
        this.setState({
          projects: mainProjects.filter(
            project => (
              `${project.number} ${project.name}`.toLowerCase().includes(searchProjectValue.toLowerCase())
            ),
          ),
        });
      }, 350),
    });
  }

  formatDateTime = date => moment(date).format('DD/MM/YYYY')

  render() {
    const {
      props: {
        startDate, glCode, projectIds, departmentIds, onIntegrationFilteringChange,
        manageDepartmentId, manageProjectId, errors, integrationType, accountIds,
        manageAccountId, onlyMatched,
      },
      state: {
        departments, projects, accounts,
      },
    } = this;

    return (
      <div className="cell-5 offset-1 offset-right-1 t-cell-16 t-offcet-right-0 t-offset-0">
        <div className="grid space-2">
          <div className="row">
            <div className="cell-16">
              <h3 className="label">{I18n.t('settings.integrations.new.setup.integration_filtering')}</h3>
            </div>
          </div>
          <div className="row">
            <div className="cell-8 t-cell-16">
              <div className="as-input required">
                <span className="label">{I18n.t('settings.integrations.new.setup.start_date')}</span>
                <div className="control right-icon">
                  <DatePicker
                    selected={moment(startDate)}
                    onChange={this.onDatePickerStartDateChange}
                    value={startDate}
                    name="startDate"
                    dateFormat="DD/MM/YYYY"
                  />
                </div>
              </div>
            </div>
          </div>
          {
            integrationType !== INTEGRATION_TYPES.QUICKBOOKS
              ? (
                <React.Fragment>
                  <div className="row">
                    <div className={`cell-16 t-cell-16 ${errors.gl_code ? 'has-error' : ''}`}>
                      <div className="as-input">
                        <span className="label">{I18n.t('settings.integrations.new.setup.gl_code')}</span>
                        <input
                          type="text"
                          name="glCode"
                          value={glCode}
                          placeholder={I18n.t('settings.integrations.new.setup.gl_code_example')}
                          onChange={onIntegrationFilteringChange}
                        />
                        { errors.gl_code ? <div className="hint">{errors.gl_code.join(', ')}</div> : '' }
                      </div>
                    </div>
                  </div>
                  <ProjectFilter
                    projects={projects}
                    projectIds={projectIds}
                    manageProjectId={manageProjectId}
                    onChangeSearchProject={this.onChangeSearchProject}
                  />
                </React.Fragment>
              ) : (
                <AccountFilter
                  accounts={accounts}
                  accountIds={accountIds}
                  manageAccountId={manageAccountId}
                />
              )
          }
          <div className="row">
            <div className="cell-16 t-cell-16">
              <div className="as-input">
                <span className="label">{I18n.t('settings.integrations.new.setup.department')}</span>
                <div className="control select-arrow">
                  <span className="select">
                    <input type="checkbox" />
                    <div className="select-content">
                      { departments.map(department => (
                        <React.Fragment key={`department-${department.id}`}>
                          <input
                            id={`department_${department.id}`}
                            value={department.id}
                            onChange={manageDepartmentId}
                            name="department"
                            type="checkbox"
                            checked={departmentIds.includes(department.id)}
                          />
                          <label htmlFor={`department_${department.id}`}>{`${department.number} ${department.name}`}</label>
                        </React.Fragment>
                      )) }
                      <div className="placeholder" data-placeholder={I18n.t('settings.integrations.new.setup.select_department')} data-selected={I18n.t('purchase_orders.form.selected')} />
                    </div>
                  </span>
                  <span />
                </div>
              </div>
            </div>
          </div>
          <div className="checkbox-list short-list">
            <p>
              <label className="has-checkbox item">
                <input
                  type="checkbox"
                  checked={onlyMatched}
                  name="onlyMatchedTransactions"
                  value={onlyMatched}
                  onChange={this.onOnlyMatchedChange}
                />
                <span className="checkbox input-s" />
              </label>
              {I18n.t('settings.integrations.new.setup.only_matched')}
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default IntegrationFiltering;
