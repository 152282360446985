import uuid from 'uuid/v4';
import DeliveryCommentModel from './delivery_comment_model';

class DeliveryItemModel {
  constructor({
    id,
    bad_items_count,
    good_items_count,
    delivery_id,
    name,
    price,
    purchase_order_item_id,
    quantity,
    sku,
    unit,
    comments,
    updated_at,
  }) {
    this.id = id || null;
    this.badItemsCount = bad_items_count || '';
    this.goodItemsCount = good_items_count || '';
    this.deliveryId = delivery_id;
    this.name = name;
    this.price = price;
    this.purchaseOrderItemId = purchase_order_item_id;
    this.quantity = quantity;
    this.sku = sku;
    this.unit = unit;
    this.updatedAt = updated_at || '';
    this.comments = comments.map(comment => new DeliveryCommentModel(comment));
    this.uuid = uuid();
    this.total = 0;
  }
}

export default DeliveryItemModel;
