import React, { useState, useEffect } from 'react';
import Button from '../../shared/buttons/button';
import ModalContent from '../../shared/modal/modal_content';
import ModalFooter from '../../shared/modal/modal_footer';
import ModalWrapper from '../../shared/modal/modal_wrapper';

function ReopenModal({ isOpen, reopen }) {
  const [open, setOpen] = useState(isOpen);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const onReopen = () => {
    reopen();
  };

  return (
    <ModalWrapper
      type="centered"
      active={open}
      closeModal={() => setOpen(false)}
      withLoader={false}
    >
      <ModalContent>
        <div className="row">
          <div className="cell-16">
            <div className="as-input required">
              <h2>{I18n.t('purchase_orders.form.reopen_po')}</h2>
              <p>
                {I18n.t('purchase_orders.form.reopen_po_warning')}
              </p>
            </div>
          </div>
        </div>
      </ModalContent>
      <ModalFooter withItems>
        <Button onClick={() => setOpen(false)}>
          {I18n.t('planned_purchases.modals.cancel')}
        </Button>
        <Button primary onClick={onReopen}>
          {I18n.t('purchase_orders.form.reopen')}
        </Button>
      </ModalFooter>
    </ModalWrapper>
  );
}
export default ReopenModal;
