import React, { useState } from 'react';

function TransactionRowActions({
  transaction, onAttachToPurchaseOrder, onAttachToBudget, state, onDetach, currentUser, setAttachInfoModalOpen,
}) {
  const [isIconHover, setIsIconHover] = useState(false);
  const iconClass = isIconHover ? 'icon-do_not_disturb' : 'icon-add_circle color-mute';
  const visible = (!currentUser.isAdmin && (state === 'complete' || state === 'rejected' || state === 'approved'))
                  || (currentUser.isAdmin && state !== 'draft');

  if (transaction && transaction.isAssigned()) {
    return (
      <React.Fragment>
        <a className={`icon-check color-${transaction.automaticallyMatched ? 'blue' : 'green'}`} onClick={onDetach} />
      </React.Fragment>
    );
  }

  const openAttachInfoModal = () => {
    setAttachInfoModalOpen(true);
  };
  const onMouseEnter = () => {
    setIsIconHover(true);
  };

  const onMouseLeave = () => {
    setIsIconHover(false);
  };

  return (
    <React.Fragment>
      { visible
        ? (transaction.id && (
          <div className="has-popup">
            <input type="checkbox" />
            <a className="icon-add_circle color-mute" />
            <ul className="popup menu bottom-right">
              <li className="item" onClick={() => { onAttachToPurchaseOrder([transaction]); }}>
                <label className="anchor" htmlFor="attach-transaction-modal">{I18n.t('purchase_orders.invoices.attach_to_po')}</label>
              </li>
              <li className="item" onClick={() => { onAttachToBudget([transaction]); }}>
                <label className="anchor" htmlFor="attach-budget-modal">{I18n.t('purchase_orders.invoices.attach_to_budget')}</label>
              </li>
            </ul>
          </div>
        ))
        : (
          <span className="not-valid">
            <span>invoice is not valid</span>
            <a
              className="has-tooltip right"
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              onClick={openAttachInfoModal}
              data-tooltip="You can't add transactions as invoice is not valid yet"
            >
              <i className={iconClass} />
            </a>
          </span>
        )}
    </React.Fragment>
  );
}

export default TransactionRowActions;
