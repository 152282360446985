import React from 'react';

function InvoicedModal(props) {
  const { closeInvoicedModal, confirmInvoiceGoods } = props;
  return (
    <div className="modal sub-modal centered modal-s" style={{ display: 'block' }}>
      <div className="modal-wrapper">
        <div className="modal-window window">
          <div className="window-header modal-header">
            {I18n.t('purchase_orders.form.transactions.invoiced_modal.mark_po_as_invoiced')}
          </div>
          <div className="window-content">
            <p className="mute">
              {I18n.t('purchase_orders.form.transactions.invoiced_modal.message')}
            </p>
          </div>
          <div className="window-footer modal-footer">
            <div className="items">
              <label className="button button-transparent button-mute inverse link" onClick={closeInvoicedModal}>{I18n.t('purchase_orders.form.transactions.invoiced_modal.cancel')}</label>
              <button className="button button-primary" onClick={confirmInvoiceGoods}>{I18n.t('purchase_orders.form.transactions.invoiced_modal.save')}</button>
            </div>
          </div>
        </div>
      </div>
      <label className="modal-backdrop" onClick={closeInvoicedModal} />
    </div>
  );
}

export default InvoicedModal;
