class IntegrationContactModel {
  constructor(attributes) {
    this.name = attributes.name || '';
    this.email = attributes.email || '';
    this.phone = attributes.phone || '';
    this.message = attributes.message || '';
  }

  toParams = () => (
    {
      integration_contact: {
        name: this.name,
        email: this.email,
        phone: this.phone,
        message: this.message,
      },
    }
  )
}

export default IntegrationContactModel;
