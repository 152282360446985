import React from 'react';
import MobileFiltersModal from './mobile_filters_modal';
import FilterModel from '../../../models/filter_model';
import Modal from '../../shared/modal';

class MobileFilters extends React.Component {
  state = {
    openModal: false,
    selectedFilters: [],
  }

  componentDidMount() {
    const params = URI(window.location.href).query(true);
    const { filterActions, filters } = this.props;

    const selectedInputs = [];

    filterActions.map((paramValue) => {
      filters[paramValue].map((values) => {
        const filterInput = new FilterModel(values);
        const { name, value } = filterInput.toParams();
        const stringValue = value.toString();

        if (!params[name] && !params[`${name}[]`]) return;

        const isArrayInclude = Array.isArray(params[`${name}[]`]) && params[`${name}[]`].includes(stringValue);
        const isValueEquals = params[name] === stringValue || params[`${name}[]`] === stringValue;

        if (isArrayInclude || isValueEquals) {
          selectedInputs.push(filterInput);
        }
      });
    });

    this.setState({ selectedFilters: selectedInputs });
  }

  onToggleModal = () => {
    const { openModal } = this.state;
    this.setState({
      openModal: !openModal,
    });
  }

  setSelectedInputs = (inputs) => {
    this.setState({ selectedFilters: inputs });
  }

  removeFilter = (filter) => {
    const { props: { onRemoveFilter }, state: { selectedFilters } } = this;
    this.setState({
      selectedFilters: selectedFilters.filter(f => f.id !== filter.id),
    }, () => {
      onRemoveFilter(filter.toParams());
    });
  }

  render() {
    const {
      props: {
        filterActions, filters, onFilter, onRemoveFilter, filtersPillsKey, onDateFilter, clearDate, currentFilters, onStateFilter, onRemoveStateFilter,
      },
      state: { openModal, selectedFilters },
    } = this;

    return (
      <div className="mobile-filters">
        { openModal ? (
          <Modal>
            <MobileFiltersModal
              filterActions={filterActions}
              filters={filters}
              onFilter={onFilter}
              onRemoveFilter={onRemoveFilter}
              onDateFilter={onDateFilter}
              clearDate={clearDate}
              key={filtersPillsKey}
              onToggleModal={this.onToggleModal}
              currentFilters={currentFilters}
              setSelectedInputs={this.setSelectedInputs}
              onStateFilter={onStateFilter}
              onRemoveStateFilter={onRemoveStateFilter}
            />
          </Modal>
        ) : null }
        <label htmlFor="mobile-filters" onClick={this.onToggleModal}>
          <i className="icon-filter_list" />
        </label>
        <div className="selected-filters">
          { selectedFilters.map(filter => (
            <label onClick={() => (this.removeFilter(filter))}>{filter.name}</label>
          )) }
        </div>
      </div>
    );
  }
}

export default MobileFilters;
