class CategoryModel {
  constructor({
    id, number, name, budget_id, not_invoiced, planned, invoiced, committed, amount, remaining,
  }) {
    this.id = id === 0 ? null : id;
    this.name = name;
    this.number = number;
    this.budgetId = budget_id;
    this.notInvoiced = not_invoiced;
    this.invoiced = invoiced;
    this.committed = committed;
    this.amount = amount;
    this.remaining = remaining;
    this.planned = planned;
    this.displayName = number ? `${number} ${name}` : name;
  }
}

export default CategoryModel;
