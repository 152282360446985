import InputBase from '@/components/uiElements/inputs';
import React, { forwardRef, useEffect, useState } from 'react';
import ReactSelect from 'react-select';
import { useDebounce } from 'use-debounce';
import _ from 'lodash';
import { applyStyles } from './styles';
import {
  Input,
  SingleValue,
  Placeholder,
  DropdownIndicator,
  Option,
  Menu,
  ValueContainer,
  selectBasePropTypes,
} from './constants';

function SelectBase(
  {
    name,
    options,
    isLoading,
    loadOptions,
    label,
    error,
    placeholder,
    disabled,
    required,
    optionSize,
    noInput,
    noPortal,
    table,
    value,
    button,
    warning,
    fixedMenu,
    hasPreview,
    ...rest
  },
  ref,
) {
  const [inputValue, setInputValue] = useState('');
  const [debouncedInputValue] = useDebounce(inputValue, 500);

  function findValue() {
    if (!options) return value;
    if (typeof value === 'string' || typeof value === 'number') return options.find(({ value: v }) => v === value);

    return value;
  }

  function trimAndLowerCase(string) {
    return string?.trim()?.toLocaleLowerCase() || '';
  }

  const className = [
    noInput ? 'input-hidden' : null,
    fixedMenu ? 'fixed-menu-width' : null,
    hasPreview ? 'has-preview' : null,
  ].filter(el => el).join(' ');

  function isOptionMatched(option) {
    const values = [option.label, option.value, option?.description];

    return !_.isEmpty(
      values.filter(v => trimAndLowerCase(v).includes(trimAndLowerCase(debouncedInputValue))),
    );
  }

  const onMenuOpen = () => {
    setTimeout(() => {
      const selectedEl = document.querySelector('.select__option--is-selected');
      if (selectedEl) {
        selectedEl.scrollIntoView();
      }
    }, 15);
  };

  useEffect(() => {
    if (!loadOptions) return;
    if (_.isEmpty(options.filter(option => isOptionMatched(option)))) loadOptions(debouncedInputValue);
  }, [debouncedInputValue]);

  return (
    <InputBase
      name={name}
      label={label}
      error={error}
      disabled={disabled}
      required={required}
      isHidden={noInput}
      table={table}
      button={button}
      warning={warning}
    >
      <ReactSelect
        className={className}
        name={name}
        ref={ref}
        menuIsOpen={noInput || undefined}
        options={options}
        onInputChange={setInputValue}
        inputValue={inputValue}
        isLoading={isLoading}
        value={findValue()}
        onMenuOpen={onMenuOpen}
        isSearchable
        menuPortalTarget={
          !noPortal ? document.querySelector('body') : undefined
        }
        classNamePrefix="select"
        menuPlacement="auto"
        isDisabled={disabled}
        placeholder={placeholder}
        components={{
          Input,
          SingleValue,
          Placeholder,
          DropdownIndicator,
          Option,
          Menu,
          ValueContainer,
        }}
        styles={applyStyles(optionSize, noInput, table, error)}
        {...rest}
      />
    </InputBase>
  );
}

export default forwardRef(SelectBase);

SelectBase.propTypes = { ...selectBasePropTypes };
