import React from 'react';

function SelectUpTo({ onPerChange, selected }) {
  const onChange = (value) => {
    onPerChange(1, value);
  };

  const showMaxList = [10, 20, 30, 50, 100];

  return (
    <span className="select select-up input-s no-border display-inline-block">
      <input
        id="perSelect"
        type="checkbox"
      />
      <div className="select-content">
        {
          showMaxList.map(number => (
            <label key={number} htmlFor="perSelect" className={`${selected == number ? 'checked' : ''}`} onClick={() => onChange(number)}>
              {`Show up to ${number}`}
            </label>
          ))
        }
      </div>
    </span>
  );
}

export default SelectUpTo;
