import React, { forwardRef } from 'react';
import SelectBase from '../index';
import { selectBasePropTypes } from '../constants';
import { OPTION_SIZE } from '../styles';

function MultiSelect(props, ref) {
  return (
    <SelectBase
      ref={ref}
      optionSize={OPTION_SIZE.MULTI}
      isMulti
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
      {...props}
    />
  );
}

export default forwardRef(MultiSelect);

MultiSelect.propTypes = { ...selectBasePropTypes };
