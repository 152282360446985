import React from 'react';

const Dropdown = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 9" {...props}>
    <path
      fill="currentColor"
      d="m1.917.956 4.55 4.58 4.549-4.58 1.397 1.41-5.946 6-5.947-6L1.917.956Z"
    />
  </svg>
);

export default Dropdown;
