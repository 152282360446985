import uuid from 'uuid/v4';
import Model from './model';

class DescriptionLineModel extends Model {
  constructor({
    id, position, e_type, description, ...attributes
  }) {
    super(attributes);
    this.uuid = uuid();
    this.id = parseInt(id);
    this.position = position;
    this.description = description || '';
    this.eType = e_type || 'line';
    this._destroy = 0;
    this.locked = false;
  }

  toParams = () => (
    {
      id: this.id,
      position: this.position,
      description: this.description,
      _destroy: this._destroy,
    }
  )

  isLock = () => {
    this._destroy === 0 || this.locked;
  }
}

export default DescriptionLineModel;
