import uuid from 'uuid/v4';

class TypeModel {
  constructor({ id, name }) {
    this.id = id;
    this.name = name;
    this.uuid = uuid();
    this.dispValues = name ? [`${name.replace(/_/, ' ')}`] : [];
  }
}

export default TypeModel;
