import React from 'react';

function NotificationBar({
  type = 'success', iconName = 'icon-info', content, mainClass = 'window-toasts', action = { show: false, content: '' },
}) {
  return (
    <div className={mainClass}>
      <div className={`toast show ${type}`}>
        <i className={iconName} />
        {' '}
        {content}
        {action.show && action.content}
      </div>
    </div>
  );
}
export default NotificationBar;
