import React from 'react';
import PropTypes from 'prop-types';
import { TABLE_IMAGE_SIZE, TABLE_IMAGE_STATE } from './constants';

export default function TableImage({ size, state, ...rest }) {
  return (
    <div className="ct-relative ct-w-fit">
      <picture
        className={`ct-block ct-rounded-full ct-overflow-hidden ct-select-none ${size}`}
      >
        <img className="ct-w-full ct-aspect-square ct-object-cover" {...rest} />
      </picture>
      {state && (
        <span
          className={`ct-absolute ct-flex ct-items-center ct-justify-center ct-rounded-full [&>svg]:ct-text-white ${state.color}`}
        >
          {state.icon}
        </span>
      )}
    </div>
  );
}

TableImage.propTypes = {
  size: PropTypes.oneOf(Object.values(TABLE_IMAGE_SIZE)),
  state: PropTypes.oneOf(Object.values(TABLE_IMAGE_STATE)),
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
};
