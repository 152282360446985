import uuid from 'uuid/v4';

class CurrencyModel {
  constructor(currency) {
    this.id = currency;
    this.uuid = uuid();

    this.dispValues = [currency];
  }
}

export default CurrencyModel;
