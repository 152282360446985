import React from 'react';
import DatePicker from 'react-datepicker';
import Warning from './warning';

function DateField({
  supplierInvoice, cellClass, inputClass, fieldName,
  setSupplierInvoiceDate, labelName, errors, placeholder, warning,
}) {
  const hasErrorClass = errors[fieldName] ? 'has-error' : '';
  const hasWarningClass = warning ? 'has-warning' : '';

  const onChange = (date) => {
    setSupplierInvoiceDate(fieldName, date);
  };

  return (
    <div className={`${cellClass} ${hasErrorClass} ${hasWarningClass}`}>
      <div className={inputClass}>
        <span className="label">{labelName}</span>
        <DatePicker
          selected={supplierInvoice[fieldName]}
          onChange={onChange}
          dateFormat="DD.MM.YYYY"
          locale={I18n.locale}
          readOnly={!supplierInvoice.editable}
          className="input-r"
          disabled={!supplierInvoice.editable}
          placeholderText={placeholder}
        />
      </div>
      <Warning warning={warning} />
    </div>
  );
}

export default DateField;
