import {
  makePostFileRequest, makeGetRequest, makePutRequest, makeDeleteRequest,
  makePostRequest,
} from '../components/shared/api';

class SupplierInvoicesService {
  static create = ({ fileToImport, purchaseOrderId, projectId }) => {
    const formData = new FormData();

    if (fileToImport) formData.append('supplier_invoice[attachments][]', fileToImport);
    if (purchaseOrderId) formData.append('supplier_invoice[purchase_order_id]', purchaseOrderId);
    if (projectId) formData.append('supplier_invoice[project_id]', projectId);

    return makePostFileRequest('/api/accountings/supplier_invoices', formData)
      .then(response => response.data);
  }

  static active = supplierInvoiceId => makeGetRequest(`/supplier_invoices/${supplierInvoiceId}/active`)
    .then((response) => {
      const { data } = response;

      return ({
        jobProcessing: data.job_processing,
        id: data.id,
      });
    })

  static terminateOCRProcess = supplierInvoiceId => makeGetRequest(`/supplier_invoices/${supplierInvoiceId}/terminate_ocr`)

  static edit = supplierInvoiceId => makeGetRequest(`/supplier_invoices/${supplierInvoiceId}/edit`)
    .then(({ data }) => ({ possible_approvers: data.possible_approvers, ...data.supplierInvoice, errors: data.errors }))

  static update = (supplierInvoiceId, params) => makePutRequest(`/api/accountings/supplier_invoices/${supplierInvoiceId}`, params, false)
    .then(({ data }) => ({ possible_approvers: data.possible_approvers, ...data.supplierInvoice, errors: data.errors }))

  static createComment = (supplierInvoice, comment) => makePostRequest(`/api/accountings/supplier_invoices/${supplierInvoice.id}/supplier_invoice_comments`, comment.toParams())
    .then(reposne => reposne.data)

  static updateWithEvent = (supplierInvoice, params) => makePutRequest(`/api/accountings/supplier_invoices/${supplierInvoice.id}`, params)
    .then(response => ({ possible_approvers: response.data.possible_approvers, ...response.data.supplier_invoice }))

  static destroy = supplierInvoice => makeDeleteRequest(
    `/api/accountings/supplier_invoices/${supplierInvoice.id}`,
  ).then(response => response.data)

  static postInvoice = supplierInvoice => makePostRequest(`/api/accountings/supplier_invoices/${supplierInvoice.id}/post_invoice`, {}, false)
    .then(response => ({ possible_approvers: response.data.possible_approvers, ...response.data.supplier_invoice }))

  static print = id => makeGetRequest(`/api/v1/supplier_invoices/${id}/print`)
    .then(response => response.data)

  static upload = (id, params) => makePutRequest(`/api/accountings/supplier_invoices/${id}/upload`, params, false)
    .then(response => response.data)

  static updateOcrFields = (id, data) => makePutRequest(`/api/accountings/supplier_invoices/${id}/update_ocr_fields`, data)
    .then(response => ({ possible_approvers: response.data.possible_approvers, ...response.data.supplier_invoice }))

  static bulkRestore = invoiceIds => makePutRequest('/api/v1/supplier_invoices/restore_all', { invoice_ids: invoiceIds }).then(response => response.data);
}

export default SupplierInvoicesService;
