import React from 'react';
import EllipsisCell from '../../common/ellipsis_cell';
import NumberCell from '../../common/number_cell';

export default function DeliveryRow({
  deliveryItem, columnView,
}) {
  return (
    <div className="tr">
      { columnView.deliveryDate
        && (
          <EllipsisCell text={deliveryItem.deliveryDate} />
        )
      }
      {
       columnView.productNumber
        && (
          <EllipsisCell text={deliveryItem.productNumber} />
        )
      }
      { columnView.sku
        && (
          <EllipsisCell text={deliveryItem.sku} />
        )
      }
      { columnView.name
        && (
          <EllipsisCell text={deliveryItem.name} />
        )
      }
      { columnView.poNumber
        && (
          <EllipsisCell text={deliveryItem.poNumber} />
        )
      }
      { columnView.raised
        && (
          <EllipsisCell text={deliveryItem.raised} />
        )
      }
      { columnView.poDeliveryDate
        && (
          <EllipsisCell text={deliveryItem.poDeliveryDate} />
        )
      }
      { columnView.supplierName
        && (
          <EllipsisCell text={`${deliveryItem.supplierName} (${deliveryItem.supplierNumber})`} />
        )
      }
      { columnView.budgetName
        && (
          <EllipsisCell text={`${deliveryItem.budgetNumber || ''} ${deliveryItem.budgetName || ''}`} />
        )
      }
      { columnView.quantity
        && (
          <NumberCell text={deliveryItem.quantity} />
        )
      }
      { columnView.unit
        && (
          <EllipsisCell text={deliveryItem.unit} />
        )
      }
      { columnView.orderedValue
        && (
          <NumberCell text={deliveryItem.orderedValue} />
        )
      }
      { columnView.orderedCurrencyValue
        && (
          <NumberCell text={deliveryItem.orderedCurrencyValue} />
        )
      }
      { columnView.goodItemsCount
        && (
          <NumberCell text={deliveryItem.goodItemsCount} additionalClass="correct" />
        )
      }
      { columnView.badItemsCount
        && (
          <NumberCell text={deliveryItem.badItemsCount} additionalClass="warning" />
        )
      }
      { columnView.receivedQuantity
        && (
          <NumberCell text={deliveryItem.receivedQuantity} />
        )
      }
      { columnView.receivedValue
        && (
          <NumberCell text={deliveryItem.receivedValue} />
        )
      }
      { columnView.receivedCurrencyValue
        && (
          <NumberCell text={deliveryItem.receivedCurrencyValue} />
        )
      }
      { columnView.state
        && (
          <a className="td has-status">
            <span className={`status capitalize ${deliveryItem.state}`}>
              {I18n.t(`purchase_orders.statuses.${deliveryItem.state}`)}
            </span>
          </a>
        )
      }
      { columnView.totalReceived
        && (
          <NumberCell text={deliveryItem.totalReceived} />
        )
      }
      { columnView.notReceived
        && (
          <NumberCell text={deliveryItem.notReceived} />
        )
      }
      { columnView.userName
        && (
          <a className="td with-avatars">
            <div className="avatars no-wrap first-arrow">
              <span className="avatar has-tooltip" tabIndex="-1">
                <img src={deliveryItem.userAvatar} />
                <span className="tooltip center">
                  {deliveryItem.userName}
                </span>
              </span>
            </div>
          </a>
        )
      }
    </div>
  );
}
