import React from 'react';
import Loader from '@/components/loader';

function ModalWrapper({
  children, type, size, active, closeModal, windowType, isLoading, withLoader = true,
}) {
  const activeClass = active ? 'active' : '';

  const htmlClasses = isLoading ? ' has-loading' : '';

  return (
    <div className={`modal ${type} sub-modal ${size} ${activeClass} ${htmlClasses}`}>
      {withLoader && <Loader />}
      <div className="modal-wrapper">
        <div className={`modal-window window ${windowType}`}>
          { children }
        </div>
      </div>
      <label className="modal-backdrop" onClick={closeModal} />
    </div>
  );
}
export default ModalWrapper;
