import * as Api from '@/components/shared/api';
import AccountingPurchaseOrderModel from '@/models/accounting_purchase_order_model';
import InvoiceModel from '@/models/invoice_model';
import SupplierInvoiceModel from '@/models/SupplierInvoiceModel';

class AccountingsService {
  static purchaseOrders = searchUrl => Api.makeGetRequest(`/api/accountings/purchase_orders${searchUrl}`).then(response => ({
    purchaseOrders: response.data.purchase_orders.map(object => (new AccountingPurchaseOrderModel(object))),
    pages: response.data.pages,
    filters: response.data.possible_filters,
    transactionsCount: response.data.transactions_count,
    purchaseOrdersCount: response.data.purchase_orders_count,
    invoicesCount: response.data.invoices_count,
    standAlone: response.data.stand_alone,
  }))

  static invoices = searchUrl => Api.makeGetRequest(`/api/accountings/invoices${searchUrl}`).then(response => ({
    invoices: response.data.invoices.map(object => (new InvoiceModel(object))),
    pages: response.data.pages,
    filters: response.data.possible_filters,
    total: response.data.total,
    transactionsCount: response.data.transactions_count,
    purchaseOrdersCount: response.data.purchase_orders_count,
    invoicesCount: response.data.invoices_count,
  }))

  static supplierInvoices = searchUrl => Api.makeGetRequest(`/api/accountings/supplier_invoices${searchUrl}`).then(response => ({
    supplierInvoices: response.data.supplier_invoices.map(object => (new SupplierInvoiceModel(object))),
    pages: response.data.pages,
    filters: response.data.possible_filters,
    total: response.data.total,
    transactionsCount: response.data.transactions_count,
    purchaseOrdersCount: response.data.purchase_orders_count,
    invoicesCount: response.data.invoices_count,
  }))

  static budgets = searchUrl => Api.makeGetRequest(`/api/cash_flow${searchUrl}`).then(response => ({
    budgets: response.data.budgets,
    columnNames: response.data.colum_names,
    pages: response.data.pages,
    settings: response.data.settings,
    filters: response.data.possible_filters,
    transactionsCount: response.data.transactions_count,
    purchaseOrdersCount: response.data.purchase_orders_count,
    invoicesCount: response.data.invoices_count,
  }))

  static purchaseOrderInvoices = purchaseOrderId => Api.makeGetRequest(`/api/accountings/purchase_orders/${purchaseOrderId}/invoices`).then(response => ({
    invoices: response.data.invoices.map(object => (new InvoiceModel(object))),
    filters: response.data.possible_filters,
  }))

  static showBudget = id => Api.makeGetRequest(`/api/cash_flow/${id}`).then(response => ({
    budget: response.data.budget,
    columnNames: response.data.column_names,
    documents: response.data.budget_entities,
    pages: response.data.pages,
    settings: response.data.settings,
    filters: response.data.possible_filters,
  }))

  static purchaseOrderInvoicesFilter = ({
    custom = [], purchaseOrderId, order, page, showOpened,
  }) => {
    const queries = [];
    const query = custom.map((attribs) => {
      if (Array.isArray(attribs.value)) {
        return attribs.value.map(v => `${attribs.name}[]=${v}`).join('&');
      }
      return `${attribs.name}=${attribs.value}`;
    }).join('&');
    if (order.sort) queries.push(`sort[by]=${order.sort}&sort[type]=${order.sortType}`);
    if (showOpened) queries.push('opened=1');
    if (page) queries.push(`page=${page}`);

    const customQuery = `?${queries.join('&')}&${query}`;

    return Api.makeGetRequest(`/api/accountings/purchase_orders/${purchaseOrderId}/invoices${customQuery}`).then(response => ({
      nextPageInvoices: response.data.invoices.map(object => (new InvoiceModel(object))),
      filters: response.data.possible_filters,
    }));
  }

  static resolveFilterParams = (custom = [], page, order, per, showCompleted = false) => {
    const query = custom.map((attribs) => {
      if (Array.isArray(attribs.value)) {
        return attribs.value.map(v => `${attribs.name}[]=${v}`).join('&');
      }
      return `${attribs.name}=${attribs.value}`;
    }).join('&');

    const orderQuery = order.sort ? `sort[by]=${order.sort}&sort[type]=${order.sortType}` : '';

    return `?&show_completed=${showCompleted}&page=${page}&per=${per}&${query}&${orderQuery}`;
  }

  static purchaseOrdersFilter = ({
    custom, page, order, showCompleted = false, per = 20,
  }) => {
    const customQuery = AccountingsService.resolveFilterParams(custom, page, order, per, showCompleted);

    return Api.makeGetRequest(`/api/accountings/purchase_orders${customQuery}`).then(response => ({
      purchaseOrders: response.data.purchase_orders.map(object => (new AccountingPurchaseOrderModel(object))),
      pages: response.data.pages,
      filters: response.data.possible_filters,
      query: customQuery,
      transactionsCount: response.data.transactions_count,
      purchaseOrdersCount: response.data.purchase_orders_count,
      invoicesCount: response.data.invoices_count,
      nextPage: response.data.next_page,
    }));
  }

  static invoicesFilter = ({ custom, page, order }) => {
    const customQuery = AccountingsService.resolveFilterParams(custom, page, order);

    return Api.makeGetRequest(`/api/accountings/invoices${customQuery}`).then(response => ({
      invoices: response.data.invoices.map(object => (new InvoiceModel(object))),
      pages: response.data.pages,
      filters: response.data.possible_filters,
      query: customQuery,
      total: response.data.total,
      transactionsCount: response.data.transactions_count,
      purchaseOrdersCount: response.data.purchase_orders_count,
      invoicesCount: response.data.invoices_count,
    }));
  }

  static supplierInvoiceFilter = ({
    custom, page, order, per,
  }) => {
    const customQuery = AccountingsService.resolveFilterParams(custom, page, order, per);

    return Api.makeGetRequest(`/api/accountings/supplier_invoices${customQuery}`).then(response => ({
      supplierInvoices: response.data.supplier_invoices,
      pages: response.data.pages,
      filters: response.data.filters,
      query: customQuery,
      total: response.data.total,
      invoicesCount: response.data.invoices_count,
      purchaseOrdersCount: response.data.purchase_orders_count,
      transactionsCount: response.data.transactions_count,
    }));
  }

  static purchaseOrder = purchaseOrderId => Api.makeGetRequest(`/api/accountings/purchase_orders/${purchaseOrderId}`).then(response => ({
    purchaseOrder: new AccountingPurchaseOrderModel(response.data.purchase_order),
  }))

  static attachInvoicesToPurchaseOrder = ({
    purchaseOrderId, removedInvoiceIds, addedInvoiceIds, markAsInvoiced,
  }) => Api.makePutRequest(`/api/accountings/purchase_orders/${purchaseOrderId}`, {
    purchase_order: {
      removed_invoice_ids: removedInvoiceIds,
      added_invoice_ids: addedInvoiceIds,
      mark_as_invoiced: markAsInvoiced,
    },
  }).then(response => ({ purchase_order: response.data.purchase_order, errors: response.data.errors || null }))

  static markPurchaseOrdersAsInvoiced = ({ purchaseOrdersIds }) => Api.makePutRequest('/api/accountings/purchase_orders/update_all', {
    purchase_orders_ids: purchaseOrdersIds,
    purchase_order: {
      state_action: 'invoice',
    },
  }).then(response => response.data)

  static invoicesBulkUpdate = ({
    invoiceIds, budgetId, categoryId, purchaseOrderId,
  }) => {
    const params = {
      invoice_ids: invoiceIds,
      invoice: {
        budget_id: budgetId,
        category_id: categoryId,
        purchase_order_id: purchaseOrderId,
      },
    };

    Object.keys(params.invoice).forEach((key) => {
      if (params.invoice[key] === undefined) {
        delete params.invoice[key];
      }
    });

    return Api.makePutRequest('/api/accountings/invoices/update_all', params).then(() => true);
  }

  static invoicesBulkDestroy = (invoiceIds) => {
    const params = invoiceIds.map(id => (`invoice_ids[]=${id}`)).join('&');

    return Api.makeDeleteRequest(`/api/accountings/invoices/destroy_all?${params}`).then(() => true);
  }

  static invoicesBulkRestore = (invoiceIds) => {
    const params = { invoice_ids: invoiceIds };
    return Api.makePutRequest('/api/accountings/invoices/restore_all', params).then(() => true);
  }

  static supplierInvoicesBulkDestroy = (invoiceIds) => {
    const params = invoiceIds.map(id => (`invoice_ids[]=${id}`)).join('&');

    return Api.makeDeleteRequest(`/api/accountings/supplier_invoices/destroy_all?${params}`).then(() => true);
  }

  static supplierInvoicesBulkRestore = (invoiceIds) => {
    const params = { invoice_ids: invoiceIds };
    return Api.makePutRequest('/api/accountings/supplier_invoices/restore_all', params).then(() => true);
  }

  static supplierInvoicesBulkSendToErp = (invoiceIds, approve = false) => {
    const params = {
      invoice_ids: invoiceIds,
      approve,
    };
    return Api.makePostRequest('/api/accountings/supplier_invoices/post_invoice_all', params).then(() => true);
  }

  static supplierInvoicesMarkAsPaid = (invoiceIds) => {
    const params = { invoice_ids: invoiceIds };
    return Api.makePostRequest('/api/accountings/supplier_invoices/mark_as_paid', params).then(() => true);
  }

  static supplierInvoicesBulkSendReminder = (invoiceIds) => {
    const params = { invoice_ids: invoiceIds };
    return Api.makePostRequest('/api/accountings/supplier_invoices/send_reminder_all', params).then(() => true);
  }
}

export default AccountingsService;
