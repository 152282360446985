import React from 'react';

class CurrencyInput extends React.Component {
  constructor(props) {
    super(props);

    const { currency } = props;
    this.state = { currency };
  }

  onChange = (e) => {
    const { onChangeCurrency } = this.props;
    onChangeCurrency(e);
  }

  render() {
    const { state: { currency }, props: { transactionCurrency } } = this;

    return (
      <React.Fragment>
        <input id={`transaction_currency_${currency}`} name="transactionCurrency" type="radio" value={currency} checked={currency == transactionCurrency} onChange={this.onChange} />
        <label htmlFor={`transaction_currency_${currency}`}>
          <span>{currency}</span>
        </label>
      </React.Fragment>
    );
  }
}

export default CurrencyInput;
