import React from 'react';
import DropdownsService from '../../../services/dropdowns_service';
import Warning from './warning';

class DropdownField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      entities: props.entities,
      inputValue: '',
    };
  }

  componentDidMount() {
    this.fetchProperData();
  }

  componentDidUpdate(prevProps) {
    const { fieldName, supplierId } = this.props;
    if (fieldName !== 'poNumber') return;

    prevProps.supplierId !== supplierId && this.fetchProperData();
  }

  onInputValue = ({ target: { value } }) => {
    const {
      fieldName, type, integrationType, supplierId, states,
    } = this.props;
    if (this.timer) clearTimeout(this.timer);
    this.setState({ inputValue: value }, () => {
      this.timer = setTimeout(() => {
        const { inputValue } = this.state;

        if (type === 'fetch') {
          DropdownsService.getEnt(fieldName, value, integrationType, '', '', supplierId, states)
            .then((response) => {
              this.setState({ entities: response, inputValue });
            });
        }
      }, 500);
    });
  };

  setValue = () => {
    const { supplierInvoice, fieldName, entities } = this.props;
    let selected = null;
    if (Array.isArray(supplierInvoice[fieldName])) {
      selected = entities.find(ent => supplierInvoice[fieldName].find(supplierInvoiceEnt => supplierInvoiceEnt.id === ent.id && supplierInvoiceEnt.destroy !== 1));
    }

    return (selected && selected.id) || supplierInvoice[fieldName].id || 'default';
  }

  onClick = (id) => {
    const {
      props: {
        updateSupplierInvoice, fieldName, supplierInvoice, mustBeSelected,
      },
    } = this;
    const entities = this.getEntities();
    let lookedEntity = entities.find(ent => ent.id === id);

    if (fieldName === 'trackingOptions') {
      if (!supplierInvoice[fieldName].find(ent => ent.id === id)) supplierInvoice[fieldName].push(lookedEntity);
      lookedEntity = supplierInvoice[fieldName].map((ent) => {
        const newEnt = ent;
        if (entities.some(el => el.id === ent.id && el.id !== lookedEntity.id)) {
          newEnt.destroy = 1;
          return newEnt;
        }
        if (newEnt.id === lookedEntity.id) newEnt.destroy = 0;
        return newEnt;
      });
    }

    if (fieldName !== 'trackingOptions' && supplierInvoice[fieldName].id === lookedEntity.id) {
      if (mustBeSelected) return;

      updateSupplierInvoice(fieldName, {});
    } else {
      updateSupplierInvoice(fieldName, lookedEntity);
    }
  }

  removeDuplicates = myArr => myArr.filter(
    (obj, pos, arr) => arr.map(mapObj => mapObj.id).indexOf(obj.id) === pos,
  )

  openModal = () => {
    const { toogleModal } = this.props;
    toogleModal(true);
  }

  disabled = () => {
    const { supplierInvoice, disabled } = this.props;
    return supplierInvoice.editable && !disabled ? ' ' : 'disabled';
  }

  expenseTypes = () => ['Bank', 'Other Current Asset', 'Credit Card']

  filterAccounts = () => {
    const {
      props: {
        fieldName, supplierInvoice,
      },
      state: { entities },
    } = this;

    return this.removeDuplicates([...entities, supplierInvoice[fieldName]])
      .filter(ent => ent.id !== '')
      .filter((ent) => {
        if (supplierInvoice.type.name == 'expense') { return this.expenseTypes().includes(ent.type); }
        return true;
      });
  }

  entitiesWithSelected = () => {
    const { props: { fieldName, supplierInvoice }, state: { entities } } = this;
    return this.removeDuplicates([...entities, supplierInvoice[fieldName]])
      .filter(ent => (ent.id !== '' && ent.id !== null && ent.id !== undefined));
  }

  getEntities = () => {
    const { props: { fieldName }, state: { entities } } = this;

    switch (fieldName) {
      case 'account':
        return this.filterAccounts();
      case 'supplier':
        return this.entitiesWithSelected();
      default:
        return entities;
    }
  }

  fetchProperData() {
    const {
      fieldName, type, integrationType, supplierId = null, states = null,
    } = this.props;

    if (type === 'fetch') {
      DropdownsService.getEnt(fieldName, '', integrationType, '', '', supplierId, states)
        .then((response) => {
          this.setState({ entities: response });
        });
    }
  }

  render() {
    const {
      props: {
        cellClass, labelName, fieldName, placeholder, children,
        withModal = false, addEdit = false, errors, notRequired,
        warning,
      },
      state: { inputValue },
    } = this;

    const hasErrorClass = errors[fieldName] ? 'has-error' : '';
    const hasWarningClass = warning ? 'has-warning' : '';
    const requiredClass = notRequired ? '' : 'required';
    const allEntities = this.getEntities();

    return (
      <div className={`${cellClass} ${hasErrorClass} ${hasWarningClass}`}>
        <div className={`as-input reduce ${requiredClass}`}>
          {withModal ? <label tabIndex="-1" className="add-edit-trigger"><i className="icon-add" onClick={this.openModal} /></label> : ''}
          <span className="label">{labelName}</span>
          <span className={`select input-r ${addEdit ? 'add-edit' : ''} ${this.disabled()}`}>
            <input type="checkbox" />
            <div className="control">
              <input
                placeholder={I18n.t('commons.actions.enter')}
                tabIndex="-1"
                type="text"
                onChange={this.onInputValue}
                value={inputValue}
              />
            </div>
            <div className="select-content">
              {
                allEntities.map((ent => (
                  <label
                    key={`${ent.id}_${fieldName}_label`}
                    onClick={() => this.onClick(ent.id)}
                    className={ent.id === this.setValue() ? 'checked' : ''}
                  >
                    {
                      ent.dispValues?.map(dispValue => <span key={dispValue}>{dispValue}</span>)
                    }
                  </label>
                )))
              }
              <div className="placeholder" data-placeholder={placeholder} />
            </div>
          </span>
        </div>
        <Warning warning={warning} />
        {children}
      </div>
    );
  }
}

export default DropdownField;
