import React from 'react';

export default function ToggleTrackingButton({
  enabled, id, name, text, onClick,
}) {
  return (
    <div className="row">
      <div className="cell-auto">
        <label className="radio-switch">
          <input
            autoComplete="off"
            id={id}
            name={name}
            type="checkbox"
            value="post"
            onClick={onClick}
            checked={enabled}
          />
          <span />
        </label>
      </div>
      <div className="cell-fill padding-left-2">{text}</div>
    </div>
  );
}
