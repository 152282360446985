import React from "react";

import Button from "./index";

export default {
  title: "Button",
  component: Button,
  argTypes: {
    text: "Text",
    onClick: () => null,
  },
};

const Template = (args) => <Button {...args} />;

export const Default = Template.bind({});

Default.args = {
  text: "Test text",
  onClick: () => null,
};
