import React from 'react';

function TransactionsTableHead() {
  return (
    <thead>
      <tr>
        <th className="no-f">Account</th>
        <th className="no-f">Vat</th>
        <th className="no-f">Department</th>
        {/* <th className="no-f">Project</th> */}
        <th className="no-f">Description</th>
        <th className="number no-f">
          Amount
        </th>
        <th className="number no-f">
          Ex vat
        </th>
        <th className="number no-f">
          Po amount
        </th>
        <th className="no-f">
          Match
        </th>
        <th className="no-f" />
      </tr>
    </thead>
  );
}

export default TransactionsTableHead;
