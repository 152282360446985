import React from 'react';
import * as URI from 'urijs';

class Table extends React.Component {
  onTableLoading = () => {
    this.setState({
      isLoading: true,
    });
  }

  onTableLoad = () => {
    this.setState({
      isLoading: false,
    });
    window.initTableScroll();
  }

  urlParamsSetup = () => {
    const params = URI(window.location.href).query(true);

    const filters = {};

    Object.keys(params).map((key) => {
      let value;
      if (key === 'query') {
        value = params[key];
      } else {
        value = Array.isArray(params[key]) ? params[key] : [params[key]];
      }

      return filters[key.replace('[', '').replace(']', '')] = value;
    });

    const order = filters.sortby ? { by: filters.sortby.join(''), type: filters.sorttype.join('') } : {};
    const { page, per } = filters;

    delete filters.page;
    delete filters.sortby;
    delete filters.sorttype;
    delete filters.per;

    return {
      filters, order, page, per,
    };
  }

  onDateFilter = ({ dateFrom, dateTo }) => {
    const { selectedFilters } = this.state;
    let newFilters = this.filters || selectedFilters;

    newFilters = { ...newFilters, date_from: dateFrom, date_to: dateTo };
    selectedFilters && this.setState({ selectedFilters: newFilters });

    if (this.filters) {
      this.filters = newFilters;
    }

    this.filter({}, 1, newFilters);
  }

  onQueryFilter = ({ value }) => {
    const { selectedFilters } = this.state;
    let newFilters = this.filters || selectedFilters;
    newFilters = { ...newFilters, query: value };

    selectedFilters && this.setState({ selectedFilters: newFilters });
    if (this.filters) {
      this.filters = newFilters;
    }

    this.filter({}, 1, newFilters);
  }

  clearDate = () => {
    const { selectedFilters } = this.state;
    const newFilters = this.filters || selectedFilters;

    delete newFilters.date_from;
    delete newFilters.date_to;

    selectedFilters && this.setState({ selectedFilters: newFilters });
    if (this.filters) {
      this.filters = newFilters;
    }

    this.filter({}, 1, newFilters);
  }

  onScroll = (e) => {
    const { ableToLoadMore } = this.state;
    if (!ableToLoadMore) return;

    const { scrollTop, scrollHeight } = e.target;

    if (scrollTop + 1000 >= scrollHeight) {
      this.ableToLoadMore = false;
      this.onTableLoading();
      this.infiniteScrollPaginate();
    }
  }

  windowContentElement = (className = 'window-content') => document.querySelector(`.${className}`);

  _renderInputs = (number, prefix = '') => (
    Array.from({ length: number }, (_v, k) => k + 1).map(i => (
      <input id={`${prefix}_column_${i}`} name={`${prefix}_table`} key={`${prefix}input_column_${i}`} defaultChecked={i === 1} type="radio" readOnly />
    ))
  )

  _renderLabels = (number, prefix = '') => (
    Array.from({ length: number }, (_v, k) => k + 1).map(i => (
      <label htmlFor={`${prefix}_column_${i}`} key={`${prefix}label_column_${i}`} />
    ))
  )

  _renderDots = (number, prefix = '') => (
    Array.from({ length: number }, (_v, k) => k + 1).map(i => (
      <div className="dot" key={`${prefix}dot_column_${i}`} />
    ))
  )

  _renderColls = (number, prefix = '') => (
    Array.from({ length: number }, (_v, k) => k + 1).map(i => (
      <div className="col" key={`${prefix}col_column_${i}`} />
    ))
  )

  onPageChange = (page) => {
    this.filter({ currentPage: page }, page);
  }

  onPerChange = (page, per) => {
    this.filter({ currentPage: 1 }, page, null, per);
  }

  onRemoveFilter = ({ name, value }) => {
    const stringValue = value.toString();
    const { selectedFilters } = this.state;

    let newFilters = selectedFilters || this.filters;
    if (newFilters[name]?.length > 1) {
      newFilters = { ...newFilters, [name]: newFilters[name].filter(val => val !== stringValue) };
    } else {
      delete newFilters[name];
    }

    selectedFilters && this.setState({ selectedFilters: newFilters });
    if (this.filters) {
      this.filters = newFilters;
    }
    this.filter({}, 1, newFilters);
  }

  onRemoveStateFilter = () => {
    const { selectedFilters } = this.state;
    const newFilters = selectedFilters || this.filters;

    delete newFilters.state;

    selectedFilters && this.setState({ selectedFilters: newFilters });
    if (this.filters) {
      this.filters = newFilters;
    }
    this.filter({}, 1, newFilters);
  }

  onOrder = ({ sort, sortType }) => {
    this.order = {
      sort,
      sortType,
    };

    this.filter();
  }
}

export default Table;
