import React from 'react';

function ModalHeader({ headerText, children, additionalClass }) {
  return (
    <div className={`window-header modal-header ${additionalClass}`}>
      { children || <h1 className="small-margin">{headerText}</h1> }
    </div>
  );
}

export default ModalHeader;
