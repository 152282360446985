import React from 'react';
import PropTypes from 'prop-types';
import IntegrationsService from '../../services/integrations_service';
import * as toast from '../shared/toast';

class IntegrationConnect extends React.Component {
  state = {
    employeeToken: '',
    redirectionUrl: '',
    isLoading: false,
  }

  static propTypes = {
    employeeToken: PropTypes.string,
  }

  componentDidMount() {
    const { integrationType } = this.props;
    IntegrationsService
      .connectionKeys({ integrationType })
      .then(({ employeeToken }) => {
        this.setState({ employeeToken });
      });
  }

  onChange = (e) => {
    const { target } = e;

    this.setState({
      [target.name]: target.value,
    });
  }

  onSubmit = () => {
    const { integrationType, connect } = this.props;
    this.setState({ isLoading: true });
    IntegrationsService.saveConnection({
      integrationType,
      ...this.state,
    }).then(() => {
      this.setState({ isLoading: false });
      connect();
    }).catch(() => {
      this.setState({ isLoading: false });
      toast.errorToast(
        I18n.t('settings.integrations.new.connect.connect_error'),
      );
    });
  }

  render() {
    const { reconnect, integrationType } = this.props;
    const { employeeToken, isLoading } = this.state;
    const buttonMessage = reconnect ? I18n.t('settings.integrations.new.connect.reconnect') : I18n.t('settings.integrations.new.connect.connect');
    const htmlClasses = isLoading ? ' disabled' : '';

    return (
      <React.Fragment>
        <div className="tile-content form-section-content">
          <div className="grid space-2 offset-4 offset-right-4 t-offset-0 t-offset-right-0 padding-top-5">
            <div className="row">
              <div className="cell-6 t-cell-16">
                <h3 className="label">{I18n.t('settings.integrations.new.connect.access_information')}</h3>
              </div>
            </div>
            <div className="row">
              <div className="cell-6 t-cell-16">
                <p className="mute">{I18n.t(`settings.integrations.new.connect.${integrationType}_integration_information`)}</p>
              </div>
            </div>
            <div className="row">
              <div className="cell-6 t-cell-16 padding-vertical-1">
                <div className="as-input required">
                  <span className="label">{I18n.t('settings.integrations.new.connect.employee_token')}</span>
                  <input
                    type="text"
                    name="employeeToken"
                    value={employeeToken}
                    onChange={this.onChange}
                    placeholder={I18n.t('settings.integrations.new.connect.enter_token')}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className={`cell-6 t-cell-16 text-center padding-top-5${htmlClasses}`} onClick={this.onSubmit}>
                <a className="button button-primary">{buttonMessage}</a>
              </div>
            </div>
          </div>
        </div>
        <div className="tile-footer" />
      </React.Fragment>
    );
  }
}

export default IntegrationConnect;
