import { components } from 'react-select';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import Checkbox from '@/components/uiElements/Checkbox';
import SelectDropdown from '@/icons/SelectDropdown';
import SelectCheckmark from '@/icons/SelectCheckmark';
import Loupe from '@/icons/Loupe';
import Reset from '@/icons/Reset';
import { useTranslation } from 'react-i18next';
import { OPTION_SIZE } from './styles';

export const selectBasePropTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
  loadOptions: PropTypes.func,
  label: PropTypes.string,
  error: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  optionSize: PropTypes.oneOf(Object.values(OPTION_SIZE)),
  noInput: PropTypes.bool,
  noPortal: PropTypes.bool,
  table: PropTypes.bool,
  warning: PropTypes.string,
  button: PropTypes.object,
  fixedMenu: PropTypes.bool,
  hasPreview: PropTypes.bool,
};

export const storybookOptions = [
  { value: 'Option 1', label: 'Option 1' },
  { value: 'Option 2', label: 'Option 2' },
  { value: 'Option 3', label: 'Option 3' },
  {
    value: 'Optiooooooooooooooooooooooooooon 4',
    label: 'Optiooooooooooooooooooooooooooon 4',
  },
  {
    value: 'Option with description',
    label: 'Option with description',
    description: 'Example description of option',
  },
  {
    value: 'Option with looooooooooooooooooooooooooooong description',
    label: 'Option with looooooooooooooooooooooooooooong description',
    description:
      'Example looooooooooooooooooooooooooooong description of option',
  },
];

export const Input = ({ ...props }) => {
  const menuIsOpen = props.selectProps?.menuIsOpen;
  const { t } = useTranslation('forms');

  return (
    <components.Input
      className={`${menuIsOpen ? 'ct-opacity-1' : 'ct-opacity-0'}`}
      inputClassName="custom"
      placeholder={t('default.textField.placeholder')}
      {...props}
    />
  );
};

export const Placeholder = ({ ...props }) => {
  const menuIsOpen = props.selectProps?.menuIsOpen;

  if (menuIsOpen) return null;
  return <components.Placeholder {...props} />;
};

export const SingleValue = ({ ...props }) => {
  const menuIsOpen = props.selectProps?.menuIsOpen;

  return (
    <components.SingleValue
      className={`${menuIsOpen ? 'ct-opacity-0' : 'ct-opacity-1'}`}
      {...props}
    />
  );
};

export const ValueContainer = ({ ...props }) => {
  const description = props.selectProps?.value?.description;
  const menuIsOpen = props.selectProps?.menuIsOpen;

  return (
    <components.ValueContainer {...props}>
      {props.children}
      {!menuIsOpen && description && (
        <span className="ct-block ct-overflow-hidden ct-text-ellipsis ct-leading-4.5 ct-text-gray-select-label">
          {description}
        </span>
      )}
    </components.ValueContainer>
  );
};

export const DropdownIndicator = ({ ...props }) => (
  <components.DropdownIndicator {...props}>
    <SelectDropdown />
  </components.DropdownIndicator>
);

export const ClearIndicator = ({ ...props }) => (
  <components.ClearIndicator {...props}>
    <Reset />
  </components.ClearIndicator>
);

export const Option = ({ ...props }) => {
  const {
    label,
    isSelected,
    selectProps: { isMulti, noIcon },
  } = props;

  return (
    <components.Option {...props}>
      {isMulti && <Checkbox value={isSelected} />}
      <span className="select__label ct-align-left">{label}</span>
      <SelectCheckmark className={`ct-w-3.5 ct-h-auto [path]:ct-fill-white ${isSelected && !isMulti && !noIcon ? 'opacity-1' : 'opacity-0'}`} />
    </components.Option>
  );
};

export const Menu = ({ ...props }) => {
  const inputRef = useRef();
  const {
    onInputChange, inputValue, isMulti, onChange,
  } = props.selectProps;

  return (
    <components.Menu {...props}>
      {isMulti && (
        <div className="ct-w-full ct-flex ct-items-center ct-p-2 ct-gap-2.5 ct-border-b ct-bg-white ct-border-gray-lighter">
          <span className="[&>svg]:ct-w-3.5 [&>svg]:ct-h-3.5 [&>svg]:ct-text-gray-cold">
            <Loupe />
          </span>
          <input
            ref={inputRef}
            onClick={() => inputRef.current.focus()}
            type="text"
            value={inputValue}
            placeholder="Search"
            onChange={e => onInputChange(e.currentTarget.value)}
            className="ct-text-xs ct-bg-transparent ct-w-full ct-outline-none ct-text-black"
          />
          <button
            onClick={() => onInputChange('')}
            className="ct-bg-transparent [&>svg]:ct-w-3.5 [&>svg]:ct-h-3.5 [&>svg]:ct-text-gray-cold-light"
          >
            <Reset />
          </button>
        </div>
      )}
      <components.MenuList {...props} />
      <div className="ct-flex ct-items-center ct-justify-end ct-w-full ct-p-2 ct-border-t ct-bg-white ct-border-gray-dark selectClear">
        <button
          className="ct-text-xs ct-text-blue hover:ct-underline selectClear"
          onClick={() => onChange(isMulti ? [] : '')}
        >
          {isMulti ? 'CLEAR ALL' : 'CLEAR'}
        </button>
      </div>

    </components.Menu>
  );
};
