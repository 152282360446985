class SupplierInvoiceFileModel {
  constructor({
    id, name, url, created_at,
  }) {
    this.id = id || '';
    this.name = name;
    this.url = url;
    this.createdAt = created_at;
  }
}

export default SupplierInvoiceFileModel;
