import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import Warning from '@/icons/Warning';
import _ from 'lodash';
import TableError from '@/icons/TableError';
import useTooltip from '@/hooks/useTooltip';
import Tooltip from '@/components/uiElements/Tooltip';

const InputBase = ({
  name,
  children,
  label,
  error,
  disabled,
  required,
  isFocused,
  isHidden,
  table,
  button,
  warning,
  hasPreview,
  value,
}, ref) => {
  const getColor = (isBorder = false) => {
    if (isError) return isBorder ? 'ct-border-red-error' : 'ct-text-red-error';
    if (warning) return isBorder ? 'ct-border-orange-dark' : 'ct-text-orange-dark';
    return undefined;
  };

  const [isTooltipOpen, tooltipTriggerRef] = useTooltip();
  const [isPreview, setIsPreview] = useState(false);

  const focusOnClick = (e) => {
    setIsPreview(false);
    const element = e.target.querySelector('input');
    if (!element) return;
    element.focus();
  };

  const isError = !_.isEmpty(error) && !disabled;
  const bottomBorderColor = getColor(true);
  const bottomTextColor = getColor();

  const inputClasses = `[&>input]:ct-text-sm [&>input]:ct-font-normal [&>input]:ct-leading-[20px] [&>input]:ct-py-2 ${
    !table ? '[&>input]:ct-px-2' : 'ct-border-none'
  } [&>input]:ct-w-full [&>input]:ct-outline-none [&>input]:ct-bg-transparent`;

  const previewClasses = (isPreview && hasPreview && !isFocused) ? `${!table ? 'after:ct-px-2' : 'after:ct-p-2 after:ct-ml-[-8px]'} after:ct-flex after:ct-z-10 after:ct-items-center after:ct-absolute after:ct-whitespace-nowrap after:ct-text-sm after:ct-font-normal after:ct-leading-[20px] after:ct-py-2 after:ct-content-[attr(value)] after:ct-left-0 after:ct-top-1/2 after:ct-translate-y-[-50%] after:ct-bg-white after:ct-shadow-modal after:ct-w-fit after:ct-min-w-full after:ct-h-full` : '';

  const className = [
    bottomBorderColor || 'ct-border-gray',
    bottomBorderColor === 'ct-border-red-error' ? 'ct-bg-gray-input-background' : null,
    inputClasses,
    table && isError ? '[&>input]:placeholder:ct-text-red-error [&>input]:ct-text-red-error' : '[&>input]:placeholder:ct-text-gray-input-placeholder',
    'ct-border-b ct-rounded',
    disabled ? 'ct-bg-gray-input-disabled ct-text-gray-cold ct-border-transparent' : 'ct-rounded-b-none',
    !table ? 'focus-within:ct-border-blue focus-within:ct-bg-gray-input-background' : 'ct-bg-transparent',
    isFocused ? 'ct-border-blue ct-bg-gray-input-background' : '',
  ].filter(el => el).join(' ');

  if (isHidden) return children;

  return (
    <div className="ct-flex ct-flex-col ct-text-xs ct-gap-0.5 ct-w-full">
      {!_.isEmpty(label) && (
        <span className="ct-flex ct-items-center ct-justify-between ct-text-xs ct-text-normal">
          <label
            htmlFor={name}
            className={`${
              isError ? 'ct-text-red-error' : 'ct-text-black-label'
            } ct-self-start custom`}
          >
            {label}
            {' '}
            {required && '*'}
          </label>
          {button && (
            <button
              type="button"
              className="ct-text-blue hover:ct-underline"
              onClick={button.onClick}
            >
              {button.text}
            </button>
          )}
        </span>
      )}
      <div
        ref={tooltipTriggerRef}
        className="ct-relative ct-flex ct-flex-1 ct-items-center ct-justify-between ct-gap-2.5"
      >
        <div ref={ref} onClick={focusOnClick} onMouseEnter={() => setIsPreview(true)} onMouseLeave={() => setIsPreview(false)} value={value} className={`ct-flex-1 ${className} ${previewClasses}`}>{children}</div>
        { isError && table && <TableError className="ct-w-3.5 ct-h-auto ct-text-red-error ct-cursor-help" /> }
        { isError && table && isTooltipOpen && (
          <div className="ct-absolute ct-top-1 ct-right-[-1px] ct-translate-y-[-100%] ct-z-20">
            <Tooltip isOpen={isTooltipOpen} direction="BOTTOM_RIGHT" text={error} className="ct-w-fit" />
          </div>
        )}
      </div>

      {!table && (
        <span
          className={`ct-flex ct-gap-1 ct-items-center ct-self-end ct-font-normal ct-text-xxs ${bottomTextColor}`}
        >
          {!isError && !warning && <span>&nbsp;</span>}
          {!isError && warning && (
            <>
              <Warning className="ct-h-2.5 ct-w-auto" />
              {warning}
            </>
          )}
          {isError && error}
        </span>
      )}
    </div>
  );
};

export default forwardRef(InputBase);

InputBase.propTypes = {
  name: PropTypes.string,
  children: PropTypes.element.isRequired,
  label: PropTypes.string,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  isFocused: PropTypes.bool,
  isHidden: PropTypes.bool,
  table: PropTypes.bool,
  button: PropTypes.object,
  warning: PropTypes.string,
  hasPreview: PropTypes.bool,
  value: PropTypes.string,
  inputRef: PropTypes.any,
};
