import React from 'react';

const SelectCheckmark = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 12" {...props}>
    <path
      fill="currentColor"
      d="M4.917 9.05 1.534 5.667.406 6.794l4.511 4.511 9.667-9.667L13.456.511l-8.539 8.54Z"
    />
  </svg>
);

export default SelectCheckmark;
