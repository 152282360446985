import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import useOutsideClick from '@/hooks/useOutsideClick';
import { boxColors } from './constants';

export default function FilterBase({ children, inputElement }) {
  const wrapperRef = useRef();
  const [isInputVisible, setIsInputVisible] = useState(false);

  useOutsideClick(wrapperRef, () => setIsInputVisible(false));

  return (
    <div ref={wrapperRef} className="ct-h-fit ct-w-fit ct-cursor-pointer">
      <div
        onClick={() => setIsInputVisible(!isInputVisible)}
        className={`ct-group ct-flex ct-w-fit ct-items-center ct-gap-3 ct-px-2.5 ct-py-2.5 ct-text-sm ct-rounded ${boxColors(
          isInputVisible,
        )}`}
      >
        {children}
      </div>

      {isInputVisible && (
        <div className="ct-mt-1.5 ct-min-w-[200px]">{inputElement}</div>
      )}
    </div>
  );
}

FilterBase.propTypes = {
  children: PropTypes.node.isRequired,
  inputElement: PropTypes.element.isRequired,
};
