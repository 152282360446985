import React from 'react';
import PropTypes from 'prop-types';
import * as api from '../../shared/api';
import * as toast from '../../shared/toast';

class InvoicingAddressModal extends React.Component {
  state = {
    name: '',
    street: '',
    line2: '',
    line3: '',
    zip_code: '',
    city: '',
    country: '',
    email: '',
    errors: {},
  }

  static propTypes = {
    category: PropTypes.string,
    name: PropTypes.string,
    street: PropTypes.string,
    line2: PropTypes.string,
    line3: PropTypes.string,
    zip_code: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
    email: PropTypes.string,
    isOpen: PropTypes.bool,
    handleClose: PropTypes.func,
    handleAdd: PropTypes.func,
  }

  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.submit = this.submit.bind(this);
    this.close = this.close.bind(this);
    this.clear = this.clear.bind(this);
    this.prepareBody = this.prepareBody.bind(this);
    this.escFunction = this.escFunction.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.escFunction, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.escFunction, false);
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  escFunction(event) {
    const { handleClose } = this.props;
    if (event.key === 'Escape') {
      handleClose();
    }
  }

  prepareBody() {
    const {
      name, street, line2, line3, zip_code, city, country, email,
    } = this.state;
    return {
      address: {
        name,
        street,
        line2,
        line3,
        zip_code,
        city,
        country,
        email,
        default: false,
        category: 'invoice',
      },
    };
  }

  submit(e) {
    const { handleAdd } = this.props;
    e.preventDefault();
    api.makePostRequest('/settings/addresses', this.prepareBody())
      .then((response) => {
        this.clear();
        this.close();
        handleAdd(response.data);
        toast.successToast(I18n.t('settings.addresses.messages.created'));
      })
      .catch((error) => {
        this.setState({ errors: error.response.data });
      });
  }

  clear() {
    this.setState({
      name: '',
      street: '',
      line2: '',
      line3: '',
      zip_code: '',
      city: '',
      country: '',
      email: '',
    });
  }

  close() {
    const { handleClose } = this.props;
    handleClose();
  }

  render() {
    const {
      props: {
        handleClose, isOpen,
      },
      state: {
        errors, name, street, line2, line3, zip_code, city, country, email,
      },
    } = this;

    return (
      [<input key={0} autoComplete="off" className="modal-open" type="radio" value="on" checked={isOpen} />,
        <div key={1} className="modal sub-modal centered modal-s">
          <form className="modal-wrapper" onSubmit={this.submit}>
            <div className="modal-window window-inside window">
              <div className="window-header modal-header">
                {I18n.t('purchase_orders.form.add_invoice_address')}
              </div>
              <div className="window-content">
                <div className="grid space-1 gutter-1">
                  <div className="row">
                    <div className={`cell-16 ${errors.name ? 'has-error' : ''}`}>
                      <div className="as-input required">
                        <span className="label">{I18n.t('purchase_orders.form.name')}</span>
                        <input
                          placeholder={I18n.t('purchase_orders.form.enter_name')}
                          id="address_name"
                          name="name"
                          type="text"
                          value={name}
                          onChange={this.onChange}
                        />
                        { errors.name
                          ? <div className="hint">{errors.name.join(', ')}</div> : ''
                      }
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className={`cell-16 ${errors.street ? 'has-error' : ''}`}>
                      <div className="as-input">
                        <span className="label">{I18n.t('purchase_orders.form.address')}</span>
                        <input
                          placeholder={I18n.t('purchase_orders.form.enter_address')}
                          id="address_street"
                          name="street"
                          type="text"
                          value={street}
                          onChange={this.onChange}
                        />
                        { errors.street
                          ? <div className="hint">{errors.street.join(', ')}</div> : ''
                      }
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className={`cell-16 ${errors.line2 ? 'has-error' : ''}`}>
                      <div className="as-input">
                        <span className="label">{I18n.t('purchase_orders.form.address_line2')}</span>
                        <input
                          placeholder={I18n.t('purchase_orders.form.enter_delivery_address_line2')}
                          id="address_line2"
                          name="line2"
                          type="text"
                          value={line2}
                          onChange={this.onChange}
                        />
                        { errors.line2
                          ? <div className="hint">{errors.line2.join(', ')}</div> : ''
                      }
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className={`cell-16 ${errors.line3 ? 'has-error' : ''}`}>
                      <div className="as-input">
                        <span className="label">{I18n.t('purchase_orders.form.address_line3')}</span>
                        <input
                          placeholder={I18n.t('purchase_orders.form.enter_delivery_address_line3')}
                          id="address_line3"
                          name="line3"
                          type="text"
                          value={line3}
                          onChange={this.onChange}
                        />
                        { errors.line3
                          ? <div className="hint">{errors.line3.join(', ')}</div> : ''
                      }
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className={`cell-7 ${errors.zip_code ? 'has-error' : ''}`}>
                      <div className="as-input">
                        <span className="label">{I18n.t('purchase_orders.form.zip_code')}</span>
                        <input
                          placeholder={I18n.t('purchase_orders.form.enter_zip_code')}
                          id="address_zip_code"
                          name="zip_code"
                          type="text"
                          value={zip_code}
                          onChange={this.onChange}
                        />
                        { errors.zip_code
                          ? <div className="hint">{errors.zip_code.join(', ')}</div> : ''
                      }
                      </div>
                    </div>
                    <div className={`cell-9 ${errors.city ? 'has-error' : ''}`}>
                      <div className="as-input">
                        <span className="label">{I18n.t('purchase_orders.form.city')}</span>
                        <input
                          placeholder={I18n.t('purchase_orders.form.enter_city')}
                          id="address_city"
                          name="city"
                          type="text"
                          value={city}
                          onChange={this.onChange}
                        />
                        { errors.city
                          ? <div className="hint">{errors.city.join(', ')}</div> : ''
                      }
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className={`cell-16 ${errors.country ? 'has-error' : ''}`}>
                      <div className="as-input">
                        <span className="label">{I18n.t('purchase_orders.form.country')}</span>
                        <input
                          placeholder={I18n.t('purchase_orders.form.enter_country')}
                          id="address_country"
                          name="country"
                          type="text"
                          value={country}
                          onChange={this.onChange}
                        />
                        { errors.country
                          ? <div className="hint">{errors.country.join(', ')}</div> : ''
                      }
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className={`cell-16 ${errors.email ? 'has-error' : ''}`}>
                      <div className="as-input">
                        <span className="label">{I18n.t('purchase_orders.form.email')}</span>
                        <input
                          placeholder={I18n.t('purchase_orders.form.enter_email')}
                          id="address_email"
                          name="email"
                          type="text"
                          value={email}
                          onChange={this.onChange}
                        />
                        { errors.email
                          ? <div className="hint">{errors.email.join(', ')}</div> : ''
                      }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="window-footer modal-footer">
                <div className="items">
                  <label
                    className="button button-transparent button-mute inverse link"
                    onClick={handleClose}
                  >
                    {I18n.t('purchase_orders.form.cancel')}
                  </label>
                  <button className="button button-primary">{I18n.t('purchase_orders.form.save')}</button>
                </div>
              </div>
            </div>
          </form>
          <label className="modal-backdrop" onClick={handleClose} />
        </div>]
    );
  }
}

export default InvoicingAddressModal;
