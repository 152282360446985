import React from "react";
import Button from "@/components/uiElements/old/Button";
import { Dialog } from "@headlessui/react";

export default function RemoveMilestonesConfirmationModal({
  isOpened,
  setIsOpened,
  afterClose,
}) {
  const { t } = I18n;

  return (
    <Dialog
      as="div"
      className="ct-relative ct-z-9999"
      open={isOpened}
      onClose={() => {}}
    >
      <div className="ct-fixed ct-inset-0 ct-flex ct-items-center ct-justify-center ct-bg-white/80">
        <Dialog.Panel className="ct-bg-white ct-mx-auto ct-rounded ct-drop-shadow-lg">
          <div className="ct-p-6">
            <div className="ct-text-2xl ct-mb-4">
              {t(`frontend.payment_plan.components.edit_modal.are_you_sure`)}
            </div>
            <div className="ct-text-xs ct-mb-8">
              {t(
                `frontend.payment_plan.components.edit_modal.remove_milestones_confirmation`
              )}
            </div>

            <div className="ct-flex ct-gap-x-4 ct-justify-end">
              <Button
                text={t("commons.actions.cancel")}
                onClick={() => setIsOpened(false)}
              />
              <Button
                primary
                text={t("commons.actions.change")}
                onClick={() => afterClose()}
              />
            </div>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}
