import React, { useState } from "react";
import PropTypes from "prop-types";

const SearchDropdown = ({ id, records, error, editable, fieldName, onChange, required, unselectOption }) => {
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [filteredRecords, setFilteredRecords] = useState(records);

  const onChangeSearch = (e) => {
    e.persist();

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    if (e.target.value) {
      setTypingTimeout(
        setTimeout(() => {
          setFilteredRecords(
            records.filter(
              (record) =>
                record.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
                record.number?.toLowerCase()?.includes(e.target.value.toLowerCase())
            )
          );
        }, 350)
      );
    } else {
      setFilteredRecords(records);
    }
  };

  return (
    <div className="row">
      <div className={`cell-16 ${error ? "has-error" : ""}`}>
        <div className={`as-input reduce ${required ? "required" : ""}`}>
          {id && unselectOption && (
            <label tabIndex="-1" className="add-edit-trigger"><i className="icon-close" onClick={unselectOption} /></label>
          )}
          <span className="label">{I18n.t(`purchase_orders.form.${fieldName}`)}</span>
          <span
            className={`select input-r add-edit${!editable ? " disabled" : ""} ${id ? "selected" : ""}`}
            name={`${fieldName}_select`}
          >
            <input type="checkbox" />
            <div className="control">
              <input
                type="text"
                tabIndex="-1"
                onChange={onChangeSearch}
                placeholder={I18n.t("purchase_orders.form.type_to_search")}
              />
            </div>
            <div className="select-content">
              {filteredRecords.map((record) => (
                <React.Fragment key={record.id}>
                  <input
                    key={`input-${record.id}`}
                    id={`${fieldName}_${record.id}`}
                    name={fieldName}
                    type="radio"
                    value={record.id}
                    checked={id === record.id}
                    onChange={onChange}
                  />
                  <label
                    className={`${id === record.id ? "checked" : ""}`}
                    key={`label-${record.id}`}
                    htmlFor={`${fieldName}_${record.id}`}
                  >
                    {`${record.number || ""} ${record.name}`}
                  </label>
                </React.Fragment>
              ))}
              <div
                className="placeholder"
                data-placeholder={I18n.t(`purchase_orders.form.select_${fieldName}`)}
                data-selected={I18n.t("purchase_orders.form.selected")}
              />
            </div>
            {error ? <div className="hint error">{error}</div> : ""}
          </span>
        </div>
      </div>
    </div>
  );
};

SearchDropdown.propTypes = {
  id: PropTypes.number,
  records: PropTypes.arrayOf(PropTypes.object),
  error: PropTypes.string,
  editable: PropTypes.bool,
  fieldName: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
};

SearchDropdown.defaultProps = {
  id: null,
  records: [],
  error: "",
  editable: false,
  required: false,
};

export default SearchDropdown;
