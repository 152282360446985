import { makeGetRequest } from '../components/shared/api';
import PurchaseOrderNumberModel from '../models/dropdowns_models/purchase_order_number_model';
import ProjectModel from '../models/dropdowns_models/project_model';
import AccountModel from '../models/dropdowns_models/account_model';
import SupplierModel from '../models/dropdowns_models/supplier_model';
import BudgetModel from '../models/dropdowns_models/budget_model';
import CurrencyModel from '../models/dropdowns_models/currency_model';
import DepartmentModel from '../models/dropdowns_models/department_model';
import VatModel from '../models/dropdowns_models/vat_model';
import TrackingCategoryModel from '../models/dropdowns_models/tracking_category_model';
import CategoryModel from '../models/dropdowns_models/category_model';
import PaymentTermModel from '../models/payment_term_model';

class DropdownsService {
  static getEnt = (entType, searchValue, integrationType, type = '', scope = '', supplierId = '', states = '') => {
    switch (entType) {
      case 'poNumber':
        return this.getPurchaseOrderNumbers(searchValue, supplierId, states);
      case 'account':
        return this.getAccounts(searchValue, integrationType);
      case 'project':
        return this.getProjects(searchValue, integrationType);
      case 'supplier':
        return this.getSuppliers(searchValue, integrationType);
      case 'currency':
        return this.getCurrencies(searchValue);
      case 'budget':
        return this.getBudgets(searchValue);
      case 'department':
        return this.getDepartments(searchValue);
      case 'vat':
        return this.getVats(searchValue, integrationType);
      case 'tracking_category':
        return this.getTrackingCategories(searchValue, integrationType, type);
      case 'category':
        return this.getCategories(searchValue, scope);
      case 'payment_terms':
        return this.getPaymentTerms(searchValue);
      default:
        return [];
    }
  }

  static getPurchaseOrderNumbers = (searchValue = null, supplierId = null, states = null) => {
    const params = new URLSearchParams();
    searchValue && params.append('search_value', searchValue);
    supplierId && params.append('supplier_id', supplierId);
    states && params.append('states', JSON.stringify(states));

    return makeGetRequest(`/api/dropdowns/purchase_order_numbers?${params}`)
      .then(response => response.data.purchase_order_numbers.map(ent => new PurchaseOrderNumberModel(ent)));
  }

  static getProjects = (searchValue = '', integrationType) => {
    if (searchValue === '') {
      return makeGetRequest(`/api/dropdowns/projects?integration_type=${integrationType}`)
        .then(response => response.data.projects.map(ent => new ProjectModel(ent)));
    }
    return makeGetRequest(`/api/dropdowns/projects?integration_type=${integrationType}&search_value=${searchValue}`)
      .then(response => response.data.projects.map(ent => new ProjectModel(ent)));
  }

  static getAccounts = (searchValue = '', integrationType) => {
    if (searchValue === '') {
      return makeGetRequest(`/api/dropdowns/accounts?integration_type=${integrationType}`)
        .then(response => response.data.accounts.map(ent => new AccountModel(ent)));
    }
    return makeGetRequest(`/api/dropdowns/accounts?integration_type=${integrationType}&search_value=${searchValue}`)
      .then(response => response.data.accounts.map(ent => new AccountModel(ent)));
  }

  static getSuppliers = (searchValue = '', integrationType) => {
    if (integrationType === '' && searchValue === '') {
      return makeGetRequest('/api/dropdowns/suppliers')
        .then(response => response.data.suppliers.map(ent => new SupplierModel(ent)));
    }
    if (integrationType === '' && searchValue !== '') {
      return makeGetRequest(`/api/dropdowns/suppliers?search_value=${searchValue}`)
        .then(response => response.data.suppliers.map(ent => new SupplierModel(ent)));
    }
    if (searchValue === '') {
      return makeGetRequest(`/api/dropdowns/suppliers?source=${integrationType}`)
        .then(response => response.data.suppliers.map(ent => new SupplierModel(ent)));
    }
    return makeGetRequest(`/api/dropdowns/suppliers?source=${integrationType}&search_value=${searchValue}`)
      .then(response => response.data.suppliers.map(ent => new SupplierModel(ent)));
  }

  static getCurrencies = (searchValue = '') => {
    if (searchValue === '') {
      return makeGetRequest('/api/dropdowns/currencies')
        .then(response => response.data.currencies.map(ent => new CurrencyModel(ent)));
    }
    return makeGetRequest(`/api/dropdowns/currencies?search_value=${searchValue}`)
      .then(response => response.data.currencies.map(ent => new CurrencyModel(ent)));
  }

  static getBudgets = (searchValue = '') => {
    if (searchValue === '') {
      return makeGetRequest('/api/dropdowns/budgets')
        .then(response => response.data.budgets.map(ent => new BudgetModel(ent)));
    }
    return makeGetRequest(`/api/dropdowns/budgets?search_value=${searchValue}`)
      .then(response => response.data.budgets.map(ent => new BudgetModel(ent)));
  }

  static getCategories = (searchValue = '', scope) => {
    if (!scope) {
      return new Promise((resolve) => { resolve(); })
        .then(() => ([]));
    }
    if (searchValue === '') {
      return makeGetRequest(`/api/dropdowns/categories?budget_id=${scope}`)
        .then(response => response.data.categories.map(ent => new CategoryModel(ent)));
    }
    return makeGetRequest(`/api/dropdowns/categories?search_value=${searchValue}&budget_id=${scope}`)
      .then(response => response.data.categories.map(ent => new CategoryModel(ent)));
  }

  static getDepartments = (searchValue = '') => {
    if (searchValue === '') {
      return makeGetRequest('/api/dropdowns/departments')
        .then(response => response.data.departments.map(ent => new DepartmentModel(ent)));
    }
    return makeGetRequest(`/api/dropdowns/departments?search_value=${searchValue}`)
      .then(response => response.data.departments.map(ent => new DepartmentModel(ent)));
  }

  static getVats = (searchValue = '', integrationType) => {
    if (searchValue === '') {
      return makeGetRequest(`/api/dropdowns/vats?integration_type=${integrationType}`)
        .then(response => response.data.vats.map(ent => new VatModel(ent)));
    }
    return makeGetRequest(`/api/dropdowns/vats?integration_type=${integrationType}&search_value=${searchValue}`)
      .then(response => response.data.vats.map(ent => new VatModel(ent)));
  }

  static getTrackingCategories = (searchValue = '', integrationType, type) => {
    if (searchValue === '') {
      return makeGetRequest(`/api/dropdowns/tracking_categories?integration_type=${integrationType}&type=${type}`)
        .then(response => response.data.tracking_categories.map(ent => new TrackingCategoryModel(ent)));
    }
    return makeGetRequest(`/api/dropdowns/tracking_categories?integration_type=${integrationType}&type=${type}&search_value=${searchValue}`)
      .then(response => response.data.tracking_categories.map(ent => new TrackingCategoryModel(ent)));
  }

  static getPaymentTerms = (searchValue = '') => {
    if (searchValue === '') {
      return makeGetRequest('/api/dropdowns/payment_terms')
        .then(response => response.data.payment_terms.map(ent => new PaymentTermModel(ent)));
    }
    return makeGetRequest(`/api/dropdowns/payment_terms?search_value=${searchValue}`)
      .then(response => response.data.payment_terms.map(ent => new PaymentTermModel(ent)));
  }
}

export default DropdownsService;
