import React, { useState } from 'react';
import * as Toast from '@/components/shared/toast';
import FileFormatValidator from '@/components/shared/file_format_validator';
import TransactionsService from '@/services/transactions_service';
import Loader from '@/components/loader';

function TransactionsUploadModal({ onToggleTransactionsUploadModal, openTransactionsUploadModal, refreshInvoices }) {
  const [fileToImport, setFileToImport] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({});

  const onFileChange = ({ target: { files } }) => {
    const [file] = files;
    if (file.size > 2000000) {
      Toast.errorToast(I18n.t('purchase_orders.form.deliveries.supplier_invoice_to_big_file_size'));
    } else if (FileFormatValidator.valid(file, /(\.xlsx)$/i)) {
      Toast.errorToast(I18n.t('purchase_orders.form.deliveries.wrong_file_format'));
    } else if (file.name.split('.').length > 2) {
      Toast.errorToast(I18n.t('purchase_orders.form.deliveries.invalid_file_name'));
    } else {
      setFileToImport(file);
    }
  };

  const importFile = () => {
    if (fileToImport === 0) {
      Toast.errorToast(I18n.t('purchase_orders.form.deliveries.missing_file'));
    } else {
      setIsLoading(true);
      TransactionsService.upload({ fileToImport })
        .then((data) => {
          setIsLoading(false);
          if (data['valid?']) {
            Toast.successToast(I18n.t('transactions.import_modal.upload_successful'));
            onToggleTransactionsUploadModal();
            refreshInvoices();
          } else {
            setError(data);
          }
        })
        .catch(({ response: { data } }) => {
          Toast.errorToast(Object.values(data).flatten()[0]);
          setIsLoading(false);
        });
    }
  };

  const htmlClass = isLoading ? 'has-loading' : '';

  const renderError = () => (
    <div className="grid space-1 vertical-start">
      <div className="row">
        <div className="cell">
          <p className="mute">
            <b style={{ whiteSpace: 'initial' }}>{I18n.t('transactions.import_modal.error_row', { row_number: error.row_number })}</b>
          </p>
        </div>
      </div>
      <div className="row">
        <div className="cell">
          <label className="label small">
            {error.msg}
          </label>
        </div>
      </div>
    </div>
  );

  return (
    <div
      className={
        `modal modal-m centered ${openTransactionsUploadModal ? 'active' : ''} ${htmlClass}`
      }
    >
      <div className="modal-wrapper">
        <Loader />
        <div className="modal-window window">
          <div className="window-header modal-header modal-header-auto">
            <h1>{I18n.t('transactions.import_modal.new_transactions')}</h1>
          </div>
          <div className="window-content">
            {error['valid?'] === false ? (
              renderError()
            ) : (
              <div className="grid space-1 vertical-start">
                <div className="row">
                  <div className="cell">
                    <p className="mute">
                      <b style={{ whiteSpace: 'initial' }}>
                        {I18n.t('transactions.import_modal.under_header')}
                        <a className="link" download target="_blank" href="/api/accountings/invoices/template_file">
                          {` ${I18n.t('transactions.import_modal.example_import_file')} `}
                        </a>
                      </b>
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="cell">
                    <label className="label small">
                      {I18n.t('transactions.import_modal.upload_transactions')}
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="cell">
                    <div className="as-input required file-button has-progress">
                      <label>
                        <input
                          type="file"
                          id="receive_all_file"
                          onChange={onFileChange}
                          name="file"
                        />
                        <div className="button block button-primary file-name no-shadow">
                          {I18n.t('purchase_orders.form.select_file')}
                        </div>
                      </label>
                      {
                        fileToImport
                          ? (
                            <div className="row">
                              <span className="state-info">{fileToImport.name}</span>
                            </div>
                          ) : null
                      }
                    </div>
                  </div>
                </div>
              </div>
            )}

          </div>
          <div className="window-footer modal-footer modal-footer-space">
            <div className="items">
              <label
                className="button button-transparent button-mute inverse link"
                onClick={onToggleTransactionsUploadModal}
              >
                {I18n.t('commons.actions.close')}
              </label>
              { error['valid?'] === false ? (
                null
              ) : (
                <button className="button button-primary" onClick={importFile}>
                  {I18n.t('commons.actions.save')}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <label className="modal-backdrop" onClick={onToggleTransactionsUploadModal} />
    </div>
  );
}

export default TransactionsUploadModal;
