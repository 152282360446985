import React from 'react';
import PropTypes from 'prop-types';
import Button from '@/components/uiElements/Button';
import { BUTTON_VARIANT } from '@/components/uiElements/Button/constants';
import { useTranslation } from 'react-i18next';
import defaultModalPropTypes from '@/components/modal/constants';
import Modal from '@/components/modal';

const DeleteModal = ({
  name, isOpen, handleClose, handleDelete, ...rest
}) => {
  const { t } = useTranslation('modals', { keyPrefix: 'deleteModal' });
  const { t: tActions } = useTranslation('actions');

  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      heading={t('heading', { item: t(`item.${name}`) })}
      subHeading={(
        <p className="ct-text-xs ct-text-black ct-text-left">
          {t('subHeading', { item: t(`item.${name}`) })}
        </p>
        )}
      actions={(
        <Button onClick={handleDelete} variant={BUTTON_VARIANT.ERROR_PRIMARY}>
          {tActions('delete')}
        </Button>
      )}
      {...rest}
    />
  );
};

export default DeleteModal;

DeleteModal.propTypes = {
  ...defaultModalPropTypes,
  name: PropTypes.string.isRequired,
  handleDelete: PropTypes.func.isRequired,
};
