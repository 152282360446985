import React from 'react';

class ItemError extends React.Component {
  render() {
    const { value, className } = this.props;
    return (
      <div className="error">
        { value.map(er => (
          <div key={er} className={`${className} hint error`}>{er}</div>
        )) }
      </div>
    );
  }
}

export default ItemError;
