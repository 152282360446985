import React, { useState } from "react";
import DatePicker from "react-datepicker-latest";
import { parseDate } from "@/tools";
import { useCashFlowBudgetContext } from './CashFlowBudget/context'

function PaymentDateCell({ doc: { id, recordType, budgetId }, date }) {
  const { cashFlowStore } = useCashFlowBudgetContext();
  const [selectedDate, setSelectedDate] = useState(!!date ? new Date(date) : null);

  const onDateChange = async (newDate) => {
    setSelectedDate(newDate);
    const mappedRecordType = {
      purchase_order: "planned_payment",
      milestone: "planned_payment",
      planned_purchase_item: "planned_purchase"
    };

    await cashFlowStore.updateRecordByType(id, mappedRecordType[recordType], newDate)
    await cashFlowStore.refreshRecord(budgetId)
  };

  if (["transaction", "manual_transaction", "residual"].includes(recordType)) return parseDate(date, 'dd.MM.yyyy')

  return (
    <span className="output">
      <DatePicker
        selected={selectedDate}
        onChange={onDateChange}
        dateFormat="dd.MM.yyyy"
        locale={I18n.locale}
        className="input-s"
      />
    </span>
  );
}

export default PaymentDateCell;
