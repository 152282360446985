import React from 'react';

export default function Info({
  poNumber, invoiced, totalAmount, deviationPrice,
}) {
  return (
    <div className="window-top">
      <span>
        <h1>
          {I18n.t('purchase_orders.invoices.attach_to')}
          {' '}
          <span className="link">{poNumber}</span>
        </h1>
        <p className="small margin-top-2">{I18n.t('purchase_orders.invoices.select_transactions_to_attach')}</p>
      </span>
      <span>
        <div className="table total">
          <div className="tr">
            <div className="td label">
              {`${I18n.t('purchase_orders.invoices.matched_transactions')} :`}
            </div>
            <div className="td value">{invoiced}</div>
          </div>
          <div className="tr">
            <div className="td label">
              {`${I18n.t('purchase_orders.invoices.po_value')} :`}
            </div>
            <div className="td value">{totalAmount}</div>
          </div>
          <div className="tr">
            <div className="td label">
              {`${I18n.t('purchase_orders.invoices.deviation_between_invoices')} :`}
            </div>
            <div className="td value red">{deviationPrice}</div>
          </div>
        </div>
      </span>
    </div>
  );
}
