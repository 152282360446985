import React from 'react';
import PropTypes from 'prop-types';
import { withLimit } from '../../shared/utils';
import InvoicingAddressModal from './invoicing_address_modal';

class InvoicingAddress extends React.Component {
  static propTypes = {
    addresses: PropTypes.array,
    addressId: PropTypes.number,
    title: PropTypes.string,
    handleAdd: PropTypes.func,
    onChange: PropTypes.func,
    canAdd: PropTypes.bool,
    editable: PropTypes.bool,
  }

  constructor(props) {
    super(props);

    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.addAddress = this.addAddress.bind(this);

    const { addresses } = props;

    this.allAddresses = addresses;

    this.state = {
      modalOpen: false,
      addresses: withLimit(addresses || []),
    };
  }

  componentDidUpdate(prevProps) {
    const { props: { addresses } } = this;
    if (JSON.stringify(addresses) !== JSON.stringify(prevProps.addresses)) {
      this.addAddresses(addresses);
    }
  }

  onChangeSearchAddress = (e) => {
    const { typingTimeout } = this.state;
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    this.setState({
      searchValue: e.target.value,
      typing: false,
      typingTimeout: setTimeout(() => {
        const { state: { searchValue }, allAddresses } = this;
        this.setState({
          addresses: withLimit(allAddresses.filter(
            address => (address.name.toLowerCase().includes(searchValue.toLowerCase())
              || address.street.toLowerCase().includes(searchValue.toLowerCase())
              || address.zip_code.toLowerCase().includes(searchValue.toLowerCase())
              || address.city.toLowerCase().includes(searchValue.toLowerCase())
              || address.country.toLowerCase().includes(searchValue.toLowerCase())
            ),
          )),
        });
      }, 350),
    });
  }

  addAddresses = (addresses) => {
    this.allAddresses = addresses;
    this.setState({ addresses: withLimit(addresses) });
  }

  addressLine = (address) => {
    const {
      street, line2, line3, zip_code, city, country,
    } = address;

    const zipAndCity = [zip_code, city].filter(Boolean).join(' ');
    const lines = [street, line2, line3].filter(Boolean).join(', ');
    const secondLineAddress = [lines, zipAndCity, country];

    return secondLineAddress.filter(Boolean).join(', ');
  }

  poAddressLine = (purchaseOrder) => {
    const {
      invoice_address_street, invoice_address_zip_code, invoice_address_city,
      invoice_address_country, invoice_address_line2, invoice_address_line3,
    } = purchaseOrder;

    const zipAndCity = [invoice_address_zip_code, invoice_address_city].filter(Boolean).join(' ');
    const lines = [invoice_address_street, invoice_address_line2, invoice_address_line3].filter(Boolean).join(', ');
    const secondLineAddress = [lines, zipAndCity, invoice_address_country];

    return secondLineAddress.filter(Boolean).join(', ');
  }

  addAddress(address) {
    const { handleAdd } = this.props;
    handleAdd(address);
  }

  closeModal() {
    this.setState({ modalOpen: false });
  }

  openModal() {
    this.setState({ modalOpen: true });
  }

  render() {
    const {
      props: {
        editable, title, error, purchaseOrder, isTutorialPending, canAdd,
        onChange, addressId,
      },
      state: {
        modalOpen, addresses,
      },
    } = this;
    return (
      <div className="row">
        <div className={`cell-16 ${error ? 'has-error' : ''}`}>
          <div className="as-input reduce required">
            {!isTutorialPending && canAdd == true && editable
              && (
                <label tabIndex="-1" className="add-edit-trigger"><i className="icon-add" onClick={this.openModal} /></label>
              )
            }
            <span className="label">{title}</span>
            <span className={`select input-r add-edit${!editable ? ' disabled' : ''} ${purchaseOrder.invoice_address_id ? ' selected' : ''}`} name="invoice_address">
              <input type="checkbox" readOnly />
              <div className="control">
                <input type="text" tabIndex="-1" onChange={this.onChangeSearchAddress} placeholder={I18n.t('purchase_orders.form.type_to_search')} />
              </div>
              <div className="select-content">
                { !_.isEmpty(purchaseOrder.invoice_address_name)
                  && (
                  <React.Fragment>
                    <input
                      key="input-copied"
                      id="company_address_copied"
                      name={`${'address_id_'}${title.toLowerCase().replace(' ', '_')}`}
                      type="radio"
                      value={purchaseOrder.invoice_address_id}
                      checked={purchaseOrder.invoice_address_id == addressId}
                      onChange={onChange}
                    />
                    <label className={`${purchaseOrder.invoice_address_id == addressId ? 'checked' : ''}`} key="label-copied" htmlFor="company_address_copied">
                      <span>{purchaseOrder.invoice_address_name}</span>
                      <span>
                        { this.poAddressLine(purchaseOrder) }
                      </span>
                    </label>
                  </React.Fragment>
                  )
                }
                { addresses.filter(address => (purchaseOrder.invoice_address_id != address.id)).map(address => (
                  <React.Fragment key={address.id}>
                    <input
                      key={`input-${address.id}`}
                      id={`company_address_${address.id}_${title.toLowerCase().replace(' ', '_')}`}
                      name={`${'address_id_'}${title.toLowerCase().replace(' ', '_')}`}
                      type="radio"
                      value={address.id}
                      checked={address.id == addressId}
                      onChange={onChange}
                    />
                    <label className={`${address.id == addressId ? 'checked' : ''}`} key={`label-${address.id}`} htmlFor={`company_address_${address.id}_${title.toLowerCase().replace(' ', '_')}`}>
                      <span>
                        {address.name}
                      </span>
                      <span>{ this.addressLine(address) }</span>
                    </label>
                  </React.Fragment>
                ))}
                <div className="placeholder" data-placeholder="Select supplier contact" data-selected={I18n.t('purchase_orders.form.selected')} />
              </div>
              {error ? <div className="hint error">{error}</div> : ''}
            </span>
          </div>
          {canAdd == true && editable
            && <InvoicingAddressModal isOpen={modalOpen} handleClose={this.closeModal} handleAdd={this.addAddress} />
          }
        </div>
      </div>
    );
  }
}

export default InvoicingAddress;
