import React from "react";
import PropTypes from "prop-types";

const PageButton = ({
  children,
  onClick,
  active = false,
  disabled = false,
}) => (
  <button
    className={`ct-flex ct-items-center ct-justify-center ct-w-4 ct-h-4 ct-text-xs ${
      active ? "ct-text-black" : "ct-text-blue"
    }`}
    disabled={disabled}
    onClick={onClick}
  >
    {children}
  </button>
);

export default PageButton;

PageButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
};
