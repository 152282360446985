import * as Api from '../components/shared/api';

class AddressContactsService {
  static index = addressId => (
    Api.makeGetRequest(`/settings/addresses/${addressId}/contacts`)
      .then(response => (response.data.address_contacts))
  )
}

export default AddressContactsService;
