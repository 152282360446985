import uuid from 'uuid/v4';
import * as moment from 'moment';
import DeliveryItemModel from './delivery_item_model';

class DeliveryModel {
  constructor({
    id, number, user_id, purchase_order_id, created_at, delivery_date,
    items, user_first_name, user_last_name, user_avatar, files,
  }) {
    this.id = id || null;
    this.number = number || 'new';
    this.userFirstName = user_first_name || 'User';
    this.userLastName = user_last_name || '';
    this.userId = user_id;
    this.userAvatar = user_avatar;
    this.purchaseOrderId = purchase_order_id;
    this.items = items.map(item => new DeliveryItemModel(item));
    this.deliveryDate = delivery_date ? moment(new Date(delivery_date)) : moment(new Date());
    this.createdAt = created_at;
    this.uuid = uuid();
    this.files = files || [];
    this._destroy = 0;
  }

  totalDeliveryValue = () => {
    if (this._destroy === 1) return 0;
    return this.items.map(item => item.price * item.goodItemsCount).reduce((sum, value) => sum + value, 0.0);
  }
}

export default DeliveryModel;
