import CustomFieldSettingModel from '@/models/custom_field_setting_model';
import * as Api from '../components/shared/api';
import ArchivePurchaseOrderModel from '../models/archive_purchase_order_model';
import ArchiveDeliveryItemModel from '../models/archive_delivery_item_model';
import ArchivePurchaseOrderItemModel from '../models/archive_purchase_order_item_model';
import ColumnViewModel from '../models/column_view_model';

class ArchiveService {
  static purchaseOrders = (searchUrl, signal = null) => Api.makeGetRequest(`/api/archive/purchase_orders${searchUrl}`, signal)
    .then(response => ({
      purchaseOrders: response.data.purchase_orders.map(object => (new ArchivePurchaseOrderModel(object))),
      columnView: new ColumnViewModel(response.data.column_view),
      customFieldSettings: response.data.custom_field_settings.map(object => (new CustomFieldSettingModel(object))),
      fieldSettings: response.data.field_settings,
      pages: response.data.pages,
      filters: response.data.possible_filters,
    })).catch((err) => {
      throw err;
    });

  static downloadPurchaseOrders = (searchUrl, signal = null) => Api.makeGetRequest(`/api/archive/purchase_orders${searchUrl}`, signal)
    .then(response => ({
      message: response.data.message,
    }
    )).catch((err) => {
      throw err;
    });

  static purchaseOrderItems = searchUrl => Api.makeGetRequest(`/api/archive/purchase_order_items${searchUrl}`)
    .then(response => ({
      purchaseOrderItems: response.data.purchase_order_items.map(object => (new ArchivePurchaseOrderItemModel(object))),
      columnView: new ColumnViewModel(response.data.column_view),
      pages: response.data.pages,
      filters: response.data.possible_filters,
    }))

  static deliveryItems = searchUrl => Api.makeGetRequest(`/api/archive/delivery_items${searchUrl}`)
    .then(response => ({
      deliveryItems: response.data.delivery_items.map(object => (new ArchiveDeliveryItemModel(object))),
      columnView: new ColumnViewModel(response.data.column_view),
      pages: response.data.pages,
      filters: response.data.possible_filters,
    }))

  static resolveFilterParams = (custom = [], page, order) => {
    const query = custom.map((attribs) => {
      if (Array.isArray(attribs.value)) {
        return attribs.value.map(v => `${attribs.name}[]=${v}`).join('&');
      }
      return `${attribs.name}=${attribs.value}`;
    }).join('&');

    const orderQuery = order.sort ? `sort[by]=${order.sort}&sort[type]=${order.sortType}` : '';

    return `?page=${page}&${query}&${orderQuery}`;
  }

  static purchaseOrdersFilter = ({ custom, page, order }, signal = null) => {
    const customQuery = ArchiveService.resolveFilterParams(custom, page, order);
    return Api.makeGetRequest(`/api/archive/purchase_orders${customQuery}`, signal)
      .then(response => ({
        purchaseOrders: response.data.purchase_orders.map(object => (new ArchivePurchaseOrderModel(object))),
        fieldSettings: response.data.field_settings,
        pages: response.data.pages,
        filters: response.data.possible_filters,
        query: customQuery,
      }));
  }

  static purchaseOrderItemsFilter = ({ custom, page, order }) => {
    const customQuery = ArchiveService.resolveFilterParams(custom, page, order);

    return Api.makeGetRequest(`/api/archive/purchase_order_items${customQuery}`)
      .then(response => ({
        purchaseOrderItems: response.data.purchase_order_items.map(object => (new ArchivePurchaseOrderItemModel(object))),
        pages: response.data.pages,
        filters: response.data.possible_filters,
        query: customQuery,
      }));
  }

  static deliveryItemsFilter = ({ custom, page, order }) => {
    const customQuery = ArchiveService.resolveFilterParams(custom, page, order);

    return Api.makeGetRequest(`/api/archive/delivery_items${customQuery}`)
      .then(response => ({
        deliveryItems: response.data.delivery_items.map(object => (new ArchiveDeliveryItemModel(object))),
        pages: response.data.pages,
        filters: response.data.possible_filters,
        query: customQuery,
      }));
  }

  static updateColumnView = params => Api.makePutRequest('/api/archive/column_view', params, false)
    .then(response => response)
    .catch(errors => errors.response.data);
}

export default ArchiveService;
