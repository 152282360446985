import React from 'react';
import ClearComponent from './index';

export default {
  component: Clear,
  title: 'Filters/Clear',
  args: {
    children: 'CLEAR ALL',
  },
  argTypes: {
    children: {
      control: 'text',
    },
  },
};

export const Clear = args => (
  <ClearComponent
    {...args}
    onClick={() => window.alert('Clicked!')} // eslint-disable-line no-alert
  />
);
