import React from 'react';
import { errorToast, successToast } from './shared/toast';
import SupplierInvoicesService from '../services/SupplierInvoicesService';
import AccountingsService from '../services/accountings_service';
import SupplierInvoiceModel from '../models/SupplierInvoiceModel';
import SupplierInvoiceTransactionModel from '../models/SupplierInvoiceTransactionModel';
import Header from './supplier_invoice/header';
import Content from './supplier_invoice/content';
import Loader from './loader';
import Errors from '../models/errors';

class SupplierInvoice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      supplierInvoice: new SupplierInvoiceModel({
        id: props.supplierInvoiceId,
        transactions: [],
        comments: [],
        supplier: { id: '' },
      }),
      isLoading: true,
      isSubmitting: false,
      supplierInvoiceErrors: new Errors({}),
      transactionsErrors: {},
    };
  }

  componentDidMount = () => {
    this.fetchSupplierInvoice();
  };

  fetchSupplierInvoice = () => {
    const { supplierInvoiceId } = this.props;

    SupplierInvoicesService.edit(supplierInvoiceId).then(({ errors, ...params }) => {
      const supplierInvoice = this.initSupplierInvoice(params);
      if (supplierInvoice.processing) this.subscribePredictions();

      this.setState({
        supplierInvoice, isLoading: false, supplierInvoiceErrors: new Errors(errors.supplier_invoice_errors), transactionsErrors: errors.transaction_errors,
      });
    });
  };

  forceOCRProcessing = () => {
    const { supplierInvoiceId } = this.props;

    SupplierInvoicesService.terminateOCRProcess(supplierInvoiceId)
      .then(() => {
        successToast(I18n.t('commons.messages.terminated'));
        this.fetchSupplierInvoice();
      })
      .catch(() => {
        this.setState({ isLoading: false, isSubmitting: false });
        errorToast(I18n.t('commons.messages.error'));
      });
  };

  initSupplierInvoice = (supplierInvoice) => {
    if (supplierInvoice.transactions.length === 0) {
      supplierInvoice.transactions.push(new SupplierInvoiceTransactionModel({}));
    }

    return new SupplierInvoiceModel(supplierInvoice);
  };

  updateSupplierInvoice = (newSupplierInvoice, callback = () => {}) => {
    this.setState({ supplierInvoice: newSupplierInvoice }, callback);
  };

  saveSupplierInvoice = (
    event = false,
    approverIds = false,
    displayToast = true,
    successCallback = () => {},
  ) => {
    const {
      state: { supplierInvoice, isSubmitting },
    } = this;
    supplierInvoice.event = event;
    supplierInvoice.approverIds = approverIds;
    const supplierInvoiceData = supplierInvoice.toFormData(supplierInvoice);

    if (isSubmitting) return;

    this.clearErrors();
    this.setState({ isSubmitting: true }, () => {
      SupplierInvoicesService.update(supplierInvoice.id, supplierInvoiceData)
        .then(({ errors, ...params }) => {
          this.setState({ supplierInvoice: new SupplierInvoiceModel(params), isSubmitting: false });
          this.setState({ supplierInvoiceErrors: new Errors(errors.supplier_invoice_errors), transactionsErrors: errors.transaction_errors });
          successCallback();
          if (displayToast) {
            successToast(I18n.t('commons.messages.changes_saved'));
          }
        })
        .catch(({ response: { data } }) => {
          this.setState({ isLoading: false, isSubmitting: false });
          this.assignErrors(data);
          errorToast(I18n.t('commons.messages.error'));
        });
    });
  };

  restoreSupplierInvoice = () => {
    const {
      state: {
        supplierInvoice: { id },
      },
    } = this;

    AccountingsService.supplierInvoicesBulkRestore([id])
      .then(() => {
        window.location.href = '/purchase_orders/invoices?view=invoices&deleted[]=0';
      })
      .catch(() => {
        errorToast(I18n.t('commons.messages.error'));
      });
  };

  updateOcrFields = (supplierInvoice, data) => {
    this.setState({ isLoading: true });

    const updateOcrFieldsAction = () => SupplierInvoicesService.updateOcrFields(supplierInvoice, data).then((params) => {
      this.setState({ supplierInvoice: new SupplierInvoiceModel(params), isLoading: false });
    });

    this.saveSupplierInvoice(false, false, true, updateOcrFieldsAction);
  };

  saveSupplierInvoiceComments = (comment) => {
    const {
      state: { supplierInvoice },
    } = this;
    SupplierInvoicesService.createComment(supplierInvoice, comment)
      .then(() => {
        successToast(I18n.t('commons.messages.changes_saved'));
      })
      .catch(() => {
        errorToast(I18n.t('commons.messages.error'));
      });
  };

  saveWithEvent = (newParams) => {
    const {
      state: { supplierInvoice },
    } = this;
    SupplierInvoicesService.updateWithEvent(supplierInvoice, { supplier_invoice: newParams })
      .then((params) => {
        this.setState({ supplier_invoice: new SupplierInvoiceModel(params) });
        this.clearErrors();
        successToast(I18n.t('commons.messages.changes_saved'));
      })
      .catch(({ response: { data } }) => {
        this.assignErrors(data);
        errorToast(I18n.t('commons.messages.error'));
      });
  };

  transferSupplierInvoice = async () => {
    const {
      props: { integrationType },
      state: { supplierInvoice, isSubmitting },
    } = this;

    if (isSubmitting) return;

    const transferAction = () => SupplierInvoicesService.postInvoice(supplierInvoice)
      .then((params) => {
        this.setState({
          supplier_invoice: new SupplierInvoiceModel(params),
          isLoading: false,
        });
        this.clearErrors();
        successToast(I18n.t('commons.messages.transfer_succeed'));
        if (integrationType === 'manual') this.exportSupplierInvoice();
      })
      .catch(({ response: { data } }) => {
        this.assignErrors(data);
        errorToast(I18n.t('commons.messages.error'));
        this.setState({ isLoading: false });
      });
    this.saveSupplierInvoice(false, false, true, transferAction);
  };

  exportSupplierInvoice = () => {
    const { supplierInvoice } = this.state;
    const query = `invoice_ids[]=${supplierInvoice.id}`;
    window.location.href = `/api/accountings/supplier_invoices/transfer_export.xlsx?${query}`;
  };

  saveAndCloseSupplierInvoice = () => {
    const {
      state: { supplierInvoice },
    } = this;
    const supplierInvoiceData = supplierInvoice.toFormData(supplierInvoice);
    SupplierInvoicesService.update(supplierInvoice.id, supplierInvoiceData)
      .then((params) => {
        this.setState({ supplierInvoice: new SupplierInvoiceModel(params) });
        window.location.href = '/purchase_orders/invoices?view=invoices&deleted[]=0';
      })
      .catch(({ response: { data } }) => {
        this.assignErrors(data);
        errorToast(I18n.t('commons.messages.error'));
      });
  };

  deleteSupplierInvoice = () => {
    const { supplierInvoice } = this.state;
    SupplierInvoicesService.destroy(supplierInvoice).then(() => {
      window.location.href = '/purchase_orders/invoices?view=invoices&deleted[]=0';
    });
  };

  clearErrors = () => {
    this.assignErrors({ supplierInvoice: { errors: {} }, transactions: {} });
  };

  assignErrors = (data) => {
    const { supplierInvoice, transactions } = data;

    if (supplierInvoice !== undefined) {
      this.setState({
        supplierInvoiceErrors: new Errors(supplierInvoice.errors),
        transactionsErrors: transactions,
      });
    }
  };

  subscribePredictions = () => {
    const {
      state: { supplierInvoice },
    } = this;
    this.subscription = App.cable.subscriptions.create(
      { channel: 'PredictionsChannel', supplier_invoice: supplierInvoice.id },
      {
        connected: () => {
          SupplierInvoicesService.active(supplierInvoice.id).then(({ jobProcessing }) => {
            supplierInvoice.processing = jobProcessing;
            if (!supplierInvoice.processing) {
              this.fetchSupplierInvoice();
            } else {
              this.setState({ supplierInvoice });
            }
          });
        },
        received: (success) => {
          if (success) {
            this.fetchSupplierInvoice();
          }
          this.subscription.unsubscribe();
        },
        disconnected: () => this.subscription.unsubscribe(),
      },
    );
  };

  render = () => {
    const {
      supplierInvoice, isLoading, supplierInvoiceErrors, transactionsErrors,
    } = this.state;
    const {
      currentUser,
      companyCurrency,
      serverTime,
      integrationType,
      enableClass,
      enableCustomer,
      enableLocation,
      standAlone,
    } = this.props;
    const htmlClasses = isLoading ? ' has-loading' : '';

    return (
      <div
        className={`modal centered modal-fullscreen with-frame active ${htmlClasses}`}
      >
        <div className="modal-wrapper">
          <Loader />
          <div className="modal-window window window-form">
            <Header
              supplierInvoice={supplierInvoice}
              serverTime={serverTime}
              forceOCRProcessing={this.forceOCRProcessing}
            />
            <Content
              supplierInvoice={supplierInvoice}
              supplierInvoiceErrors={supplierInvoiceErrors}
              transactionsErrors={transactionsErrors}
              updateSupplierInvoice={this.updateSupplierInvoice}
              updateOcrFields={this.updateOcrFields}
              saveSupplierInvoice={this.saveSupplierInvoice}
              saveAndCloseSupplierInvoice={this.saveAndCloseSupplierInvoice}
              restoreSupplierInvoice={this.restoreSupplierInvoice}
              deleteSupplierInvoice={this.deleteSupplierInvoice}
              currentUser={currentUser}
              companyCurrency={companyCurrency}
              saveWithEvent={this.saveWithEvent}
              transferSupplierInvoice={this.transferSupplierInvoice}
              possibleApprovers={supplierInvoice.possibleApprovers}
              saveSupplierInvoiceComments={this.saveSupplierInvoiceComments}
              integrationType={integrationType}
              enableClass={enableClass}
              enableCustomer={enableCustomer}
              enableLocation={enableLocation}
              standAlone={standAlone}
            />
          </div>
        </div>
      </div>
    );
  };
}

export default SupplierInvoice;
