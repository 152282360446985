import React from 'react';
import PropTypes from 'prop-types';
import IntegrationContactForm from './integrations/integration_contact_form';

class IntegrationOff extends React.Component {
  state = {
    showContact: false,
  }

  static propTypes = {
    autopilotImagePath: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }

  showContact = () => {
    this.setState({ showContact: true });
  }

  render() {
    const { autopilotImagePath, email, name } = this.props;
    const { showContact } = this.state;
    return (
      <div className="body-content-wrapper">
        <legend>{I18n.t('settings.integrations.new.accounting_system_integration')}</legend>
        <div className="grid space-3">
          <div className="cell-16">
            <div className="tile form-section">
              <div className="grid integration-demo">
                <div className="cell-6 tile-content form-section-content t-cell-16">
                  <div className="padding-bottom-4 text-center">
                    <h1 className="margin-top-0">
                      {I18n.t('settings.integrations.off.sync')}
                    </h1>
                    <p>{I18n.t('settings.integrations.off.connect')}</p>
                    { !showContact
                      ? (
                        <p className="text-center">
                          <button type="button" className="button button-primary button-xl" onClick={this.showContact}>{I18n.t('settings.integrations.off.contact_us')}</button>
                        </p>
                      ) : null
                    }
                  </div>
                </div>
                { !showContact
                  ? (
                    <div className="cell-10 tile-content form-section-content t-cell-16 text-center">
                      <img src={autopilotImagePath} alt="plugandplay" />
                      <h3 className="text-center">{I18n.t('settings.integrations.off.autopilot')}</h3>
                    </div>
                  )
                  : <IntegrationContactForm email={email} name={name} />
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default IntegrationOff;
