import React, { useState, useEffect } from 'react';
import Button from '../common/button';


function WindowToast({ opened, messages = [''] }) {
  const [toastOpened, setToastOpened] = useState(opened);

  useEffect(() => {
    setToastOpened(opened);
  }, [opened]);

  const onCloseToast = () => {
    setToastOpened(false);
  };

  if (!toastOpened) return null;
  return (
    <div className="toast show error">
      <div className="toast-content">
        <p>{messages[0]}</p>
        <Button onClick={onCloseToast}>
          {I18n.t('commons.actions.cancel')}
        </Button>
      </div>
    </div>
  );
}

export default WindowToast;
