
import React from 'react';

function ModalContent({ children, additionalClass }) {
  return (
    <div className={`window-content ${additionalClass}`}>
      {children}
    </div>
  );
}

export default ModalContent;
