import React from 'react';
import uuid from 'uuid/v4';
import _ from 'lodash';

export default function ColumnViewSettings({
  columnView, handleUpdate, visibleColumns, customFieldSettings,
}) {
  const _renderTranslation = (col) => {
    if (col.includes('custom_field_setting')) {
      const field = customFieldSettings.find(object => object.slug === col);
      return field.name;
    }
    return I18n.t(`purchase_orders.partials.columns_popup.${col}`);
  };

  return (
    <div className="has-popup columns-popup popup-under-header">
      <input type="checkbox" id="columns-popup" />
      <i className="icon-view_column" />
      <div className="popup bottom-right">
        <div className="popup-header">
          View columns
          <div className="pause" />
          <label className="icon-view_column" htmlFor="columns-popup" />
        </div>
        <div className="popup-content">
          <div className="checkbox-list">
            {visibleColumns.map((col, index) => (
              <label key={uuid()} className={`has-checkbox ${index === 0 ? 'disabled' : ''}`}>
                <input
                  className="column-view-input"
                  name="column-filter"
                  type="checkbox"
                  value={_.camelCase(col)}
                  checked={columnView[_.camelCase(col)]}
                  autoComplete="off"
                  onChange={handleUpdate}
                />
                <span className="checkbox input-s" />
                <span>
                  {_renderTranslation(col)}
                </span>
              </label>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
