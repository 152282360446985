import React from 'react';
import PropTypes from 'prop-types';
import { BUTTON_VARIANT, BUTTON_VARIANT_CLASSES } from './constants';

export default function Button({
  children,
  variant,
  onClick,
  disabled = false,
  type = 'button',
  className,
}) {
  return (
    <button
      className={`ct-font-medium ct-text-sm ct-uppercase ct-py-2.5 ct-px-8 ct-rounded ct-border ct-select-none ct-touch-manipulation disabled:ct-pointer-events-none ${
        BUTTON_VARIANT_CLASSES?.[variant] || ''
      } ${className}
      `}
      onClick={onClick}
      type={type}
      disabled={disabled}
    >
      {children}
    </button>
  );
}

Button.propTypes = {
  children: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  variant: PropTypes.oneOf(Object.values(BUTTON_VARIANT)).isRequired,
  disabled: PropTypes.bool,
  type: PropTypes.oneOf(['button', 'reset', 'submit']),
};
