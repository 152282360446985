import * as Api from '../components/shared/api';
import DepartmentModel from '../models/department_model';

class IntegrationsDepartmentsService {
  constructor(integrationType) {
    this.integrationType = integrationType;
  }

  all = () => Api.makeGetRequest(`/integrations/departments?integration_type=${this.integrationType}`)
    .then(response => (
      response.data.map(value => new DepartmentModel(value))
    ))
}

export default IntegrationsDepartmentsService;
