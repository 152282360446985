import React, { useState } from 'react';
import DropdownField from '@/components/common/dropdown_field';
import ItemListService from '@/services/item_list_service';
import * as Toast from '../shared/toast';
import FileFormatValidator from '../shared/file_format_validator';
import Loader from '../loader';

function ImportItemsModal({
  onToggleImportModal, isImportModalOpen, itemsType,
}) {
  const [state, setState] = useState({
    selectedSupplier: null,
    fileToImport: null,
    isLoading: false,
    renderConfirmationComponent: false,
    newRecords: 0,
    oldRecords: 0,
    failedRecords: 0,
  });

  const selectSupplier = (_fieldName, selectedSupplier) => {
    setState(prevState => ({ ...prevState, selectedSupplier }));
  };

  const onFileChange = ({ target: { files } }) => {
    const [file] = files;
    if (file.size > 20000000) {
      Toast.errorToast(I18n.t('purchase_orders.form.deliveries.to_big_file_size'));
    } else if (FileFormatValidator.valid(file, /(\.xlsx)$/i)) {
      Toast.errorToast(I18n.t('purchase_orders.form.deliveries.wrong_file_format'));
    } else if (file.name.split('.').length > 2) {
      Toast.errorToast(I18n.t('purchase_orders.form.deliveries.invalid_file_name'));
    } else if (selectedSupplier === null && itemsType == 'supplier_items') {
      Toast.errorToast(I18n.t('supplier_items.import_modal.empty_supplier_error_message'));
    } else {
      setState(prevState => ({ ...prevState, fileToImport: file }));
    }
  };

  const importFile = () => {
    const { selectedSupplier, fileToImport } = state;
    if (fileToImport === null) {
      Toast.errorToast(I18n.t('supplier_items.import_modal.empty_file_error_message'));
    } else {
      setState(prevState => ({ ...prevState, isLoading: true }));
      const params = { file: fileToImport, supplierId: selectedSupplier?.id };
      ItemListService.import(params)
        .then((data) => {
          if (data.error) {
            setState(prevState => ({ ...prevState, isLoading: false }));
            Toast.errorToast(data.error);
          } else {
            const { old_records, new_records, failed_records } = data;
            setState(prevState => ({
              ...prevState,
              isLoading: false,
              renderConfirmationComponent: true,
              oldRecords: old_records,
              newRecords: new_records,
              failedRecords: failed_records,
              fileToImport: null,
            }));
            Toast.successToast('Items imported');
          }
        })
        .catch(() => {
          setState(prevState => ({ ...prevState, isLoading: false }));
          onToggleImportModal();
          Toast.errorToast(I18n.t('commons.messages.error'));
        });
    }
  };

  const closeModal = () => {
    setState(prevState => ({ ...prevState, renderConfirmationComponent: false }));
    onToggleImportModal();
  };

  const {
    selectedSupplier, isLoading, renderConfirmationComponent,
    oldRecords, newRecords, failedRecords, fileToImport,
  } = state;

  const htmlClasses = isLoading ? ' has-loading' : '';

  const Header = () => {
    if (itemsType == 'supplier_items') {
      return <h1 className="small-margin">{I18n.t('supplier_items.import_modal.upload_items')}</h1>;
    }

    return <h1 className="small-margin">{I18n.t('supplier_items.import_modal.upload_products')}</h1>;
  };

  return (
    <div
      className={
        `modal centered sub-modal modal-m ${isImportModalOpen ? 'active' : ''} ${htmlClasses}`
      }
    >
      <div className="modal-wrapper">
        <div className="modal-window window">
          <div className="window-header modal-header modal-header-auto modal-header-small">
            <Header />
          </div>
          <div className="window-content">
            {
            renderConfirmationComponent
              ? null
              : (
                <h4 className="mute">
                  <b>{I18n.t('supplier_items.import_modal.please_note')}</b>
                  {I18n.t('supplier_items.import_modal.import_info1')}
                  <a className="link" download target="_blank" href={`/api/supplier_items/template_file?itemsType=${itemsType}`}>
                    {` ${I18n.t('supplier_items.import_modal.template_file')} `}
                  </a>
                  {I18n.t('supplier_items.import_modal.import_info2')}
                </h4>
              )
          }
            <Loader />
            <div className="grid space-3">
              <div className="row">
                <div className="cell-16">
                  {
                    renderConfirmationComponent && itemsType == 'supplier_items'
                      ? (
                        <div className="as-input margin-top-4">
                          <span className="label">
                            {I18n.t('supplier_items.import_modal.upload_to_supplier')}
                          </span>
                          <div className="output">
                            {selectedSupplier?.name}
                          </div>
                        </div>
                      )
                      : (itemsType == 'supplier_items'
                        ? (
                          <DropdownField
                            cellClass="as-input required margin-top-4"
                            labelName={I18n.t('supplier_items.supplier')}
                            fieldName="supplier"
                            placeholder={I18n.t('purchase_orders.form.select_supplier')}
                            entities={[]}
                            type="fetch"
                            field={{ supplier: { id: selectedSupplier?.id } }}
                            updateField={selectSupplier}
                            errors={{}}
                          />
                        ) : (
                          null
                        )
                      )
                  }
                </div>
              </div>
              <div className="row">
                <div className="cell-16">
                  {
                    renderConfirmationComponent
                      ? (
                        <div>
                          <h3>
                            {`${I18n.t('supplier_items.import_modal.updated_items')}: ${oldRecords}`}
                            <br />
                            {`${I18n.t('supplier_items.import_modal.new_items')}: ${newRecords}`}
                            <br />
                            {`${I18n.t('supplier_items.import_modal.invalid_items')}: ${failedRecords}`}
                          </h3>
                        </div>
                      )
                      : (

                        <div className="as-input required">
                          <input
                            type="file"
                            id="receive_all_file"
                            onChange={onFileChange}
                            name="file"
                            style={{ display: 'none' }}
                          />
                          <label htmlFor="receive_all_file">
                            <div className="button block button-primary file-name">
                              {I18n.t('purchase_orders.form.select_file')}
                            </div>
                          </label>
                        </div>

                      )
                  }
                </div>
              </div>
              {
                fileToImport
                  ? (
                    <div className="row">
                      <div className="cell offset-1 offset-right-1 has-progress">
                        <p className="state-info">{fileToImport.name}</p>
                      </div>
                    </div>
                  ) : null
              }
            </div>
          </div>
          <div className="window-footer modal-footer modal-footer-space">
            <div className="items">
              <label
                className="button button-transparent button-mute inverse link"
                onClick={closeModal}
              >
                { renderConfirmationComponent ? I18n.t('commons.actions.close') : I18n.t('commons.actions.cancel')}
              </label>
              {
                renderConfirmationComponent
                  ? null
                  : (
                    <button className="button button-primary" onClick={importFile}>
                      {I18n.t('commons.actions.save')}
                    </button>
                  )
              }
            </div>
          </div>
        </div>
      </div>
      <label className="modal-backdrop" onClick={closeModal} />
    </div>
  );
}

export default ImportItemsModal;
