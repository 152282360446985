import uuid from 'uuid/v4';

class VatModel {
  constructor(params) {
    this.id = (params && params.id) || '';
    this.name = (params && params.name);
    this.uuid = uuid();
    this.rate = (params && params.rate);
    this.isReverseCharge = (params && params.reverse_charge);
    this.externalId = params?.external_id;
    this.dispValues = [this.name];
  }
}

export default VatModel;
