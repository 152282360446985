import { DateTime } from 'luxon';

class PlannedPaymentModel {
  constructor({
    id,
    paymentMethod,
    paymentDate,
    valueCents,
    currency,
    exchangeRate,
    usageRate,
    valueCentsInCompanyCurrency,
    purchaseOrderInvoiceId,
  } = {}) {
    this.id = id;
    this.paymentMethod = paymentMethod;
    this.paymentDate = paymentDate || DateTime.now().toString();
    this.exchangeRate = exchangeRate || 1.0;
    this.usageRate = usageRate || 0.0;
    this.currency = currency;
    this.valueCents = valueCents;
    this.valueCentsInCompanyCurrency = valueCentsInCompanyCurrency;
    this.purchaseOrderInvoiceId = purchaseOrderInvoiceId;
  }

  get value() {
    return Number(this.valueCents / 100).toFixed(2);
  }

  get valueInCompanyCurrency() {
    return Number(this.valueCentsInCompanyCurrency / 100).toFixed(2);
  }
}

export default PlannedPaymentModel;
