import CategoryModel from './category_model';
import Model from './model';

class BudgetModel extends Model {
  constructor({
    categories, ...attributes
  }) {
    super(attributes);
    this.displayName = attributes.number ? `${attributes.number} ${attributes.name}` : attributes.name;
    this.categories = categories ? categories.map(category => new CategoryModel(category)) : [];
  }
}

export default BudgetModel;
