import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CreateSupplierMessage } from '@/services/SupplierMessageService';

function SupplierMessageModal({
  supplierMessage,
  poSetup,
  isSentToSupplierOpen,
  isSupplierMessageOpen,
  sendToSupplier,
  handleClose,
  purchaseOrder,
  updateSupplierMessage,
}) {
  const prefillEmail = () => {
    const { supplier_contact_email, supplier_contact_id, supplier_email } = purchaseOrder;
    const contact_email = supplier_contact_id ? supplier_contact_email : false;
    const email = (supplierMessage.emails || contact_email || supplier_email || '');
    // TODO: We should declare the type properly before returning from the edit endpoint, and not modify
    // the data here.
    if (typeof email === 'string') return email;

    return email ? email.join(', ') : '';
  };

  const [state, setState] = useState({
    id: supplierMessage.id,
    emails: prefillEmail(),
    cc: supplierMessage.cc ? supplierMessage.cc.join(', ') : '',
    subject: supplierMessage.subject || poSetup.supplier_message_subject || '',
    body: supplierMessage.body || poSetup.supplier_message_body || '',
    errors: {},
  });

  const onChange = (e) => {
    const { name, value } = e.target;
    setState(prevState => ({ ...prevState, [name]: value }));
  };

  const submitHandler = (e) => {
    e.preventDefault();
    const {
      emails, cc, subject, body,
    } = state;
    const data = {
      emails,
      cc,
      subject,
      body,
    };

    CreateSupplierMessage(purchaseOrder.id, data, updateSupplierMessage).then(({ success, errors }) => {
      if (success && isSentToSupplierOpen) {
        sendToSupplier();
      } else if (!success) {
        setState(prevState => ({ ...prevState, errors }));
      }
    });
  };

  const {
    errors, emails, cc, subject, body,
  } = state;
  const isOpen = () => isSentToSupplierOpen || isSupplierMessageOpen || false;

  useEffect(() => {
    const escFunction = (event) => {
      if (event.key === 'Escape') {
        handleClose();
      }
    };
    document.addEventListener('keydown', escFunction, false);
    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, [handleClose]);

  useEffect(() => {
    const contactEmailChanged = supplierMessage.supplier_contact_id !== purchaseOrder.supplier_contact_id;
    const supplierEmailChanged = supplierMessage.supplier_email !== purchaseOrder.supplier_email;
    if (contactEmailChanged || supplierEmailChanged) {
      setState(prevState => ({ ...prevState, emails: prefillEmail() }));
    }
  }, [purchaseOrder]);

  return (
    <>
      <input autoComplete="off" className="modal-open" type="radio" value="on" checked={isOpen()} readOnly />
      <div className="modal sub-modal centered modal-s">
        <form className="modal-wrapper" onSubmit={submitHandler}>
          <div className="modal-window window-inside window" id="sent-to-supplier-modal">
            <div className="window-header modal-header">
              {I18n.t('purchase_orders.form.sending_to_supplier')}
            </div>
            <div className="window-content">
              <div className="grid space-1 gutter-1">
                <div className="row">
                  <div className="cell-16">
                    <p>
                      {isSentToSupplierOpen
                        ? I18n.t('supplier.modal.send_to_supplier.description') : I18n.t('supplier.modal.need_approval.description')}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className={`cell-16 ${errors.emails ? 'has-error' : ''}`}>
                    <div className="as-input required has-tooltip">
                      <span className="label">{I18n.t('purchase_orders.form.to_supplier_email_address')}</span>
                      <input placeholder={I18n.t('purchase_orders.form.enter_email')} id="supplier_message_email" name="emails" type="text" value={emails} onChange={onChange} />
                      { errors.emails && <div className="hint">{errors.emails.join(', ')}</div> }
                      <div className="tooltip">
                        {I18n.t('purchase_orders.form.multiple_email_addresses')}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className={`cell-16 ${errors.cc ? 'has-error' : ''}`}>
                    <div className="as-input">
                      <span className="label">{I18n.t('purchase_orders.form.cc')}</span>
                      <input placeholder={I18n.t('purchase_orders.form.enter_email')} id="supplier_message_cc" name="cc" type="text" value={cc} onChange={onChange} />
                      { errors.cc && <div className="hint">{errors.cc.join(', ')}</div> }
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className={`cell-16 ${errors.subject ? 'has-error' : ''}`}>
                    <div className="as-input required">
                      <span className="label">{I18n.t('purchase_orders.form.subject')}</span>
                      <input placeholder={I18n.t('purchase_orders.form.enter_subject')} id="supplier_message_subject" name="subject" type="text" value={subject} onChange={onChange} />
                      { errors.subject && <div className="hint">{errors.subject.join(', ')}</div> }
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className={`cell-16 ${errors.body ? 'has-error' : ''}`}>
                    <div className="as-input required">
                      <span className="label">{I18n.t('purchase_orders.form.message')}</span>
                      <textarea placeholder={I18n.t('purchase_orders.form.enter_comment')} id="supplier_message_body" name="body" rows={4} value={body} onChange={onChange} />
                      { errors.body && <div className="hint">{errors.body.join(', ')}</div> }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="window-footer modal-footer">
              <div className="items">
                <label
                  className="button button-transparent button-mute inverse link"
                  onClick={handleClose}
                >
                  {I18n.t('purchase_orders.form.cancel')}
                </label>
                <button className="button button-primary">
                  {isSentToSupplierOpen
                    ? I18n.t('supplier.modal.send_to_supplier.submit_button') : I18n.t('supplier.modal.need_approval.submit_button')}
                </button>
              </div>
            </div>
          </div>
        </form>
        <label className="modal-backdrop" onClick={handleClose} />
      </div>
    </>
  );
}

SupplierMessageModal.propTypes = {
  supplierMessage: PropTypes.object.isRequired,
  poSetup: PropTypes.object.isRequired,
  isSentToSupplierOpen: PropTypes.bool.isRequired,
  isSupplierMessageOpen: PropTypes.bool.isRequired,
  sendToSupplier: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  purchaseOrder: PropTypes.object.isRequired,
  updateSupplierMessage: PropTypes.func.isRequired,
};

export default SupplierMessageModal;
