import React, { useState, useRef, useEffect } from "react";
import { DateRangePicker } from "react-date-range";

import "react-date-range/dist/styles.css";
import "./index.scss";

const DateTimePicker = ({
  ref = {},
  selectedDateRange = String,
  startDate = new Date(),
  endDate = new Date(),
  staticRanges = [],
  callbackAfterOnChange = () => {},
  callbackAfterOnShownDateChange = () => {},
  ...props
} = {}) => {
  const [datePickerEnabled, setDatePickerEnabled] = useState(false);
  const [ranges, setRanges] = useState([
    {
      startDate: startDate,
      endDate: endDate,
      key: "selection",
    },
  ]);
  const dateRangeRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dateRangeRef.current &&
        !dateRangeRef.current.contains(event.target)
      ) {
        setDatePickerEnabled(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dateRangeRef]);

  const onChange = (item) => {
    setRanges([item.selection]);
    callbackAfterOnChange(item);
  };

  const onShownDateChange = (date) => {
    callbackAfterOnShownDateChange(date);
  };

  return (
    <div ref={dateRangeRef} className="DropdownDateTimePicker">
      <button
        className="select input padding-0 padding-left-1 padding-right-1 input-xs input-round filter-select"
        onClick={() => setDatePickerEnabled(!datePickerEnabled)}
        role="button"
        aria-expanded={datePickerEnabled}
      >
        {selectedDateRange}
      </button>

      {datePickerEnabled && (
        <DateRangePicker
          {...props}
          onChange={onChange}
          onShownDateChange={onShownDateChange}
          ranges={ranges}
          staticRanges={staticRanges}
        />
      )}
    </div>
  );
};

export default DateTimePicker;
