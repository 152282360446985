import React from 'react';
import PropTypes from 'prop-types';
import Checkmark from '@/icons/Checkmark';

export default function Checkbox({
  id,
  value,
  onChange,
  label = '',
  disabled = false,
}) {
  const renderLabel = () => {
    if (!label) return null;
    if (typeof label === 'string') {
      return (
        <label
          className="ct-text-xs ct-text-black ct-select-none ct-leading-7"
          htmlFor={id}
        >
          {label}
        </label>
      );
    }
    return label;
  };
  const checkedStyles = value
    ? 'ct-bg-blue ct-border-transparent disabled:ct-bg-gray disabled:ct-border-gray'
    : 'ct-bg-white ct-border-gray-input-placeholder disabled:ct-bg-gray-lighter disabled:ct-border-gray';
  return (
    <div className="ct-flex ct-items-center ct-gap-2.5">
      <div className="ct-relative ct-flex ct-items-center ct-justify-center [&>svg]:ct-absolute [&>svg]:ct-w-2 [&>svg]:ct-h-auto [&>svg>path]:ct-text-white [&>svg]:ct-pointer-events-none">
        <input
          className={`ct-flex ct-items-center ct-appearance-none ct-h-4 ct-w-4 ct-rounded-sm ct-border ct-cursor-pointer disabled:ct-pointer-events-none ${checkedStyles}`}
          type="checkbox"
          id={id}
          onChange={onChange}
          disabled={disabled}
        />
        {value && <Checkmark />}
      </div>
      {renderLabel()}
    </div>
  );
}

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  label: PropTypes.node,
  disabled: PropTypes.bool,
};
