import React from 'react';

export default function Footer({
  onSave, onCancel, markAsInvoiced, toggleMarkAsInvoiced, filterOpened, showOpened, footerText, notInvoiced = true,
}) {
  const filterTextKey = showOpened ? footerText[0] : footerText[1];

  const markAsInvoicedCheckbox = () => (
    <label className="has-checkbox hide-on-complete">
      <input type="checkbox" checked={markAsInvoiced} onChange={toggleMarkAsInvoiced} />
      <span className="checkbox input-l" />
      <span className="link">{I18n.t('purchase_orders.invoices.mark_po_as_invoiced')}</span>
    </label>
  );

  return (
    <div className="window-footer modal-footer">
      <div className="items items-end center">
        <a onClick={filterOpened} className="link to-left">{I18n.t(`purchase_orders.invoices.${filterTextKey}`)}</a>
        {notInvoiced && markAsInvoicedCheckbox()}
        <label className="button inverse button-mute hide-on-complete" htmlFor="attach-modal-close" onClick={onCancel}>{I18n.t('commons.actions.cancel')}</label>
        <a className="button button-primary hide-on-complete" onClick={onSave}>{I18n.t('purchase_orders.invoices.save_changes')}</a>
      </div>
    </div>
  );
}
