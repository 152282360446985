import React from 'react';

import FileFormatValidator from '../shared/file_format_validator';
import * as Toast from '../shared/toast';

class ReceiveAllModal extends React.Component {
  isModalOpen = () => {
    const { isReceiveAllModalOpen } = this.props;
    return isReceiveAllModalOpen ? 'active' : '';
  }

  saveReceives = () => {
    const { confirmReceiveGoods } = this.props;
    confirmReceiveGoods('receive_all');
  }

  onFileChange = ({ target: { files } }) => {
    const { onReceiveAllFileChange } = this.props;
    const [file] = files;
    if (file.size > 20000000) {
      Toast.errorToast(I18n.t('purchase_orders.form.deliveries.to_big_file_size'));
    } else if (FileFormatValidator.valid(file)) {
      Toast.errorToast(I18n.t('purchase_orders.form.deliveries.wrong_file_format'));
    } else if (file.name.split('.').length > 2) {
      Toast.errorToast(I18n.t('purchase_orders.form.deliveries.invalid_file_name'));
    } else {
      onReceiveAllFileChange(file);
    }
  }

  render() {
    const {
      handleReceiveAllModal, onChangeComment, commentBody,
      receiveAllFile, commentError,
    } = this.props;

    return (
      <div key={1} className={`modal sub-modal centered modal-m ${this.isModalOpen()}`}>
        <div className="modal-wrapper">
          <div className="modal-window window">
            <div className="window-header modal-header">
              {I18n.t('purchase_orders.form.receive_all')}
            </div>
            <div className="window-content">
              <p className="mute">
                {I18n.t('purchase_orders.form.receive_all_message')}
              </p>
              <div className="as-input required">
                <input
                  type="file"
                  id="receive_all_file"
                  onChange={this.onFileChange}
                  name="file"
                  style={{ display: 'none' }}
                />
                <label htmlFor="receive_all_file">
                  <div className="button block button-primary file-name">
                    {I18n.t('purchase_orders.form.select_file')}
                  </div>
                </label>
              </div>
              <br />
              <a className="link" target="_blank">
                {
                  receiveAllFile
                    ? (
                      <React.Fragment>
                        <i className="icon-attach_file" />
                        {receiveAllFile.name}
                      </React.Fragment>
                    )
                    : null
                }
              </a>
              <br />
              <div className="grid">
                <div className="cell">
                  <div className={`cell-16 ${commentError ? 'has-error' : ''}`}>
                    <textarea
                      className="block"
                      value={commentBody}
                      onChange={({ target: { value } }) => onChangeComment(value)}
                      placeholder={I18n.t('purchase_orders.form.enter_comment')}
                      rows="6"
                    />
                    { commentError ? <div className="hint error">{commentError}</div> : null }
                  </div>
                </div>
              </div>
            </div>
            <div className="window-footer modal-footer">
              <div className="items">
                <label
                  className="button button-transparent button-mute inverse link"
                  onClick={() => handleReceiveAllModal()}
                >
                  {I18n.t('purchase_orders.form.cancel')}
                </label>
                <button
                  className="button button-primary"
                  onClick={this.saveReceives}
                >
                  {I18n.t('purchase_orders.form.receive_all')}
                </button>
              </div>
            </div>
          </div>
        </div>
        <label className="modal-backdrop" onClick={() => handleReceiveAllModal()} />
      </div>
    );
  }
}

export default ReceiveAllModal;
