import React from 'react';
import PropTypes from 'prop-types';

export default function TableButton({ onClick, children }) {
  return (
    <button
      className="ct-flex ct-h-3.5 ct-w-fit [&>svg]:ct-w-full [&>svg]:ct-h-3.5 [&>svg>path]:ct-fill-gray-table-button"
      type="button"
      onClick={onClick}
    >
      {children}
    </button>
  );
}

TableButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
};
