import React from "react";
import moment from "moment";
import { PAYMENT_METHODS } from "@/config/constants";

export default function PreviewPaymentRow({ payment, numberFormat, dateFormat }) {
  const { paymentMethod, paymentDate, usageRate, value, purchaseOrderInvoiceId } = payment;
  const { thousandsSeparator, decimalMark } = numberFormat;
  const amountFormat = { delimiter: thousandsSeparator, separator: decimalMark, precision: 2 }

  const rowActions = () => {
    if (purchaseOrderInvoiceId)
      return (
        <div className="ct-text-gray ct-text-sm">
          <i className="icon-lock ct-text-base"></i>
        </div>
      );
  };

  function trBackground() {
    if (purchaseOrderInvoiceId) return "ct-bg-green-light";
    if (paymentMethod === PAYMENT_METHODS.RESIDUAL) return "ct-bg-opacity-50";

    return "ct-bg-white";
  }

  return (
    <tr className={`${trBackground()} ct-text-xs ct-h-6 ct-text-black-1`}>
      <td className="ct-capitalize custom">{I18n.t(`frontend.payment_plan.method.${paymentMethod}`)}</td>
      <td className="ct-text-left custom">{moment(paymentDate).format(dateFormat)}</td>
      <td className="ct-font-bold ct-text-center custom">{I18n.toNumber(usageRate, amountFormat)}%</td>
      <td className="ct-text-right custom">{I18n.toNumber(value, amountFormat)}</td>
      <td className="ct-w-0 custom">{rowActions()}</td>
    </tr>
  );
}
