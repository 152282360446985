import React from 'react';
import PropTypes from 'prop-types';

function CellDiv({
  children, className, hasEllipsis, link, dataEllipsis,
}) {
  const classHasEllipsis = hasEllipsis ? 'has-ellipsis' : '';
  const classLink = link ? 'link' : '';

  const buildDivClassName = ['td', className, classHasEllipsis, classLink].join(' ');

  return <div className={buildDivClassName} data-ellipsis={dataEllipsis}>{children}</div>;
}

CellDiv.propTypes = {
  className: PropTypes.string.isRequired,
  link: PropTypes.bool,
  hasEllipsis: PropTypes.bool,
  dataEllipsis: PropTypes.string,
  children: PropTypes.element,
};

export default CellDiv;
