const VISIBLE_FILTERS_LIMIT = 2;

export const generateFilters = (filters) => {
  const items = [];
  for (const [index, item] of filters.entries()) {
    if (index < VISIBLE_FILTERS_LIMIT) items.push(item);
    else {
      items.push(`+${filters.length - VISIBLE_FILTERS_LIMIT}`);
      break;
    }
  }
  return items.join(', ');
};

export const boxColors = isInputVisible => (isInputVisible
  ? 'ct-bg-black-title ct-text-white'
  : 'ct-bg-gray-filter-background ct-text-black-title');

export const iconClasses = 'ct-w-3.5 ct-h-auto [&>path]:ct-fill-gray-cold';
