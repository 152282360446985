import React, { useState } from 'react';
import { withUUID } from '@/components/shared/utils';
import * as Toast from '@/components/shared/toast';
import { successToast, errorToast } from '@/components/shared/toast';
import FileFormatValidator from '@/components/shared/file_format_validator';
import SupplierInvoicesService from '@/services/SupplierInvoicesService';
import SupplierInvoiceAttachmentModel from '@/models/SupplierInvoiceAttachmentModel';

export default function UploadAttachmentModal({
  isOpen, close, id, addAttachments,
}) {
  const [processing, setProcessing] = useState(false);
  const [filesToImport, setFileToImport] = useState([]);

  const submit = (e) => {
    e.preventDefault();
    if (filesToImport.length === 0) {
      Toast.errorToast(I18n.t('purchase_orders.form.deliveries.missing_file'));
    } else {
      setProcessing(true);
      const formData = new FormData();

      filesToImport.forEach(file => (
        formData.append('supplier_invoice[attachments][]', file)
      ));

      SupplierInvoicesService.upload(id, formData)
        .then((response) => {
          addAttachments(response.attachments.map(attachment => new SupplierInvoiceAttachmentModel(attachment)));
          clearFormState();
          successToast(I18n.t('attachments.messages.added'));
        }).catch((error) => {
          setFileToImport([]);
          setProcessing(false);
          if (error.response.data.attachments) {
            errorToast(error.response.data.attachments[0]);
          } else {
            errorToast(I18n.t('commons.messages.error'));
          }
        });
    }
  };

  const onFileChange = (e) => {
    const { target: { files } } = e;
    const importFiles = Array.from(files);
    const error = importFiles.some((file) => {
      if (file.size > 2000000) {
        errorToast(I18n.t('purchase_orders.form.deliveries.supplier_invoice_to_big_file_size'));
        return true;
      }
      if (file.name.split('.').length > 2) {
        errorToast(I18n.t('purchase_orders.form.deliveries.invalid_file_name'));
        return true;
      }
      if (FileFormatValidator.valid(file, /(\.jpg|\.png|\.pdf)$/i)) {
        errorToast(I18n.t('purchase_orders.form.deliveries.wrong_file_format'));
        return true;
      }
    });
    if (!error) setFileToImport(withUUID(importFiles));

    e.target.files = null;
  };

  const clearFormState = () => {
    setFileToImport([]);
    setProcessing(false);
    close();
  };

  return (
    <React.Fragment>
      <input key={0} autoComplete="off" className="modal-open" type="radio" value="on" checked={isOpen} />
      <div key={1} className="modal sub-modal centered modal-s">
        <form className="modal-wrapper" onSubmit={submit}>
          <div className="modal-window window-inside window">
            <div className="window-header padding-bottom-2 modal-header window-header-auto">
              <div>
                <h1>
                  {I18n.t('supplier_invoices.edit.modal.upload_new')}
                </h1>
                <p className="mute margin-top-0">
                  {I18n.t('supplier_invoices.edit.modal.upload_description')}
                </p>
              </div>
            </div>
            <div className="window-content">
              <div className="grid vertical-start">
                <div className="row">
                  <div className="cell">
                    <p className="mute">
                      <b>{I18n.t('supplier_invoices.edit.modal.choose_file')}</b>
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="cell">
                    <div className="as-input required file-button has-progress">
                      <label className="drop-zone">
                        <i className="icon-upload_file" />
                        <span className="mute">
                          { I18n.translate('supplier_invoices.edit.modal.drag_and_drop') }
                          <br />
                          { I18n.translate('supplier_invoices.edit.modal.or') }
                        </span>
                        <input type="file" onChange={onFileChange} name="file" multiple="multiple" />
                        <div className={`button button-s block button-primary file-name no-shadow${processing ? ' hide' : ''}`}>{I18n.t('supplier_invoices.edit.modal.select_file')}</div>
                      </label>
                      <div className={`progress${processing ? ' animate' : ' hide'}`}>
                        <div style={{ width: '100%' }} />
                      </div>
                      {
                        filesToImport.map(fileToImport => (
                          <div key={fileToImport.uuid} className="row">
                            <span className="state-info">{fileToImport.name || ''}</span>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="window-footer modal-footer">
              <div className="items">
                <label className="button button-transparent button-mute inverse link" onClick={close}>{I18n.t('supplier_invoices.edit.modal.close')}</label>
                <input type="submit" value={I18n.t('supplier_invoices.edit.modal.save')} className={`button button-primary${processing ? ' hide' : ''}`} />
              </div>
            </div>
          </div>
        </form>
        <label className="modal-backdrop" onClick={clearFormState} />
      </div>
    </React.Fragment>
  );
}
