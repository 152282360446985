import Model from './model';

class Errors extends Model {
  constructor({
    account, tax_code, amount, value_cents, supplier_id, doc_number, ...attributes
  }) {
    super(attributes);
    this.invoiceNumber = doc_number;
    this.supplier = supplier_id;
    this.account = account;
    this.vat = tax_code;
    this.amount = amount || value_cents;
  }
}

export default Errors;
