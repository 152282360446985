import React from 'react';
import BannerComponent from './index';
import { BANNER_ICON, BANNER_TYPE } from './constants';

export default {
  component: Banner,
  title: 'Design System/Banner',
  args: {
    children: 'Example banner message. Have a nice day!',
    type: 'INFO',
    icon: 'INFO',
    buttonText: '',
  },
  argTypes: {
    children: {
      control: 'text',
    },
    type: {
      control: 'radio',
      options: Object.keys(BANNER_TYPE),
    },
    icon: {
      control: 'radio',
      options: Object.keys(BANNER_ICON),
    },
    buttonText: {
      control: 'text',
    },
  },
};

export const Banner = args => (
  <BannerComponent {...args} buttonAction={() => window.alert('Clicked!')} /> // eslint-disable-line no-alert
);
