class Model {
  constructor(attributes) {
    Object.keys(attributes).forEach((key) => {
      const newKey = Model.normalize(key);

      if (!this[newKey]) this[newKey] = attributes[key];
    });
  }

  static normalize = value => (
    value.replace(/([-_][a-z0-9])/ig, $1 => $1.toUpperCase()
      .replace('-', '')
      .replace('_', ''))
  )
}

export default Model;
