import React, { useState } from 'react';
import AccountingsService from '../../../services/accountings_service';
import * as toast from '../../shared/toast';
import Error from '../shared/error';

function PurchaseOrderMarkAsInvoicedModal({ purchaseOrders, filter, onToggleMarkAsInvoiced }) {
  const [numberOfPurchaseOrders, setNumbersOfPurchaseOrders] = useState(purchaseOrders.length);
  const [errors, setErrors] = useState([]);
  const [confirmButtonDisabled, setConfirmButtonDisabled] = useState(false);

  const onConfirm = () => {
    setConfirmButtonDisabled(true);
    const purchaseOrdersIds = purchaseOrders.map(purchaseOrder => purchaseOrder.id);
    const params = { purchaseOrdersIds };

    AccountingsService.markPurchaseOrdersAsInvoiced(params).then((response) => {
      if (response.errors.length > 0) {
        setErrors(response.errors);
        setNumbersOfPurchaseOrders(response.count);
      } else {
        onToggleMarkAsInvoiced();
      }
      filter();
      toast.successToast(I18n.t('commons.messages.changes_saved'));
    }).catch(() => {
      toast.errorToast(I18n.t('commons.messages.error'));
    });
  };

  const errorsPresent = errors.length > 0;

  return (
    <div style={{ display: 'block' }} className="modal searching-modal centered modal-s">
      <div className="modal-wrapper">
        <div className="modal-window window-inside window">
          <div className="window-content">
            {errorsPresent ? (
              <>
                <p style={{ marginBottom: '1.5rem' }}>
                  {I18n.t('purchase_orders.mark_as_invoiced_error', { number1: numberOfPurchaseOrders, number2: errors.length })}
                </p>
                {errors.map(error => <Error error={error} key={error.id || error.message} />)}
              </>
            ) : (
              <p>{I18n.t('purchase_orders.mark_as_invoiced_text', { number: numberOfPurchaseOrders })}</p>
            )}
          </div>
          <div className="window-footer modal-footer">
            {errorsPresent ? (
              <div className="button button-danger" onClick={onToggleMarkAsInvoiced}>{I18n.t('commons.actions.ok')}</div>
            ) : (
              <div className="items">
                <label className="button button-transparent button-mute inverse link" onClick={onToggleMarkAsInvoiced}>
                  {I18n.t('purchase_orders.invoices.cancel')}
                </label>
                <button className="button button-danger" onClick={onConfirm} disabled={confirmButtonDisabled}>
                  {I18n.t('purchase_orders.mark_as_invoiced')}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <label className="modal-backdrop" onClick={onToggleMarkAsInvoiced} />
    </div>
  );
}

export default PurchaseOrderMarkAsInvoicedModal;
