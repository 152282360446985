import React, { forwardRef } from 'react';
import SelectBase from '../index';
import { selectBasePropTypes } from '../constants';

function Select(props, ref) {
  return <SelectBase ref={ref} {...props} />;
}

export default forwardRef(Select);

Select.propTypes = { ...selectBasePropTypes };
