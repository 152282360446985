import React from 'react';

function ConfirmationPopup({
  onConfirm, onReject, confirmButtonText,
}) {
  return (
    <div style={{ display: 'block' }} className="modal searching-modal centered modal-m">
      <div className="modal-wrapper">
        <div className="modal-window window">
          <div className="window-content">
            <h2>{I18n.t('settings.integrations.new.connect.start_modal.description_post_line_1')}</h2>
            <p>{I18n.t('settings.integrations.new.connect.start_modal.description_post_line_2')}</p>
          </div>
          <div className="window-footer modal-footer">
            <div className="items">
              <label className="button button-transparent button-mute inverse link" onClick={onReject}>{I18n.t('purchase_orders.form.cancel')}</label>
              <div onClick={onConfirm} className="button button-info">{confirmButtonText}</div>
            </div>
          </div>
        </div>
      </div>
      <label className="modal-backdrop" onClick={onReject} />
    </div>
  );
}

export default ConfirmationPopup;
