import React, { useState } from 'react';
import { DateTime } from 'luxon';
import FilterComponent from './index';
import { storybookOptions } from '../../inputs/selects/constants';
import MultiSelect from '../../inputs/selects/MultiSelect';
import RangeDateField from '../../inputs/dates/RangeDateField';
import { dateFilterFormat } from '../../inputs/dates/constants';

export default {
  component: Filter,
  title: 'Filters/Filter',
  args: {
    title: 'Filtered by',
  },
  argTypes: {
    title: {
      control: 'text',
    },
  },
};

export const Filter = (args) => {
  const [values, setValues] = useState([]);

  return (
    <FilterComponent
      {...args}
      selected={values.map(el => el.label)}
      onRemove={() => window.alert('Removing filter!')} // eslint-disable-line no-alert
      inputElement={(
        <MultiSelect
          noInput
          noPortal
          id="storybook-filter"
          name="storybook-filter"
          options={storybookOptions}
          value={values}
          onChange={setValues}
        />
      )}
    />
  );
};

export const DateFilter = (args) => {
  const [value, setValue] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);

  return (
    <FilterComponent
      {...args}
      selected={[
        DateTime.fromJSDate(value[0].startDate).toLocaleString(
          dateFilterFormat,
        ),
        DateTime.fromJSDate(value[0].endDate).toLocaleString(dateFilterFormat),
      ].join(' - ')}
      onRemove={() => window.alert('Removing filter!')} // eslint-disable-line no-alert
      inputElement={(
        <RangeDateField
          noInput
          id="storybook-date-filter"
          name="storybook-date-filter"
          disableRanges
          value={value}
          onChange={setValue}
        />
      )}
    />
  );
};
