import React from 'react';

class TableHead extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sort: props.order.by,
      sortType: props.order.type || 'asc',
    };
  }

  onSort = (e) => {
    const name = e.target.getAttribute('data-name');
    const { state: { sortType, sort }, props: { onOrder } } = this;
    let newSortType = sortType;
    if (sort === name) {
      newSortType = sortType === 'desc' ? 'asc' : 'desc';
    }

    this.setState({ sort: name, sortType: newSortType }, () => {
      onOrder(this.state);
    });
  }

  _renderSortArrow = (name) => {
    const { sort, sortType } = this.state;

    if (sort !== name) return;

    return (
      <a className={`sort ${sortType}`} />
    );
  }

  render() {
    const { headers } = this.props;

    return (
      <thead>
        <tr>
          <th />
          {
            headers.map(header => (
              <th key={header.name} className={`${header.class || ''}`} data-name={header.name} onClick={this.onSort}>
                {I18n.t(header.translation)}
                {this._renderSortArrow(header.name)}
              </th>
            ))
          }
        </tr>
      </thead>
    );
  }
}

export default TableHead;
