import uuid from 'uuid/v4';

class DepartmentModel {
  constructor(params) {
    this.id = (params && params.id) || '';
    this.name = (params && params.name);
    this.uuid = uuid();

    this.dispValues = [this.name];
  }
}

export default DepartmentModel;
