import React from 'react';
import AttachPurchaseOrderSetup from './attach_purchase_order_setup';
// import ClosePurchaseOrderSetup from './close_purchase_order_setup';

function AutomaticMatching(props) {
  const { onAutomaticMatchingChange, onAutomaticMatchingToggle } = props;
  return (
    <div className="cell-9 padding-right-1 t-cell-16">
      <div className="grid space-2">
        <div className="row">
          <div className="cell-16">
            <h3 className="label">
              {I18n.t('settings.integrations.new.setup.automatic_matching')}
            </h3>
          </div>
        </div>
        <div className="row">
          <div className="cell-16">
            <h4 className="margin-bottom-0">
              {`${I18n.t('settings.integrations.new.setup.attach_to_purchase_order_if')} :`}
            </h4>
          </div>
        </div>
        <AttachPurchaseOrderSetup
          onChange={onAutomaticMatchingChange}
          onToggle={onAutomaticMatchingToggle}
          {...props}
        />
        {/* <div className="row">
          <div className="cell-16 t-cell-16">
            <h4 className="margin-bottom-0">
              {I18n.t('settings.integrations.new.setup.close_purchase_order_if')}
              :
            </h4>
          </div>
        </div>
        <ClosePurchaseOrderSetup
          onChange={props.onAutomaticMatchingChange}
          onToggle={props.onClosePurchaseOrderToggle}
          {...props}
        /> */}
      </div>
    </div>
  );
}

export default AutomaticMatching;
