import React from 'react';
import useModal from '@/hooks/useModal';
import InfoModalComponent from '@/components/modals/InfoModal';

export const InfoModal = (args) => {
  const [isOpen,, handleClose] = useModal();
  return <InfoModalComponent {...args} isOpen={isOpen} handleClose={handleClose} storybook />;
};

export default {
  component: InfoModal,
  title: 'Modals',
  args: {
    heading: 'Info modal heading',
    message: 'Info modal message,',
  },
  argTypes: {
    heading: {
      control: 'text',
    },
    message: {
      control: 'text',
    },
  },
};
