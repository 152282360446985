import React from 'react';

export default function InvoiceOverrideModal({ onConfirm, onReject, invoicesNumber }) {
  return (
    <div className="modal active sub-sub-modal searching-modal centered modal-s">
      <div className="modal-wrapper">
        <div className="modal-window window-inside window">
          <div className="window-content">
            <p>{I18n.t('purchase_orders.invoices.override_invoices_alert', { number: invoicesNumber })}</p>
          </div>
          <div className="padding-top-5 window-footer modal-footer">
            <div className="items">
              <label className="button button-transparent button-mute inverse link" onClick={onReject}>{I18n.t('purchase_orders.invoices.cancel')}</label>
              <div className="button button-danger" onClick={onConfirm}>{I18n.t('purchase_orders.invoices.override')}</div>
            </div>
          </div>
        </div>
      </div>
      <label className="modal-backdrop" onClick={onReject} />
    </div>
  );
}
