import React, { useEffect, useState } from 'react';
import memoize from 'memoize-one';
import FilterRow from '@/components/common/filters/filter_row';
import usePrevious from '@/hooks/use_previous';
import _ from 'lodash';

export default function FilterDropdown({
  actionName, filters, onFilter, onRemoveFilter, onStateFilter, onRemoveStateFilter, currentName, updateCurrentName,
  defaultValue,
}) {
  const [selected, setSelected] = useState(defaultValue ? 1 : 0);
  const previousState = usePrevious({ filters });

  useEffect(() => {
    if (previousState?.filters && !_.isEqual(previousState.filters[actionName], filters[actionName])) setSelected(defaultValue ? 1 : 0);
  }, [filters]);

  const increaseSelected = () => {
    setSelected(currentSelected => currentSelected + 1);
  };

  const decreaseSelected = () => {
    setSelected(currentSelected => currentSelected - 1);
  };

  const isRadioFilter = () => actionName === 'invoice_states';

  const createItemData = memoize((
    items, filterActionName, onFilterCallback, onRemoveFilterCallback,
    onStateFilterCallback, onRemoveStateFilterCallback, stateCurrentName, updateCurrentNameCallback,
    increaseSelectedFunc, decreaseSelectedFunc, filterDefaultValue,
  ) => ({
    items,
    actionName: filterActionName,
    onFilter: onFilterCallback,
    onRemoveFilter: onRemoveFilterCallback,
    onStateFilter: onStateFilterCallback,
    onRemoveStateFilter: onRemoveStateFilterCallback,
    currentName: stateCurrentName,
    updateCurrentName: updateCurrentNameCallback,
    increaseSelected: increaseSelectedFunc,
    decreaseSelected: decreaseSelectedFunc,
    defaultValue: filterDefaultValue,
    itemsCount: items.length,
  }));

  const itemData = createItemData(
    filters[actionName] || [], actionName, onFilter, onRemoveFilter,
    onStateFilter, onRemoveStateFilter, currentName, updateCurrentName,
    increaseSelected, decreaseSelected, defaultValue,
  );

  const getPlaceholder = () => {
    if (selected > 0 && !isRadioFilter()) {
      return `${selected} ${I18n.t('purchase_orders.form.selected')}`;
    }

    if (isRadioFilter()) {
      return 'states';
    }

    return I18n.t(`dropdowns.${actionName}`);
  };

  return (
    itemData && (
      <span
        key={actionName}
        className={`select input-xs filter-select can-unselect ${isRadioFilter() ? '' : 'count-selected'} ${selected <= 0 ? '' : 'selected'}`}
      >
        <input type="checkbox" />
        <div className="select-content">
          {itemData.items.map((item, index) => (
            <FilterRow
              key={`filter_dropdown_${item.id}`}
              data={itemData}
              index={index}
            />
          ))}
          <div
            className="placeholder"
            data-selected={I18n.t('purchase_orders.form.selected')}
            data-placeholder={getPlaceholder()}
          />
        </div>
      </span>
    )
  );
}
