import React from 'react';
import { PropTypes } from 'prop-types';
import TableCell from '@/components/uiElements/tables/regular/TableCell';
import TABLE_ROW_SIZE from './constants';

export default function TableRow({
  children,
  disableHover,
  size = TABLE_ROW_SIZE.SMALL,
  actions,
  ...rest
}) {
  const hover = !disableHover ? 'hover:ct-bg-gray-table-hover' : '';
  const border = !disableHover ? 'ct-border-gray-table-border ct-border-y' : '';

  return (
    <tr
      className={`[&>*]:ct-px-4 ct-w-full ct-bg-white custom [picture]:ct-w-6 ${border} ${size} ${hover}`}
      {...rest}
    >
      {children}
      {actions && (
        <TableCell>
          <div className="ct-flex ct-items-center ct-justify-end ct-gap-3">
            {actions}
          </div>
        </TableCell>
      )}
    </tr>
  );
}

TableRow.propTypes = {
  children: PropTypes.node.isRequired,
  disableHover: PropTypes.bool,
  size: PropTypes.oneOf(Object.values(TABLE_ROW_SIZE)),
  actions: PropTypes.node,
};
