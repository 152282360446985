import React from 'react';
import * as URI from 'urijs';
import FilterDropdown from '@/components/common/filters/filter_dropdown';
import DateFilterInput from './date_filter_input';

class MobileFiltersModal extends React.Component {
  constructor(props) {
    super(props);

    this.selectedInputs = [];
  }

  state = {
    currentName: URI(window.location.href).query(true).state,
  }

  updateCurrentName = (currentName) => {
    this.setState({ currentName });
  }

  pushSelectedPill = (selectedInput) => {
    const { setSelectedInputs } = this.props;
    this.selectedInputs.push(selectedInput);
    setSelectedInputs(this.selectedInputs);
  }

  render() {
    const {
      filterActions, filters, onFilter, onRemoveFilter, onToggleModal, onDateFilter, clearDate, currentFilters, onRemoveStateFilter, onStateFilter,
    } = this.props;
    const { currentName } = this.state;
    const dateFrom = currentFilters.date_from;
    const dateTo = currentFilters.date_to;

    return (
      <div className="has-filter has-popup mobile-filter" style={{ display: 'block' }}>
        <div className="popup" style={{ display: 'block' }}>
          <div className="popup-content">
            <h3>{I18n.t('purchase_orders.filter_by')}</h3>
            <div className="grid space-2 grid-form">
              { filterActions.map(actionName => (
                <div className="row">
                  <div className="cell">
                    <FilterDropdown
                      key={actionName}
                      actionName={actionName}
                      filters={filters}
                      onFilter={onFilter}
                      onRemoveFilter={onRemoveFilter}
                      onStateFilter={onStateFilter}
                      onRemoveStateFilter={onRemoveStateFilter}
                      currentName={currentName}
                      updateCurrentName={this.updateCurrentName}
                    />
                  </div>
                </div>
              )) }
              <div className="row">
                <div className="cell">
                  <DateFilterInput
                    onDateFilter={onDateFilter}
                    onRemoveFilter={onRemoveFilter}
                    clearDate={clearDate}
                    dateFrom={dateFrom}
                    dateTo={dateTo}
                  />
                </div>
              </div>
            </div>
          </div>
          <label className="popup-backdrop" htmlFor="mobile-filters" onClick={onToggleModal} />
        </div>
      </div>
    );
  }
}

export default MobileFiltersModal;
