import React, { useEffect, useRef, useState } from 'react';
import Warning from './warning';

function StandardInputField({
  cellClass, required, labelName, inputName, supplierInvoice, updateSupplierInvoice,
  errors, numberField, placeholder, warning, displayValue,
}) {
  const hasErrorClass = errors[inputName] ? 'has-error' : '';
  const hasWarningClass = warning ? 'has-warning' : '';
  const [active, setActive] = useState(false);
  const inputRef = useRef(null);

  const onChange = (e) => {
    const { target, target: { value } } = e;
    const newSupplierInvoice = supplierInvoice;
    if (numberField && value !== '-') {
      const newValue = Number(value).toFixed(2);
      newSupplierInvoice[inputName] = Number.isNaN(Number(newValue)) ? Number(0).toFixed(2) : newValue;
    } else {
      newSupplierInvoice[inputName] = value;
    }

    const position = target.selectionStart;

    updateSupplierInvoice(newSupplierInvoice, () => {
      target.selectionEnd = position;
    });
  };

  const onBlur = () => { setActive(false); };
  const onFocus = () => { setActive(true); };

  useEffect(() => {
    active && inputRef.current.select();
  }, [active]);

  const { editable } = supplierInvoice;

  return (
    <div className={`${cellClass} ${hasErrorClass} ${hasWarningClass}`}>
      <div className={`as-input reduce ${required ? 'required' : ''}`}>
        <span className="label">{labelName}</span>
        <input
          ref={inputRef}
          name={inputName}
          readOnly={!editable}
          disabled={!editable}
          className="output input-r"
          value={(!active && displayValue) ? displayValue : supplierInvoice[inputName]}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          placeholder={placeholder}
        />
      </div>
      <Warning warning={warning} />
    </div>
  );
}

export default StandardInputField;
