import axios from 'axios';

const csrfToken = () => document.getElementsByName('csrf-token')[0]?.content;

export const makeGetRequest = async (url, signal = null) => {
  const instance = axios.create({
    credentials: 'same-origin',
    headers: {
      'X-CSRF-Token': csrfToken(),
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

  try {
    return await instance.get(url, { signal });
  } catch (err) {
    if (axios.isCancel(err)) {
      throw err;
    } else {
      sessionExpireHandler(err);
      return err;
    }
  }
};

export const makePostRequest = async (url, data) => {
  const instance = axios.create({
    credentials: 'same-origin',
    headers: {
      'X-CSRF-Token': csrfToken(),
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

  try {
    return await instance.post(url, JSON.stringify(data));
  } catch (err) {
    sessionExpireHandler(err);
    return err;
  }
};

export const makePostFileRequest = async (url, data) => {
  const instance = axios.create({
    credentials: 'same-origin',
    headers: {
      'X-CSRF-Token': csrfToken(),
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

  try {
    return await instance.post(url, data);
  } catch (err) {
    sessionExpireHandler(err);
    return err;
  }
};

export const makePutRequest = async (url, data, stringify = true) => {
  const instance = axios.create({
    credentials: 'same-origin',
    headers: {
      'X-CSRF-Token': csrfToken(),
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

  try {
    return await instance.put(url, stringify ? JSON.stringify(data) : data);
  } catch (err) {
    sessionExpireHandler(err);
    return err;
  }
};

export const makeDeleteRequest = async (url) => {
  const instance = axios.create({
    credentials: 'same-origin',
    headers: {
      'X-CSRF-Token': csrfToken(),
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });


  try {
    return await instance.delete(url);
  } catch (err) {
    sessionExpireHandler(err);
    return err;
  }
};

const sessionExpireHandler = (err) => {
  const { response: { status } } = err;
  if (status === 401) {
    window.location.reload();
  } else {
    throw err;
  }
};
