import React from 'react';
import uuid from 'uuid/v4';
import adminsAvatar from '../../../../../assets/images/admins_avatar.png';

function ApproveActions({ approveActions }) {
  return (
    approveActions.map(approveAction => (
      approveAction.type === 'admin' ? (
        <span key={uuid()} className={`${approveAction.state} avatar has-tooltip`} tabIndex="-1">
          {
              approveAction.users?.length > 1 ? (
                <img src={adminsAvatar} />
              ) : (
                <img src={approveAction.users[0].avatar_url} />
              )
            }
          <span className="tooltip center">
            <ul>
              { approveAction.users.map(user => (
                <li key={uuid()}>
                  {user.display_name}
                </li>
              ))}
            </ul>
          </span>
        </span>
      ) : (
        approveAction.users.map(user => (
          <span key={uuid()} className={`${approveAction.state} avatar has-tooltip`} tabIndex="-1">
            <img src={user.avatar_url} />
            <span className="tooltip center">
              <ul>
                <li key={uuid()}>
                  {user.display_name}
                </li>
              </ul>
            </span>
          </span>
        ))
      )
    ))
  );
}

export default ApproveActions;
