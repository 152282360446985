import React from 'react';

export default function AccountingNavs({
  mounted, onToggleTransactionModal, onToggleInvoiceUploadModal,
  mountPurchaseOrders, mountInvoices, mountTransactions, integrationWay, email,
  purchaseOrdersCount, transactionsCount, invoicesCount, invoiceModuleEnabled,
  mountCashFlow, cashFlowEnabled,
}) {
  const isInvoicesMounted = () => (
    mounted === 'invoices'
  );

  const isTransactionsMounted = () => (
    mounted === 'transactions'
  );

  const isPurchaseOrdersMounted = () => (
    mounted === 'purchaseOrders'
  );

  const isCashFlowMounted = () => (
    mounted === 'cash_flow'
  );

  return (
    <div className="tab-navs">
      <div className="tile-header tab-labels tile-header-short">
        <label
          className={isPurchaseOrdersMounted() ? 'active' : ''}
          onClick={mountPurchaseOrders}
        >
          <h2>
            <span>
              {I18n.t('purchase_orders.invoices.purchase_orders')}
              { purchaseOrdersCount
                ? (
                  <div className="count">{purchaseOrdersCount}</div>
                ) : null
              }
            </span>
          </h2>
        </label>
        <label
          className={isInvoicesMounted() ? 'active' : ''}
          onClick={mountInvoices}
        >
          { integrationWay === 'post' || (integrationWay !== 'fetch' && invoiceModuleEnabled)
            ? (
              <h2>
                <span>
                  {I18n.t('purchase_orders.invoices.invoices')}
                </span>
                { invoicesCount
                  ? (
                    <div className="count">{invoicesCount}</div>
                  ) : null
                }
              </h2>
            )
            : null
          }
        </label>
        <label className={isTransactionsMounted() ? 'active' : ''} onClick={mountTransactions}>
          <h2>
            <span>
              {I18n.t('purchase_orders.invoices.accounting_transactions')}
            </span>
            { transactionsCount
              ? (
                <div className="count">{transactionsCount}</div>
              ) : null
              }
          </h2>
        </label>
        { cashFlowEnabled ? (
          <label className={isCashFlowMounted() ? 'active' : ''} onClick={mountCashFlow}>
            <h2>
              <span>
                {I18n.t('purchase_orders.invoices.cash_flow')}
              </span>
            </h2>
          </label>
        ) : null
        }
        { isInvoicesMounted() || isTransactionsMounted()
          ? (
            <div className="tab-addons">
              <div />
              <div className="active">
                <div className="actions items items-divided">
                  { isInvoicesMounted()
                    ? (
                      <>
                        <label className="link" htmlFor="new-transaction-modal" onClick={() => (onToggleInvoiceUploadModal())}>{ I18n.t('purchase_orders.invoices.add_invoice') }</label>
                        <a className="link mute">
                          <i className="icon-attach_email" />
                          {`from: ${email}`}
                        </a>
                      </>
                    )
                    : null
                  }
                  { isTransactionsMounted()
                    ? <label className="link" htmlFor="new-transaction-modal" onClick={() => (onToggleTransactionModal())}>{ I18n.t('purchase_orders.invoices.add_transaction') }</label>
                    : null
                  }
                  {/* <label className="link" htmlFor="upload-transaction-modal">Upload Transactions</label> */}
                </div>
              </div>
            </div>
          ) : null
        }
      </div>
    </div>
  );
}
