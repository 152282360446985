import React, { useState, useEffect } from 'react';
import IntegrationsCompaniesService from '@/services/integrations_companies_service';

export default function CompanySetup({
  externalCompanyId, onChange, setIsLoading, integrationType,
}) {
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    new IntegrationsCompaniesService(integrationType).all().then((fetchedCompanies) => {
      setCompanies(fetchedCompanies);
    }).then(() => {
      if (companies.length === 1) {
        onChange({ target: { name: 'externalCompanyId', value: companies[0].id } });
      }
      setIsLoading(false);
    });
  }, []);

  return (
    <div className="cell-5 offset-1 offset-right-1 t-cell-16 t-offcet-right-0 t-offset-0">
      <div className="grid space-2">
        <div className="row">
          <div className="cell-16 t-cell-16">
            <div className="as-input required">
              <span className="label">{I18n.t('settings.integrations.new.setup.company')}</span>
              <span className="select">
                <input type="checkbox" />
                <div className="select-content">
                  { companies.map(company => (
                    <React.Fragment key={`external-company-${company.id}}`}>
                      <input
                        id={`external_company_${company.id}`}
                        value={company.id}
                        onChange={onChange}
                        name="externalCompanyId"
                        type="radio"
                        checked={company.id === parseInt(externalCompanyId)}
                      />
                      <label htmlFor={`external_company_${company.id}`}>{company.name}</label>
                    </React.Fragment>
                  )) }
                  <div className="placeholder" data-placeholder={I18n.t('settings.integrations.new.setup.select_company')} data-selected={I18n.t('purchase_orders.form.selected')} />
                </div>
              </span>
              <span />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
