import _ from 'lodash';
import PasteItemHeader from './paste_item_header';
import PasteItemRow from './paste_item_row';

const notAllowedMark = '+';

class PasteItems {
  constructor(pasteValue, fieldsMap) {
    this.pasteValue = pasteValue;
    this.fieldsMap = fieldsMap;
    this.headers = [];
    this.rows = [];
  }

  addHeader = (headerRaw) => {
    const newHeader = new PasteItemHeader(headerRaw, this.fieldsMap);
    if (this.headers.find(header => !header.isEmpty() && header.field === newHeader.field)) {
      newHeader.clearField();
    }
    this.headers = this.headers.concat([newHeader]);
  };

  parse = () => {
    const results = this.pasteValue
      .split('\n')
      .map(line => (_.isEmpty(line)
        ? null
        : line.split('\t').map(col => (col[0] === notAllowedMark ? col.replace(notAllowedMark, '') : col))));
    const rows = _.reject(results, _.isEmpty);
    this.headers = [];
    rows[0].forEach(headerRaw => this.addHeader(headerRaw));
    rows.shift();
    const missingHeaders = Object.keys(this.fieldsMap).filter(
      key => this.fieldsMap[key].constraints && !this.headers.find(header => header.field === key),
    );
    missingHeaders.forEach(header => this.addHeader(header));
    const maxRowValuesLength = Math.max(...rows.map(row => row.length));
    const missingHeadersLength = maxRowValuesLength - this.headers.length;
    for (let i = 0; i < missingHeadersLength; i += 1) {
      this.addHeader('');
    }
    this.rows = rows.map(row => new PasteItemRow(row, this.headers));
  };

  getRows = (valid) => {
    const { rows } = this;
    if (valid) {
      return rows.filter(row => row.isValid());
    }
    return rows;
  };

  getHeaders = (selected) => {
    if (selected) {
      return this.getSelectedHeaders();
    }
    return this.headers;
  };

  getSelectedHeaders = () => {
    const { headers } = this;
    return headers.filter(header => !header.isEmpty());
  };

  getUnselectedHeaders = () => {
    const fields = Object.keys(this.fieldsMap);
    return fields
      .filter(field => !this.headers.find(header => header.field === field))
      .map(field => new PasteItemHeader(field, this.fieldsMap));
  };

  remove = (index) => {
    this.rows.splice(index, 1);
  };

  toOrderItems = () => this.getRows(true).map(row => row.toOrderItem());
}

export default PasteItems;
