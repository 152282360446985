import uuid from 'uuid/v4';

export const withLimit = (objects, selectedId) => {
  let limitedObjects = objects.slice(0, 100);
  const selectedInLimitedObject = limitedObjects.find(obj => obj.id === selectedId);
  const selectedObject = objects.find(obj => obj.id === selectedId);
  if (selectedObject && !selectedInLimitedObject) {
    limitedObjects = [...limitedObjects, selectedObject];
  }
  return limitedObjects;
};
export const withMoreLimit = objects => objects.slice(0, 500);

export const withUUID = objects => objects.map((object) => {
  const newObject = object;
  newObject.uuid = uuid();
  return newObject;
});
