import React from 'react';

const StrictBudgetControlModal = ({
  onToggleModal, isModalOpen, type,
}) => {
  const modalClass = isModalOpen ? 'active' : null;
  return (
    <div className={`modal sub-modal centered modal-s ${modalClass}`}>
      <div className="modal-wrapper">
        <div className="modal-window window">
          <div className="window-header modal-header">
            {I18n.t('purchase_orders.form.strict_budget_header')}
          </div>
          <div className="window-content">
            <p className="mute">
              {I18n.t(`purchase_orders.form.strict_${type}_message`)}
            </p>
          </div>
          <div className="window-footer modal-footer">
            <div className="items">
              <button className="button button-primary close-modal" onClick={onToggleModal}>
                {I18n.t('commons.actions.close')}
              </button>
            </div>
          </div>
        </div>
      </div>
      <label htmlFor="" className="modal-backdrop close-modal" onClick={onToggleModal} />
    </div>
  );
};

export default StrictBudgetControlModal;
