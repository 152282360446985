import React, { forwardRef } from 'react';

function TextField(
  {
    name,
    type = 'text',
    withLabel,
    rightContent,
    transparent,
    className,
    onClick,
    ...rest
  },
  ref,
) {
  return (
    <div className="ct-relative ct-w-fit ct-unset">
      {withLabel && <label>Label</label>}

      <input
        name={name}
        type={type}
        ref={ref}
        className={`
          ${rightContent ? 'ct-pr-7' : 'ct-pr-0'} 
          ${transparent ? 'ct-bg-transparent' : ''} 
          ${className || ''}
          ct-py-2 ct-text-sm ct-border-gray ct-border-b ct-h-8 active:ct-border-blue focus-visible:ct-outline-none focus-visible:ct-border-blue custom`}
        {...rest}
      />
      <div
        onClick={onClick}
        className="ct-absolute ct-bottom-[10px] ct-right-2 ct-gray-cold ct-leading-[12px]"
      >
        {rightContent}
      </div>
    </div>
  );
}

export default forwardRef(TextField);
