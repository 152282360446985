export const PAYMENT_METHODS = {
  AUTOMATIC: 'automatic',
  MILESTONE: 'milestone',
  RESIDUAL: 'residual',
};

export const INTEGRATION_TYPES = {
  QUICKBOOKS: 'quickbooks',
  XERO: 'xero',
  SAGE_ACCOUNTING_ZA: 'sage_accounting_za',
  TRIPLETEX: 'tripletex',
  POWER_OFFICE: 'power_office',
  TWENTYFOUR_SEVEN_OFFICE: 'tfso',
  SAGE_BUSINESS_CLOUD_ACCOUNTING: 'sage50',
};
