import Model from './model';

class SupplierModel extends Model {
  constructor({
    id, name, deleted_at, ...attributes
  }) {
    super(attributes);
    this.id = id;
    this.name = name;
    this.deletedAt = deleted_at;
  }
}

export default SupplierModel;
