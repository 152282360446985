import React from 'react';
import PropTypes from 'prop-types';
import Button from '@/components/uiElements/Button';
import { BUTTON_VARIANT } from '@/components/uiElements/Button/constants';
import { useTranslation } from 'react-i18next';
import defaultModalPropTypes from '@/components/modal/constants';
import Modal from '@/components/modal';

const InfoModal = ({
  isOpen, handleClose, heading, message, ...rest
}) => {
  const { t: tActions } = useTranslation('actions');

  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      heading={heading}
      subHeading={(
        <p className="ct-text-xs ct-text-black ct-text-left">
          {message}
        </p>
        )}
      closeButton={(
        <Button onClick={handleClose} variant={BUTTON_VARIANT.SECONDARY}>
          {tActions('close')}
        </Button>
)}
      {...rest}
    />
  );
};

export default InfoModal;

InfoModal.propTypes = {
  ...defaultModalPropTypes,
  heading: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};
