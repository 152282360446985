import * as Api from '../components/shared/api';
import BudgetModel from '../models/budget_model';

class BudgetsService {
  static all = async (searchValue) => {
    const params = new URLSearchParams();
    params.append('search', searchValue);

    const response = await Api.makeGetRequest(`/api/budgets?${params}`);
    return response.data.budgets.map(budget => new BudgetModel(budget));
  }

  static show = id => Api.makeGetRequest(`/budgets/${id}`)
    .then(response => response.data?.budget)

  static importAll = (budgetId, params) => Api.makePostRequest(`/api/import/budgets/${budgetId}/categories`, { categories: params })
    .then(response => response.data)
}

export default BudgetsService;
