import React, { createContext, useContext, useMemo, useState } from "react";

export const EditModalContext = createContext({ currentEdditingPayment: null, setCurrentEdditingPayment: () => null });

export const EditModalProvider = (props) => {
  const [currentEdditingPayment, setCurrentEdditingPayment] = useState();

  const value = useMemo(
    () => ({
      currentEdditingPayment,
      setCurrentEdditingPayment,
    }),
    [currentEdditingPayment]
  );

  return <EditModalContext.Provider value={value}>{props.children}</EditModalContext.Provider>;
};

export const useEditModalContext = () => useContext(EditModalContext);
