import React from 'react';
import CellTd from './cell_td';

function Row({
  rowType = 'tr', children, arrayOfElements, className,
}) {
  const renderElements = () => (
    <>
      {arrayOfElements.map(element => <CellTd>{element}</CellTd>)}
    </>
  );

  const row = {
    tr: (
      <tr className={className}>
        { arrayOfElements?.length > 1 ? renderElements() : children }
      </tr>
    ),
    div: (
      <div className={`tr ${className}`}>
        { arrayOfElements?.length > 1 ? renderElements() : children }
      </div>
    ),
  };

  return (row[rowType]);
}

export default Row;
