import React from 'react';
import FilterDropdown from '@/components/common/filters/filter_dropdown';
import TableHead from '../shared/table_head';
import AttachInvoiceOrderRow from '../invoices/attach_invoice_order_row';
import AccountingsService from '../../../services/accountings_service';
import PurchaseOrderAttachmentsFooter from '../shared/footer';
import PurchaseOrderInfo from './attachments/info';
import Table from '../../shared/table';
import * as toast from '../../shared/toast';
import * as swipe from '../../shared/swipe';
import Loader from '../../loader';
import RefreshPaymentPlan from './refreshPaymentPlan';

class PurchaseOrderAttach extends Table {
  constructor(props) {
    super(props);

    this.order = {};
    this.filters = {};
    this.page = 1;
    this.addedInvoiceIds = [];
    this.removedInvoiceIds = [];
    this.ableToLoadMore = true;
  }

  state = {
    invoices: [],
    currentPage: 1,
    markAsInvoiced: false,
    purchaseOrder: {},
    showOpened: false,
    isLoading: true,
    filters: [],
    refresh: false,
  }

  componentDidMount() {
    const { purchaseOrderId } = this.props;

    const promisePurchaseOrder = AccountingsService.purchaseOrder(purchaseOrderId).then(({ purchaseOrder }) => {
      this.setState({
        purchaseOrder,
      });
    });

    const promiseInvoices = AccountingsService.purchaseOrderInvoices(purchaseOrderId).then(({ invoices, filters }) => {
      this.setState({
        invoices,
        filters,
      });
    });

    Promise.all([promisePurchaseOrder, promiseInvoices]).then(() => { this.onTableLoad(); swipe.triggerSwipeEvent(); });
  }

  componentWillUnmount() {
    this.windowContentElement().removeEventListener('scroll', this.onScroll, false);
  }

  onAttach = (invoiceId) => {
    const filtered = this.removedInvoiceIds.filter(id => (id !== invoiceId));

    if (this.removedInvoiceIds.length === filtered.length) {
      this.addedInvoiceIds.push(invoiceId);
    } else {
      this.removedInvoiceIds = filtered;
    }
  }

  onDisattach = (invoiceId) => {
    const filtered = this.addedInvoiceIds.filter(id => (id !== invoiceId));

    if (this.addedInvoiceIds.length === filtered.length) {
      this.removedInvoiceIds.push(invoiceId);
    } else {
      this.addedInvoiceIds = filtered;
    }
  }

  filterOpened = () => {
    const { showOpened } = this.state;

    this.filter({ showOpened: !showOpened });
  }

  onFilter = ({ name, value }) => {
    const stringValue = value.toString();

    if (this.filters[name]) {
      this.filters[name].push(stringValue);
    } else {
      this.filters[name] = [stringValue];
    }

    this.filter();
  }

  filter = (additionalState = {}, page = 1) => {
    const attributes = {
      custom: Object.keys(this.filters).map(key => ({ name: key, value: this.filters[key] })),
      purchaseOrderId: this.props.purchaseOrderId,
      page,
      order: this.order,
      showOpened: (additionalState.showOpened === undefined) ? this.state.showOpened : additionalState.showOpened,
    };

    this.onTableLoading();

    AccountingsService.purchaseOrderInvoicesFilter(attributes).then(({ nextPageInvoices, filters }) => {
      this.setState({
        invoices: nextPageInvoices,
        filters,
        currentPage: 1,
        ...additionalState,
      });

      const scrollElement = this.windowContentElement();

      scrollElement.scrollTop = 0;
      scrollElement.addEventListener('scroll', this.onScroll, false);
      this.ableToLoadMore = true;
    }).then(() => this.onTableLoad());
  }

  infiniteScrollPaginate = () => {
    this.onTableLoading();

    const { currentPage, invoices } = this.state;
    const nextPage = currentPage + 1;

    const attributes = {
      purchaseOrderId: this.props.purchaseOrderId,
      page: nextPage,
      order: this.order,
      showOpened: this.state.showOpened,
    };

    AccountingsService.purchaseOrderInvoicesFilter(attributes).then(({ nextPageInvoices, filters }) => {
      if (nextPageInvoices.length > 0) {
        this.setState({
          invoices: invoices.concat(nextPageInvoices),
          filters,
          currentPage: nextPage,
          isLoading: false,
        }, () => {
          this.ableToLoadMore = true;
        });
      } else {
        this.setState({
          isLoading: false,
        });
      }
    });
  }

  toggleMarkAsInvoiced = () => {
    this.setState({
      markAsInvoiced: !this.state.markAsInvoiced,
    });
  }

  onSave = () => {
    const {
      state: { markAsInvoiced },
      props: {
        purchaseOrderId, onToggleAttachPoSettings, filter,
      },
      addedInvoiceIds,
      removedInvoiceIds,
    } = this;

    const params = {
      removedInvoiceIds,
      addedInvoiceIds,
      purchaseOrderId,
      markAsInvoiced,
    };

    this.onTableLoading();

    AccountingsService.attachInvoicesToPurchaseOrder(params).then(({ purchase_order, errors }) => {
      this.setState({ purchaseOrder: purchase_order, refresh: true });
      filter();
      onToggleAttachPoSettings();
      errors ? toast.errorToast(errors) : toast.successToast(I18n.t('commons.messages.changes_saved'));
    }).catch(() => {
      toast.errorToast(I18n.t('commons.messages.error'));
    });
  }

  headers = () => (
    [
      { name: 'date', translation: 'purchase_orders.partials.invoices_body.date' },
      { name: 'supplier', translation: 'purchase_orders.supplier' },
      { name: 'po_number', translation: 'purchase_orders.number' },
      { name: 'supplier_invoice', translation: 'purchase_orders.partials.invoices_body.supplier_invoice' },
      { name: 'invoice_number', translation: 'purchase_orders.partials.invoices_body.invoice' },
      { name: 'account', translation: 'purchase_orders.partials.invoices_body.account' },
      { name: 'project', translation: 'purchase_orders.partials.invoices_body.project' },
      { name: 'description', translation: 'purchase_orders.partials.invoices_body.description' },
      { name: 'value', translation: 'purchase_orders.partials.invoices_body.value', class: 'number price' },
      { name: 'actions' },
    ]
  )

  render() {
    const {
      invoices, markAsInvoiced, purchaseOrder, showOpened, isLoading, filters, refresh,
    } = this.state;
    const {
      onToggleAttachPoSettings,
      currency,
      purchaseOrderId,
      recommended,
    } = this.props;
    const currentInvoices = recommended ? invoices.filter(invoice => !invoice.purchaseOrderId) : invoices;
    const dotsNumber = this.headers().length;
    const htmlClasses = isLoading ? ' has-loading' : '';

    return (
      <div className="sub-modal modal active centered modal-table full-width window">
        <div className="modal-wrapper">
          <div className={`modal-window window window-form${htmlClasses}`}>
            <Loader />
            <RefreshPaymentPlan
              refresh={refresh}
              toggleRefresh={() => this.setState({ refresh: !refresh })}
            />
            <div className="window-header modal-header static-height column">
              <PurchaseOrderInfo {...purchaseOrder} />
              <div className="window-bar">
                <div className="filters">
                  <FilterDropdown
                    key="projects"
                    actionName="projects"
                    filters={filters}
                    onFilter={this.onFilter}
                    onRemoveFilter={this.onRemoveFilter}
                  />
                </div>
              </div>
            </div>
            <div className="window-content">
              <div className="table-fluid table-column-9">
                {this._renderInputs(dotsNumber, 'attach')}
                <div className="table-header with-filters">
                  <div className="table-nav">
                    {this._renderLabels(dotsNumber, 'attach')}
                    {this._renderDots(dotsNumber, 'attach')}
                  </div>
                </div>
                <div className="table table-selectable table-sortable">
                  <div className="colgroup">
                    {this._renderLabels(dotsNumber, 'attach')}
                  </div>
                  <TableHead
                    onOrder={this.onOrder}
                    order={this.order}
                    headers={this.headers()}
                  />
                  <div className="tbody">
                    {currentInvoices.map(invoice => (
                      <AttachInvoiceOrderRow
                        purchaseOrderId={purchaseOrderId}
                        invoice={invoice}
                        key={invoice.id}
                        onAttach={this.onAttach}
                        onDisattach={this.onDisattach}
                        currency={currency}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <PurchaseOrderAttachmentsFooter
              onSave={this.onSave}
              onCancel={onToggleAttachPoSettings}
              toggleMarkAsInvoiced={this.toggleMarkAsInvoiced}
              markAsInvoiced={markAsInvoiced}
              filterOpened={this.filterOpened}
              showOpened={showOpened}
              footerText={['show_all_recommended_transactions', 'show_all_opened_transactions']}
              notInvoiced={!['invoiced', 'completed'].includes(purchaseOrder.state)}
            />
          </div>
        </div>
        <label className="modal-backdrop" htmlFor="attach-modal-close" onClick={onToggleAttachPoSettings} />
      </div>
    );
  }
}

export default PurchaseOrderAttach;
