import React, { useEffect } from 'react';
import { usePurchaseOrderContext } from '../../../pages/purchaseOrder/context';

const RefreshPaymentPlan = ({ refresh, toggleRefresh }) => {
  const { paymentPlanStore } = usePurchaseOrderContext();
  const refreshPaymentPlan = paymentPlanStore.refreshRecord;

  useEffect(() => {
    if (!refresh) return;

    refreshPaymentPlan();
    toggleRefresh();
  }, [refresh]);

  return <></>;
};

export default RefreshPaymentPlan;
