import React from 'react';

const Reset = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 12">
    <path
      fill="currentColor"
      d="m.928 11.77-.831-.832 4.572-4.572L.097 1.794l.83-.831L5.5 5.535 10.072.963l.83.831-4.57 4.572 4.57 4.572-.83.831L5.5 7.197.928 11.77Z"
    />
  </svg>
);

export default Reset;
