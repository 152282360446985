import PropTypes from 'prop-types';
import React from 'react';
import { BANNER_ICON, BANNER_TYPE } from './constants';

const Banner = ({
  children, type, icon, buttonText, buttonAction,
}) => (
  <div
    className={`ct-relative ct-flex ct-items-center ct-justify-between ct-px-6 ct-z-10 ${BANNER_TYPE[type]}`}
  >
    <div className="ct-flex ct-items-center ct-gap-4 ct-text-white ct-font-bold ct-text-sm ct-py-5">
      {BANNER_ICON[icon]}
      {children}
    </div>
    {buttonText && buttonAction && (
      <button
        className="ct-text-sm ct-font-medium ct-py-2.5 ct-px-5 ct-text-white ct-border ct-border-white ct-rounded hover:ct-underline"
        onClick={buttonAction}
      >
        {buttonText.toLocaleUpperCase()}
      </button>
    )}
  </div>
);

export default Banner;

Banner.propTypes = {
  children: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.keys(BANNER_TYPE)).isRequired,
  icon: PropTypes.oneOf(Object.keys(BANNER_ICON)).isRequired,
  buttonText: PropTypes.string,
  buttonAction: PropTypes.func,
};
