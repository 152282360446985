import uuid from 'uuid/v4';
import TrackingOptionModel from './tracking_option_model';

class TrackingCategoryModel {
  constructor(params) {
    this.id = (params && params.id) || '';
    this.name = (params && params.name);
    this.uuid = uuid();
    this.dispValues = [this.name];
    this.tracking_options = params.tracking_options.map(tracking_option => (
      new TrackingOptionModel(tracking_option)
    ));
  }
}

export default TrackingCategoryModel;
