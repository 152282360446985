class ArchivePurchaseOrderItemModel {
  constructor(params) {
    this.id = params.id;
    this.companyId = params.company_id;
    this.raised = params.raised;
    this.number = params.number;
    this.poNumber = params.po_number;
    this.description = params.description;
    this.supplierId = params.supplier_id;
    this.supplierName = params.supplier_name;
    this.categoryName = params.category_name;
    this.categoryNumber = params.category_number;
    this.budgetName = params.budget_name;
    this.budgetNumber = params.budget_number;
    this.receivedQuantity = params.received_quantity;
    this.receivedValue = params.received_value;
    this.quantity = params.quantity;
    this.price = params.price;
    this.orderedValue = params.ordered_value;
    this.currency = params.currency;
    this.orderedCurrencyValue = params.ordered_currency_value;
    this.unit = params.unit;
    this.state = params.state;
  }
}

export default ArchivePurchaseOrderItemModel;
