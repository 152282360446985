import React from 'react';

import PasteLink from './paste_link';

export default function PasteItemsLink({ addItemsToPoItemList, children }) {
  const placeholder = 'Item number\tDescription\tQuantity\tUnit Measure\tUnit price\n'
    + '#24516\tRivets 3mm\t1\tkg\t20000\n'
    + '#24517\tRivets 4mm\t10\tkg\t10000\n'
    + '#24565\tRivets 5mm\t2\tkg\t25000\n';

  const fieldsMap = {
    sku: {
      columns: ['item number', 'number', 'sku'],
      label: I18n.t('purchase_orders.form.items.sku'),
    },
    description: {
      columns: ['name', 'description', 'desc', 'title'],
      label: I18n.t('purchase_orders.form.items.description'),
    },
    quantity: {
      columns: ['quantity', 'count'],
      label: I18n.t('purchase_orders.form.items.quantity'),
      constraints: { numericality: true },
    },
    unit: {
      columns: ['unit measure', 'unit', 'measure'],
      label: I18n.t('purchase_orders.form.items.unit'),
    },
    unit_price: {
      columns: ['unit price', 'unit_price', 'price', 'amount', 'value'],
      label: I18n.t('purchase_orders.form.items.unit_price'),
      constraints: { numericality: true },
    },
  };

  return (
    <PasteLink
      type="items"
      placeholder={placeholder}
      fieldsMap={fieldsMap}
      onImport={addItemsToPoItemList}
      afterImport="close"
    >
      { children }
    </PasteLink>
  );
}
