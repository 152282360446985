import { makeAutoObservable, runInAction } from 'mobx';
import * as Api from '@/components/shared/api';
import camelcaseKeys from 'camelcase-keys';
import queryString from 'query-string';
import _ from 'lodash';

export default class CashFlowStore {
  isLoading = false;

  record = {
    budgetEntities: [],
  };

  collection = [];

  total = {
    records: [],
    outOfScope: {},
  };

  possibleFilters = [];

  settings = {
    cutOfDate: null,
    periodType: 'monthly',
  };

  columnNames = [];

  lastParams = {};

  constructor() {
    makeAutoObservable(this);
  }

  fetchRecord(id, params) {
    this.isLoading = true;
    const queryParams = queryString.stringify(this.formatParams(params), {
      arrayFormat: 'bracket',
    });
    this.lastParams = params;

    return Api.makeGetRequest(`/api/cash_flow/${id}?${queryParams}`).then(
      ({ data = {} }) => {
        const convertedData = camelcaseKeys(data, { deep: true });
        const {
          budget,
          possibleFilters,
          settings,
          columnsHeaders = [],
          entities = [],
        } = convertedData;

        runInAction(() => {
          this.record = {
            ...budget,
            entities,
          };
          this.possibleFilters = possibleFilters;
          this.settings = settings;
          this.columnNames = columnsHeaders;

          this.isLoading = false;
        });
      },
    );
  }

  refreshRecord(id) {
    return this.fetchRecord(id, this.lastParams);
  }

  formatParams = params => _.mapKeys(params, (_v, key) => _.snakeCase(key))

  fetchCollection(params) {
    this.isLoading = true;
    const queryParams = queryString.stringify(this.formatParams(params), {
      arrayFormat: 'bracket',
    });
    this.lastParams = params;

    return Api.makeGetRequest(`/api/cash_flow?${queryParams}`).then(
      ({ data = {} }) => {
        const convertedData = camelcaseKeys(data, { deep: true });
        const {
          budgets = [],
          total,
          possibleFilters,
          settings,
          columnsHeaders = [],
        } = convertedData;

        runInAction(() => {
          this.collection = _.sortBy(budgets, 'name');
          this.total = total;
          this.possibleFilters = possibleFilters;
          this.settings = settings;
          this.columnNames = columnsHeaders;

          this.isLoading = false;
        });
      },
    );
  }

  refreshCollection() {
    return this.fetchCollection(this.lastQuery);
  }

  updateRecordByType = (id, type, value) => {
    const params = { record_id: id, record_type: type, payment_date: value };
    return Api.makePostRequest('/api/cash_flow/update_payment_date', params);
  }
}
