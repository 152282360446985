import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import DropdownField from './common/dropdown_field';
import StandardInputField from './common/standard_input_field';
import DateField from './common/date_field';
import TypeModel from '../../models/dropdowns_models/type_model';
import SupplierModel from '../../models/dropdowns_models/supplier_model';
import RateService from '../../services/rate_service';
import SupplierModal from '../purchase_order/supplier_info/supplier_modal';
import DropdownsService from '../../services/dropdowns_service';
import { INTEGRATION_TYPES } from '../../config/constants';

function SideImageSection({
  errors, supplierInvoice, updateSupplierInvoice, companyCurrency, integrationType, enableLocation, standAlone, updateOcrFields,
}) {
  const [currencies, setCurrencies] = useState([]);
  const [trackingCategories, setTrackingCategories] = useState([]);
  const [openCreateSupplierModal, setOpenCreateSupplierModal] = useState(false);
  const [paymentTerms, setPaymentTerms] = useState([]);

  const supplierDropdown = useRef(null);
  const fieldValuesMap = {
    poNumber: 'poNumber',
    supplier: 'id',
  };

  useEffect(() => {
    if (supplierInvoice.processing) return;

    currencies.length === 0 && getCurrencies();
    trackingCategories.length === 0 && getTrackingOptions();
    paymentTerms.length === 0 && getPaymentTerms();
  }, [supplierInvoice?.processing]);

  const updateSupplierInvoiceField = (fieldName, lookedEntity, currentSupplierInvoice = supplierInvoice.callback, callback) => {
    const newSupplierInvoice = currentSupplierInvoice;
    newSupplierInvoice[fieldName] = lookedEntity;

    if ((fieldName === 'poNumber' || fieldName === 'supplier') && !!lookedEntity[fieldValuesMap[fieldName]]) {
      calculateCurrencyValues(lookedEntity?.currency?.id).then((values) => {
        updateOcrFields(newSupplierInvoice, { field: fieldName, value: lookedEntity[fieldValuesMap[fieldName]], currency_values: values });
      });
    } else updateSupplierInvoice(newSupplierInvoice, callback);

    if (fieldName === 'supplier' && integrationType === INTEGRATION_TYPES.SAGE_BUSINESS_CLOUD_ACCOUNTING) {
      onChangeCurrency('currency', { id: lookedEntity.currency?.id });
    }
  };

  const onChangeCurrency = (fieldName, lookedEntity) => {
    if (!lookedEntity.id) return;

    RateService.findBy(companyCurrency, lookedEntity.id).then((value) => {
      const amount = calculateValue(supplierInvoice.currencyAmount, value);
      const newSupplierInvoice = { ...supplierInvoice, exchangeRate: value, amount };
      updateSupplierInvoiceField(fieldName, lookedEntity, newSupplierInvoice);
    });
  };

  const calculateCurrencyValues = async (currency) => {
    if (!currency) return;

    const data = await RateService.findBy(companyCurrency, currency);
    const amount = calculateValue(supplierInvoice.currencyAmount, data);

    return { exchange_rate: data, amount, currency };
  };

  const calculateValue = (currencyAmount, rate = supplierInvoice.exchangeRate) => {
    const newValue = Math.round((currencyAmount / rate) * 100) / 100;

    return Number(newValue).toFixed(2);
  };

  const onCurrencyAmountChange = (newSupplierInvoice, callback = () => { }) => {
    const amount = calculateValue(newSupplierInvoice.currencyAmount);
    updateSupplierInvoiceField('amount', amount, newSupplierInvoice, callback);
  };

  const setNewSupplier = (resources) => {
    const newSupplier = new SupplierModel(resources);
    const newSupplierInvoice = supplierInvoice;
    newSupplierInvoice.supplier = newSupplier;

    updateSupplierInvoice(newSupplierInvoice);
    const { current: { onInputValue } } = supplierDropdown;
    onInputValue({ target: { value: '' } });
  };

  const getCurrencies = () => {
    DropdownsService.getEnt('currency')
      .then((response) => {
        setCurrencies(response.map(ent => ent.id));
      });
  };

  const getPaymentTerms = () => {
    DropdownsService.getEnt('payment_terms')
      .then((response) => {
        setPaymentTerms(response);
      });
  };

  const getTrackingOptions = () => {
    if (integrationType === INTEGRATION_TYPES.QUICKBOOKS) {
      DropdownsService.getEnt('tracking_category', '', integrationType, 'supplierInvoice')
        .then((response) => {
          setTrackingCategories(response);
        });
    }
  };

  const isDebug = () => {
    const { debug } = URI(window.location.href).query(true);
    return debug == '1';
  };

  const types = () => ['expense', 'invoice', 'vendor_credit'].map(type => new TypeModel({ id: type, name: type }));
  const xeroTypes = () => ['invoice', 'vendor_credit'].map(type => new TypeModel({ id: type, name: type }));

  return (
    <div className="window-frame-sidebar">
      <div className={classNames('form-section', 'cell-16', { disabled: supplierInvoice.processing })}>
        <div className="form-section-header">
          <span>
            {I18n.t('supplier_invoices.edit.side_image_section.document')}
          </span>
        </div>
        <SupplierModal
          isOpen={openCreateSupplierModal}
          handleClose={() => setOpenCreateSupplierModal(false)}
          currencies={currencies}
          companyCurrency={companyCurrency}
          handleAdd={setNewSupplier}
          source={integrationType}
          paymentTerms={paymentTerms}
          modalPosition="text-right"
        />
        <div className="form-section-content ct-mt-8">
          <div className="grid space-3 gutter-2">
            <div className="row">
              {
                isDebug()
                  ? (
                    `${JSON.stringify(supplierInvoice.prediction, null, '\n')}`
                  ) : null
              }

              {
                supplierInvoice.type?.id === 'expense' && integrationType === INTEGRATION_TYPES.QUICKBOOKS
                  ? (
                    <DropdownField
                      cellClass="cell-8 mp-cell-16"
                      labelName={I18n.t('supplier_invoices.edit.side_image_section.account')}
                      fieldName="account"
                      placeholder="Select account"
                      type="fetch"
                      entities={[]}
                      updateSupplierInvoice={updateSupplierInvoiceField}
                      supplierInvoice={supplierInvoice}
                      errors={errors}
                      integrationType={integrationType}
                    />
                  )
                  : null
              }
            </div>
            <div className="row">
              <DropdownField
                cellClass="cell-7 m-cell-7 mp-cell-15"
                labelName={I18n.t('supplier_invoices.edit.side_image_section.po_number')}
                fieldName="poNumber"
                placeholder={I18n.t('supplier_invoices.edit.side_image_section.enter_po_number')}
                entities={[]}
                type="fetch"
                updateSupplierInvoice={updateSupplierInvoiceField}
                supplierInvoice={supplierInvoice}
                errors={errors}
                notRequired
                states={['sent_to_supplier', 'received']}
                supplierId={supplierInvoice?.supplier?.id || supplierInvoice?.poNumber?.supplierId}
                warning={supplierInvoice.predictionFor('poNumber')}
              />
              <div className="cell-1 vertical-items-center">
                <div
                  className="has-tooltip max-width-2 h3 display-inline-block right-middle"
                  data-tooltip={I18n.t('supplier_invoices.edit.side_image_section.po_tooltip')}
                >
                  <i className="icon-info" />
                </div>
              </div>
            </div>
            <div className="row">
              <DropdownField
                cellClass="cell-7 offset-right-1 mp-cell-16"
                labelName={I18n.t('supplier_invoices.edit.side_image_section.type')}
                fieldName="type"
                placeholder="Type"
                type="custom"
                entities={integrationType === INTEGRATION_TYPES.QUICKBOOKS ? types() : xeroTypes()}
                updateSupplierInvoice={updateSupplierInvoiceField}
                supplierInvoice={supplierInvoice}
                errors={errors}
                mustBeSelected
              />
              <StandardInputField
                cellClass="cell-8 m-cell-8 mp-cell-16"
                required
                labelName={I18n.t('supplier_invoices.edit.side_image_section.invoice_number')}
                inputName="invoiceNumber"
                supplierInvoice={supplierInvoice}
                placeholder={I18n.t('supplier_invoices.edit.side_image_section.enter_invoice_number')}
                updateSupplierInvoice={updateSupplierInvoice}
                errors={errors}
                warning={supplierInvoice.predictionFor('docNumber')}
              />
            </div>
            <div className="row">
              <DropdownField
                ref={supplierDropdown}
                cellClass="cell-16"
                labelName={I18n.t('supplier_invoices.edit.side_image_section.supplier')}
                fieldName="supplier"
                placeholder="Select supplier"
                entities={[]}
                type="fetch"
                updateSupplierInvoice={updateSupplierInvoiceField}
                supplierInvoice={supplierInvoice}
                addEdit
                withModal
                toogleModal={setOpenCreateSupplierModal}
                errors={errors}
                integrationType={integrationType}
                warning={supplierInvoice.predictionFor('supplierId')}
              />
            </div>
            <div className="row">
              <StandardInputField
                cellClass="cell-6 m-cell-8 mp-cell-16"
                required
                labelName={I18n.t('supplier_invoices.edit.side_image_section.currency_amount')}
                inputName="currencyAmount"
                supplierInvoice={supplierInvoice}
                placeholder={I18n.t('supplier_invoices.edit.side_image_section.enter_currency_amount')}
                updateSupplierInvoice={onCurrencyAmountChange}
                errors={errors}
                warning={supplierInvoice.predictionFor('currencyAmount')}
                numberField
                displayValue={I18n.toNumber(supplierInvoice.currencyAmount, { delimiter: ' ', precision: 2 })}
              />
              <DropdownField
                cellClass="cell-4 m-cell-8 mp-cell-16"
                labelName={I18n.t('supplier_invoices.edit.side_image_section.currency')}
                fieldName="currency"
                placeholder="Select currency"
                type="fetch"
                entities={[]}
                updateSupplierInvoice={onChangeCurrency}
                supplierInvoice={supplierInvoice}
                errors={errors}
                disabled={integrationType === INTEGRATION_TYPES.SAGE_BUSINESS_CLOUD_ACCOUNTING}
                warning={supplierInvoice.predictionFor('currency')}
              />
              <StandardInputField
                cellClass="cell-6 m-cell-8 mp-cell-16"
                required
                labelName={I18n.t('supplier_invoices.edit.side_image_section.amount')}
                inputName="amount"
                placeholder={I18n.t('supplier_invoices.edit.side_image_section.enter_amount')}
                supplierInvoice={supplierInvoice}
                updateSupplierInvoice={updateSupplierInvoice}
                errors={errors}
                numberField
                displayValue={I18n.toNumber(supplierInvoice.amount, { delimiter: ' ', precision: 2 })}
              />
            </div>
            <div className="row">
              <DateField
                cellClass="cell-7 m-cell-10 m-offset-0 m-offset-right-8 mp-cell-16 mp-offset-right-0"
                inputClass="as-input reduce required"
                fieldName="docDate"
                labelName={I18n.t('supplier_invoices.edit.doc_date')}
                setSupplierInvoiceDate={updateSupplierInvoiceField}
                placeholder={I18n.t('supplier_invoices.edit.side_image_section.enter_doc_date')}
                supplierInvoice={supplierInvoice}
                errors={errors}
                warning={supplierInvoice.predictionFor('docDate')}
              />
              <DateField
                cellClass="cell-7 offset-2  m-cell-10 m-offset-0 m-offset-right-8 mp-cell-16 mp-offset-right-0"
                inputClass="as-input reduce required"
                fieldName="dueDate"
                labelName={I18n.t('supplier_invoices.edit.due_date')}
                setSupplierInvoiceDate={updateSupplierInvoiceField}
                placeholder={I18n.t('supplier_invoices.edit.side_image_section.enter_due_date')}
                supplierInvoice={supplierInvoice}
                errors={errors}
                warning={supplierInvoice.predictionFor('dueDate')}
              />
            </div>
            <div className="row">
              <StandardInputField
                cellClass="cell-14 m-cell-16"
                labelName={I18n.t('supplier_invoices.edit.side_image_section.reference')}
                inputName="reference"
                placeholder={I18n.t('supplier_invoices.edit.side_image_section.enter_reference')}
                supplierInvoice={supplierInvoice}
                updateSupplierInvoice={updateSupplierInvoice}
                errors={errors}
              />
            </div>
            {standAlone && (
              <div className="row">
                <div className="cell-14 m-cell-16">
                  <span className="label" htmlFor="paid">{I18n.t('supplier_invoices.edit.side_image_section.paid')}</span>
                </div>
                <div className="cell-2">
                  <label className="radio-switch">
                    <input id="paid" type="checkbox" name="paid" checked={supplierInvoice.paid} onChange={() => { updateSupplierInvoiceField('paid', !supplierInvoice.paid); }} />
                    <span />
                  </label>
                </div>
              </div>
            )}
            {
              integrationType === INTEGRATION_TYPES.QUICKBOOKS && enableLocation
                ? (
                  trackingCategories.map(ent => (
                    <DropdownField
                      key={ent.uuid}
                      cellClass="cell-8 mp-cell-16"
                      labelName={ent.name}
                      fieldName="trackingOptions"
                      placeholder={`Select ${ent.name.toLowerCase()}`}
                      type="post"
                      entities={ent.tracking_options}
                      updateSupplierInvoice={updateSupplierInvoiceField}
                      supplierInvoice={supplierInvoice}
                      errors={errors}
                      notRequired
                    />
                  ))
                )
                : null
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default SideImageSection;
