import React from 'react';

class DepartmentInput extends React.Component {
  constructor(props) {
    super(props);

    const { department } = props;
    this.state = { department };
  }

  onChange = (e) => {
    const { onChangeDepartment } = this.props;
    onChangeDepartment(e);
  }

  render() {
    const { state: { department }, props: { invoiceDepartmentName } } = this;

    return (
      <React.Fragment>
        <input id={`transaction_department_${department.id}`} name="departmentName" type="radio" value={department.name} checked={department.name == invoiceDepartmentName} onChange={this.onChange} />
        <label htmlFor={`transaction_department_${department.id}`}>
          <span>{department.name}</span>
        </label>
      </React.Fragment>
    );
  }
}

export default DepartmentInput;
