import React from 'react';
import IntegrationsService from '../../services/integrations_service';
import * as toast from '../shared/toast';
import { isFetchType } from '../shared/integration_types';

class CredentialsIntegrationConnect extends React.Component {
  state = {
    email: '',
    password: '',
    redirectionUrl: '',
    emailEmpty: false,
    passwordEmpty: false,
    isLoading: false,
  }

  componentDidMount() {
    const { integrationType } = this.props;
    IntegrationsService
      .connectionKeys({ integrationType })
      .then(({ email }) => {
        this.setState({
          email,
        });
      });
  }

  onChange = (e) => {
    const { target } = e;
    const valid = this.validateEmpty(target.value);

    this.setState({
      [target.name]: target.value,
      [`${target.name}Empty`]: valid,
    });
  }

  validateEmpty = (value) => {
    if (value === '' || value === undefined) { return true; }
    return false;
  }

  validateEmptyAllAndSave = (callbakck) => {
    const { state: { email, password } } = this;
    this.setState({
      emailEmpty: this.validateEmpty(email),
      passwordEmpty: this.validateEmpty(password),
    }, callbakck);
  }

  onSubmit = () => {
    this.validateEmptyAllAndSave(() => {
      const {
        props: {
          integrationType, connect, setIntegrationScope,
        },
        state: {
          emailEmpty, passwordEmpty,
        },
      } = this;

      if (!emailEmpty && !passwordEmpty) {
        this.setState({ isLoading: true });
        IntegrationsService.saveCredentialsConnection({
          integrationType,
          ...this.state,
        }).then(() => {
          this.setState({ isLoading: false });
          setIntegrationScope(isFetchType(integrationType) ? 'fetch' : 'post');
          connect();
        }).catch(() => {
          this.setState({ isLoading: false });
          toast.errorToast(
            I18n.t('settings.integrations.new.connect.connect_error'),
          );
        });
      }
    });
  }

  render() {
    const { reconnect, integrationType } = this.props;
    const {
      email,
      password,
      isLoading,
      emailEmpty,
      passwordEmpty,
    } = this.state;

    const buttonMessage = reconnect ? I18n.t('settings.integrations.new.connect.reconnect') : I18n.t('settings.integrations.new.connect.connect');
    const htmlClasses = isLoading ? ' disabled' : '';

    return (
      <React.Fragment>
        <div className="tile-content form-section-content">
          <div className="grid space-2 offset-4 offset-right-4 t-offset-0 t-offset-right-0 padding-top-5">
            <div className="row">
              <div className="cell-6 t-cell-16">
                <h3 className="label">{I18n.t('settings.integrations.new.connect.access_information')}</h3>
              </div>
            </div>
            <div className="row">
              <div className="cell-6 t-cell-16">
                <p className="mute">{I18n.t(`settings.integrations.new.connect.${integrationType}_integration_information`)}</p>
              </div>
            </div>
            <div className="row">
              <div className={`cell-6 t-cell-16 padding-vertical-1 ${emailEmpty ? 'has-error' : ''}`}>
                <div className="as-input required">
                  <span className="label">{I18n.t('settings.integrations.new.connect.email')}</span>
                  <input
                    type="text"
                    name="email"
                    value={email}
                    onChange={this.onChange}
                    placeholder={I18n.t('settings.integrations.new.connect.enter_email')}
                  />
                </div>
                {emailEmpty ? <div className="hint error">{I18n.t('errors.messages.blank')}</div> : null}
              </div>
            </div>
            <div className="row">
              <div className={`cell-6 t-cell-16 padding-vertical-1 ${passwordEmpty ? 'has-error' : ''}`}>
                <div className="as-input required">
                  <span className="label">{I18n.t('settings.integrations.new.connect.password')}</span>
                  <input
                    type="password"
                    name="password"
                    value={password}
                    onChange={this.onChange}
                    placeholder={I18n.t('settings.integrations.new.connect.enter_password')}
                  />
                </div>
                {passwordEmpty ? <div className="hint error">{I18n.t('errors.messages.blank')}</div> : null}
              </div>
            </div>
            <div className="row">
              <div className={`cell-6 t-cell-16 text-center padding-top-5${htmlClasses}`} onClick={this.onSubmit}>
                <a className="button button-primary">{buttonMessage}</a>
              </div>
            </div>
          </div>
        </div>
        <div className="tile-footer" />
      </React.Fragment>
    );
  }
}

export default CredentialsIntegrationConnect;
