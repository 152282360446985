import React from 'react';
import PropTypes from 'prop-types';
import * as api from '../shared/api';
import * as toast from '../shared/toast';

import Textarea from '../shared/textarea';

class Comments extends React.Component {
  static propTypes = {
    body: PropTypes.string,
  }

  state = {
    comment: {
      body: '',
    },
  }

  constructor(props) {
    super(props);

    this.onCommentBodyChange = this.onCommentBodyChange.bind(this);
    this.addComment = this.addComment.bind(this);
    this.handleSubmitPress = this.handleSubmitPress.bind(this);
  }

  onCommentBodyChange(e) {
    this.setState({ comment: { body: e.target.value } });
  }

  addComment(e) {
    const { props: { addActivity }, state: { comment } } = this;
    e.preventDefault();
    api.makePostRequest('comments', { purchase_order_comment: comment })
      .then((response) => {
        addActivity(response.data.comment);
        this.setState({ comment: { body: '' } });
        toast.successToast(I18n.t('comments.messages.added'));
      });
  }

  handleSubmitPress(e) {
    e.key == 'Enter' ? this.addComment(e) : false;
  }

  render() {
    const { comment: { body } } = this.state;
    return (
      <form className="new-comment" onSubmit={this.addComment}>
        <Textarea
          className="auto-height"
          placeholder={I18n.t('purchase_orders.form.add_new_comment')}
          onChange={this.onCommentBodyChange}
          value={body}
          onKeyPress={this.handleSubmitPress}
          name="new_comment"
          extraHeight={2}
        />
        <button className="submit">
          <i className="icon-send" />
        </button>
      </form>
    );
  }
}

export default Comments;
