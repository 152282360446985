import React, { useState } from 'react';
import CheckboxComponent from './index';

export default {
  component: Checkbox,
  title: 'Design System/Buttons/Checkbox',
  args: {
    label: 'Check me!',
    disabled: false,
  },
  argTypes: {
    label: {
      control: 'text',
    },
    disabled: {
      control: 'boolean',
    },
  },
};

export const Checkbox = (args) => {
  const [value, setValue] = useState(false);
  return (
    <CheckboxComponent
      {...args}
      id="storybook-checkbox"
      value={value}
      onChange={() => setValue(!value)}
    />
  );
};
