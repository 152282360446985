import React from 'react';

class IntegrationRefresh extends React.Component {
  render() {
    const {
      linkClass, onRefresh, isProcessing, isIntegrated,
    } = this.props;
    const htmlClasses = isProcessing ? ' processing' : '';

    return (
      <React.Fragment>
        { isIntegrated ? (
          <div className={`control link ${linkClass || ''}`}>
            <button
              onClick={onRefresh}
              className={`button button-info button-icon button-s will-processing ${htmlClasses}`}
            >
              <i className="icon-refresh" />
            </button>
          </div>
        ) : null }

      </React.Fragment>
    );
  }
}

export default IntegrationRefresh;
