import uuid from 'uuid/v4';

class UserModel {
  constructor({
    id, first_name, last_name, avatar_url, display_name,
  }) {
    this.id = id || '';
    this.firstName = first_name;
    this.lastName = last_name;
    this.displayName = display_name;
    this.avatarUrl = avatar_url || '';
    this.uuid = uuid();
    this.selected = false;
  }
}

export default UserModel;
