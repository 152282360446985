import React from 'react';

export default function BulkActions({
  onToggleMarkAsInvoiced, purchaseOrders, onToggleBulkActions,
}) {
  const selectedPurchaseOrders = purchaseOrders.length;

  return (
    <div className="table-bulk" style={{ display: 'flex' }}>
      <div className="actions">
        <div className="items items-divided">
          <span>
            <span className="selected" data-selected="selected">
              {selectedPurchaseOrders}
              {' '}
              {I18n.t('purchase_orders.invoices.selected')}
            </span>
          </span>
          <span>
            <span className="select input-s select-transparent display-inline-block">
              <input type="checkbox" />
              <div className="select-content">
                <label onClick={onToggleMarkAsInvoiced}>{I18n.t('purchase_orders.mark_as_invoiced')}</label>
                <div className="placeholder" data-placeholder={I18n.t('purchase_orders.invoices.bulk_action')} />
              </div>
            </span>
          </span>
        </div>
      </div>
      <label onClick={onToggleBulkActions} className="icon-close" htmlFor="table-bulk" />
    </div>
  );
}
