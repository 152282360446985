import React, { forwardRef, useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import DateFieldBase from '@/components/uiElements/inputs/dates';
import { createStaticRanges, displayValue } from './constants';
import { dateFieldBasePropTypes } from '../constants';

function RangeDateField({ onChange, value, ...rest }, ref) {
  const [visibleYear, setVisibleYear] = useState(
    DateTime.fromJSDate(new Date()).year,
  );

  const [staticRanges, setStaticRanges] = useState(
    createStaticRanges(DateTime.fromJSDate(new Date())),
  );

  const onShownDateChange = (date) => {
    setVisibleYear(DateTime.fromJSDate(date).year);
    setStaticRanges(createStaticRanges(DateTime.fromJSDate(date)));
  };

  return (
    <DateFieldBase
      {...rest}
      ref={ref}
      inputValue={displayValue(value[0])}
      dateComponent={(
        <DateRangePicker
          {...rest}
          headerContent={(
            <span className="rdrStaticRange rdrStaticRangeHeader">
              {visibleYear}
            </span>
          )}
          monthDisplayFormat="MMMM yyyy"
          onChange={item => onChange([item.selection])}
          direction="horizontal"
          months={2}
          onShownDateChange={onShownDateChange}
          ranges={value}
          staticRanges={staticRanges}
        />
      )}
    />
  );
}

export default forwardRef(RangeDateField);

RangeDateField.propTypes = {
  ...dateFieldBasePropTypes,
  value: PropTypes.object,
  onChange: PropTypes.func,
};
