import PropTypes from 'prop-types';
import React from 'react';
import STATUS_VARIANT from './constants';

export default function Status({ variant, text }) {
  return (
    <div
      className={`ct-w-25 ct-text-xs ct-h-5 ct-overflow-hidden ct-select-none ct-font-medium ct-flex ct-items-center ct-justify-center ct-whitespace-nowrap ct-rounded ${variant}`}
    >
      {text}
    </div>
  );
}

Status.propTypes = {
  variant: PropTypes.oneOf(Object.values(STATUS_VARIANT)).isRequired,
  text: PropTypes.string.isRequired,
};
