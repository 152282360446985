import { useEffect } from 'react';

export default function useOutsideClick(ref, callback) {
  useEffect(() => {
    const handleClickOutside = ({ target }) => {
      if (ref.current && !ref.current.contains(target) && (typeof target.className === 'string' && !target.className.includes('select') && !target.className.includes('selectClear') && !target.className.includes(('toast')))) callback();
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}
