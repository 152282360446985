import React from 'react';

function Warning({
  warning,
}) {
  if (warning) {
    return (
      <div className="hint text-right has-tooltip bottom-right">
        <i className="icon-warning" />
        <span>{warning}</span>
      </div>
    );
  }
  return null;
}

export default Warning;
