import * as moment from 'moment';
import * as Api from '../components/shared/api';
import InvoiceModel from '../models/invoice_model';
import DepartmentModel from '../models/department_model';
import SupplierModel from '../models/supplier_model';

class InvoicesService {
  static purchaseOrderInvoices = purchaseOrderId => (
    Api.makeGetRequest(`/api/purchase_orders/${purchaseOrderId}/invoices`)
      .then(({ data: { invoices, possible_filters } }) => ({
        invoices: invoices.map(value => new InvoiceModel(value)),
        possible_filters,
      }))
  )

  static new = () => (
    Api.makeGetRequest('/api/invoices/new')
      .then((response) => {
        const {
          invoice, currencies, suppliers, departments, exchange_rate,
        } = response.data;

        const invoiceModel = new InvoiceModel(invoice);
        invoiceModel.docDate = moment(new Date());
        invoiceModel.dueDate = moment(new Date());

        return {
          invoice: invoiceModel,
          currencies,
          suppliers: suppliers.map(values => new SupplierModel(values)),
          departments: departments.map(value => new DepartmentModel(value)),
          exchangeRate: exchange_rate,
        };
      })
  )

  static show = id => Api.makeGetRequest(`/api/invoices/${id}`)
    .then((response) => {
      const {
        invoice, currencies, suppliers, departments, exchange_rate,
      } = response.data;
      return {
        invoice: new InvoiceModel(invoice),
        currencies,
        suppliers: suppliers.map(values => new SupplierModel(values)),
        departments: departments.map(value => new DepartmentModel(value)),
        exchangeRate: exchange_rate,
      };
    })

  create = params => Api.makePostFileRequest('/api/invoices', params)
    .then(response => ({ invoice: new InvoiceModel(response.data), errors: response.data.errors || null }))
    .catch(errors => errors.response.data);

  update = (params, id) => Api.makePutRequest(`/api/invoices/${id}`, params, false)
    .then(response => ({ invoice: new InvoiceModel(response.data), errors: response.data.errors || null }))
    .catch(errors => errors.response.data);

  unattach = (params, id) => Api.makePutRequest(`/api/invoices/${id}`, params)
    .then(response => ({ invoice: new InvoiceModel(response.data) }))
    .catch(errors => errors.response.data);

  destroy = id => Api.makeDeleteRequest(`/api/invoices/${id}`)
    .then(status => status)
    .catch(errors => errors.response.data);
}

export default InvoicesService;
