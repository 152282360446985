class FilterModel {
  constructor({
    id, name, value, filter_param, filterParam,
  }) {
    this.id = id;
    this.name = name;
    this.value = value;
    this.filterParam = filter_param || filterParam;
  }

  toParams = () => ({
    name: this.filterParam,
    value: this.value,
  })
}

export default FilterModel;
