import React from 'react';
import PropTypes from 'prop-types';
import Lock from '@/icons/Lock';
import Warning from '@/icons/Warning';
import './style.css';

export default function ProgressBar({ value, max, strict = false }) {
  const isWarning = value / max >= 0.95;
  return (
    <div className="ct-flex ct-items-center ct-gap-3.5 ct-h-4">
      <progress
        value={value}
        max={max}
        className={isWarning ? 'progress progress-warning' : 'progress'}
      />
      {strict && (
        <span
          className={`ct-flex ct-items-center ct-justify-center ${
            isWarning ? 'ct-text-red-dark' : 'ct-text-blue-light'
          }`}
        >
          {isWarning ? <Warning className="ct-h-2.5 ct-w-auto" /> : <Lock />}
        </span>
      )}
    </div>
  );
}

ProgressBar.propTypes = {
  value: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  strict: PropTypes.bool,
};
