import React from 'react';
import PropTypes from 'prop-types';
import TOOLTIP_POSITION from './constants';

export default function Tooltip({
  isOpen, text, direction, className,
}) {
  if (!isOpen) return null;

  return (
    <div
      className={`ct-relative ct-min-h-5.5 ct-bg-black-tooltip ct-rounded ct-leading-tight after:ct-block after:ct-w-1.5 after:ct-h-1.5 after:ct-bg-black-tooltip after:ct-absolute after:ct-rotate-45 after:ct-z-[-1] ${className} ${TOOLTIP_POSITION[direction]}`}
    >
      <p className="ct-relative ct-py-1.5 ct-px-2.5 ct-text-ellipsis ct-overflow-hidden ct-text-white ct-text-xxs">
        {text}
      </p>
    </div>
  );
}

Tooltip.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  direction: PropTypes.oneOf(Object.keys(TOOLTIP_POSITION)),
  className: PropTypes.string,
};

Tooltip.defaultProps = {
  direction: 'BOTTOM_RIGHT',
  className: 'ct-w-26',
};
