import React, { useState, useEffect, useRef } from 'react';
import ExportButton from './export_button';

function FilterBar({
  statesFilterOptions, currentStateFilter, setStateFilter,
  suppliersFilterOptions, currentSupplierFilter, setSupplierFilter,
  categoriesFilterOptions, currentCategoryFilter, setCategoryFilter,
  groupsFilterOptions, currentGroupFilter, setGroupFilter, itemsType,
  onSearchQuery,
}) {
  const [searchValue, setSearchValue] = useState('');
  let timer = null;
  const filtersRendered = useRef(false);

  useEffect(() => {
    if (filtersRendered.current) {
      // We are using timer to not flood our servers with requests when user didn't finish typing in search bar
      // 500 ms should be long enough and fast enough at the same time
      timer = setTimeout(() => { onSearchQuery(searchValue); }, 500);
    }
    filtersRendered.current = true;
  }, [searchValue]);

  const onSearch = (e) => {
    if (timer) clearTimeout(timer);

    setSearchValue(e.target.value);
  };

  return (
    <div className="table-actions">
      <div className="filters t-under-hide">
        <span className={`select input-xs filter-select can-unselect ${currentStateFilter.id ? 'selected' : ''}`}>
          <input type="checkbox" />
          <div className="select-content">
            {
              statesFilterOptions.map(state => (
                <label key={state.id} className={`${currentStateFilter.id === state.id ? 'checked' : ''}`} onClick={() => setStateFilter(state)}>{state.name}</label>
              ))
            }
            <div className="placeholder" data-selected={I18n.t('purchase_orders.form.selected')} data-placeholder={I18n.t('dropdowns.states')} />
          </div>
        </span>
        { itemsType == 'supplier_items' && (
        <span className={`select input-xs filter-select can-unselect ${currentSupplierFilter.id ? 'selected' : ''}`}>
          <input type="checkbox" />
          <div className="select-content">
            {
                  suppliersFilterOptions.map(supplier => (
                    <label key={supplier.id} className={`${currentSupplierFilter.id === supplier.id ? 'checked' : ''}`} onClick={() => setSupplierFilter(supplier)}>{supplier.name}</label>
                  ))
                }
            <div className="placeholder" data-selected={I18n.t('purchase_orders.form.selected')} data-placeholder={I18n.t('dropdowns.supplier')} />
          </div>
        </span>
        )
        }
        <span className={`select input-xs filter-select can-unselect ${currentCategoryFilter.id ? 'selected' : ''}`}>
          <input type="checkbox" />
          <div className="select-content">
            {
              categoriesFilterOptions.map(cateogry => (
                <label key={cateogry.id} className={`${currentCategoryFilter.id === cateogry.id ? 'checked' : ''}`} onClick={() => setCategoryFilter(cateogry)}>{cateogry.name}</label>
              ))
            }
            <div className="placeholder" data-selected={I18n.t('purchase_orders.form.selected')} data-placeholder={I18n.t('dropdowns.categories')} />
          </div>
        </span>
        <span className={`select input-xs filter-select can-unselect ${currentGroupFilter.id ? 'selected' : ''}`}>
          <input type="checkbox" />
          <div className="select-content">
            {
              groupsFilterOptions.map(group => (
                <label key={group.id} className={`${currentGroupFilter.id === group.id ? 'checked' : ''}`} onClick={() => setGroupFilter(group)}>{group.name}</label>
              ))
            }
            <div className="placeholder" data-selected={I18n.t('purchase_orders.form.selected')} data-placeholder={I18n.t('dropdowns.groups')} />
          </div>
        </span>
      </div>
      <div className="right-actions">
        <div className="items items-divided">
          <div className="control icon-search input-search left-icon">
            <input
              className="input-s width-2"
              id="items_search"
              placeholder={I18n.t('search')}
              type="text"
              value={searchValue}
              onChange={onSearch}
            />
          </div>
          <ExportButton />
        </div>
      </div>
    </div>
  );
}

export default FilterBar;
