class ProjectModel {
  constructor({
    id, name, number, option_name, transaction_project_id, deleted_at,
  }) {
    this.id = parseInt(id);
    this.name = `${option_name ? `${name} - ${option_name}` : name}`;
    this.number = number;
    this.destroy = 0;
    this.transactionProjectId = transaction_project_id;
    this.deletedAt = deleted_at;
  }
}

export default ProjectModel;
