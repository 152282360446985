import React from 'react';
import NumberFieldComponent from './index';

export default {
  component: NumberField,
  title: 'Form/Inputs/NumberField',
  args: {
    label: 'Amount',
    currency: '',
    error: '',
    percent: false,
    decimal: false,
    required: false,
    disabled: false,
    buttonText: '',
    warning: '',
  },
  argTypes: {
    label: {
      control: 'text',
    },
    currency: {
      control: 'text',
    },
    error: {
      control: 'text',
    },
    percent: {
      control: 'boolean',
    },
    decimal: {
      control: 'boolean',
    },
    required: {
      control: 'boolean',
    },
    disabled: {
      control: 'boolean',
    },
    buttonText: {
      control: 'text',
    },
    warning: {
      control: 'text',
    },
  },
};

export const NumberField = ({ buttonText, ...rest }) => (
  <div style={{ width: '250px' }}>
    <NumberFieldComponent
      name="storybook-number-field"
      button={{ onClick: () => {}, text: buttonText }}
      {...rest}
    />
  </div>
);
