import React, { useState } from 'react';
import RegistrationsService from '../../../services/registrations_service';

const availableLanguages = [{ key: 'Norwegian', value: 'nb' }, { key: 'English', value: 'en' }];

const NewCompany = ({
  company, countries, xeroToken, currencies = [],
}) => {
  const [companyErrors, setCompanyErrors] = useState(null);
  const [newCompany, setNewCompany] = useState(company);
  const [disabled, setDisabled] = useState(false);

  const submitUser = (e) => {
    e.preventDefault();
    setDisabled(true);

    RegistrationsService
      .createCompany({ company: newCompany, xero: xeroToken })
      .then(({ errors, path }) => { errors ? errorResponse(errors) : window.location.replace(path); });
  };

  const errorResponse = (errors) => {
    setCompanyErrors(errors);
    setDisabled(false);
  };

  const updateCompanyDetails = (name, value) => {
    setNewCompany({ ...newCompany, [name]: value });
  };

  const withError = error => (error ? ' has-error' : '');

  return (
    newCompany
      ? (
        <form onSubmit={e => submitUser(e)}>
          <div className="tile-content with-padding">
            <div className="grid space-3 gutter-1 grid-form">
              <div className="row">
                <div className={`cell-14 tp-cell-16 ${withError(companyErrors?.name)}`}>
                  <div className="as-input required">
                    <span className="label">{I18n.t('registrations.xero.new.company_name')}</span>
                    <input name="company_name" id="company-name" required onChange={e => updateCompanyDetails('name', e.target.value)} defaultValue={newCompany.name} placeholder={I18n.t('registrations.xero.new.enter_company_name')} />
                    <div className="hint error">
                      {companyErrors?.name?.join(', ')}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className={`cell-14 tp-cell-16 ${withError(companyErrors?.slug)}`}>
                  <div className="as-input required">
                    <span className="label">{I18n.t('registrations.xero.new.company_slug')}</span>
                    <input name="company_slug" id="company-slug" required onChange={e => updateCompanyDetails('slug', e.target.value)} defaultValue={newCompany.slug} placeholder={I18n.t('registrations.xero.new.enter_company_slug')} />
                    <div className="hint error">
                      {companyErrors?.slug?.join(', ')}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className={`cell-14 tp-cell-16 ${withError(companyErrors?.street)}`}>
                  <div className="as-input required">
                    <span className="label">{I18n.t('registrations.xero.new.company_address')}</span>
                    <input name="company_address" required onChange={e => updateCompanyDetails('street', e.target.value)} defaultValue={newCompany.street} placeholder={I18n.t('registrations.xero.new.enter_company_address')} />
                    <div className="hint error">
                      {companyErrors?.street?.join(', ')}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className={`cell-5 tp-cell-6 m-cell-16 ${withError(companyErrors?.zip_code)}`}>
                  <div className="as-input required">
                    <span className="label">{I18n.t('registrations.xero.new.zip_code')}</span>
                    <input name="zip_code" required onChange={e => updateCompanyDetails('zip_code', e.target.value)} defaultValue={newCompany.zip_code} placeholder={I18n.t('registrations.xero.new.enter_zip_code')} />
                    <div className="hint error">
                      {companyErrors?.street?.join(', ')}
                    </div>
                  </div>
                </div>
                <div className={`cell-9 tp-cell-10 m-cell-16 ${withError(companyErrors?.city)}`}>
                  <div className="as-input required">
                    <span className="label">{I18n.t('registrations.xero.new.city')}</span>
                    <input name="city" required onChange={e => updateCompanyDetails('city', e.target.value)} defaultValue={newCompany.city} placeholder={I18n.t('registrations.xero.new.enter_city')} />
                    <div className="hint error">
                      {companyErrors?.city?.join(', ')}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className={`cell-14 tp-cell-16 ${withError(companyErrors?.country)}`}>
                  <div className="as-input required">
                    <span className="label">{I18n.t('registrations.xero.new.country')}</span>
                    <span className="select input-r add-edit selected" name="country">
                      <input type="checkbox" />
                      <div className="control">
                        <input type="text" tabIndex="-1" onChange={e => updateCompanyDetails('country', e.target.value)} placeholder={I18n.t('registrations.xero.new.country')} />
                      </div>
                      <div className="select-content">
                        {(['Norway'].concat(countries.filter(country => (country != 'Norway')))).map(country => (
                          [
                            <input key={`input-${country}`} id={country} name="country" type="radio" value={country} onChange={e => updateCompanyDetails('country', e.target.value)} />,
                            <label className={`${country === newCompany.country ? 'checked' : ''}`} key={`label-${country}`} htmlFor={country}>
                              <span>{country}</span>
                            </label>,
                          ]
                        ))}
                        <div className="placeholder" data-placeholder={I18n.t('registrations.xero.new.country')} defaultValue={newCompany.country} />
                      </div>
                      {companyErrors?.country ? <div className="hint error">{companyErrors?.country?.join(', ')}</div> : ''}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className={`cell-7 tp-cell-16 ${withError(companyErrors?.language)}`}>
                  <div className="as-input required">
                    <span className="label">{I18n.t('registrations.xero.new.preferred_language')}</span>
                    <span className="select input-r add-edit" name="preferred_language">
                      <input type="checkbox" />
                      <div className="control">
                        <input type="text" tabIndex="-1" onChange={e => updateCompanyDetails('language', e.target.value)} placeholder={I18n.t('registrations.xero.new.preferred_language')} />
                      </div>
                      <div className="select-content">
                        {availableLanguages.map(lang => (
                          [
                            <input key={`input-${lang.value}`} id={lang.value} name="preferred_language" type="radio" value={lang.value} onChange={e => updateCompanyDetails('language', e.target.value)} />,
                            <label className={`${lang.value === newCompany.language ? 'checked' : ''}`} key={`label-${lang.key}`} htmlFor={lang.value}>
                              <span>{lang.key}</span>
                            </label>,
                          ]
                        ))}
                        <div className="placeholder" data-placeholder={I18n.t('registrations.xero.new.preferred_language')} />
                      </div>
                      {companyErrors?.language ? <div className="hint error">{companyErrors?.language?.join(', ')}</div> : ''}
                    </span>
                  </div>
                </div>
                <div className={`cell-7 tp-cell-16 ${withError(companyErrors?.currency)}`}>
                  <div className="as-input required">
                    <span className="label">{I18n.t('registrations.xero.new.currency')}</span>
                    <span className="select input-r add-edit" name="currency">
                      <input type="checkbox" />
                      <div className="control">
                        <input type="text" tabIndex="-1" onChange={e => updateCompanyDetails('currency', e.target.value)} placeholder={I18n.t('registrations.xero.new.currency')} />
                      </div>
                      <div className="select-content">
                        {currencies.map(currency => (
                          [
                            <input key={`input-${currency}`} id={currency} name="currency" type="radio" value={currency} onChange={e => updateCompanyDetails('currency', e.target.value)} />,
                            <label className={`${currency === newCompany.currency ? 'checked' : ''}`} key={`label-${currency}`} htmlFor={currency}>
                              <span>{currency}</span>
                            </label>,
                          ]
                        ))}
                        <div className="placeholder" data-placeholder={I18n.t('registrations.xero.new.currency')} />
                      </div>
                      {companyErrors?.currency ? <div className="hint error">{companyErrors?.currency?.join(', ')}</div> : ''}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="cell with-action">
                  <div className="actions">
                    <button type="submit" disabled={disabled} className="button button-primary button-l">{disabled ? I18n.t('registrations.common.loading') : I18n.t('registrations.common.next')}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      ) : <></>
  );
};

export default NewCompany;
