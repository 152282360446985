import moment from 'moment';

class IntegrationFilteringModel {
  constructor({
    start_date, gl_code, project_ids, department_ids, account_ids, external_company_id,
    only_matched,
  }) {
    this.startDate = (start_date ? moment(start_date) : moment());
    this.glCode = gl_code || '';
    this.departmentIds = department_ids || [];
    this.projectIds = project_ids || [];
    this.accountIds = account_ids || [];
    this.externalCompanyId = external_company_id;
    this.onlyMatched = only_matched;
  }

  manageDepartmentId = (id) => {
    const intId = parseInt(id);
    const isIdExists = this.departmentIds.find(departmentId => departmentId === intId);
    if (!isIdExists) this.departmentIds.push(intId);
    else this.departmentIds = this.departmentIds.filter(departmentId => departmentId !== intId);

    return this;
  }

  manageProjectId = (id) => {
    const intId = parseInt(id);
    const isIdExists = this.projectIds.find(projectId => projectId === intId);
    if (!isIdExists) this.projectIds.push(intId);
    else this.projectIds = this.projectIds.filter(projectId => projectId !== intId);

    return this;
  }

  manageAccountId = (id) => {
    const intId = parseInt(id);
    const isIdExists = this.accountIds.find(accountId => accountId === intId);
    if (!isIdExists) this.accountIds.push(intId);
    else this.accountIds = this.accountIds.filter(accountId => accountId !== intId);

    return this;
  }


  toParams = () => ({
    start_date: this.startDate,
    gl_code: this.glCode,
    department_ids: this.departmentIds,
    project_ids: this.projectIds,
    account_ids: this.accountIds,
    external_company_id: this.externalCompanyId,
    only_matched: this.onlyMatched,
  })
}

export default IntegrationFilteringModel;
