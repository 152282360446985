import React from 'react';

export default function ClosePurchaseOrderSetup({
  onChange, onToggle, closeViaReceivedValueEquality,
  closeViaTotalPurchaseOrderAndInvoiceValue, totalPurchaseOrderAndInvoiceValue,
  totalPurchaseOrderAndInvoicePercentage,
}) {
  return (
    <div className="row">
      <div className="cell-16 checkbox-list">
        <p>
          <label className="has-checkbox">
            <input
              type="checkbox"
              checked={closeViaTotalPurchaseOrderAndInvoiceValue}
              name="closeViaTotalPurchaseOrderAndInvoiceValue"
              onClick={onToggle}
            />
            <span className="checkbox input-s" />
          </label>
          {I18n.t('settings.integrations.new.setup.total_po_value_and_invoice')}
          <span className="control">
            <input
              className="width-07"
              type="text"
              name="totalPurchaseOrderAndInvoiceValue"
              value={totalPurchaseOrderAndInvoiceValue}
              disabled={!closeViaTotalPurchaseOrderAndInvoiceValue}
              onChange={onChange}
            />
          </span>
          {I18n.t('settings.integrations.new.setup.and')}
          <span className="control" data-addon="%">
            <input
              className="width-06"
              type="text"
              name="totalPurchaseOrderAndInvoicePercentage"
              value={totalPurchaseOrderAndInvoicePercentage}
              disabled={!closeViaTotalPurchaseOrderAndInvoiceValue}
              onChange={onChange}
            />
          </span>
          {I18n.t('settings.integrations.new.setup.of_po_value')}
        </p>
        <p>
          <label className="has-checkbox">
            <input
              type="checkbox"
              checked={closeViaReceivedValueEquality}
              name="closeViaReceivedValueEquality"
              onClick={onToggle}
            />
            <span className="checkbox input-s" />
          </label>
          {I18n.t('settings.integrations.new.setup.only_close_if_received_value_equals')}
        </p>
      </div>
    </div>
  );
}
