import React from 'react';
import { timeAgoInWords } from '@/tools/index';
import { INTEGRATION_TYPES } from '../../config/constants';

function Comment({
  comment: {
    user, approvers, body, actionType, externalDocNumber, createdAt, supplierInvoiceNumber,
  }, integrationType,
}) {
  const _renderCommentBody = () => {
    if (actionType === 'transferred' && integrationType === INTEGRATION_TYPES.SAGE_ACCOUNTING_ZA) {
      return I18n.t(
        'supplier_invoices.activities.transferred_with_doc_number',
        { user: `${user.firstName} ${user.lastName}`, number: externalDocNumber },
      );
    }

    return I18n.t(`supplier_invoices.activities.${actionType}`, { user: `${user.firstName} ${user.lastName}`, approvers, number: supplierInvoiceNumber });
  };

  return (
    <div className="comment">
      { user.id === null || user.id === ''
        ? null
        : (
          <div className="avatar">
            <img src={user.avatarUrl} />
          </div>
        )}
      <div className="comment-wrapper">
        <div className="user-name">
          { timeAgoInWords(createdAt) }
        </div>
        <div className="comment-content">
          { user.id === null || user.id === ''
            ? I18n.t(`supplier_invoices.activities.${actionType}`, { email: body })
            : _renderCommentBody()
          }
          { actionType === 'commented'
            && (
              <p>{body}</p>
            )
          }
        </div>
        <div className="comment-timestamp" />
      </div>
    </div>
  );
}

export default Comment;
