import React from 'react';
import useModal from '@/hooks/useModal';
import ModalComponent from '.';

export const Modal = () => {
  const [isOpen, handleOpen, handleClose] = useModal(true);

  return (
    <>
      <button type="button" onClick={handleOpen}>Open modal</button>
      <ModalComponent isOpen={isOpen} handleClose={handleClose} heading="Example">
        <p>Modal content</p>
        <p>Modal content</p>
        <p>Modal content</p>
      </ModalComponent>
    </>
  );
};

export default {
  component: Modal,
  title: 'Modal',
};
